import React from 'react';

import { NavLink } from 'react-router-dom';
import { LoadingComponent } from '@gooddata/sdk-ui';

import * as Component from 'components';

import { WorkspaceType } from 'enums';
import { useLogo, useTypedSelector } from 'hooks';

/**
 * Component containing all main logo related logic
 * @author Konstantin Krumin
 */
interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
	const adminOverviewToggle = useTypedSelector(state => state.manageConsult.toggle);
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const { logoImage, logoReplacementText, isLogoLoading } = useLogo();

	let logoComponent;

	if (isLogoLoading && !logoImage && !logoReplacementText) {
		logoComponent = <LoadingComponent />;
	}

	if (!isLogoLoading && logoImage) {
		logoComponent = (
			<img src={logoImage} alt="Brand Logo" style={{ width: '20rem', maxWidth: '200px' }} />
		);
	}

	if (!isLogoLoading && !logoImage && logoReplacementText) {
		logoComponent = (
			<Component.HolText textType="title" textColor="text-gray-400">
				{logoReplacementText}
			</Component.HolText>
		);
	}

	if (!adminOverviewToggle) {
		return (
			<>
				{currentWorkspace.type !== WorkspaceType.ADVANCED ? (
					<NavLink to="/diagnostics" className="mx-auto">
						{logoComponent}
					</NavLink>
				) : (
					<NavLink to="/" className="mx-auto">
						{logoComponent}
					</NavLink>
				)}
			</>
		);
	} else {
		return <></>;
	}
};
