export enum FactorColors {
	ORANGE = '#EC7B37',
	LIGHT_ORANGE = '#F8BD9D',
	YELLOW = '#F5B94C',
	LIGHT_YELLOW = '#F9DCA7',
	GREEN = '#9ED3BF',
	LIGHT_GREEN = '#CDE8E0',
	BLUE = '#4BAFDA',
	LIGHT_BLUE = '#A5D8EB',
	PURPLE = '#492F8A',
	LIGHT_PURPLE = '#9481B6',
	GREY = '#D3D3D3',
	LIGHT_GREY = '#F8F8F8'
}

export enum DiagnosticColors {
	YELLOW = '#F4BA4E',
	ORANGE = '#E56327',
	PURPLE = '#2D2374',
	GREEN = '#9FD4BF',
	BLUE = '#35A3CF',
	PINK = '#E052B8'
}

export enum NavbarColors {
	YELLOW = '#F4BA4E',
	ORANGE = '#EB7B39',
	PURPLE = '#492E86',
	GREEN = '#9FD4BF',
	BLUE = '#4FB1D8',
	GRAY = '#666'
}

export enum SubmetricBarchartColors {
	PURPLE = '#492F8A',
	YELLOW = '#F5B94C',
	ORANGE = '#EC7B37',
	BLUE = '#4BAFDA'
}

export enum DiagnosticsOptions {
	CHATS = 'Chats',
	EMAILS = 'Emails',
	MEETINGS = 'Meetings',
	ENPS = 'eNPS',
	PULSE = 'Pulse',
	NOISE = 'Noise',
	MOVEMENT = 'Movement',
	CONGESTION = 'Congestion',
	LIGHT = 'Light Quality',
	TEMPERATURE = 'Temperature',
	AIR_QUALITY = 'Air Quality',
	ABSENTEEISM = 'Absenteeism',
	CONNECTIVITY = 'Connectivity',
	TURNOVER = 'Turnover',
	SENTIMENT = 'Sentiment',
	TOXICITY = 'Toxicity'
}

export enum SentimentType {
	NEGATIVE = 'Negative',
	NEUTRAL = 'Neutral',
	POSITIVE = 'Positive'
}

export enum ToxicityType {
	THREAT = 'Threat',
	INSULT = 'Insult',
	TOXICITY = 'Toxicity',
	PROFANITY = 'Profanity',
	SEVERE_TOXICITY = 'Severe Toxicity',
	IDENTITY_ATTACK = 'Identity Attack'
}

export enum ActionPlanDaysDirection {
	DAYS_REMAINING = 'Days Remaining',
	DAYS_UNTIL_START = 'Days Until Start'
}

export enum WorkspaceType {
	ADVANCED,
	LIGHT,
	SENSORS_ONLY
}
