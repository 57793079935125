import { IResultHeader } from '@gooddata/sdk-model';

import * as Util from 'utils';

import { HolmetricsFactorType } from 'types';

export const extractAxisText = (arr: IResultHeader[] | undefined): string[] => {
	return (arr as any[])?.map(item => {
		if (!item?.attributeHeaderItem?.name || item?.attributeHeaderItem?.name === '') {
			return 'All Others';
		}
		return item?.attributeHeaderItem?.name;
	});
};

export const generateHeatmapLegend = (identifier: HolmetricsFactorType) => {
	const legendSettings: { colors: string[]; scales: string[] } = {
		colors: [],
		scales: []
	};

	for (let i = 1; i <= 10; i++) {
		legendSettings.colors.push(Util.getMetricColor(i * 10, identifier));

		legendSettings.scales.push(i.toString());
	}

	return legendSettings;
};
