import React, { useEffect, useRef, useState } from 'react';

import { FaRegUser, FaUser } from 'react-icons/fa';

import * as Util from 'utils';

import { ProfileApi } from 'api';
import { useOnClickOutside } from 'hooks';

/**
 * A tooltip that indicated that the element has been shared with other users
 * @author Konstantin Krumin
 * @param color - color of the icon
 * @param text - additional text to display
 * @param sharedBy - indicates who it was shared by
 * @param sharedDate - indicates when it was shared
 */
interface HolSharedTooltipProps {
	text: string;
	color?: string;
	sharedBy: string;
	sharedDate?: string;
}

export const HolSharedTooltip: React.FC<HolSharedTooltipProps> = ({
	text,
	sharedBy,
	sharedDate = new Date(),
	color = 'text-font-dark'
}) => {
	const [userName, setUserName] = useState<string>();
	const [year, month, date] = new Date(sharedDate).toISOString().substr(0, 10).split('-');

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const node = useRef<HTMLInputElement | null>(null);

	const Api = ProfileApi();

	const showDropDown = () => setDropdownOpen(!dropdownOpen);
	useOnClickOutside(node, () => setDropdownOpen(false));

	useEffect(() => {
		Api.fetchUserName(sharedBy)
			.then(res => setUserName(res))
			.catch(err => Util.logErrorMessage(err));
	}, [sharedBy, setUserName, Api]);

	return (
		<div ref={node} className="flex flex-row select-none z-10">
			<button className="flex relative" onClick={showDropDown}>
				<FaRegUser className={`text-xl left-2.5 my-auto inset-y-1/2 absolute ${color}`} />
				<FaRegUser className="text-2xl my-auto left-0.5 inset-y-1/2 absolute text-white" />
				<FaUser className="text-2xl my-auto left-0 inset-y-1/2 absolute text-white" />
				<FaRegUser className={`text-2xl my-auto left-0 inset-y-1/2 absolute ${color}`} />
			</button>

			<div className="flex ml-10 top-2 absolute">
				<div
					className={`origin-left p-2.5 transition duration-150 ease-in-out transform bg-white rounded-sm shadow-card ${
						dropdownOpen ? 'min-w-44 overflow-hidden scale:100' : ' scale-0'
					}`}
				>
					<p className="text-sm font-sofia-bold mb-1">{text}</p>
					<p className="text-xxs font-sofia-reg">
						Shared by: {userName ?? 'No Info'} on {`${month}/${date}/${year}`}{' '}
					</p>
				</div>
			</div>
		</div>
	);
};
