import React, { useEffect, useState } from 'react';

/**
 * Custom hook which gets the size of an element when window resizes
 * @author Misael Esperanzate
 * @param ref - the reference to the element we are observing
 */

export const useResizeObserver = (ref: React.RefObject<HTMLDivElement>) => {
	const [dimensions, setDimensions] = useState<DOMRectReadOnly>();

	useEffect(() => {
		// observe the current DOM element
		const observeTarget = ref.current;

		// get entries from the element and set dimensions
		const resizeObserver = new ResizeObserver(entries => {
			entries.forEach(entry => {
				setDimensions(entry.contentRect);
			});
		});

		// pointing to the element we want to observe
		resizeObserver.observe(observeTarget!);

		return () => {
			// cleanup function
			resizeObserver.unobserve(observeTarget!);
		};
	}, [ref]);

	return dimensions;
};
