import React from 'react';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { DiagnosticColors, DiagnosticsOptions, ToxicityType } from 'enums';

import { TOXICITY_HEADLINE_METRICS } from './constants';

/**
 * The Toxicity tab on the Diagnostics page
 * @author Konstantin Krumin
 */
export const Toxicity: React.FC = () => {
	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const diagnosticDateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [diagnosticDateFilter.relativeDateFilter, ...demographicFilter];

	return (
		<div className="grid grid-cols-12 gap-6 print:block">
			<Component.HolCard hideHeader columns="col-span-full">
				<header className="flex flex-col md2:flex-row justify-between col-span-full sm:col-span-6 lg2:col-span-4 p-5 print:flex-row">
					<Component.HolText textType="heading1">
						{DiagnosticsOptions.TOXICITY}
					</Component.HolText>

					<div className="flex flex-col items-center">
						<div className="flex space-x-2">
							{TOXICITY_HEADLINE_METRICS.map(headlineMetric => {
								return (
									<Component.DiagnosticHeadlineDisplay
										textType="title"
										valueType="integer"
										filters={filtersArr}
										textColor={headlineMetric.textColor}
										headlineMeasure={headlineMetric.measure}
									/>
								);
							})}
						</div>

						<Component.HolText textType="heading3">Toxic Comments</Component.HolText>
					</div>
				</header>

				<Component.ColumnChart
					chartName={DiagnosticsOptions.TOXICITY}
					columns={[
						{
							name: ToxicityType.TOXICITY,
							color: DiagnosticColors.ORANGE,
							value: Ldm.ToxicityToxicity.Sum
						},
						{
							name: ToxicityType.SEVERE_TOXICITY,
							color: DiagnosticColors.PURPLE,
							value: Ldm.ToxicitySevereToxicity.Sum
						},
						{
							name: ToxicityType.PROFANITY,
							color: DiagnosticColors.BLUE,
							value: Ldm.ToxicityObscene.Sum
						},
						{
							name: ToxicityType.IDENTITY_ATTACK,
							color: DiagnosticColors.YELLOW,
							value: Ldm.ToxicityIdentityAttack.Sum
						},
						{
							name: ToxicityType.THREAT,
							color: DiagnosticColors.GREEN,
							value: Ldm.ToxicityThreat.Sum
						},
						{
							name: ToxicityType.INSULT,
							color: DiagnosticColors.PINK,
							value: Ldm.ToxicityInsult.Sum
						}
					]}
				/>
			</Component.HolCard>

			<Component.ToxicityHeatmapChart />
		</div>
	);
};
