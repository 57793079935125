import { GoodDataSdkError } from '@gooddata/sdk-ui';

import { Action } from '../actions';
import ActionType from '../action-types';

interface ErrorState {
	showError: boolean;
	errorDetails: GoodDataSdkError | undefined;
}

const initialState: ErrorState = {
	showError: false,
	errorDetails: undefined
};

const errorReducer = (state: ErrorState = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_SHOW_ERROR:
			const error = action.payload;
			return {
				...state,
				...error
			};
		default:
			return state;
	}
};

export default errorReducer;
