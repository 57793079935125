import React, { useState } from 'react';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model';
import { useBackend, useCancelablePromise } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { IDiagnosticSelected, ReportDiagnosticMetricType } from 'types';
import { REPORT_DIAGNOSTIC_METRIC_LDM, REPORT_DIAGNOSTIC_OPTIONS } from 'constants/dataset';

/**
 * A dropdown used to set up diagnostics
 * @param selectedDiagnostics - currently selected diagnostics
 * @param setDiagnosticsSelected - a function used to set up diagnostics
 */
interface DiagnosticSelectProps {
	selectedDiagnostics: { [key: string]: IDiagnosticSelected } | null;
	setDiagnosticsSelected: (d: { [key: string]: any } | null) => void;
}

export const DiagnosticSelect: React.FC<DiagnosticSelectProps> = ({
	selectedDiagnostics,
	setDiagnosticsSelected
}) => {
	const [nestedOptions, setNestedOptions] = useState<{
		[x: string]: ReportDiagnosticMetricType[];
	} | null>(null);

	const workspace = useTypedSelector(state => state.user.currentWorkspace);
	const backend = useBackend();

	// Get the available diagnostics in a workspace
	useCancelablePromise(
		{
			promise: async () => {
				const result = await backend
					?.workspace(workspace.id)
					.execution()
					.forItems(
						[Ldm.DiagnosticsType],
						[newPositiveAttributeFilter(Ldm.DiagnosticsStatus, ['true'])]
					)
					.execute();

				const res = await result?.readAll();
				const diagnostics = res?.headerItems[0][0].map(
					(d: any) => d.attributeHeaderItem.name
				);

				const options = Object.fromEntries(
					Object.entries(REPORT_DIAGNOSTIC_OPTIONS).filter(([name, val]) =>
						diagnostics?.includes(name.toLowerCase())
					)
				);

				setNestedOptions(options);
			}
		},
		[workspace]
	);

	const handleSelect = (group: string, subGroup: string) => {
		const tempSelect: { [key: string]: IDiagnosticSelected } = { ...selectedDiagnostics };

		if (!tempSelect[group]) {
			tempSelect[group] = {
				diagnosticSubgroups: [subGroup],
				diagnosticMetrics: [
					REPORT_DIAGNOSTIC_METRIC_LDM[subGroup as ReportDiagnosticMetricType]
				]
			};
		} else {
			tempSelect[group].diagnosticSubgroups.push(subGroup);
			tempSelect[group].diagnosticMetrics.push(
				REPORT_DIAGNOSTIC_METRIC_LDM[subGroup as ReportDiagnosticMetricType]
			);
		}

		setDiagnosticsSelected(tempSelect);
	};

	const handleRemove = (group: string, subGroup: string) => {
		const tempSelect: { [key: string]: IDiagnosticSelected } = { ...selectedDiagnostics };

		const { diagnosticSubgroups, diagnosticMetrics } = tempSelect[group];
		const subGroupIndex = diagnosticSubgroups.indexOf(subGroup);
		const ldmIndex = diagnosticMetrics.indexOf(
			REPORT_DIAGNOSTIC_METRIC_LDM[subGroup as ReportDiagnosticMetricType]
		);

		if (diagnosticSubgroups.length === 1) {
			delete tempSelect[group];
		} else {
			diagnosticSubgroups.splice(subGroupIndex, 1);
			diagnosticMetrics.splice(ldmIndex, 1);
		}

		setDiagnosticsSelected(tempSelect);
	};

	const diagnosticsSelected = () => {
		const selected: [string, string][] = [];

		if (selectedDiagnostics) {
			Object.keys(selectedDiagnostics).forEach(d => {
				const subGroups = selectedDiagnostics[d].diagnosticSubgroups;
				subGroups.forEach(s => {
					selected.push([d, s]);
				});
			});
		}

		return selected;
	};

	return (
		<Component.HolNestedMultiSelectDropdown
			handleNestedOptionSelect={(group: string, subGroup: string) =>
				handleSelect(group, subGroup)
			}
			handleNestedOptionRemove={(group: string, subGroup: string) =>
				handleRemove(group, subGroup)
			}
			nestedOptions={nestedOptions}
			setSelectedOptions={setDiagnosticsSelected}
			defaultMultiSelected={diagnosticsSelected()}
		/>
	);
};
