import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { IInsightDataPoint } from 'types';
import { RELATIVE_DATE_FILTERS } from 'constants/dataset';

import {
	sortArrByCodeVal,
	assignInsightsCustomColor,
	reverseArrForExceptionInsights
} from './helpers';
import { CreateCommentOverlay, NoResponseText, StackedAreaChart } from './components';

import { IQuestionData } from '../../types';

/**
 * Component that handles displaying insight information for each question
 * @author Konstantin Krumin
 */
interface InsightProps {
	questionData: IQuestionData;
	factorName: string;
	totalCount: number;
}

export const Insight: React.FC<InsightProps> = ({ questionData, factorName, totalCount }) => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const dateFilter = RELATIVE_DATE_FILTERS.thisMonth;

	return (
		<article key={questionData.questionId} className="print:block">
			<Execute
				seriesBy={[Ldm.SurveyResponsePercentBW]}
				slicesBy={[Ldm.AnswerText, Ldm.QuestionId, Ldm.AnswerCode, Ldm.PredictiveIndicator]}
				filters={[
					...demographicFilter,
					newPositiveAttributeFilter(Ldm.QuestionId, [questionData.questionId]),
					dateFilter
				]}
			>
				{execution => {
					const { result } = execution;

					const dataArrs = result?.dataView?.headerItems?.[0] ?? [];

					const optionsTextArr = dataArrs?.[0] ?? [];
					const optionsSentimentCode = (dataArrs[2] as any[])?.map(
						item => item?.attributeHeaderItem?.name
					);

					const predictiveIndicatorText = (dataArrs[3] as any[])?.map(
						item => item?.attributeHeaderItem?.name
					)?.[0];

					const series = Util.extractDataSeriesInArr(result);
					const dataPoints = series.map(values => values.rawData().map(Number))[0];

					let sortedResponses: IInsightDataPoint[] = [];

					const responses: IInsightDataPoint[] =
						optionsTextArr.map((option: any, i) => {
							const attrs = option.attributeHeaderItem;
							const name = attrs.name;

							const code: any = optionsSentimentCode?.[i];

							return {
								name,
								code,
								data: [Math.round(dataPoints[i])]
							};
						}) || [];

					let sortedArr = sortArrByCodeVal(responses);

					sortedArr = reverseArrForExceptionInsights(
						sortedArr,
						factorName,
						questionData.questionId
					);

					if (sortedArr) {
						sortedResponses = sortedArr?.map(item => {
							return {
								...item,
								color: assignInsightsCustomColor(
									item.code,
									factorName,
									questionData.questionId
								)
							};
						});
					}

					if (execution.isLoading) {
						return <LoadingComponent className="my-8" />;
					} else {
						return (
							<Component.HolAccordion
								titleContent={
									<div className="flex flex-1 items-center mt-2 mx-3">
										<div className="text-left mr-5 w-4/12">
											<Component.HolText textType="heading3">
												{predictiveIndicatorText
													? predictiveIndicatorText
													: questionData.title}
											</Component.HolText>
										</div>

										<div className="flex justify-end w-7/12">
											{sortedArr.length > 0 ? (
												<div>
													<HighchartsReact
														highcharts={Highcharts}
														options={Util.stackedBarchartConfig(
															sortedResponses,
															250
														)}
													/>
												</div>
											) : (
												<NoResponseText />
											)}
										</div>

										<div
											className="flex justify-end w-1/12"
											onClick={e => e.stopPropagation()}
										>
											<CreateCommentOverlay
												factorName={factorName}
												totalResponse={totalCount}
												responses={sortedResponses}
												questionTitle={questionData.title}
												questionId={questionData.questionId}
											/>
										</div>
									</div>
								}
								bodyContent={
									<div className="flex flex-col space-y-2">
										{predictiveIndicatorText && (
											<div className="mx-9">
												<Component.HolText textType="heading3">
													{questionData.title}
												</Component.HolText>
											</div>
										)}

										<StackedAreaChart
											factorName={factorName}
											questionId={questionData.questionId}
										/>
									</div>
								}
							/>
						);
					}
				}}
			</Execute>
		</article>
	);
};
