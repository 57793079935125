import React from 'react';
import { InsightView } from '@gooddata/sdk-ui-ext';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { DiagnosticColors, DiagnosticsOptions } from 'enums';

/**
 * The Meetings section under Diagnostics that display the results of all the meetings that happen
 * @author Misael Esperanzate & Konstantin Krumin
 */
export const Meetings: React.FC = () => {
	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const dateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [dateFilter.relativeDateFilter, ...demographicFilter];

	return (
		<div className="grid grid-cols-12 gap-6 print:block">
			<Component.HolCard hideHeader columns="col-span-full">
				<div className="flex flex-col md2:flex-row justify-between col-span-full sm:col-span-6 lg2:col-span-4 p-5 print:flex-row">
					<Component.HolText textType="heading1">
						{DiagnosticsOptions.MEETINGS}
					</Component.HolText>

					<div className="flex flex-col md:flex-row md:items-center print:flex-row print:items-center">
						<Component.MainHeadlineDisplay
							title="Total Minutes Meetings"
							filters={filtersArr}
							headlineMeasure={Ldm.EventsDayCount.Sum}
						/>

						<Component.DiagnosticsCard
							tableTitles={{
								firstRow: 'Avg # Meetings/Emp/Day',
								secondRow: 'Outside of Work Hours'
							}}
							filters={filtersArr}
							measuresCollection={{
								A1: {
									measureIndicator: Ldm.MeetingsAVGWeekdays,
									dataType: 'float'
								},
								A2: {
									measureIndicator: Ldm.EventsPercentOWH,
									dataType: 'percentage'
								}
							}}
						/>
					</div>
				</div>

				<Component.ColumnChart
					chartName="Minutes in Meetings"
					columns={[
						{
							name: 'Minutes in Meetings',
							color: DiagnosticColors.BLUE,
							value: Ldm.EventLengthDaySum.Avg
						}
					]}
				/>
			</Component.HolCard>

			<Component.HolCard
				title="Meetings Heatmap"
				styles="print-page-break"
				columns="col-span-full"
			>
				<div className="mx-auto md2:p-8">
					<div className="pl-2 h-full">
						<InsightView
							filters={filtersArr}
							config={Util.gooddataHeatmapConfig([DiagnosticColors.BLUE])}
							insight={Ldm.Insights.EventsHeatmap}
						/>
					</div>
				</div>
			</Component.HolCard>
		</div>
	);
};
