import { KpiValueType } from 'types';

import { SplineChartValueType } from '../types';

export const parseKPIValue = (value: KpiValueType, type: SplineChartValueType) => {
	if (type === 'percentage') {
		return value;
	}

	return value === 'No Data' || value === '-' ? 'No Data' : value * 10;
};
