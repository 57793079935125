import React, { useState } from 'react';
import { InsightView } from '@gooddata/sdk-ui-ext';

import * as Util from 'utils';
import * as Component from 'components';

import { IGeneralOption } from 'types';
import { useTypedSelector } from 'hooks';

import { setHeatmapColor } from './helpers';
import { SENTIMENT_HEATMAP_OPTIONS } from './constants';

/**
 * Displays sentiment heatmap
 */
interface SentimentHeatmapChartProps {}

export const SentimentHeatmapChart: React.FC<SentimentHeatmapChartProps> = () => {
	const [selectedSentimentHeatmap, setSelectedSentimentHeatmap] = useState<IGeneralOption>(
		SENTIMENT_HEATMAP_OPTIONS[0]
	);

	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const diagnosticDateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [diagnosticDateFilter.relativeDateFilter, ...demographicFilter];

	const handleSentimentHeatmapChange = (selectedValue: IGeneralOption | null) => {
		if (selectedValue) setSelectedSentimentHeatmap(selectedValue);
	};

	return (
		<Component.HolCard
			title="Sentiment Heatmap"
			styles="print-page-break"
			columns="col-span-full"
			titleElement={
				<Component.HolSingleSelectDropdown
					textSize="text-md"
					borderRadius="rounded-xl"
					options={SENTIMENT_HEATMAP_OPTIONS}
					selectedOption={selectedSentimentHeatmap}
					handleSelect={handleSentimentHeatmapChange}
				/>
			}
		>
			<div className="mx-auto md2:p-8">
				<div className="px-2 h-full">
					<InsightView
						filters={filtersArr}
						insight={selectedSentimentHeatmap.value as string}
						config={Util.gooddataHeatmapConfig([
							setHeatmapColor(selectedSentimentHeatmap.label)
						])}
					/>
				</div>
			</div>
		</Component.HolCard>
	);
};
