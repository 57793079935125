import React, { ReactNode } from 'react';

import { getInitials } from './helpers';

/**
 * An avatar component displaying user initials
 * @author Michaela Mempin
 * @param textSize - text size
 * @param content - additional content
 * @param size - size of the component
 * @param color - color of the avatar background
 * @param userName - user name that is used to extract initials
 */
interface HolAvatarProps {
	size: number;
	color?: string;
	textSize?: string;
	userName?: string;
	content?: ReactNode;
}

export const HolAvatar: React.FC<HolAvatarProps> = ({
	userName,
	textSize,
	content,
	color,
	size
}) => {
	return (
		<div className="h-full">
			<div
				style={{ width: `${size}px`, height: `${size}px` }}
				className={`
                    ${color ? color : 'bg-users-title'}
					${textSize ? textSize : 'text-lg'}
                    flex text-white rounded-full justify-contents-center`}
			>
				<span className="my-auto mx-auto">
					{userName && getInitials(userName)}
					{content && content}
				</span>
			</div>
		</div>
	);
};
