import { useAxiosPrivate } from 'hooks';

import { DeleteCommentType, UpdateCommentType, CreateCommentType } from './types';

export const InsightsCommentsApi = () => {
	const { serverAxios } = useAxiosPrivate();

	/**
	 * An api that retrieves insights comments
	 */
	const getComments = async (workspaceId: string, currentPage = 1, itemsPerPage = 10) => {
		return await serverAxios
			.get(
				`${process.env.REACT_APP_HOST}/insights/comments/${workspaceId}?page=${currentPage}&limit=${itemsPerPage}`,
				{
					withCredentials: true
				}
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that adds the user comment for the insights charts
	 * @param commentData - comment data
	 */
	const createComment: CreateCommentType = async commentData => {
		return await serverAxios
			.post(`${process.env.REACT_APP_HOST}/insights/comments/create`, commentData, {
				withCredentials: true
			})
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that edits the specified comment
	 * @param userId - current user that is logged in
	 * @param workspaceId - current workspace that the user is in
	 * @param commentInfoUuid - the comment's UUID that the user wants to delete
	 * @param updatedComment - a string with an update of the comment for the history item
	 */
	const updateComment: UpdateCommentType = async (
		workspaceId,
		userId,
		commentInfoUuid,
		updatedComment
	) => {
		return await serverAxios
			.put(
				`${process.env.REACT_APP_HOST}/insights/comments/${workspaceId}/${userId}/${commentInfoUuid}`,
				{ updatedComment }
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that deletes the specified comment
	 * @param userId - current user that is logged in
	 * @param workspaceId - current workspace that the user is in
	 * @param commentInfoUuid - the comment's UUID that the user wants to delete
	 */
	const deleteComment: DeleteCommentType = async (workspaceId, userId, commentInfoUuid) => {
		return await serverAxios
			.delete(
				`${process.env.REACT_APP_HOST}/insights/comments/${workspaceId}/${userId}/${commentInfoUuid}`
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	return {
		getComments,
		createComment,
		deleteComment,
		updateComment
	};
};
