import { IInsightDataPoint } from 'types';
import { INSIGHT_CHART_COLORS } from 'constants/misc';

/**
 * Helper function that sorts a IInsightDataPoint[] array by code value
 * @author Konstantin Krumin
 * @param arr - an array of IInsightDataPoint[] type
 * @returns sorted IInsightDataPoint[] array
 */
export const sortArrByCodeVal = (arr: IInsightDataPoint[]) => {
	return arr?.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
};

/**
 * Helper function that determines whether insight data is to be reversed based on factor name or question id
 * @author Konstantin Krumin
 * @param factorName - selected factor name
 * @param questionId - id of the selected question
 */
const isInsightToBeReversed = (factorName: string, questionId: string) => {
	return (
		factorName === 'Work Engagement' ||
		factorName === 'Burnout' ||
		questionId === 'TM3' ||
		questionId === 'TE1' ||
		questionId === 'TE2'
	);
};

/**
 * Helper function that reverses an insights array for particular factors and questions
 * @author Konstantin Krumin
 * @param arr - an array of IInsightDataPoint[] type
 * @param factorName - selected factor name
 * @param questionId - id of the selected question
 * @returns reversed IInsightDataPoint[] array if the factor or question id matches or leaves array as is
 */
export const reverseArrForExceptionInsights = (
	arr: IInsightDataPoint[],
	factorName: string,
	questionId: string
) => {
	if (isInsightToBeReversed(factorName, questionId)) {
		return arr?.reverse();
	}

	return arr;
};

/**
 * Helper function that assigns correct coloring to the insights based on sentiment code
 * @author Konstantin Krumin
 * @param sentimentCode - a sentiment code provided by GoodData (ex., O9N-A4, O3N-A2)
 * @param factorName - selected factor name
 * @param questionId - id of the selected question
 * @returns corresponding hex color
 */
export const assignInsightsCustomColor = (
	sentimentCode: string,
	factorName: string,
	questionId: string
) => {
	const lastChar = +sentimentCode.slice(-1);
	const chartColors = [...INSIGHT_CHART_COLORS];

	if (isInsightToBeReversed(factorName, questionId)) {
		return chartColors.reverse()[lastChar - 1];
	}

	return chartColors[lastChar - 1];
};
