import { SVGIconProps } from 'types';

export const Users: React.FC<SVGIconProps> = ({ fill = '#666' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill={fill}>
		<g id="Group_1061" data-name="Group 1061" transform="translate(-1490.403 -1490.001)">
			<path
				id="Path_295"
				data-name="Path 295"
				d="M1499.777,1500.548c.2,0,.4,0,.593,0,.55,0,1.1,0,1.65,0a.289.289,0,0,0,.3-.26.3.3,0,0,0-.24-.3.781.781,0,0,0-.174-.015c-.684,0-1.367,0-2.051,0-.14,0-.191-.027-.19-.182q.008-2.787,0-5.576c0-.232.049-.28.283-.28.673,0,1.346,0,2.019,0a.311.311,0,0,0,.346-.3c-.006-.174-.13-.276-.341-.276q-1.041,0-2.083,0a.765.765,0,0,0-.8.812v2.788c0,.95,0,1.9,0,2.852,0,.127-.036.162-.162.161-.566-.006-1.132-.007-1.7,0-.137,0-.169-.041-.168-.172.006-.742,0-1.484,0-2.227a1.9,1.9,0,0,0-1.9-1.905h-2.852a1.894,1.894,0,0,0-1.912,1.909q0,1.923,0,3.845a.928.928,0,0,0,1.142.942c.155-.033.172.008.172.147q-.006,2.507,0,5.015a1.361,1.361,0,0,0,.018.286,1.153,1.153,0,0,0,1.894.632c.091-.078.133-.076.218,0a1.083,1.083,0,0,0,.824.29,1.15,1.15,0,0,0,1.088-1.2q0-2.523,0-5.047c0-.126.016-.163.158-.128a.915.915,0,0,0,1.126-.706,4.133,4.133,0,0,0,.025-.91c0-.155.028-.211.2-.207.545.012,1.09.01,1.634,0,.152,0,.2.033.2.192q-.009,2.82,0,5.64a.775.775,0,0,0,.8.8q1.033,0,2.067,0a.32.32,0,0,0,.356-.287c0-.179-.131-.286-.35-.286h-1.971c-.282,0-.328-.045-.328-.321v-5.5c0-.043,0-.086,0-.128C1499.657,1500.574,1499.695,1500.547,1499.777,1500.548Zm-3.282.836c0,.274-.134.44-.355.442a.392.392,0,0,1-.38-.44q0-1.666,0-3.333c0-.2-.078-.307-.231-.329a.277.277,0,0,0-.33.229.867.867,0,0,0-.013.191q0,4.686,0,9.373a.581.581,0,1,1-1.158.094c-.007-.229,0-.459,0-.688q0-2.275,0-4.551c0-.232-.092-.351-.274-.357a.267.267,0,0,0-.3.248,1.208,1.208,0,0,0,0,.16v5.127a.584.584,0,0,1-.751.592.556.556,0,0,1-.408-.533c-.005-.384,0-.769,0-1.153v-8.284a.909.909,0,0,0-.024-.254.271.271,0,0,0-.309-.193.251.251,0,0,0-.238.239,1.158,1.158,0,0,0,0,.144v3.237a.475.475,0,0,1-.094.345.36.36,0,0,1-.64-.217c-.006-.144,0-.288,0-.433q0-1.722,0-3.444a1.308,1.308,0,0,1,1.342-1.349c.939,0,1.88.012,2.819-.005a1.353,1.353,0,0,1,1.357,1.361C1496.488,1498.863,1496.495,1500.123,1496.495,1501.384Z"
			/>
			<path
				id="Path_296"
				data-name="Path 296"
				d="M1503.521,1496.34h3.717c.5,0,.993,0,1.489,0,.252,0,.361-.114.361-.364,0-.448,0-.9,0-1.345a1.3,1.3,0,0,0-1.078-1.234,10.071,10.071,0,0,0-1.07-.027.4.4,0,0,0-.347.158.588.588,0,0,1-.811.15.935.935,0,0,1-.188-.173.384.384,0,0,0-.311-.135c-.282,0-.565,0-.848,0a1.287,1.287,0,0,0-1.31,1.306c0,.422,0,.844,0,1.265C1503.124,1496.253,1503.21,1496.34,1503.521,1496.34Zm.178-1.69a.694.694,0,0,1,.685-.7c.25-.008.5,0,.753,0a.217.217,0,0,1,.165.079,1.126,1.126,0,0,0,1.6.006.28.28,0,0,1,.212-.086c.229.006.459,0,.688,0a.712.712,0,0,1,.712.716c0,.325,0,.651,0,.977,0,.095-.026.134-.126.13-.176-.006-.353-.005-.529,0-.088,0-.125-.026-.122-.119.006-.144,0-.288,0-.432,0-.215-.113-.347-.291-.345s-.281.133-.282.338c0,.144-.006.288,0,.432.005.1-.035.128-.131.126-.315,0-.63,0-.945,0-.3,0-.6-.005-.9,0-.123,0-.154-.038-.153-.156a1.9,1.9,0,0,0-.019-.526.279.279,0,0,0-.454-.15.264.264,0,0,0-.1.225c0,.154-.006.31,0,.464.006.116-.039.148-.148.142a4.5,4.5,0,0,0-.48,0c-.127.008-.152-.044-.15-.158C1503.7,1495.291,1503.7,1494.971,1503.7,1494.65Z"
			/>
			<path
				id="Path_297"
				data-name="Path 297"
				d="M1508.12,1500.255a5.323,5.323,0,0,0-1.1-.055.535.535,0,0,0-.452.184.589.589,0,0,1-.964-.033.381.381,0,0,0-.3-.148c-.331,0-.661-.008-.992.014a1.3,1.3,0,0,0-1.187,1.295c.012.432,0,.865,0,1.3,0,.267.1.362.368.362h5.236c.254,0,.36-.108.36-.36,0-.443,0-.886,0-1.329A1.292,1.292,0,0,0,1508.12,1500.255Zm.276,2.344c-.181-.005-.363-.005-.545,0-.082,0-.114-.027-.112-.111.005-.149,0-.3,0-.448,0-.213-.123-.343-.3-.337a.3.3,0,0,0-.27.335c0,.139-.007.278,0,.417.007.111-.029.148-.143.145-.309-.007-.619,0-.929,0-.293,0-.587-.006-.881,0-.128,0-.185-.027-.172-.166a3.214,3.214,0,0,0,0-.368c-.005-.226-.121-.366-.3-.363s-.274.143-.275.362c0,.138,0,.277,0,.416,0,.093-.033.121-.122.118-.176-.005-.352-.006-.528,0-.1,0-.128-.036-.126-.13,0-.31.014-.62,0-.929a.72.72,0,0,1,.745-.763c.224-.006.449,0,.673,0a.283.283,0,0,1,.209.1,1.121,1.121,0,0,0,1.572-.007.277.277,0,0,1,.225-.092,6.494,6.494,0,0,1,.814.025.7.7,0,0,1,.578.656c.009.342,0,.683.005,1.025C1508.517,1502.571,1508.486,1502.6,1508.4,1502.6Z"
			/>
			<path
				id="Path_298"
				data-name="Path 298"
				d="M1507.792,1507.05c-.267,0-.534.008-.8,0a.515.515,0,0,0-.435.18.588.588,0,0,1-.952-.04.338.338,0,0,0-.288-.139c-.3,0-.6,0-.9,0a1.267,1.267,0,0,0-1.295,1.3c0,.427,0,.854,0,1.282,0,.28.091.371.373.371h5.256a.294.294,0,0,0,.335-.334q0-.657,0-1.314A1.283,1.283,0,0,0,1507.792,1507.05Zm.723,2.262c0,.078-.018.119-.106.117q-.281-.008-.561,0c-.086,0-.11-.035-.108-.115,0-.155,0-.31,0-.465a.287.287,0,1,0-.573,0c0,.144-.006.289,0,.432.006.11-.028.15-.142.147-.31-.007-.62,0-.929,0s-.6,0-.9,0c-.116,0-.166-.026-.157-.151a3.8,3.8,0,0,0,0-.4c-.007-.21-.132-.349-.3-.343s-.27.142-.271.349c0,.144,0,.289,0,.433,0,.084-.031.112-.112.111-.182,0-.363,0-.545,0-.077,0-.119-.018-.118-.106,0-.363-.005-.727.007-1.089a.678.678,0,0,1,.608-.6c.277-.011.555,0,.833-.005a.2.2,0,0,1,.141.06,1.13,1.13,0,0,0,1.624,0,.215.215,0,0,1,.158-.06c.256,0,.512,0,.769,0a.687.687,0,0,1,.674.661C1508.521,1508.629,1508.512,1508.97,1508.515,1509.312Z"
			/>
			<path
				id="Path_299"
				data-name="Path 299"
				d="M1493.739,1495.419a1.812,1.812,0,1,0-.015-3.624,1.812,1.812,0,1,0,.015,3.624Zm-.007-3.049a1.237,1.237,0,1,1-1.242,1.241A1.237,1.237,0,0,1,1493.732,1492.37Z"
			/>
			<path
				id="Path_300"
				data-name="Path 300"
				d="M1504.471,1498.468a1.615,1.615,0,0,0,1.636,1.633,1.638,1.638,0,0,0,1.634-1.643,1.635,1.635,0,0,0-3.27.01Zm2.7.013a1.059,1.059,0,0,1-1.068,1.045,1.032,1.032,0,0,1-1.053-1.051,1.055,1.055,0,0,1,1.059-1.069A1.072,1.072,0,0,1,1507.167,1498.481Z"
			/>
			<path
				id="Path_301"
				data-name="Path 301"
				d="M1506.091,1493.271a1.635,1.635,0,1,0-1.62-1.637A1.627,1.627,0,0,0,1506.091,1493.271Zm.007-2.694a1.06,1.06,0,1,1,.01,2.119,1.06,1.06,0,0,1-.01-2.119Z"
			/>
			<path
				id="Path_302"
				data-name="Path 302"
				d="M1506.086,1506.942a1.636,1.636,0,1,0-1.615-1.64A1.616,1.616,0,0,0,1506.086,1506.942Zm.024-2.706a1.069,1.069,0,0,1,0,2.137,1.069,1.069,0,0,1,0-2.137Z"
			/>
		</g>
	</svg>
);
