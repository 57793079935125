import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { IAttribute, IRelativeDateFilter } from '@gooddata/sdk-model';

import * as Util from 'utils';
import * as Component from 'components';

import { KpiValueType, IDataInfo } from 'types';

import { isKpiValueNotEmpty } from './helpers';

/**
 * Component for displaying kpi values for admin view
 * @author Misael Esperanzate
 * @param slicesBy - slicing set up for the specified metric
 * @param styles - any extra styling to the wrapper component
 * @param dataInfo - meta information on the metric to display
 * @param dateFilter - date filter set up for the requested dataset
 * @param workspace - specific workspace to be used in execute component
 * @param isPercentageValue - determines whether it's a percentage value and should be processed
 */
interface AdminScoreDisplayProps {
	styles?: string;
	workspace: string;
	slicesBy: IAttribute[];
	dataInfo: IDataInfo;
	dateFilter: IRelativeDateFilter | null;
	isPercentageValue?: boolean;
}

export const AdminScoreDisplay: React.FC<AdminScoreDisplayProps> = ({
	styles,
	slicesBy,
	dataInfo,
	workspace,
	dateFilter,
	isPercentageValue = false
}) => {
	return (
		<Execute
			workspace={workspace}
			seriesBy={[dataInfo.value]}
			slicesBy={slicesBy}
			filters={[dateFilter]}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;
				let kpiValue: KpiValueType = '-';

				if (typeof result !== 'undefined') {
					kpiValue = isPercentageValue
						? Util.createReadablePercentageValue(result, '-')
						: Util.getLatestDataPointVal(result, '-');
				}

				if (execution.isLoading) {
					return <LoadingComponent className="my-4" />;
				} else {
					return (
						<div className={styles ? styles : ''}>
							<div className="flex justify-between px-8">
								<Component.HolText styles="font-sofia-regular text-lg">
									{dataInfo.title}
								</Component.HolText>

								<Component.HolText textType="heading1">
									{kpiValue}
									{isKpiValueNotEmpty(kpiValue) && dataInfo.afterText}
									{isKpiValueNotEmpty(kpiValue) && isPercentageValue && '%'}
								</Component.HolText>
							</div>
						</div>
					);
				}
			}}
		</Execute>
	);
};
