import React from 'react';
import { LoadingComponent } from '@gooddata/sdk-ui';

import * as Component from 'components';

import { useWorkspaceDemographics } from 'hooks';
import { DEMOGRAPHIC_LDM } from 'constants/dataset';
import { DemographicType, IDemographicFilter } from 'types';

/**
 * A dropdown used to set up demographic filter
 * @param selectedDemographics - currently selected demographics
 * @param setDemographicSelected - a function used to set up demographics
 */
interface DemographicSelectProps {
	selectedDemographics: { [key: string]: IDemographicFilter } | null;
	setDemographicSelected: (d: { [key: string]: IDemographicFilter } | null) => void;
}

export const DemographicSelect: React.FC<DemographicSelectProps> = ({
	selectedDemographics,
	setDemographicSelected
}) => {
	const { demographicGroups, nestedDemographicGroups, isLoading } = useWorkspaceDemographics();

	const handleSelect = (group: string, subGroup: string) => {
		const tempSelect: { [key: string]: IDemographicFilter } = { ...selectedDemographics };

		if (!tempSelect[group]) {
			const demoGroupLdm = DEMOGRAPHIC_LDM[group as DemographicType];

			tempSelect[group] = {
				demographicLdm: demoGroupLdm,
				demographicSubGroups: [subGroup]
			};
		} else {
			tempSelect[group].demographicSubGroups.push(subGroup);
		}

		setDemographicSelected(tempSelect);
	};

	const handleRemove = (group: string, subGroup: string) => {
		const tempSelect: { [key: string]: IDemographicFilter } = { ...selectedDemographics };

		const subGroupArr = tempSelect[group].demographicSubGroups;
		const tempSelectIndex = subGroupArr.indexOf(subGroup);

		if (subGroupArr.length === 1) {
			delete tempSelect[group];
		} else {
			subGroupArr.splice(tempSelectIndex, 1);
		}

		setDemographicSelected(tempSelect);
	};

	const demographicsSelected = () => {
		const selected: [string, string][] = [];

		if (selectedDemographics) {
			Object.keys(selectedDemographics).forEach(d => {
				const subGroups = selectedDemographics[d].demographicSubGroups;
				subGroups?.forEach(s => {
					selected.push([d, s]);
				});
			});
		}

		return selected;
	};

	return (
		<>
			{isLoading ? (
				<LoadingComponent className="mt-3" />
			) : demographicGroups !== null && demographicGroups.length > 0 ? (
				<Component.HolNestedMultiSelectDropdown
					handleNestedOptionSelect={(group: string, subGroup: string) =>
						handleSelect(group, subGroup)
					}
					handleNestedOptionRemove={(group: string, subGroup: string) =>
						handleRemove(group, subGroup)
					}
					nestedOptions={nestedDemographicGroups}
					defaultMultiSelected={demographicsSelected()}
					setSelectedOptions={setDemographicSelected}
				/>
			) : (
				<div className="flex items-center mt-2">
					<Component.HolText textType="heading3">Not available</Component.HolText>
				</div>
			)}
		</>
	);
};
