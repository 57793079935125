import * as Component from 'components';

interface OverviewActionPlansTitleProps {}

export const OverviewActionPlansTitle: React.FC<OverviewActionPlansTitleProps> = () => {
	return (
		<div className="flex items-center space-x-2">
			<Component.HolText textType="heading1">Action Plans</Component.HolText>
			<div className="bg-hol-pale-red text-white font-sofia-bold rounded-lg px-3">Active</div>
		</div>
	);
};
