import '@gooddata/sdk-ui-charts/styles/css/main.css';

import React, { useState } from 'react';

import { IFilter } from '@gooddata/sdk-model';
import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';
import * as Util from 'utils';

/**
 * The description of a given factor
 * @author Misael Esperanzate
 * @param filter - The selected factor to view description.
 */
interface DescriptionProps {
	filter: IFilter[];
}
export const Description: React.FC<DescriptionProps> = ({ filter }) => {
	const [loading, setLoading] = useState(true);

	return (
		<div>
			<Execute
				filters={filter}
				seriesBy={[Ldm.Score.Avg]}
				slicesBy={[Ldm.FactorDescription]}
				onLoadingFinish={() => setLoading(false)}
				onError={errInfo => {
					if (errInfo.message !== 'CANCELLED') {
						Util.logErrorMessage(errInfo);
					}
				}}
			>
				{execution => {
					const { result } = execution;
					const slices = result?.data().slices().toArray();

					let factorDescription: string = '';

					// get the titles of each slice
					slices?.forEach(slice => (factorDescription = slice.sliceTitles()[0]));

					return <div>{loading ? <LoadingComponent /> : <>{factorDescription}</>}</div>;
				}}
			</Execute>
		</div>
	);
};
