import React, { useRef } from 'react';

import * as Img from 'img';
import * as Component from 'components';

import { WorkspaceType } from 'enums';
import { OktaContext } from 'utils/auth';
import { useActions, useTypedSelector, useOnClickOutside } from 'hooks';

import {
	NAVBAR_DATA,
	NAVBAR_DATA_LIGHT,
	NAVBAR_DATA_SENSORS_ONLY,
	SECONDARY_NAVBAR_DATA
} from './constants';
import { UserMenu, Subnavigation } from './components';

/**
 * Main navigation component that contains the navbar data and style
 */
interface NavigationProps {}

export const Navigation: React.FC<NavigationProps> = () => {
	const toggle = useTypedSelector(state => state.manageConsult.toggle);
	const { role: userRole } = useTypedSelector(state => state.user.userInfo);
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);
	const sidebarToggle = useTypedSelector(state => state.sidebar.toggle);

	const { setSidebarToggle } = useActions();
	const { oktaLogout } = OktaContext();

	const trigger = useRef<HTMLButtonElement | null>(null);

	// get the reference of current element
	const node = useRef<HTMLInputElement | null>(null);

	// custom hook that handles changes to a user click outside of menu
	useOnClickOutside(node, () => setSidebarToggle(false));

	return (
		<div className="md:w-64 xl3:w-96 shadow-navbar">
			{/* Mobile responsiveness */}
			<div
				className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 md:hidden md:z-auto transition-opacity duration-200 ${
					sidebarToggle ? 'opacity-100' : 'opacity-0 pointer-events-none'
				}`}
				aria-hidden="true"
			/>
			{/* Sidebar */}
			<div
				className={`absolute flex flex-col z-40 left-0 top-0 md:static md:left-auto md:top-auto md:translate-x-0 transform h-screen w-64 xl3:w-96  flex-shrink-0 bg-white px-4 pb-10 pt-70px transition-transform duration-200 ease-in-out ${
					sidebarToggle ? 'translate-x-0' : '-translate-x-64'
				}`}
			>
				{/* Sidebar header */}
				<div className="flex">
					<button
						ref={trigger}
						className="md:hidden block text-gray-500 hover:text-gray-400 mx-auto"
						onClick={() => setSidebarToggle(false)}
						aria-controls="sidebar"
						aria-expanded={sidebarToggle}
					>
						<span className="sr-only">Close sidebar</span>
						<svg
							className="w-6 h-6 fill-current"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
						</svg>
					</button>

					<Component.Logo />
				</div>

				{/* sidebar items */}
				<div className="justify-items-center items-center" ref={node}>
					<UserMenu />

					{!toggle && (
						<>
							{currentWorkspace.type === WorkspaceType.SENSORS_ONLY &&
								NAVBAR_DATA_SENSORS_ONLY.map((item, idx) => {
									return <Subnavigation key={idx} item={item} />;
								})}

							{currentWorkspace.type === WorkspaceType.LIGHT &&
								NAVBAR_DATA_LIGHT.map((item, idx) => {
									return <Subnavigation key={idx} item={item} />;
								})}

							{currentWorkspace.type === WorkspaceType.ADVANCED &&
								NAVBAR_DATA.map((item, idx) => {
									return <Subnavigation key={idx} item={item} />;
								})}

							<div className="border-b w-2/3 ml-4 my-4 border-hol-grey"></div>

							{SECONDARY_NAVBAR_DATA(userRole, currentWorkspace.type).map(
								(item, idx) => {
									return <Subnavigation key={idx} item={item} />;
								}
							)}
						</>
					)}

					<a
						className="flex px-5 py-2 flex-grow cursor-pointer text-hol-grey"
						href="https://pay.holmetrics.com/p/login/14k0445Ah69d9BC7ss"
						rel="noreferrer"
						target="_blank"
					>
						<span className="h-6 w-6 mr-5">
							<Img.Account />
						</span>
						<span className="text-lg font-sofia-regular">Account</span>
					</a>

					<div
						className="flex px-5 py-2 flex-grow cursor-pointer text-hol-grey"
						onClick={oktaLogout}
					>
						<span className="h-6 w-6 ml-1 mr-4">
							<Img.Logout />
						</span>
						<span className="text-lg font-sofia-regular">Logout</span>
					</div>
				</div>

				<div className="flex-1"></div>

				<footer className="text-font-footer font-sofia-reg text-sm text-center">
					<p className="mb-1">Powered by Hölmetrics </p>
					<p>v.9.5.0 - Elnora</p>
				</footer>
			</div>
		</div>
	);
};
