import { KpiValueType } from 'types';

/** Helper function to check for percentage and adds the percent symbol */
const cleanPercent = (percentage: number) => {
	const tooLow = !Number.isFinite(+percentage);
	const tooHigh = percentage > 100;
	return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

/** Helper function to calculate values that will be passed to the Circle and Text components */
export const calculateOnlyPositiveValues = (propValue: KpiValueType) => {
	if (propValue === 'No Data') {
		return {
			text: 'No Data' as KpiValueType,
			circlePercentage: 0
		};
	}

	if (propValue === '-') {
		return {
			text: '-' as KpiValueType,
			circlePercentage: 0
		};
	}

	const cleanVal = cleanPercent(propValue);

	return {
		text: cleanVal,
		circlePercentage: cleanVal
	};
};

export const calculateAllValues = (propValue: number) => {
	return {
		text: propValue,
		circlePercentage: Math.abs(propValue)
	};
};
