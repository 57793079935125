import { IColumnChartSeries, IStackedColumnChartLabel } from 'types';

import { IDataset } from '../types';

/**
 * @author Konstantin Krumin
 * This function takes a dataset with empty fields, that contains all possible combinations
 * for this date range and available options, and fills it out with actual data where applicable
 * @param emptyDataset - an array containing a combinations of all possible options (i.e., date x possible data types)
 * @param incomingDataset - an array containing actual data coming from GoodData
 */
export const fillDataset = (emptyDataset: IDataset[], incomingDataset: IDataset[]): IDataset[] => {
	const updatedDataset = [...emptyDataset];

	incomingDataset.forEach(({ name, date, value }) => {
		const index = updatedDataset.findIndex(data => data.name === name && data.date === date);

		if (index !== -1) {
			updatedDataset[index].value = value;
		}
	});

	return updatedDataset;
};

/**
 * @author Konstantin Krumin
 * This function converts dataset to a series format suitable for Highcharts
 * @param dataset - an array of data containing necessary properties
 */
export const convertDatasetToSeries = (dataset: IDataset[]) => {
	const names = new Set(dataset.map(data => data.name));
	const result: IColumnChartSeries[] = [];

	names.forEach(name => {
		const data = dataset.filter(d => d.name === name).map(d => d.value);
		result.push({ data, name });
	});

	return result;
};

/**
 * @author Konstantin Krumin
 * This assigns proper coloring for the series based on a category (matches values by name property from both arrays)
 * @param series - contains a series array without colors
 * @param colors - contains an array with colors for each specified name
 */
export const matchColorsToSeries = (
	series: IColumnChartSeries[],
	colors: IStackedColumnChartLabel[]
) => {
	return series.map(seriesObj => {
		const matchingObj = colors.find(colorObj => colorObj.name === seriesObj.name);

		return {
			name: seriesObj.name,
			data: seriesObj.data,
			color: matchingObj?.color
		} as IColumnChartSeries;
	});
};
