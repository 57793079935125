import { useEffect } from 'react';

import { EventType, HandlerType } from './types';

/**
 * Custom hook which handles the change of side navbar if it is enabled or not.
 * This is only used in the navigation component.
 * @author Misael Esperanzate
 * @param ref - The reference to the HTML element
 * @param handler - Function that sets the side navbar to be closed
 */
export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
	ref: React.RefObject<T>,
	handler: HandlerType
) => {
	useEffect(() => {
		const listener = (event: EventType) => {
			const el = ref?.current;
			if (!el || el.contains(event?.target as Node) || null) {
				return;
			}
			handler(event);
		};

		document.addEventListener('mousedown', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
		};
	}, [ref, handler]);
};
