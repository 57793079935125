import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { FaCopy } from 'react-icons/fa';
import { MdDelete, MdEdit } from 'react-icons/md';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { ActionType } from 'types';
import { ActionPlanApi } from 'api';
import { useActions, useTypedSelector } from 'hooks';

import { CIRCLE_ADDITIONAL_PARAMS } from './constants';

/**
 * Displays selected action plan details
 * @author Konstantin Krumin
 */
export const ActionPlan: React.FC = () => {
	const { uuid } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [actionType, setActionType] = useState<ActionType>('edit');
	const [openModal, setOpenModal] = useState(false);

	const { currentWorkspace, userId } = useTypedSelector(state => state.user);
	const { currentPage, actionPlans } = useTypedSelector(state => state.actionPlanning);

	const actionPlan = useTypedSelector(state => state.actionPlanning.selectedActionPlan);
	const selectedDemographics = Util.parseDefaultDemographicValues(actionPlan?.data);

	const { setActionPlan, deleteActionPlan, setActionPlanCurrentPage } = useActions();

	const Api = ActionPlanApi();
	const componentRef = useRef(null);

	const factors = Util.parseDefaultFactorValues(actionPlan?.data);
	const diagnostics = Util.parseDefaultDiagnosticValues(actionPlan?.data);
	const kpis = [Util.parseDefaultKPIValues(actionPlan?.data)].filter(item => item !== undefined);

	const daysInfo = Util.calculateDifferenceInDays(actionPlan?.startDate!, actionPlan?.endDate!);
	const daysColor = daysInfo.isActive ? 'text-hol-bright-blue' : 'text-hol-light-grey';

	useEffect(() => {
		if (uuid) {
			Api.getActionPlan(currentWorkspace.id, userId, uuid)
				.then(result => setActionPlan(result[0]))
				.catch(err => Util.logErrorMessage(err));
		}
		// eslint-disable-next-line
	}, [uuid, userId, currentWorkspace.id]);

	const editHandler = () => {
		setOpenModal(true);
		setActionType('edit');
	};

	const duplicateHandler = () => {
		setOpenModal(true);
		setActionType('duplicate');
	};

	const deleteHandler = () => {
		Api.deleteActionPlan(currentWorkspace.id, userId, uuid!)
			.then(() => {
				deleteActionPlan(uuid!);
				Analytics.deleteExistingActionPlan();
			})
			.then(() => {
				const pageNum = Util.setupPageNumToDisplay(actionPlans.length, currentPage);
				setActionPlanCurrentPage(pageNum);
			})
			.then(() => {
				if (location.pathname.includes('/action-plan/')) {
					navigate('/action-plans');
				}
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<>
			<div ref={componentRef} className="max-w-screen-2xl mx-auto">
				<Component.PrintOrientation orientation="landscape" />
				<Component.PageHeader
					showPrintButton
					parentComponentRef={componentRef}
					pageType="action-plan"
				/>

				<Component.HolCard columns="col-span-full">
					<div className="flex flex-1 flex-col justify-between space-y-2 p-5 md2:flex-row print:flex-row">
						<div className="max-w-md space-y-2">
							{actionPlan?.actionPlanName && (
								<div className="flex items-center space-x-3">
									<Component.HolText
										textType="title"
										textColor="text-hol-dark-blue"
									>
										{actionPlan?.actionPlanName}
									</Component.HolText>

									<div className="flex items-center space-x-1">
										{actionPlan.userId === userId && (
											<>
												<Component.HolTooltip
													message="Duplicate"
													bgColor="bg-hol-dark-green"
												>
													<Component.HolIconButton
														icon={<FaCopy />}
														onClick={duplicateHandler}
													/>
												</Component.HolTooltip>

												<Component.HolTooltip
													message="Edit"
													bgColor="bg-hol-dark-green"
												>
													<Component.HolIconButton
														icon={<MdEdit />}
														onClick={editHandler}
													/>
												</Component.HolTooltip>

												<Component.HolTooltip
													message="Delete"
													bgColor="bg-hol-dark-green"
												>
													<Component.HolIconButton
														icon={<MdDelete />}
														onClick={deleteHandler}
													/>
												</Component.HolTooltip>
											</>
										)}

										{actionPlan.isShared ? (
											<Component.HolSharedTooltip
												color="text-gray-300"
												text="Shared Action Plan"
												sharedBy={actionPlan.userId!}
												sharedDate={actionPlan.createdAt?.toString()}
											/>
										) : (
											<></>
										)}
									</div>
								</div>
							)}

							{actionPlan?.createdAt && (
								<Component.HolText
									textType="baseLight1"
									textColor="text-hol-dark-blue"
								>
									Created on {Util.formatDate(actionPlan?.createdAt)}
								</Component.HolText>
							)}

							{actionPlan?.startDate && actionPlan?.endDate && (
								<Component.HolText>
									Date Range: {Util.formatDate(actionPlan?.startDate)} -{' '}
									{Util.formatDate(actionPlan?.endDate)}
								</Component.HolText>
							)}

							{actionPlan?.detailedSummary && (
								<Component.HolText>
									Detailed Summary: {actionPlan?.detailedSummary}
								</Component.HolText>
							)}

							{selectedDemographics && (
								<>
									{selectedDemographics.map(demographic => {
										return (
											<Component.HolText key={demographic.demographicLabel}>
												{demographic.demographicLabel}:{' '}
												{demographic.groupsSelected.join(', ')}
											</Component.HolText>
										);
									})}
								</>
							)}
						</div>

						<div className="flex flex-col justify-center items-center p-5 mr-10">
							<Component.HolText
								styles="text-8xl font-new-spirit font-medium"
								textColor={daysColor}
							>
								{daysInfo.days > 0 ? daysInfo.days : '- -'}
							</Component.HolText>
							<Component.HolText
								styles="text-base font-new-spirit font-medium"
								textColor={daysColor}
							>
								{daysInfo.subText}
							</Component.HolText>
						</div>
					</div>

					<div className="px-5 flex flex-col space-y-8">
						<Component.HolText textType="heading1" textColor="text-hol-grey">
							Results
						</Component.HolText>

						{diagnostics.length !== 0 && (
							<div className="flex flex-col space-y-2">
								<Component.HolText textType="heading2" textColor="text-hol-grey">
									Diagnostics
								</Component.HolText>

								<div className="col-span-full grid grid-cols-3 lg2:grid-cols-4 xl2:grid-cols-5 print:flex print:flex-wrap">
									{diagnostics.map(diagnostic => {
										return (
											<div
												key={diagnostic.identifier}
												className="flex flex-grow justify-center col-span-full lg:col-span-1 mb-5"
											>
												<Component.ActionPlanScoreDisplay
													identifier={diagnostic.identifier!}
													title={diagnostic.title}
													subtitle={diagnostic.subtitle}
													ldmMeasureRef={diagnostic.value}
													endDate={actionPlan?.endDate!}
													startDate={actionPlan?.startDate!}
													circleAdditionalParams={
														CIRCLE_ADDITIONAL_PARAMS
													}
													isPercentageValue={
														diagnostic.identifier === 'eNPS' ||
														diagnostic.identifier === 'communication'
															? false
															: true
													}
													displayStyle="progress-bar"
													isTitleOnRightSide
												/>
											</div>
										);
									})}
								</div>
							</div>
						)}

						{factors.length !== 0 && (
							<div className="flex flex-col space-y-4 print-page-break">
								<Component.HolText textType="heading2" textColor="text-hol-grey">
									Factors
								</Component.HolText>

								<div className="col-span-full grid grid-cols-3 xl:grid-cols-4 xl2:grid-cols-5 print:flex print:flex-wrap">
									{factors.map(factor => {
										return (
											<div
												key={factor.identifier}
												className="flex flex-grow justify-center col-span-full lg:col-span-1 px-5 mb-5"
											>
												<Component.ActionPlanScoreDisplay
													identifier={factor.identifier!}
													title={factor.label}
													ldmMeasureRef={factor.value}
													startDate={actionPlan?.startDate!}
													endDate={actionPlan?.endDate!}
													displayStyle="large-number"
													showHistoricSplineChart
												/>
											</div>
										);
									})}
								</div>
							</div>
						)}

						{kpis.length > 0 && actionPlan?.startDate && (
							<div className="space-y-3 print-page-break">
								<Component.HolText textType="heading1" textColor="text-hol-grey">
									KPIs
								</Component.HolText>

								<div className="grid grid-cols-12 gap-6 print:block">
									{kpis.map(kpi => {
										return (
											<Component.KpiChart
												key={kpi?.label}
												title={kpi?.label}
												startDate={actionPlan?.startDate!}
											/>
										);
									})}
								</div>
							</div>
						)}
					</div>
				</Component.HolCard>
			</div>

			{openModal && (
				<Component.ActionPlanOverlay
					openModal
					type={actionType}
					defaultActionPlan={actionPlan}
					openModalCallback={setOpenModal}
				/>
			)}
		</>
	);
};
