import {
	SentimentCategoriesWithDataType,
	SentimentCategoriesWithCleanedDataType,
	HistoricInsightUnprocessedDatasetType
} from '../types';
import { SHORT_MONTHS } from 'constants/misc';

/**
 * Function that generates dates in a reverse order (ex., if 'Dec 2022' and 3 provided as argument -> ['Oct 2022', 'Nov 2022', 'Dec 2022'])
 * @param date - a last date from which the leading dates should be generated in short date formatDate
 * @param length - indicates how many dates should be added to the arrays of dates
 * @returns an arrays of short format dates ['Jan 2022', 'Feb 2022', 'Mar 2022']
 */
export const generateDatesArr = (date: string, length: number) => {
	const [month, year] = date.split(' ');
	const monthIndex = SHORT_MONTHS.indexOf(month);

	// initialize the result array with the input date
	const result: string[] = [date];

	// decrement the month index and year until we reach the required length
	let currentYear = +year;
	let currentMonthIndex = monthIndex;
	while (result.length < length) {
		currentMonthIndex--;
		if (currentMonthIndex < 0) {
			currentMonthIndex = 11;
			currentYear--;
		}
		result.unshift(`${SHORT_MONTHS[currentMonthIndex]} ${currentYear}`);
	}

	return result;
};

/**
 * Helper function that returns an array with sorted dates (MMM YYYY) in short format for the length of 12 months
 * @author Konstantin Krumin
 * @param today - today's date
 * @returns an array with sorted dates (MMM YYYY) in short format (e.g., ['Dec 2022', 'Jan 2023', 'Feb 2023'])
 */
export const generateDatesArr12M = (today: Date): string[] => {
	const result: string[] = [];
	const currentMonth = today.getMonth();
	const currentYear = today.getFullYear();

	for (let i = 11; i >= 0; i--) {
		const d = new Date(currentYear, currentMonth - i, 1);
		const month = d.toLocaleString('default', { month: 'short' });
		const year = d.getFullYear();
		result.push(`${month} ${year}`);
	}

	return result;
};

/**
 * Helper function that creates an array with objects for all possible datapoints
 * For example, if there are 12 months and 3 categories an array with 36 objects for a combination of each month and category will be returned
 * @author Konstantin Krumin
 * @param startingDate - starting date from which an array of previous dates will be generated sliced by months
 * @param categories - an array of categories
 * @returns an array of objects containing value, date and category fields
 */
export const createEmptyDataset = (
	startingDate: Date,
	categories: string[]
): HistoricInsightUnprocessedDatasetType[] => {
	return generateDatesArr12M(startingDate)
		.map(date => {
			return categories.map(category => {
				return {
					value: 0,
					date: date,
					category: category
				};
			});
		})
		.flat();
};

/**
 * Helper function that populates dataset with data, where category and date matches
 * @author Konstantin Krumin
 * @param datasetWithAllOptions - dataset with all options
 * @param datasetFilled - dataset containing filled entries that need to be merged in the dataset with all possible options
 * @returns a populated array of objects containing value, date and category fields
 */
export const populateDataset = (
	datasetWithAllOptions: HistoricInsightUnprocessedDatasetType[],
	datasetFilled: HistoricInsightUnprocessedDatasetType[]
) => {
	const processedDataset = [...datasetWithAllOptions];

	processedDataset.forEach((datapointExternal, idx) => {
		return datasetFilled.forEach(datapointInternal => {
			const category = datapointInternal.category;

			if (
				datapointExternal.date === datapointInternal.date &&
				datapointExternal.category === category
			) {
				processedDataset[idx] = {
					category: category,
					date: datapointExternal.date,
					value: datapointInternal.value
				};
			}
		});
	});

	return processedDataset;
};

/**
 * Function that sorts an array of MMM YYYY date strings in a proper order
 * @param dates - an array of strings in format like ['Jan 2022', 'Feb 2022', 'Aug 2022']
 */
export const sortShortGDDates = (dates: string[]) => {
	// Create a map of month names to month numbers
	const monthMap: { [key: string]: number } = {
		Jan: 1,
		Feb: 2,
		Mar: 3,
		Apr: 4,
		May: 5,
		Jun: 6,
		Jul: 7,
		Aug: 8,
		Sep: 9,
		Oct: 10,
		Nov: 11,
		Dec: 12
	};

	return dates?.sort((a, b) => {
		// Split each date into its month and year parts
		const [monthA, yearA] = a.split(' ');
		const [monthB, yearB] = b.split(' ');

		// Convert the month names to month numbers
		const monthNumA = monthMap[monthA];
		const monthNumB = monthMap[monthB];

		// Compare the years first, then the months
		if (yearA < yearB) {
			return -1;
		} else if (yearA > yearB) {
			return 1;
		} else if (monthNumA < monthNumB) {
			return -1;
		} else if (monthNumA > monthNumB) {
			return 1;
		} else {
			return 0;
		}
	});
};

/**
 * Function that removes leading 0s if all of the provided arrays have 0 at the specified index
 * @param arrays - an array of arrays with numbers
 */
export const removeLeadingZeros = (arrays: number[][]) => {
	// Map the input array to a new array, where each element is the
	// index of the first non-zero element in the corresponding input array
	const indices = arrays.map(array => array.findIndex(x => x !== 0));

	// Find the minimum index among the indices
	const minIndex = Math.min(...indices);

	// To eliminate cases where some arrays are only 0s and -1 returned since there're no non-0 values in such arrays
	const sliceIndex = minIndex === -1 ? 0 : minIndex;

	// Return a new array, where each element is the corresponding
	// input array with the leading zeros up to the minimum index removed
	return arrays.map(array => array.slice(sliceIndex));
};

/**
 * Function that removes trailing 0s if all of the provided arrays have 0 at the specified index
 * @param arrays - an array of arrays with numbers
 */
export const removeTrailingZeros = (arrays: number[][]) => {
	// Find the length of the shortest array
	let minLength = Math.min(...arrays.map(a => a.length));

	// Iterate backwards from the end of the arrays
	for (let i = minLength - 1; i >= 0; i--) {
		// Check if all arrays have a 0 at this index
		let allZeros = arrays.every(a => a[i] === 0);
		if (allZeros) {
			// If all arrays have a 0 at this index, remove it from all arrays
			arrays = arrays.map(a => a.slice(0, -1));
		} else {
			// If any array has a non-zero value at this index, stop the loop
			break;
		}
	}

	return arrays;
};

/**
 * Function that removes reduntant 0s and reformats the object accordingly for further use by insights
 * @param dataset - a dataset with values the sentiments that display as part of the insight
 */
export const removeRedundantZerosFromDataset = (dataset: SentimentCategoriesWithDataType) => {
	const cleanedDataset: SentimentCategoriesWithCleanedDataType = {};
	const datasetCategories = Object.keys(dataset);

	const datasetValues = datasetCategories
		.map(category => {
			return Object.values(dataset[category]) as number[][];
		})
		.flat();

	const tempDatasetValues = removeLeadingZeros(datasetValues);
	const cleanedDatasetValues = removeTrailingZeros(tempDatasetValues);

	datasetCategories.forEach((category, idx) => {
		cleanedDataset[category] = cleanedDatasetValues[idx];
	});

	return cleanedDataset;
};

/**
 * Helper function that filters an array to only leave unique array entries
 * @author Konstantin Krumin
 * @returns filtered array
 */
export const uniqueStr = (value: string, index: number, self: string[]) => {
	return self.indexOf(value) === index;
};
