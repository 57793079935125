import React from 'react';

import { LoadingComponent } from '@gooddata/sdk-ui/esm/base/react/LoadingComponent';

import { IGeneralOption } from 'types';

/**
 * A component containing selected value
 * @author Michaela Mempin
 * @param selected - currently selected value
 * @param textSize - text size of the element
 * @param placeholder - default placeholder text
 * @param options - an array of available options for selection
 */
interface SelectedValueProps {
	options: IGeneralOption[] | undefined;
	selected?: IGeneralOption | null | undefined;
	placeholder: string;
	textSize?: string;
}

export const SelectedValue: React.FC<SelectedValueProps> = ({
	options,
	selected,
	placeholder,
	textSize = 'text-sm'
}) => {
	return (
		<>
			{/* Show loading if there are no options yet or Display what is currently selected */}
			{options === undefined ? (
				<LoadingComponent />
			) : !selected ? (
				<p className={`truncate inline-block text-lg text-font-light-1`}>{placeholder}</p>
			) : (
				<p className={`truncate inline-block ${textSize} text-font-reg`}>
					{selected.label}
				</p>
			)}
		</>
	);
};
