import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { RolesApi } from 'api';
import { WorkspaceType } from 'enums';
import { IGeneralOption } from 'types';
import { useActions, useTypedSelector } from 'hooks';

import CompanyInfo from 'utils/company';

/**
 * A user menu component containing user info and a dropdown to switch between available workspaces
 */
interface UserMenuProps {}

export const UserMenu: React.FC<UserMenuProps> = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const {
		setDemographic,
		setManagementToggle,
		setCurrentWorkspace,
		setUserWorkspaceRole,
		setReportFullscreen
	} = useActions();

	const user = useTypedSelector(state => state.user.userInfo);
	const workspaces = useTypedSelector(state => state.user.workspaces);
	const toggle = useTypedSelector(state => state.manageConsult.toggle);
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);
	const fullscreenReport = useTypedSelector(state => state.report.fullscreenReport);

	const { getName } = CompanyInfo(currentWorkspace.id);
	const { getCurrentRole } = RolesApi();
	// transformed array of workspace objects to be able to use it in dropdown selection
	const workspaceObj = workspaces.map(v => {
		return {
			label: v.name,
			value: v
		};
	});

	let options: any = [...workspaceObj];

	const defaultValue = workspaceObj.find(workspace => workspace.value.id === currentWorkspace.id);

	const handleWorkspaceSelect = async (v: IGeneralOption | null) => {
		const defaultWs = workspaces[0];

		if (!v) {
			setCurrentWorkspace(defaultWs);
			setManagementToggle(true);
			navigate('/', { replace: true });
		} else {
			const workspace = v.value;
			const role = await getCurrentRole(workspace.id, user.profileId);

			setUserWorkspaceRole(role);
			setCurrentWorkspace(workspace);

			setDemographic([], {});

			if (workspace.type !== WorkspaceType.ADVANCED) {
				navigate('/diagnostics', { replace: true });
			}

			if (location.pathname.includes('/change') && !!fullscreenReport) {
				setReportFullscreen(false);
			}

			if (location.pathname.includes('/action-plan/')) {
				navigate('/action-plans');
			}
		}

		Analytics.changeWorkspace();
	};

	return (
		<div className="my-9">
			<div className="flex flex-row space-x-4 mx-4">
				<Component.HolAvatar userName={user.name} size={37} />
				<div className="flex flex-col w-full">
					<span className="text-lg text-font-reg mb-1 font-sofia-regular">
						{user.name}
					</span>
					<span className="text-lg text-font-reg font-sofia-regular">
						{/* If the user is not under the company, use the Consultant firm's name */}
						{user.companyName ? user.companyName : getName()}
					</span>
				</div>
			</div>

			{!toggle && (
				<div className="my-8 w-full">
					<Component.HolSingleSelectDropdown
						selectNone={true}
						options={options}
						selectNoneLabel="See All"
						borderRadius="rounded-xl"
						selectedOption={defaultValue}
						handleSelect={opt => handleWorkspaceSelect(opt)}
					/>
				</div>
			)}
		</div>
	);
};
