import React, { useEffect } from 'react';

import * as Util from 'utils';
import * as Component from 'components';

import { ActionPlanApi } from 'api';
import { useActions, useTypedSelector } from 'hooks';

import { OverviewActionPlansTitle } from './components';

/**
 * A card showing only active action plans (current date is between the start and end dates of the action plan)
 * @author Konstantin Krumin
 */
interface ActiveActionPlanCardProps {}

export const ActiveActionPlanCard: React.FC<ActiveActionPlanCardProps> = () => {
	const { userId, currentWorkspace } = useTypedSelector(state => state.user);
	const {
		activeActionPlans,
		activeCurrentPage,
		activeItemsPerPage,
		totalActiveActionPlansCount
	} = useTypedSelector(state => state.actionPlanning);

	const { setActiveActionPlans, setActiveActionPlanCurrentPage, setTotalActiveActionPlansCount } =
		useActions();

	const Api = ActionPlanApi();

	useEffect(() => {
		Api.getActionPlans(currentWorkspace.id, userId, true, activeCurrentPage, activeItemsPerPage)
			.then(result => {
				setActiveActionPlans(result.results);
				setTotalActiveActionPlansCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
		// eslint-disable-next-line
	}, [currentWorkspace.id, userId]);

	const handlePageChange = (updatedPageNum: number) => {
		setActiveActionPlanCurrentPage(updatedPageNum);

		Api.getActionPlans(currentWorkspace.id, userId, true, updatedPageNum, activeItemsPerPage)
			.then(result => {
				setActiveActionPlans(result.results);
				setTotalActiveActionPlansCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<Component.HolCard
			columns="col-span-full"
			styles="print-page-break"
			headerHorizontalMargin="mx-0"
			cardBackgroundColor="bg-transparent"
			titleElement={<OverviewActionPlansTitle />}
		>
			{activeActionPlans && activeActionPlans.length > 0 ? (
				<div>
					<div className="grid grid-cols-12 gap-6">
						{activeActionPlans.map(actionPlan => {
							return (
								<Component.ActionPlanOverview
									actionPlan={actionPlan}
									key={actionPlan.uuid}
									disableActions
								/>
							);
						})}
					</div>

					<Component.StatelessPagination
						arrayName="plan(s)"
						bgColor="bg-users-title"
						iconColor="text-users-title"
						itemsPerPage={activeItemsPerPage}
						totalCount={totalActiveActionPlansCount}
						currentListLength={activeActionPlans.length}
						onChangePage={handlePageChange}
						currentPage={activeCurrentPage}
					/>
				</div>
			) : (
				<div className="flex justify-center p-12">
					<Component.HolText textType="heading3">
						There are no active action plans...
					</Component.HolText>
				</div>
			)}
		</Component.HolCard>
	);
};
