import React from 'react';

import * as Component from 'components';

/**
 * Using an iframe to display knowledge base from freshdesk to the support page
 * @author Misael Esperanzate
 */
export const Support: React.FC = () => {
	return (
		<>
			<header className="flex justify-end">
				<Component.HolHamburgerButton />
			</header>
			<iframe
				className="bg-transparent"
				src="https://newaccount1626714800877.freshdesk.com/support/solutions"
				style={{ minWidth: '100%', height: '185rem' }}
				title="Knowledge Base"
			/>
		</>
	);
};
