import { ICurrentDiagnostic, DataViewType } from 'types';

import ActionType from '../action-types';
import { SetCurrentDiagnosticsAction, SetDateFilterAction } from '../actions';

/**
 * Sets currently selected diagnostics option
 * @author Konstantin Krumin
 * @param currentDiagnostics - currently selected diagnostic option
 */
export const setCurrentDiagnostics = (
	currentDiagnostics: ICurrentDiagnostic
): SetCurrentDiagnosticsAction => {
	return {
		type: ActionType.SET_CURRENT_DIAGNOSTICS,
		payload: {
			currentDiagnostics
		}
	};
};

/**
 * Sets date filter for the diagnostics page
 * @author Konstantin Krumin
 * @param dataView - date filter type selected
 */
export const setDateFilter = (dataView: DataViewType): SetDateFilterAction => {
	return {
		type: ActionType.SET_DIAGNOSTICS_DATE_FILTER,
		payload: {
			dataView
		}
	};
};
