import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonType } from './types';

/**
 * A regular button component
 * @param type - type of the button
 * @param label - text to be displayed inside the button
 * @param customStyle - any additional custom styling additions to the button
 * @param isDisabled - a boolean parameter that forces disabling of the button
 * @param customEdge - a parameter that determines the edge shape of the button
 * @param icon - optional icon to be displayed on the left side of the text inside the button
 * @param onClick - a function that triggers some event on button click
 */
interface HolButtonProps {
	label: string;
	icon?: ReactNode;
	type?: ButtonType;
	customEdge?: string;
	isDisabled?: boolean;
	customStyle?: string;
	onClick: any;
}

export const HolButton: React.FC<HolButtonProps> = ({
	type,
	icon,
	label,
	customStyle,
	isDisabled = false,
	customEdge = 'rounded-md',
	onClick
}) => {
	let buttonType: string = 'bg-hol-dark-purple hover:opacity-70';

	if (type) {
		switch (type) {
			case 'primary':
				buttonType = 'bg-hol-dark-purple hover:opacity-70';
				break;
			case 'secondary':
				buttonType = 'bg-hol-bright-blue hover:opacity-70';
				break;
			case 'users':
				buttonType = 'bg-input-border hover:bg-users-title';
				break;
			case 'holmetrics':
				buttonType = 'bg-hol-light-blue hover:bg-hol-dark-blue';
		}
	}

	if (isDisabled) {
		customStyle = `${customStyle} opacity-40 hover:opacity-40 cursor-not-allowed hover:cursor-not-allowed`;
	}

	const classes = twMerge(
		`flex justify-center space-x-2 transition ease-in-out duration-150 items-center font-sofia-regular text-white text-base py-2 px-5 
		${customEdge} 
		${buttonType}
		${customStyle} print-hide`
	);

	return (
		<button className={classes} disabled={isDisabled} onClick={onClick}>
			{!icon && <p className="mx-auto">{label}</p>}
			{icon && (
				<div className="flex justify-center items-center space-x-2 font-sofia-regular">
					<span>{icon}</span>
					<span>{label}</span>
				</div>
			)}
		</button>
	);
};
