import React, { useEffect, useRef } from 'react';

import * as Component from 'components';

import { useActions, useTypedSelector } from 'hooks';
import { DiagnosticsOptions, WorkspaceType } from 'enums';

import { ENPS } from './enps';
import { Light } from './light';
import { Noise } from './noise';
import { Pulse } from './pulse';
import { Chats } from './chats';
import { Emails } from './emails';
import { Meetings } from './meetings';
import { Turnover } from './turnover';
import { Toxicity } from './toxicity';
import { Sentiment } from './sentiment';
import { AirQuality } from './air-quality';
import { Temperature } from './temperature';
import { Absenteeism } from './absenteeism';
import { Connectivity } from './connectivity';

const isSensorTab = (selectedDiagnostics: DiagnosticsOptions) => {
	if (
		selectedDiagnostics === DiagnosticsOptions.AIR_QUALITY ||
		selectedDiagnostics === DiagnosticsOptions.TEMPERATURE ||
		selectedDiagnostics === DiagnosticsOptions.LIGHT ||
		selectedDiagnostics === DiagnosticsOptions.NOISE
	) {
		return true;
	}

	return false;
};

/**
 * The Diagnostics page with info on Chats, Emails, Meetings and other Diagnostics
 * @author Konstantin Krumin
 */
export const Diagnostics: React.FC = () => {
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);
	const { currentDiagnostics } = useTypedSelector(state => state?.diagnostics);

	const { setCurrentDiagnostics } = useActions();

	const componentRef = useRef(null);

	useEffect(() => {
		if (currentWorkspace.type === WorkspaceType.SENSORS_ONLY) {
			setCurrentDiagnostics({ label: 'Noise', value: DiagnosticsOptions.NOISE });
		}
		// eslint-disable-next-line
	}, [currentWorkspace]);

	return (
		<div ref={componentRef} className="max-w-screen-2xl mx-auto">
			<Component.PrintOrientation orientation="landscape" />
			<Component.PageHeader
				showDateFilter
				showPrintButton
				showLastUpdatedText
				showCategorySwitchDropdown
				parentComponentRef={componentRef}
				showDemographicFilter={
					!isSensorTab(currentDiagnostics.value) &&
					currentWorkspace.type !== WorkspaceType.LIGHT
				}
				pageType="diagnostics"
			/>

			{currentDiagnostics.value === DiagnosticsOptions.ENPS && <ENPS />}
			{currentDiagnostics.value === DiagnosticsOptions.LIGHT && <Light />}
			{currentDiagnostics.value === DiagnosticsOptions.NOISE && <Noise />}
			{currentDiagnostics.value === DiagnosticsOptions.PULSE && <Pulse />}
			{currentDiagnostics.value === DiagnosticsOptions.CHATS && <Chats />}
			{currentDiagnostics.value === DiagnosticsOptions.EMAILS && <Emails />}
			{currentDiagnostics.value === DiagnosticsOptions.MEETINGS && <Meetings />}
			{currentDiagnostics.value === DiagnosticsOptions.TURNOVER && <Turnover />}
			{currentDiagnostics.value === DiagnosticsOptions.TOXICITY && <Toxicity />}
			{currentDiagnostics.value === DiagnosticsOptions.SENTIMENT && <Sentiment />}
			{currentDiagnostics.value === DiagnosticsOptions.AIR_QUALITY && <AirQuality />}
			{currentDiagnostics.value === DiagnosticsOptions.TEMPERATURE && <Temperature />}
			{currentDiagnostics.value === DiagnosticsOptions.ABSENTEEISM && <Absenteeism />}
			{currentDiagnostics.value === DiagnosticsOptions.CONNECTIVITY && <Connectivity />}
		</div>
	);
};
