import * as Util from 'utils';

import { KpiValueType } from 'types';

/**
 * Text to be displayed in the middle of the progress bar
 * @param textSize - text size
 * @param value - the value to be displayed
 * @param showPercentSign - a boolean determining whether % sign should show
 * @param overwriteText - any additional text that needs to overwrite the value
 * @param showPlusOrMinusSign - a boolean determining whether + / - sign should show
 */
interface ProgressBarTextProps {
	textSize: string;
	value: KpiValueType;
	showPercentSign: boolean;
	showPlusOrMinusSign: boolean;
	overwriteText: string | undefined;
}

export const ProgressBarText: React.FC<ProgressBarTextProps> = ({
	textSize,
	value,
	overwriteText,
	showPercentSign,
	showPlusOrMinusSign
}) => {
	const textValue = Util.scoreDisplayText(
		value,
		showPercentSign,
		showPlusOrMinusSign,
		overwriteText
	);

	return (
		<text
			x="50%"
			y="50%"
			textAnchor="middle"
			dominantBaseline="central"
			className={`font-new-spirit font-medium ${
				textValue === 'No Data' ? 'text-base' : textSize
			}`}
		>
			{textValue}
		</text>
	);
};
