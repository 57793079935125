import React, { ReactElement } from 'react';

import * as Ldm from 'ldm';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { WorkspaceType } from 'enums';
import { IGeneralOption } from 'types';
import { useActions, useTypedSelector } from 'hooks';
import { DIAGNOSTIC_OPTIONS, DIAGNOSTIC_OPTIONS_SENSORS_ONLY } from 'constants/misc';

import { PageType } from './types';
import { DEFAULT_DATE_VIEW_OPTIONS } from './constants';

/**
 * The header component for app page
 * @author Konstantin Krumin
 * @param pageType - type of the current page
 * @param showDateFilter - boolean determining whether date filter should be showing
 * @param showPrintButton - boolean determining whether print button should be showing
 * @param showLastUpdatedText - boolean determining whether last updated text should be showing
 * @param showDemographicFilter - boolean determining whether demographic filter should be showing
 * @param showCategorySwitchDropdown - boolean determining whether category switch should be showing
 * @param additionalCustomComponentLeft - an optional parameter used to pass elements to display on left side of the header
 * @param parentComponentRef - ref parameter used for the printing the page
 */
interface PageHeaderProps {
	pageType: PageType;
	showDateFilter?: boolean;
	showPrintButton?: boolean;
	showLastUpdatedText?: boolean;
	showDemographicFilter?: boolean;
	showCategorySwitchDropdown?: boolean;
	additionalCustomComponentLeft?: ReactElement;
	parentComponentRef?: React.MutableRefObject<null>;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
	pageType,
	parentComponentRef,
	showDateFilter = false,
	showPrintButton = false,
	showLastUpdatedText = false,
	showDemographicFilter = false,
	showCategorySwitchDropdown = false,
	additionalCustomComponentLeft
}) => {
	const { currentDiagnostics, dateFilter: currentDateFilter } = useTypedSelector(
		state => state?.diagnostics
	);
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const { setCurrentDiagnostics, setDateFilter } = useActions();

	const diagnosticsOptions =
		currentWorkspace.type === WorkspaceType.SENSORS_ONLY
			? DIAGNOSTIC_OPTIONS_SENSORS_ONLY
			: DIAGNOSTIC_OPTIONS;

	const onCategoryChangeHandler = (selectedValue: IGeneralOption | null) => {
		const selectedOption = diagnosticsOptions.find(
			({ value }) => value === selectedValue?.value
		);

		if (selectedOption) {
			setCurrentDiagnostics(selectedOption);
			Analytics.changeDiagnosticsType(selectedOption);
		}
	};

	const time = pageType === 'diagnostics' ? Ldm.LastDiagnosticsUpdate : Ldm.LastScoresUpdate;

	return (
		<header className="flex flex-col py-4">
			<div className="flex flex-col space-y-4 md2:flex-row md2:space-y-0 justify-between items-center print:flex-row">
				<div className="flex flex-col md:flex-row">
					<Component.HolHamburgerButton />

					{additionalCustomComponentLeft}

					<div className="flex items-center space-x-3">
						{showCategorySwitchDropdown && (
							<div className="w-44">
								<Component.HolSingleSelectDropdown
									options={diagnosticsOptions}
									textSize="text-md"
									borderRadius="rounded-xl"
									selectedOption={currentDiagnostics}
									handleSelect={onCategoryChangeHandler}
								/>
							</div>
						)}

						{showLastUpdatedText && <Component.LastUpdated slicedBy={time} />}
					</div>
				</div>

				<div className="flex space-x-4">
					{showDateFilter && (
						<Component.DateFilter
							selectedDataView={currentDateFilter?.dataView}
							dataViewOptions={DEFAULT_DATE_VIEW_OPTIONS}
							onValueChange={setDateFilter}
						/>
					)}

					{showDemographicFilter && <Component.DemographicFilterOverlay />}

					{showPrintButton && parentComponentRef && (
						<Component.PrintButton
							componentRef={parentComponentRef}
							documentTitle={pageType}
						/>
					)}
				</div>
			</div>
		</header>
	);
};
