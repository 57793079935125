import React, { useEffect, useRef, useState } from 'react';

import { FaTimes, FaChevronDown } from 'react-icons/fa';
import { LoadingComponent } from '@gooddata/sdk-ui/esm/base/react/LoadingComponent';

import { useOnClickOutside } from 'hooks';

/**
 * A dropdown select component used for nested options
 * @author Michaela Mempin
 * @param showGroup
 * @param borderColor - border color
 * @param borderRadius - border radius
 * @param nestedOptions - contains selected nested options
 * @param defaultMultiSelected - contains default selected options
 * @param handleNestedOptionSelect - a function that passes the group and the subgroup (e.g., handleNestedOptionSelect('Department', 'Accounting'))
 * @param handleNestedOptionRemove - a function that handles removal of the previously selected nestyed options
 * @param setSelectedOptions - a function used to clear selected options
 */
interface HolNestedMultiSelectDropdownProps {
	showGroup?: boolean;
	borderColor?: string;
	borderRadius?: string;
	defaultMultiSelected?: [string, string][];
	nestedOptions: { [key: string]: string[] } | null;
	handleNestedOptionSelect: (group: string, subGroup: string) => void;
	handleNestedOptionRemove: (group: string, subGroup: string) => void;
	setSelectedOptions: any;
}

export const HolNestedMultiSelectDropdown: React.FC<HolNestedMultiSelectDropdownProps> = ({
	nestedOptions,
	showGroup = false,
	defaultMultiSelected = [],
	borderRadius = 'rounded-md',
	borderColor = 'border-input-border hover:border-font-light-1',
	handleNestedOptionSelect,
	handleNestedOptionRemove,
	setSelectedOptions
}) => {
	// Array of tuples of options and their nestedOptions, ex: [["Department", "Accounting"], ["Age", "21-24"]]
	const [multiSelected, setMultiSelected] = useState<[string, string][]>(defaultMultiSelected);
	const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
	const [subGroups, setSubGroups] = useState<string[] | null>(null);

	const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

	// Close the dropdown select if user clicks outside of component
	const node = useRef<HTMLInputElement | null>(null);
	useOnClickOutside(node, () => setIsSelectOpen(false));

	const handleSelect = (subGroup: string) => {
		if (selectedGroup) {
			setMultiSelected(selected => [...selected, [selectedGroup, subGroup]]);
			handleNestedOptionSelect(selectedGroup, subGroup);
		}
	};

	const handleRemove = (groupTuple: [string, string]) => {
		const [group, subGroup] = groupTuple;

		const tempMultiSelect = [...multiSelected];
		const index = tempMultiSelect.indexOf(groupTuple);

		tempMultiSelect.splice(index, 1);

		setMultiSelected(tempMultiSelect);
		handleNestedOptionRemove(group, subGroup);
	};

	const handleClearSelect = () => {
		setMultiSelected([]);
		setSelectedOptions(null);
	};

	const selectGroup = (o: string) => {
		if (selectedGroup === o) {
			setSelectedGroup(null);
		} else {
			setSelectedGroup(o);
		}
	};

	const handleClick = (e: any) => {
		const target = e.target;

		if (target.id === 'nested-select') {
			setIsSelectOpen(!isSelectOpen);
		}
	};

	useEffect(() => {
		if (selectedGroup && nestedOptions) {
			// If the sub group is already selected, do not add it as option.
			const unselectedOptions = nestedOptions[selectedGroup]?.filter(
				x => ![...multiSelected].some(a => a[1] === x)
			);

			if (unselectedOptions) {
				setSubGroups(unselectedOptions);
			}
		}
	}, [selectedGroup, multiSelected, nestedOptions]);

	useEffect(() => {
		setMultiSelected(defaultMultiSelected);
	}, [defaultMultiSelected]);

	return (
		<div className="select-none w-full text-md">
			<div
				ref={node}
				className={`relative border ${borderColor} ${borderRadius} transition-all duration-150 ease-in-out`}
			>
				<div
					id="nested-select"
					className="cursor-pointer px-2 flex items-center justify-between"
					style={{ minHeight: '35px' }}
					onClick={handleClick}
				>
					{!nestedOptions ? (
						<LoadingComponent />
					) : multiSelected.length === 0 ? (
						<p className="text-lg text-font-light-1">Select...</p>
					) : (
						<div className="flex flex-wrap items-center">
							{multiSelected.map(m => (
								<span
									key={`opt_${m}`}
									className="bg-font-light items-center text-sm p-1 rounded-md flex mr-1 my-1 space-x-2"
								>
									<p className="text-change-title">
										{showGroup ? (
											<>
												{m[0]}{' '}
												<span className="text-font-light-1 italic">
													( {m[1]} )
												</span>
											</>
										) : (
											m[1]
										)}
									</p>
									<FaTimes
										className="text-font-dark text-xs cursor-pointer"
										onClick={() => handleRemove(m)}
										key={`opt_x_${m}`}
									/>
								</span>
							))}
						</div>
					)}

					<p className="p-3.5"></p>

					<div className="flex flex-row space-x-3 items-center">
						{multiSelected.length > 0 && (
							<FaTimes
								className="transition-all text-base duration-150 ease-in-out hover:text-font-light-1 text-gray-300 cursor-pointer"
								onClick={() => handleClearSelect()}
							/>
						)}
						<div className={`border-l pl-3 pr-1`}>
							<FaChevronDown
								className={`transition-all duration-150 ease-in-out transform text-base hover:text-font-light-1 text-gray-300 cursor-pointer ${
									isSelectOpen ? 'rotate-180' : ''
								}`}
								onClick={() => {
									setIsSelectOpen(!isSelectOpen);
								}}
							/>
						</div>
					</div>
				</div>

				{/* Dropdown options */}
				<div
					className={`mt-0.5 text-font-select scrollbar border ${borderRadius} h-min z-10 absolute origin-top overflow-auto w-full transform transition-all duration-150 ease-in-out ${
						isSelectOpen ? 'scale-y-100' : 'scale-y-0'
					}`}
					style={{ maxHeight: '350px' }}
				>
					<div className={`w-full bg-white`}>
						{nestedOptions &&
							Object.keys(nestedOptions).map((o, idx) => (
								<>
									<div
										key={idx}
										className={`p-2 text-base flex flex-row justify-between items-center ${
											selectedGroup === o
												? 'bg-change-title bg-opacity-20'
												: ''
										} hover:bg-change-title hover:bg-opacity-10 cursor-pointer`}
										onClick={() => selectGroup(o)}
									>
										{o}
										<FaChevronDown
											className={`text-sm transition-all duration-150 ease-in-out transform hover:text-font-light-1 text-gray-300 cursor-pointer ${
												selectedGroup === o ? 'rotate-180' : ''
											}`}
											onClick={() => selectGroup(o)}
										/>
									</div>
									<div
										className={`origin-top overflow-hidden w-full transform transition-all duration-100 ease-in-out ${
											selectedGroup === o
												? 'scale-y-100 h-full'
												: 'scale-y-0 h-0'
										}`}
									>
										{subGroups &&
											subGroups.map((subG, idx) => (
												<p
													key={idx}
													className="pl-4 py-2.5 text-font-reg text-sm hover:bg-change-title hover:bg-opacity-10 cursor-pointer"
													onClick={() => handleSelect(subG)}
												>
													{subG}
												</p>
											))}

										{subGroups && subGroups.length === 0 && (
											<p className="pl-4 py-2.5 text-font-reg text-sm italic opacity-80">
												Not available
											</p>
										)}
									</div>
								</>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};
