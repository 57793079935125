import { DiagnosticColors, SentimentType } from 'enums';

export const setHeatmapColor = (type: string) => {
	switch (type) {
		case SentimentType.POSITIVE:
			return DiagnosticColors.PURPLE;
		case SentimentType.NEUTRAL:
			return DiagnosticColors.YELLOW;
		case SentimentType.NEGATIVE:
			return DiagnosticColors.ORANGE;
		default:
			return DiagnosticColors.BLUE;
	}
};
