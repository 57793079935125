// @ts-ignore
import FreshChat from 'react-freshchat';

import { useTypedSelector } from 'hooks';

/**
 * Component to render FreshChat widget
 * @author Konstantin Krumin
 * @param chatName - user's chat name
 */
interface ChatWidgetProps {
	chatName: string;
}

export const ChatWidget: React.FC<ChatWidgetProps> = ({ chatName }) => {
	const { userInfo } = useTypedSelector(state => state.user);

	const firstName = chatName.split(' ').slice(0, -1).join(' ') ?? '';
	const lastName = chatName.split(' ').slice(-1).join(' ') ?? '';

	return (
		<FreshChat
			token={process.env.REACT_APP_CHAT_TOKEN}
			host={process.env.REACT_APP_CHAT_HOST}
			config={{
				headerProperty: {
					direction: 'rtl'
				}
			}}
			externalId={userInfo.profileId}
			onInit={(widget: any) => {
				widget.user.setProperties({
					email: userInfo.email,
					firstName: firstName,
					lastName: lastName
				});
			}}
		/>
	);
};
