import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { KpiValueType, IDiagnosticDataInfo } from 'types';

import { ParentWrapper } from './components';
import { CIRCLE_ADDITIONAL_PARAMS } from './constants';

/**
 * A display component showing metrics related to diagnostics highlights
 * @author Konstantin Krumin
 * @param diagnosticInfo - diagnostic meta information and LDM score reference
 * @param isPercentageValue - determines whether the value is integer or percentage value
 */
interface DiagnosticScoreDisplayProps {
	isPercentageValue: boolean;
	diagnosticInfo: IDiagnosticDataInfo;
}

export const DiagnosticScoreDisplay: React.FC<DiagnosticScoreDisplayProps> = ({
	diagnosticInfo,
	isPercentageValue = false
}) => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	return (
		<Execute
			seriesBy={[diagnosticInfo.value]}
			filters={[...demographicFilter, diagnosticInfo.dateFilter]}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				if (!result) {
					return (
						<ParentWrapper>
							<LoadingComponent className="my-8" />
						</ParentWrapper>
					);
				}

				let kpiValue: KpiValueType = 'No Data';

				// Get the latest data point from trendline to display
				if (typeof result !== 'undefined') {
					kpiValue = isPercentageValue
						? Util.createReadablePercentageValue(result)
						: Util.getLatestDataPointVal(result);
				}

				if (execution.isLoading) {
					return (
						<ParentWrapper>
							<LoadingComponent className="my-8" />
						</ParentWrapper>
					);
				} else {
					return (
						<ParentWrapper>
							<div className="flex flex-col text-center h-12">
								<Component.HolText textType="heading3">
									{diagnosticInfo.title}
								</Component.HolText>

								{diagnosticInfo?.subtitle && (
									<Component.HolText textType="baseLight1">
										{diagnosticInfo?.subtitle}
									</Component.HolText>
								)}
							</div>

							<div className="flex justify-center items-center mt-2">
								<Component.ProgressBar
									kpiValue={kpiValue}
									showPercentSign={isPercentageValue}
									circleAdditionalParams={CIRCLE_ADDITIONAL_PARAMS}
									color={Util.getMetricColor(kpiValue, diagnosticInfo.identifier)}
								/>

								{diagnosticInfo?.rightSideLabel && (
									<Component.HolText textType="heading3">
										{diagnosticInfo?.rightSideLabel}
									</Component.HolText>
								)}
							</div>

							{diagnosticInfo.identifier !== 'pulseParticipation' && (
								<div>
									<Component.StackedBarChart
										submetrics={diagnosticInfo.submetrics}
									/>
								</div>
							)}

							{diagnosticInfo.identifier === 'pulseParticipation' && (
								<div className="flex flex-col items-center justify-center space-y-2 p-2">
									<Component.SplineChart
										pageType="action"
										valueType="percentage"
										type={diagnosticInfo.identifier}
										dateFilter={diagnosticInfo.submetrics.dateFilter}
										seriesBy={[diagnosticInfo.submetrics.values[0].value]}
										slicesBy={[Util.getDateAssetByDataView('Week')]}
									/>

									<div className="flex justify-center">
										<Component.HolText textType="heading3">
											The last 7 days
										</Component.HolText>
									</div>
								</div>
							)}
						</ParentWrapper>
					);
				}
			}}
		</Execute>
	);
};
