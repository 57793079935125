import React from 'react';

import { FiPrinter } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import * as Component from 'components';
import * as Analytics from 'utils/analytics';

/**
 * Print / Save PDF button component
 * @author Konstantin Krumin
 * @param documentTitle - name under which the document will be saved
 * @param componentRef - ref to the component, contents of which will be passed to the browser's printing client
 */
interface PrintButtonProps {
	componentRef: React.MutableRefObject<null>;
	documentTitle?: string;
}

export const PrintButton: React.FC<PrintButtonProps> = ({
	componentRef,
	documentTitle = 'document'
}) => {
	const { pathname } = useLocation();

	const handlePrint = useReactToPrint({
		copyStyles: true,
		documentTitle: `${documentTitle}.pdf`,
		content: () => componentRef.current,
		onAfterPrint: () => {
			Analytics.printPage(pathname);
		}
	});

	return (
		<div className="flex items-center print-hide">
			<Component.HolButton
				label="Print"
				type="secondary"
				icon={<FiPrinter />}
				onClick={() => handlePrint()}
			/>
		</div>
	);
};
