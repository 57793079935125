import { combineReducers } from 'redux';

import userReducer from './user';
import errorReducer from './error';
import factorReducer from './factor';
import reportReducer from './report';
import sidebarReducer from './sidebar';
import dataViewReducer from './data-view';
import demographicReducer from './demographic';
import userManagerReducer from './manage-users';
import insightsCommentsReducer from './insights';
import actionPlanningReducer from './action-plan';
import diagnosticFilterReducer from './diagnostics';
import manageConsultantReducer from './manage-consultant';

/**
 * Used so we can create and combine different reducers when necessary
 * @author Matthew Douglas & Misael Esperanzate
 */

const reducers = combineReducers({
	user: userReducer,
	error: errorReducer,
	factor: factorReducer,
	sidebar: sidebarReducer,
	report: reportReducer,
	dataView: dataViewReducer,
	userManager: userManagerReducer,
	demographics: demographicReducer,
	diagnostics: diagnosticFilterReducer,
	actionPlanning: actionPlanningReducer,
	manageConsult: manageConsultantReducer,
	insightsComments: insightsCommentsReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
