import React from 'react';

import { TextType } from 'types';

/**
 * A text component that will be use to render various different text throughout the app
 * @author Matthew Douglas
 * @param styles - additional styling
 * @param textColor - a custom way to adjust the color if needed
 * @param textType - the type of text that will adjust the size, weight, and color of text
 */
interface HolTextProps {
	styles?: string;
	textColor?: string;
	textType?: TextType;
}

export const HolText: React.FC<HolTextProps> = ({ textType, textColor, styles, children }) => {
	// default text type
	let type = 'font-sofia-regular text-base';

	// default text color
	let color = 'text-font-reg';

	if (textType) {
		switch (textType) {
			case 'title':
				type = 'font-new-spirit font-medium text-3xl';
				color = 'text-font-title';
				break;
			case 'heading1':
				type = 'font-new-spirit font-medium text-2xl ';
				break;
			case 'heading2':
				type = 'font-new-spirit font-medium text-xl';
				break;
			case 'heading3':
				type = 'font-new-spirit font-medium text-lg';
				break;
			case 'baseBold':
				type = 'font-sofia-bold text-base';
				break;
			case 'baseLight1':
				type = 'font-sofia-regular text-base opacity-80';
				break;
			case 'baseLight2':
				type = 'font-sofia-regular text-base opacity-40';
				break;
		}
	}

	if (textColor) {
		color = textColor;
	}

	return <p className={`${styles ? styles : type} ${color}`}>{children}</p>;
};
