import React from 'react';
import { InsightView } from '@gooddata/sdk-ui-ext';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { DiagnosticColors, DiagnosticsOptions } from 'enums';

/**
 *  The Light Quality section under Diagnostics that display the light quality / intensity metrics that comes from sensors
 * @author Konstantin Krumin
 */
export const Light: React.FC = () => {
	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const dateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [dateFilter.relativeDateFilter, ...demographicFilter];

	return (
		<div className="grid grid-cols-12 gap-6 print:block">
			<Component.HolCard hideHeader columns="col-span-full">
				<div className="flex justify-between items-start flex-wrap col-span-full sm:col-span-6 lg2:col-span-4 px-5 pt-5">
					<Component.HolText textType="heading1">
						{DiagnosticsOptions.LIGHT}
					</Component.HolText>

					<div className="flex justify-center items-center">
						<Component.MainHeadlineDisplay
							additionalText="nm"
							filters={filtersArr}
							title="Avg Light Spectrum"
							headlineMeasure={Ldm.SensorLight}
						/>
					</div>
				</div>

				<Component.ColumnChart
					chartName="nm"
					columns={[
						{
							name: 'nm',
							color: DiagnosticColors.GREEN,
							value: Ldm.SensorLight
						}
					]}
				/>
			</Component.HolCard>

			<Component.HolCard
				title="Light Heatmap"
				columns="col-span-full"
				styles="print-page-break"
			>
				<div className="mx-auto md2:p-8">
					<div className="pl-2 h-full">
						<InsightView
							filters={filtersArr}
							config={Util.gooddataHeatmapConfig([DiagnosticColors.GREEN])}
							insight={Ldm.Insights.DiagnosticsLightHeatmap}
						/>
					</div>
				</div>
			</Component.HolCard>
		</div>
	);
};
