import React from 'react';

import * as Img from 'img';

import { HolIconType } from 'types';

/**
 * A component that used to generate a custom icon from the ./img folder
 * @author Konstantin Krumin
 * @param iconType - the name of the icon to display
 * @param color - color for the provided icon
 */
interface HolIconProps {
	iconType: HolIconType;
	color: string;
}

export const HolIcon: React.FC<HolIconProps> = ({ iconType, color }) => {
	const generateIcon = (iconType: string, color: string) => {
		switch (iconType) {
			case 'account':
				return <Img.Account fill={color} />;
			case 'action-plans':
				return <Img.ActionPlans fill={color} />;
			case 'change':
				return <Img.Change fill={color} />;
			case 'communication':
				return <Img.Communication fill={color} />;
			case 'diagnostics':
				return <Img.Diagnostics fill={color} />;
			case 'factors':
				return <Img.Factors fill={color} />;
			case 'my-libraries':
				return <Img.MyLibraries fill={color} />;
			case 'overview':
				return <Img.Overview fill={color} />;
			case 'sensors':
				return <Img.Sensors fill={color} />;
			case 'support':
				return <Img.Support fill={color} />;
			case 'users':
				return <Img.Users fill={color} />;
			default:
				return <Img.Logo />;
		}
	};

	const icon = generateIcon(iconType, color);

	return icon;
};
