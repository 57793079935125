import { IAttribute } from '@gooddata/sdk-model';

import * as Util from 'utils';

import { DataViewType } from 'types';

import { Action } from '../actions';

/**
 * This is the data view state of the app that will hold all the information on
 * how the user is visualizing the data based on date criteria and filters
 * @author Matthew Douglas
 */

interface DataViewState {
	type: DataViewType;
	dateAsset: IAttribute;
}

const initialDataViewType: DataViewType = 'Year';

const initialState: DataViewState = {
	type: initialDataViewType,
	dateAsset: Util.getDateAssetByDataView(initialDataViewType)
};

const dataViewReducer = (state: DataViewState = initialState, action: Action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default dataViewReducer;
