import React from 'react';

import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { DataViewType, IGeneralOption } from 'types';

import { DEFAULT_DATAVIEW_OPTIONS } from './constants';

/**
 * A dropdown component to select the date range to filter data from
 * @author Matthew Douglas
 * @param dataViewOptions - an array of data view options
 * @param selectedDataView - currently selected data view type
 * @param showLabel - a boolean that determines whether "View Data by Last" label should be shown
 * @param onValueChange - a callback function that modifies selected date in the parent component
 */
interface DateFilterProps {
	showLabel?: boolean;
	selectedDataView: DataViewType;
	dataViewOptions?: { label: string; value: DataViewType }[];
	onValueChange: (dataView: DataViewType) => void;
}

export const DateFilter: React.FC<DateFilterProps> = ({
	selectedDataView,
	showLabel = true,
	dataViewOptions = DEFAULT_DATAVIEW_OPTIONS,
	onValueChange
}) => {
	const dataViewChangeHandler = (selected: IGeneralOption | null) => {
		onValueChange(selected?.value as DataViewType);
		Analytics.applyDateFilter();
	};

	// handler for changing the value in the dropdown
	const findSelectedValue = () => {
		return dataViewOptions.find(type => type.value === selectedDataView);
	};

	return (
		<div className="flex items-center lg:space-x-2">
			{showLabel && (
				<Component.HolText styles="hidden lg:inline-flex">
					View Data by Last
				</Component.HolText>
			)}
			<Component.HolSingleSelectDropdown
				options={dataViewOptions}
				selectedOption={findSelectedValue()}
				handleSelect={dataViewChangeHandler}
				borderRadius="rounded-xl"
			/>
		</div>
	);
};
