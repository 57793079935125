import { SVGIconProps } from 'types';

export const Logout: React.FC<SVGIconProps> = ({ fill = '#666' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill={fill}>
		<g id="Group_1064" data-name="Group 1064" transform="translate(-1438.657 -1489.743)">
			<path
				id="Path_310"
				data-name="Path 310"
				d="M1447.139,1506.285a2.484,2.484,0,0,0-.386,0c-.169.02-.2-.048-.2-.2q.008-3.513,0-7.025v-6.167c0-.262-.07-.374-.288-.456q-1.884-.708-3.769-1.416c-.406-.153-.812-.309-1.218-.463l.007-.032q4.529,0,9.057,0c.14,0,.151.06.151.172,0,.969,0,7.864,0,8.833a.428.428,0,0,0,.216.423c.289.151.579-.053.579-.412,0-1.12,0-8.166,0-9.286,0-.368-.139-.506-.509-.506h-11.629c-.357,0-.5.139-.5.5v16.352a.413.413,0,0,0,.327.479q1.183.448,2.37.894,2.3.866,4.6,1.73c.372.139.6-.029.6-.428,0-.672,0-1.344,0-2.016,0-.129.029-.18.167-.173a1.932,1.932,0,0,0,.468-.013.38.38,0,0,0,.345-.42A.392.392,0,0,0,1447.139,1506.285Zm-1.359-4.191v6.487c0,.208,0,.211-.2.137l-5.872-2.21c-.275-.1-.273-.108-.273-.4v-15.176c0-.243,0-.239.234-.15l5.337,2.01c.194.072.386.148.583.213a.247.247,0,0,1,.2.278Q1445.776,1497.691,1445.78,1502.094Z"
			/>
			<path
				id="Path_311"
				data-name="Path 311"
				d="M1444.229,1499.068a.41.41,0,0,0-.384-.413.4.4,0,0,0-.407.386c-.01.123-.051.122-.145.085-.233-.093-.469-.181-.7-.267a.394.394,0,0,0-.538.219.387.387,0,0,0,.265.516c.261.1.523.2.784.3.331.124.331.124.335.487a.4.4,0,1,0,.8,0c0-.213,0-.425,0-.638h0C1444.231,1499.516,1444.238,1499.292,1444.229,1499.068Z"
			/>
		</g>
	</svg>
);
