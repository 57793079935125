import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import * as Component from 'components';

import { INavbarData } from '../../types';
import { setIconMargins } from '../../helpers';

/**
 * A subnavigation component
 * @author Misael Esperanzate
 * @param item - the object that has info of each specific page
 */
interface SubnavigationProps {
	item: INavbarData;
}

export const Subnavigation: React.FC<SubnavigationProps> = ({ item }) => {
	const location = useLocation();

	// function that checks which page we are currently on and change color
	const navIconColor = location.pathname === item.path ? '#fff' : item.color;
	const navBgColor = location.pathname === item.path ? item.color : '#fff';

	return (
		<div
			className="px-5 py-2 justify-items-center items-center rounded-sm w-full"
			style={{ backgroundColor: navBgColor }}
		>
			<NavLink
				to={item.path}
				className={`block ${
					item.path === location.pathname ? 'text-white' : 'text-hol-grey'
				}`}
			>
				<div className="flex flex-grow items-start">
					<span className={`h-6 w-6 ${setIconMargins(item.iconType)}`}>
						<Component.HolIcon iconType={item.iconType} color={navIconColor} />
					</span>
					<span
						className={`text-lg font-new-spirit ${
							item.category === 'main-category' && 'font-medium'
						}`}
					>
						{item.title}
					</span>
				</div>
			</NavLink>
		</div>
	);
};
