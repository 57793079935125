import * as Util from 'utils';

import { useAxiosPrivate } from 'hooks/useAxiosPrivate';

import { IGooddataWorkspaceUsers, UserStatusType } from './types';

// Get users in the current workspace from Gooddata
export const UserApi = () => {
	const { gooddataAxios, serverAxios } = useAxiosPrivate();

	const getWorkspaceUsersCount = async (workspaceId: string | number) => {
		return gooddataAxios
			.get<IGooddataWorkspaceUsers>(
				`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/projects/${workspaceId}/users`
			)
			.then(res => {
				return res.data.users.filter(
					({ user }) =>
						user.content.status === 'ENABLED' && user.meta.title !== 'Technical User'
				).length;
			})
			.catch(err => {
				Util.logErrorMessage(err);
			});
	};

	const getWorkspaceUsers = async (workspaceId: string | number) => {
		return gooddataAxios
			.get<IGooddataWorkspaceUsers>(
				`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/projects/${workspaceId}/users`
			)
			.then(res => {
				return res.data.users
					.filter(
						({ user }) =>
							user.content.status === 'ENABLED' &&
							user.meta.title !== 'Technical User'
					)
					.map(({ user }) => {
						const roleUri = user.content.userRoles[0].split('/');
						const roleId = roleUri[roleUri.length - 1];

						return {
							email: user.content.email,
							firstname: user.content.firstname,
							lastname: user.content.lastname,
							status: user.content.status,
							userRole: Util.getHolmetricsRoleName(roleId),
							profileId: user.links.self.split('/').pop()!
						};
					});
			})
			.catch(err => {
				Util.logErrorMessage(err);
			});
	};

	const sendInvite = async (
		email: string,
		currentUserEmail: string,
		workspaceId: string,
		workspaceName: string
	) => {
		return serverAxios
			.post(`${process.env.REACT_APP_HOST}/invite/send`, {
				email: email,
				sent_by: currentUserEmail,
				workspace: workspaceId,
				workspaceName: workspaceName
			})
			.then(res => {
				if (res.status !== 200) {
					return {
						message: `${res.data}`,
						bgColor: 'bg-hol-yellow',
						borderColor: 'border-hol-yellow'
					};
				}
				return {
					message: `An invitation was sent to ${email}. The invite link will expire in 7 days.`,
					bgColor: 'bg-hol-dark-green',
					borderColor: 'border-hol-dark-green'
				};
			})
			.catch(err => {
				return {
					message: `${err}`,
					bgColor: 'bg-hol-red',
					borderColor: 'border-hol-red'
				};
			});
	};

	// Disables user from viewing workspace, but can be enabled again without re-inviting
	const deactivateUser = async (workspaceId: string, userId: string) => {
		return await changeUserStatus(workspaceId, userId, 'DISABLED');
	};

	const enableUser = async (workspaceId: string, userId: string) => {
		return await changeUserStatus(workspaceId, userId, 'ENABLED');
	};

	const changeUserStatus = async (
		workspaceId: string,
		userId: string,
		status: UserStatusType
	) => {
		return gooddataAxios
			.post(
				`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/projects/${workspaceId}/users`,
				{
					user: {
						content: {
							status: status
						},
						links: {
							self: `/gdc/account/profile/${userId}`
						}
					}
				}
			)
			.then(res => {
				return res;
			})
			.catch(err => {
				Util.logErrorMessage(err);
			});
	};

	// Delete user account from domain
	const deleteUserFromDomain = async (profileId: string) => {
		return gooddataAxios
			.delete(
				`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/account/profile/${profileId}`
			)
			.then(res => {
				return res;
			})
			.catch(err => {
				Util.logErrorMessage(err);
			});
	};

	return {
		getWorkspaceUsersCount,
		deleteUserFromDomain,
		getWorkspaceUsers,
		deactivateUser,
		enableUser,
		sendInvite
	};
};
