import ReactGA from 'react-ga';

import { ICurrentDiagnostic } from 'types';

enum Category {
	edit = 'edit',
	print = 'print',
	filter = 'filter',
	select = 'select',
	create = 'create',
	delete = 'delete',
	archive = 'archive',
	unarchive = 'unarchive',
	checkTooltip = 'check-tooltip',
	createComment = 'create-comment',
	seeCommentsOverlay = 'see-comments-overlay',
	openCreateCommentOverlay = 'open-create-comment-overlay'
}

/** FILTERS RELATED ANALYTICS */

export const applyDateFilter = () => {
	const action = 'applied-date-filter';

	ReactGA.event({
		action: action,
		category: Category.filter,
		label: `${action}-on-diagnostics-page`
	});
};

export const applyDemographicFilter = () => {
	const action = 'applied-demographic-filter';

	ReactGA.event({
		action: action,
		category: Category.filter,
		label: `${action}-on-diagnostics-page`
	});
};

/** INSIGHTS COMMENTS RELATED ANALYTICS */

export const openCreateCommentOverlay = (factorName: string) => {
	const action = `opened-create-comment-overlay-for-${factorName.toLowerCase()}`;

	ReactGA.event({
		action: action,
		label: `${action}-on-factors-page`,
		category: Category.openCreateCommentOverlay
	});
};

export const seeCommentsOverlay = (factorType: string) => {
	const action = `saw-comments-overlay-for-${factorType.toLowerCase()}`;

	ReactGA.event({
		action: action,
		label: `${action}-on-factors-page`,
		category: Category.seeCommentsOverlay
	});
};

export const createComment = (factorName: string) => {
	const action = `created-comment-for-${factorName.toLowerCase()}`;

	ReactGA.event({
		action: action,
		category: Category.createComment,
		label: `${action}-on-factors-page`
	});
};

export const updateComment = () => {
	const action = 'updated-comment';

	ReactGA.event({
		action: action,
		category: Category.edit,
		label: `${action}-on-factors-page`
	});
};

export const deleteComment = () => {
	const action = 'deleted-comment';

	ReactGA.event({
		action: action,
		category: Category.delete,
		label: `${action}-on-factors-page`
	});
};

/** CHANGE CHART RELATED ANALYTICS  */

export const createNewReport = () => {
	const action = 'created-new-report';

	ReactGA.event({
		action: action,
		category: Category.create,
		label: `${action}-on-change-page`
	});
};

export const deleteExistingReport = () => {
	const action = 'deleted-existing-report';

	ReactGA.event({
		action: action,
		category: Category.delete,
		label: `${action}-on-change-page`
	});
};

/** ACTION PLAN RELATED ANALYTICS */

export const createNewActionPlan = () => {
	const action = 'created-new-action-plan';

	ReactGA.event({
		action: action,
		category: Category.create,
		label: `${action}-on-action-plan-page`
	});
};

export const duplicateActionPlan = () => {
	const action = 'created-duplicate-action-plan';

	ReactGA.event({
		action: action,
		category: Category.create,
		label: `${action}-on-action-plan-page`
	});
};

export const editExistingActionPlan = () => {
	const action = 'edited-existing-action-plan';

	ReactGA.event({
		action: action,
		category: Category.edit,
		label: `${action}-on-action-plan-page`
	});
};

export const deleteExistingActionPlan = () => {
	const action = 'deleted-existing-action-plan';

	ReactGA.event({
		action: action,
		category: Category.delete,
		label: `${action}-on-action-plan-page`
	});
};

export const archiveActionPlan = () => {
	const action = 'archived-action-plan';

	ReactGA.event({
		action: action,
		category: Category.archive,
		label: `${action}-on-action-plan-page`
	});
};

export const unarchiveActionPlan = () => {
	const action = 'unarchived-action-plan';

	ReactGA.event({
		action: action,
		category: Category.unarchive,
		label: `${action}-on-action-plan-page`
	});
};

/** MISCELLANEOUS ANALYTICS */

export const changeDemographicType = () => {
	const action = 'changed-demographic-type';

	ReactGA.event({
		action: action,
		category: Category.select,
		label: `${action}-on-factors-page`
	});
};

export const changeWorkspace = () => {
	const action = 'changed-workspace';

	ReactGA.event({
		action: action,
		category: Category.select,
		label: `${action}-in-navbar`
	});
};

export const changeDiagnosticsType = (selectedDiagnostics: ICurrentDiagnostic) => {
	const action = `changed-diagnostics-type-to-${selectedDiagnostics.value.toLowerCase()}`;

	ReactGA.event({
		action: action,
		category: Category.select,
		label: `${action}-on-diagnostics-page`
	});
};

export const checkTooltipInfo = (factorType: string) => {
	const action = `checked-info-on-${factorType}-tooltip`;

	ReactGA.event({
		action: action,
		category: Category.checkTooltip,
		label: `${action}-on-factors-page`
	});
};

export const printPage = (currentPage: string) => {
	const action = 'print-page';

	if (currentPage === '/') {
		currentPage = 'home';
	} else {
		currentPage = currentPage.replace(/\//g, '');
	}

	ReactGA.event({
		action: action,
		category: Category.print,
		label: `${action}-on-${currentPage}-page`
	});
};
