import React, { useEffect, useRef, useState } from 'react';

import { FaChevronDown } from 'react-icons/fa';

import * as Component from 'components';

import { IGeneralOption } from 'types';
import { useOnClickOutside } from 'hooks';

import { SelectedValue } from './components';

/**
 * A regular dropdown single-select component
 * @author Michaela Mempin
 * @param borderRadius - border radius
 * @param textSize - text size of the element
 * @param selectedOption - contains selected option
 * @param options - contains available options for selection
 * @param hoverBgColor - background color to be shown on hover
 * @param placeholder - placeholder text to be shown by default
 * @param selectNoneLabel - a label to show when no options were selected
 * @param selectNone - a boolean determining whether nothing has been selected
 * @param handleSelect - a callback function that passes the selected option to the parent component
 */
interface HolSingleSelectDropdownProps {
	textSize?: string;
	selectNone?: boolean;
	placeholder?: string;
	hoverBgColor?: string;
	borderRadius?: string;
	selectNoneLabel?: string;
	options: IGeneralOption[] | undefined;
	selectedOption?: IGeneralOption | null;
	handleSelect: (opt: IGeneralOption | null) => void;
}

export const HolSingleSelectDropdown: React.FC<HolSingleSelectDropdownProps> = ({
	options,
	selectNone = false,
	selectedOption = null,
	textSize = 'text-base',
	selectNoneLabel = 'None',
	placeholder = 'Select...',
	borderRadius = 'rounded-md',
	hoverBgColor = 'bg-change-title',
	handleSelect
}) => {
	const SelectNone = {
		label: selectNoneLabel,
		value: null
	};

	const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
	const [selected, setSelected] = useState<IGeneralOption | null>();

	useEffect(() => {
		setSelected(selectedOption);
	}, [selectedOption]);

	// Close the dropdown select if user clicks outside of component.
	const node = useRef<HTMLInputElement | null>(null);
	useOnClickOutside(node, () => setIsOpenDropdown(false));

	const handleSelectedOption = (select: IGeneralOption) => {
		if (select.value === null) {
			setSelected(null);
			handleSelect(null);
		} else {
			setSelected(select);
			handleSelect(select);
		}
	};

	return (
		<div className="select-none bg-white flex flex-col">
			<div
				className={`relative duration-150 ease-in-out w-full ${textSize}`}
				ref={node}
				onClick={() => {
					setIsOpenDropdown(!isOpenDropdown);
				}}
			>
				<div
					className={`flex flex-row items-center cursor-pointer justify-between border border-input-border hover:border-font-light-1 ${borderRadius} px-2.5 transition-all duration-150 ease-in-out`}
					style={{ minHeight: '35px' }}
				>
					{/* Show loading if there are no options yet or Display what is currently selected */}

					<SelectedValue
						options={options}
						selected={selected}
						placeholder={placeholder}
						textSize={textSize}
					/>

					<p className="p-3.5"></p>

					{/* Chevron to indicate dropdown open/close */}
					<div className="border-l pl-3 pr-1">
						<FaChevronDown
							className={`transition-all duration-150 ease-in-out transform hover:text-font-light-1 text-gray-300 ${textSize} cursor-pointer ${
								isOpenDropdown ? 'rotate-180' : ''
							}`}
							onClick={() => {
								setIsOpenDropdown(!isOpenDropdown);
							}}
						/>
					</div>
				</div>

				{/* List of options */}
				<div
					className={`h-min z-20 absolute origin-top overflow-hidden top-9 w-full transform transition-all duration-150 ease-in-out ${
						isOpenDropdown ? 'scale-y-100' : 'scale-y-0'
					}`}
				>
					<div
						className={`${textSize} text-font-select bg-white transition-all border overflow-hidden ${borderRadius} ease-in-out duration-300 transform`}
					>
						{options && options.length > 0 ? (
							options.map((opt, idx) => (
								<p
									key={idx}
									className={`${
										selected?.label === opt.label ? hoverBgColor : 'white'
									} bg-opacity-20 p-2 hover:${hoverBgColor} hover:bg-opacity-10 cursor-pointer`}
									onClick={() => handleSelectedOption(opt)}
								>
									{opt.label}
								</p>
							))
						) : (
							<div className="flex items-center m-2">
								<Component.HolText textType="baseLight2">
									No options available
								</Component.HolText>
							</div>
						)}

						{selectNone && (
							<>
								<hr className="mx-2 mt-5 border-gray-300" />

								<p
									className={`p-2 hover:${hoverBgColor} hover:bg-opacity-10 cursor-pointer`}
									onClick={() => handleSelectedOption(SelectNone)}
								>
									{SelectNone.label}
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
