import { IActionPlan, IArchivedActionPlan } from 'types';

import { Action } from '../actions';
import ActionType from '../action-types';

/**
 * This is the state where all the action planning state resides
 * @author Konstantin Krumin
 */

interface ActionPlanningState {
	actionPlans: IActionPlan[];
	activeActionPlans: IActionPlan[];
	archivedActionPlans: IArchivedActionPlan[];
	selectedActionPlan: IActionPlan | undefined;
	totalActionPlansCount: number;
	totalActiveActionPlansCount: number;
	totalArchivedActionPlansCount: number;
	itemsPerPage: number;
	activeItemsPerPage: number;
	archivedItemsPerPage: number;
	currentPage: number;
	activeCurrentPage: number;
	archivedCurrentPage: number;
}

const initialState: ActionPlanningState = {
	actionPlans: [],
	activeActionPlans: [],
	archivedActionPlans: [],
	selectedActionPlan: undefined,
	totalActionPlansCount: 0,
	totalActiveActionPlansCount: 0,
	totalArchivedActionPlansCount: 0,
	itemsPerPage: 4,
	activeItemsPerPage: 4,
	archivedItemsPerPage: 10,
	currentPage: 1,
	activeCurrentPage: 1,
	archivedCurrentPage: 1
};

const actionPlanningReducer = (state: ActionPlanningState = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_ACTION_PLAN:
			return {
				...state,
				selectedActionPlan: action.payload.actionPlan
			};
		case ActionType.SET_ACTION_PLANS:
			return {
				...state,
				actionPlans: action.payload.actionPlans
			};
		case ActionType.SET_ACTIVE_ACTION_PLANS:
			return {
				...state,
				activeActionPlans: action.payload.activeActionPlans
			};
		case ActionType.SET_ARCHIVED_ACTION_PLANS:
			return {
				...state,
				archivedActionPlans: action.payload.archivedActionPlans
			};
		case ActionType.SET_ACTION_PLANS_COUNT:
			return {
				...state,
				totalActionPlansCount: action.payload.totalActionPlansCount
			};
		case ActionType.SET_ACTIVE_ACTION_PLANS_COUNT:
			return {
				...state,
				totalActiveActionPlansCount: action.payload.totalActiveActionPlansCount
			};
		case ActionType.SET_ARCHIVED_ACTION_PLANS_COUNT:
			return {
				...state,
				totalArchivedActionPlansCount: action.payload.totalArchivedActionPlansCount
			};
		case ActionType.SET_ACTION_PLAN_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload.currentPage
			};
		case ActionType.SET_ACTIVE_ACTION_PLAN_CURRENT_PAGE:
			return {
				...state,
				activeCurrentPage: action.payload.activeCurrentPage
			};
		case ActionType.SET_ARCHIVED_ACTION_PLAN_CURRENT_PAGE:
			return {
				...state,
				archivedCurrentPage: action.payload.archivedCurrentPage
			};
		case ActionType.CREATE_OR_DUPLICATE_OR_EDIT_ACTION_PLAN:
			return {
				...state,
				actionPlans: [...state.actionPlans, action.payload.actionPlan]
			};
		case ActionType.DELETE_ACTION_PLAN:
			const filteredActionPlans = state.actionPlans.filter(
				actionPlan => actionPlan.uuid !== action.payload.actionPlanUuid
			);
			return {
				...state,
				actionPlans: filteredActionPlans
			};
		default:
			return state;
	}
};

export default actionPlanningReducer;
