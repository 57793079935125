import React from 'react';
import { InsightView } from '@gooddata/sdk-ui-ext';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { DiagnosticColors, DiagnosticsOptions } from 'enums';

/**
 * The Email section under Diagnostics that display the results of emails sent and received
 * @author Misael Esperanzate & Konstantin Krumin
 */
export const Emails: React.FC = () => {
	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const dateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [dateFilter.relativeDateFilter, ...demographicFilter];

	return (
		<div className="grid grid-cols-12 gap-6 print:block">
			<Component.HolCard hideHeader columns="col-span-full">
				<div className="flex flex-col md2:flex-row justify-between col-span-full sm:col-span-6 lg2:col-span-4 p-5 print:flex-row">
					<Component.HolText textType="heading1">
						{DiagnosticsOptions.EMAILS}
					</Component.HolText>

					<div className="flex flex-col md:flex-row md:items-center print:flex-row print:items-center">
						<Component.MainHeadlineDisplay
							title="Total Emails"
							filters={filtersArr}
							headlineMeasure={Ldm.EmailsDayTotal.Sum}
						/>

						<Component.DiagnosticsCard
							tableTitles={{
								firstRow: 'Avg # Emails/Emp/Day',
								secondRow: 'Outside of Work Hours',
								firstCol: 'Sent',
								secondCol: 'Received'
							}}
							showTableHead
							filters={filtersArr}
							measuresCollection={{
								A1: {
									measureIndicator: Ldm.EmailsAVGSentWeekdays,
									dataType: 'integer'
								},
								A2: {
									measureIndicator: Ldm.EmailsPercentSentOWH,
									dataType: 'percentage'
								},
								B1: {
									measureIndicator: Ldm.EmailsAVGReceivedWeekdays,
									dataType: 'integer'
								},
								B2: {
									measureIndicator: Ldm.EmailsPercentReceivedOWH,
									dataType: 'percentage'
								}
							}}
						/>
					</div>
				</div>

				<Component.ColumnChart
					valueType="integer"
					chartName="Email(s)"
					columns={[
						{
							name: 'Received',
							color: DiagnosticColors.BLUE,
							value: Ldm.EmailsDayReceived.Avg
						},
						{
							name: 'Sent',
							color: DiagnosticColors.PURPLE,
							value: Ldm.EmailsDaySent.Avg
						}
					]}
				/>
			</Component.HolCard>

			<Component.HolCard
				title="Emails Sent Heatmap"
				columns="col-span-full"
				styles="print-page-break"
			>
				<div className="p-8 mx-auto">
					<div className="pl-2 h-full">
						<InsightView
							filters={filtersArr}
							insight={Ldm.Insights.EmailsSentHeatmap}
							config={Util.gooddataHeatmapConfig([DiagnosticColors.PURPLE])}
						/>
					</div>
				</div>
			</Component.HolCard>

			<Component.HolCard
				title="Emails Received Heatmap"
				styles="print-page-break"
				columns="col-span-full"
			>
				<div className="mx-auto md2:p-8">
					<div className="pl-2 h-full">
						<InsightView
							filters={filtersArr}
							config={Util.gooddataHeatmapConfig([DiagnosticColors.BLUE])}
							insight={Ldm.Insights.EmailsReceivedHeatmap}
						/>
					</div>
				</div>
			</Component.HolCard>
		</div>
	);
};
