import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';

import { Insight } from './components';
import { IQuestionData } from './types';

/**
 * Component that handles displaying of the insights / predictive indicators visualizations
 * @author Konstantin Krumin
 * @param factorName - current factor that has been selected for filtering
 */
interface InsightsProps {
	factorName: string;
}

export const Insights: React.FC<InsightsProps> = ({ factorName }) => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	return (
		<Execute
			seriesBy={[Ldm.Score.Sum]}
			slicesBy={[Ldm.QuestionText, Ldm.QuestionId]}
			filters={[...demographicFilter, newPositiveAttributeFilter(Ldm.Factor, [factorName])]}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				if (!result) {
					return (
						<div>
							<LoadingComponent className="my-8" />
						</div>
					);
				}

				const slices = Util.extractDataSeriesInArr(result);

				const questionsTextArr: IQuestionData[] = [];

				let totalCount = 0;

				// get all titles and total responses from the slice
				slices?.forEach(slice =>
					slice.dataPoints().forEach(datapoint => {
						const [title, questionId] = datapoint.sliceDesc!.sliceTitles();
						totalCount = parseInt(datapoint.formattedValue()!);

						questionsTextArr.push({
							title: title,
							questionId: questionId
						});
					})
				);

				return (
					<>
						{questionsTextArr.map((question, idx) => (
							<Insight
								questionData={question}
								factorName={factorName}
								totalCount={totalCount}
								key={idx}
							/>
						))}

						{questionsTextArr.length === 0 && (
							<div className="mx-5">
								<Component.HolText textType="heading3">
									No data is available
								</Component.HolText>
							</div>
						)}
					</>
				);
			}}
		</Execute>
	);
};
