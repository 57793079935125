import { ICircleAdditionalParams } from 'types';

export const OVERVIEW_PAGE_CIRCLE_PARAMS: ICircleAdditionalParams = {
	size: 224,
	radius: 73,
	rotate: 112,
	textSize: 'text-5xl',
	strokeWidth: '1rem'
};

export const FACTOR_PAGE_CIRCLE_PARAMS: ICircleAdditionalParams = {
	size: 126,
	radius: 41.25,
	rotate: 63,
	textSize: 'text-2xl',
	strokeWidth: '0.7rem'
};
