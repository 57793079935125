import React, { useRef } from 'react';

import * as Component from 'components';

import { useActions, useTypedSelector } from 'hooks';
import { FACTOR_TYPES, FACTORS_INFO } from 'constants/dataset';

/**
 * The factors page where all of the information about the organization factors is presented
 * @author Misael Esperanzate & Matthew Douglas
 */
export const Factors: React.FC = () => {
	const componentRef = useRef(null);

	const factor = useTypedSelector(state => state.factor);
	const { setSelectedFactor } = useActions();

	return (
		<div ref={componentRef} className="flex flex-col max-w-screen-2xl mx-auto print:block">
			<Component.PrintOrientation orientation="portrait" />
			<Component.PageHeader
				showPrintButton
				showLastUpdatedText
				showDemographicFilter
				parentComponentRef={componentRef}
				pageType="factors"
			/>

			<div className="grid md2:grid-cols-4 xl2:grid-cols-8 gap-4 mb-4 print:flex print:flex-wrap">
				{/* Factors */}
				{FACTOR_TYPES.map(factorType => {
					const factorInfo = FACTORS_INFO[factorType];

					return (
						<Component.FactorCardAdvanced
							key={factorType}
							factorInfo={factorInfo}
							isCardSelected={factor.selectedCard === factorType}
							onSelectFactor={() => {
								setSelectedFactor({
									factorState: factorInfo,
									selectedCard: factorInfo.identifier
								});
							}}
						/>
					);
				})}
			</div>

			{/* Factors Heatmap */}
			<Component.FactorsHeatmapChart />

			{/* Predictive Indicators and Insights */}
			<Component.HolCard
				columns="col-span-full"
				styles="print-page-break"
				headerTitleTextType="heading1"
				cardBackgroundColor="bg-transparent"
				topRightElement={<Component.InsightsCommentsTable />}
			>
				<div className="grid grid-cols-12 gap-6">
					<Component.HolCard
						title={`Predictive Indicators - ${factor.factorState.title}`}
						headerTitleTextType="heading1"
						columns="col-span-full xl:col-span-6"
						headerHorizontalMargin="mx-2"
						styles="p-3"
					>
						<Component.Insights factorName={factor.factorState.title} />
					</Component.HolCard>
				</div>
			</Component.HolCard>
		</div>
	);
};
