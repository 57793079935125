import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import * as Component from 'components';

/**
 * Reusable component to create pagination
 * This component requires to pass the whole dataset (cannot pass data split in chunks with page & limit query params)
 * @author Michaela Mempin
 * @param iconColor - The color of the progress bar
 * @param bgColor - sets up a background color for pagination component overall
 * @param hideNumItems - a boolean that hides the count of total number of items
 * @param itemList - a parameter that determines total number of items in the list
 * @param itemsPerPage - a parameter that determines how many items will show per page
 * @param currentListLength - a parameter that determines how many items are showing on the current page
 * @param setPageList - function that gets triggered on page change
 */
interface StatefulPaginationProps {
	itemList: any[];
	bgColor: string;
	iconColor: string;
	arrayName?: string;
	itemsPerPage: number;
	hideNumItems?: boolean;
	setPageList: React.Dispatch<React.SetStateAction<any[]>>;
}

export const StatefulPagination: React.FC<StatefulPaginationProps> = ({
	bgColor,
	itemList,
	iconColor,
	itemsPerPage,
	hideNumItems,
	arrayName = 'item(s)',
	setPageList
}) => {
	const [currentPage, setCurrentPage] = useState<number>(1);

	const numberOfPages = Math.ceil(itemList.length / itemsPerPage);
	const pages = Array.from(Array(numberOfPages).keys());

	const lastPostIndex = currentPage * itemsPerPage;
	const firstPostIndex = lastPostIndex - itemsPerPage;
	const currentList = [...itemList].slice(firstPostIndex, lastPostIndex);

	useEffect(() => {
		setPageList(currentList);
		// eslint-disable-next-line
	}, [currentPage]);

	const handleNextPage = () => {
		if (currentPage < numberOfPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const pageButtons = pages.map((_, idx) => {
		const pageNum = idx + 1;

		const primaryColor = currentPage === pageNum ? `${bgColor}` : 'bg-transparent';
		const textColor = currentPage === pageNum ? 'text-white' : 'text-font-dark';
		const textWeight = currentPage === pageNum ? 'font-sofia-bold' : 'font-sofia-reg';
		const hoverEffect = currentPage === pageNum ? '' : `hover:bg-opacity-30 hover:${bgColor}`;

		return (
			<ul
				key={pageNum}
				className="cursor-pointer"
				onClick={() => {
					setCurrentPage(idx + 1);
				}}
			>
				<Component.HolAvatar
					size={33}
					color={`${primaryColor} ${hoverEffect} transition ease-in-out`}
					content={<p className={`${textWeight} ${textColor} text-sm`}>{idx + 1}</p>}
				/>
			</ul>
		);
	});

	return (
		<div className="flex py-3 justify-between">
			{!hideNumItems ? (
				<p className="font-sofia-reg text-sm">
					Showing
					<span className="font-sofia-bold">
						{' '}
						{firstPostIndex + 1}-{firstPostIndex + currentList.length}{' '}
					</span>
					from
					<span className="font-sofia-bold"> {itemList.length} </span>
					{arrayName}
				</p>
			) : (
				<span></span>
			)}

			<div className="flex relative space-x-3 px-5 justify-end">
				<button className="flex group ml-2" onClick={handlePrevPage}>
					<FaChevronLeft
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 my-auto left-0 inset-y-1/2 absolute`}
					/>
					<FaChevronLeft
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 text-opacity-50 my-auto left-2 inset-y-1/2 absolute`}
					/>
				</button>

				{pageButtons}

				<button className="flex group" onClick={handleNextPage}>
					<FaChevronRight
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 my-auto right-0 inset-y-1/2 absolute`}
					/>
					<FaChevronRight
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 text-opacity-50 my-auto right-2 inset-y-1/2 absolute`}
					/>
				</button>
			</div>
		</div>
	);
};
