import { RoleType } from 'types';
import { USER_ROLES } from 'constants/misc';

export const getRoleOptions = (userRole: RoleType) => {
	// If user is 'Holmetrics', can select all options ['Holmetrics', 'Consultant Admin', 'Consultant', 'Organization Admin', 'Organization Viewer']
	const allRoles = Object.keys(USER_ROLES);
	let validRoles = allRoles;

	// If user is not holmetrics, can select roles below them.
	// User is 'Consultant Admin' can select  ['Consultant', 'Organization Admin', 'Organization Viewer']
	// User is 'Consultant' can select  ['Organization Admin', 'Organization Viewer']....
	if (userRole !== 'Holmetrics') {
		const lengthRoles = allRoles.length;
		const roleIndex = allRoles.indexOf(userRole) + 1;
		validRoles = allRoles.slice(roleIndex - lengthRoles);
	}

	const options = validRoles.map(role => ({
		label: role,
		value: role
	}));

	return options;
};

/** This function compares role hierarchy */
export const isHigherRole = (currentUserRole: RoleType, userRole: RoleType) => {
	const roles = Object.keys(USER_ROLES);
	const currentIdx = roles.indexOf(currentUserRole);
	const userIdx = roles.indexOf(userRole);

	return currentIdx <= userIdx;
};
