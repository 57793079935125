import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import * as Component from 'components';

/**
 * Reusable component to create pagination
 * This component has no internal state for page control - it accepts it as props
 * @author Konstantin Krumin & Michaela Mempin
 * @param arrayName - the name of current array
 * @param iconColor - The color of the progress bar
 * @param currentPage - indicates the current page that is showing right now
 * @param bgColor - sets up a background color for pagination component overall
 * @param hideNumItems - a boolean that hides the count of total number of items
 * @param totalCount - a parameter that shows how many items in that dataset overall
 * @param itemsPerPage - a parameter that determines how many items will show per page
 * @param currentListLength - a parameter that determines how many items are showing on the current page
 * @param onChangePage - function that gets triggered on page change
 */
interface StatelessPaginationProps {
	bgColor: string;
	iconColor: string;
	arrayName?: string;
	totalCount: number;
	currentPage: number;
	itemsPerPage: number;
	hideNumItems?: boolean;
	currentListLength: number;
	onChangePage: (updatedPageNum: number) => void;
}

export const StatelessPagination: React.FC<StatelessPaginationProps> = ({
	bgColor,
	iconColor,
	totalCount,
	currentPage,
	itemsPerPage,
	hideNumItems,
	currentListLength,
	arrayName = 'items(s)',
	onChangePage
}) => {
	const numberOfPages = Math.ceil(totalCount / itemsPerPage);
	const pages = Array.from(Array(numberOfPages).keys());

	const lastPostIndex = currentPage * itemsPerPage;
	const firstPostIndex = lastPostIndex - itemsPerPage;

	const handleNextPage = () => {
		if (currentPage < numberOfPages) {
			onChangePage(currentPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			onChangePage(currentPage - 1);
		}
	};

	const pageButtons = pages.map((_, idx) => {
		const pageNum = idx + 1;

		const primaryColor = currentPage === pageNum ? `${bgColor}` : 'bg-transparent';
		const textColor = currentPage === pageNum ? 'text-white' : 'text-font-dark';
		const textWeight = currentPage === pageNum ? 'font-sofia-bold' : 'font-sofia-reg';
		const hoverEffect = currentPage === pageNum ? '' : `hover:bg-opacity-30 hover:${bgColor}`;

		return (
			<ul
				key={pageNum}
				className="cursor-pointer"
				onClick={() => {
					onChangePage(idx + 1);
				}}
			>
				<Component.HolAvatar
					color={`${primaryColor} ${hoverEffect} transition ease-in-out`}
					content={<p className={`${textWeight} ${textColor} text-sm`}>{idx + 1}</p>}
					size={33}
				/>
			</ul>
		);
	});

	return (
		<div className="flex py-3 justify-between print-hide">
			{!hideNumItems ? (
				<p className="font-sofia-reg text-sm">
					Showing{' '}
					<span className="font-sofia-bold">
						{totalCount > 0
							? `${firstPostIndex + 1}-${firstPostIndex + currentListLength}`
							: '0'}
					</span>{' '}
					from <span className="font-sofia-bold"> {totalCount} </span>
					{arrayName}
				</p>
			) : (
				<span></span>
			)}

			<div className="flex relative space-x-3 px-5 justify-end">
				<button className="flex group ml-2" onClick={handlePrevPage}>
					<FaChevronLeft
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 my-auto left-0 inset-y-1/2 absolute`}
					/>
					<FaChevronLeft
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 text-opacity-50 my-auto left-2 inset-y-1/2 absolute`}
					/>
				</button>

				{pageButtons}

				<button className="flex group" onClick={handleNextPage}>
					<FaChevronRight
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 my-auto right-0 inset-y-1/2 absolute`}
					/>
					<FaChevronRight
						className={`${iconColor} transform transition ease-in-out group-hover:scale-125 duration-200 text-opacity-50 my-auto right-2 inset-y-1/2 absolute`}
					/>
				</button>
			</div>
		</div>
	);
};
