import { IDemographicFilter, IDemographicSelected } from 'types';

import ActionType from '../action-types';
import { SetDemographicAction } from '../actions';

/**
 * Sets demographics filter selection
 * @author Konstantin Krumin
 * @param demographics - an array of objects w/ selected demographics info and corresponding groups
 */
export const setDemographic = (
	demographics: IDemographicSelected[],
	selectedDemographics: { [key: string]: IDemographicFilter }
): SetDemographicAction => {
	return {
		type: ActionType.SET_DEMOGRAPHIC,
		payload: {
			filter: demographics,
			selected: selectedDemographics
		}
	};
};
