import { useEffect, useState } from 'react';

import {
	IMeasure,
	IAttribute,
	IAbsoluteDateFilter,
	IMeasureDefinitionType,
	IPositiveAttributeFilter,
	newPositiveAttributeFilter
} from '@gooddata/sdk-model';
import { DataValue, IDataView } from '@gooddata/sdk-backend-spi';
import { useBackend, useCancelablePromise } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';

import {
	IReport,
	ISeriesConfig,
	IReportDataConfig,
	ReportGDDiagnosticType,
	ReportDiagnosticMetricType
} from 'types';
import { useTypedSelector, useWorkspaceKpis } from 'hooks';
import { DEMOGRAPHIC_LDM, REPORT_DIAGNOSTIC_METRIC_LDM } from 'constants/dataset';
import { REPORT_DIAGNOSTIC_COLORS, REPORT_FACTOR_COLORS, REPORT_KPI_COLORS } from 'constants/misc';

import { SPLINE_DASHES } from './constants';
import { getChartData, getHeaderItemNames } from './helpers';

export const useChangeData = (
	reportInfo: IReport,
	dataConfig: IReportDataConfig,
	monthAxis: string[]
) => {
	const backend = useBackend();
	const { departmentKpis } = useWorkspaceKpis();
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);
	const reportData = JSON.parse(reportInfo.data);

	const [chartSeries, setChartSeries] = useState<ISeriesConfig[] | null>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [groupLineStyle, setGroupLineStyle] = useState<{ [id: string]: string }>();

	const getExecuteResult = async (
		items: any,
		filters: (IAbsoluteDateFilter | IPositiveAttributeFilter)[]
	) => {
		const result = await backend
			?.workspace(currentWorkspace.id)
			.execution()
			.forItems(items, filters)
			.execute()
			.catch(() => {
				return Promise.reject();
			});

		const res = await result?.readAll();
		return res;
	};

	const getMeasures = (
		measures: string[],
		data: DataValue[][],
		demoMonth: string[],
		colorSelect: { [id: string]: string },
		demographic: string = '',
		lastIndex?: number
	) => {
		const seriesConfig: Set<ISeriesConfig> = new Set();

		measures?.forEach((m, i) => {
			const spliceIndex = lastIndex ? lastIndex : data[i].length;
			const measureValues = data[i].splice(0, spliceIndex).map(Number);
			const valueByMonth = getChartData(demoMonth, monthAxis, measureValues);

			const dashStyle = demographic === '' ? SPLINE_DASHES[0] : groupLineStyle![demographic];

			seriesConfig.add({
				name: `${demographic};;${m}`,
				type: 'spline',
				dashStyle: dashStyle,
				data: valueByMonth,
				color: colorSelect[m],

				marker: {
					radius: 5,
					lineWidth: 2,
					lineColor: null
				}
			});
		});

		return Array.from(seriesConfig);
	};

	const getFactorData = async (
		factorItems: (IAttribute | IMeasure<IMeasureDefinitionType>)[],
		filters: (IAbsoluteDateFilter | IPositiveAttributeFilter)[]
	) => {
		const seriesConfig: ISeriesConfig[] = [];

		const factorRes = await getExecuteResult(factorItems, filters);

		const m = factorRes?.data! as DataValue[][];
		const data = m.map(inner => inner.slice());
		const attributeItems = factorRes?.headerItems[1]!;

		const measures = factorRes?.headerItems[0][0].map((h: any) => {
			return h.measureHeaderItem.name
				.replace(/Score.\s/g, '')
				.replace(/Bins.\s/g, '')
				.replace(/. Anonymized/g, '');
		}) as string[];

		const [segment, trend]: string[][] = getHeaderItemNames(attributeItems);

		// If demographic exists, segment is demographic[], else, segment is months[]
		if (segment && trend) {
			const uniqueDemo = Array.from(new Set(segment));

			uniqueDemo.forEach(d => {
				const lastIndex = segment.lastIndexOf(d) + 1;
				const demoMonth = trend.splice(0, lastIndex);

				const series = getMeasures(
					measures,
					data,
					demoMonth,
					REPORT_FACTOR_COLORS,
					d,
					lastIndex
				);
				seriesConfig.push(...series);
			});
		} else {
			const series = getMeasures(measures, data, segment, REPORT_FACTOR_COLORS);
			seriesConfig.push(...series);
		}

		return seriesConfig;
	};

	const getKpiData = (kpiRes: IDataView | undefined) => {
		const seriesConfig: ISeriesConfig[] = [];
		const m = kpiRes?.data! as DataValue[][];
		const data = m.map(inner => inner.slice());
		const attributeItems = kpiRes?.headerItems[1]!;

		const [kpiNames, segment, trend, granularity]: string[][] =
			getHeaderItemNames(attributeItems);

		const kpiNameGranularity = kpiNames.map((name, idx) => `${name} (${granularity[idx]})`);
		const uniqueKpi = Array.from(new Set(kpiNameGranularity));

		// If demographic exists, segment is demographic[], else, segment is months[]
		if (segment && trend) {
			const uniqueDemo = Array.from(new Set(segment));

			uniqueDemo.forEach(d => {
				const lastIndex = segment.lastIndexOf(d) + 1;
				const demoMonth = trend.splice(0, lastIndex);

				const series = getMeasures(
					uniqueKpi,
					data,
					demoMonth,
					REPORT_KPI_COLORS,
					d,
					lastIndex
				);
				seriesConfig.push(...series);
			});
		} else {
			const series = getMeasures(uniqueKpi, data, segment, REPORT_KPI_COLORS);
			seriesConfig.push(...series);
		}

		return seriesConfig;
	};

	const getKpiGranularity = async (
		filters: (IAbsoluteDateFilter | IPositiveAttributeFilter)[],
		demoLdm: IAttribute | null = null
	) => {
		const seriesConfig: ISeriesConfig[] = [];

		const { demographics, kpi } = reportData;
		const { Department, Employee } = kpi!; // Department is an array of kpi names with a Department level granularity, same as Employee

		const demographicsCopy = JSON.parse(
			JSON.stringify(demographics.Department.demographicSubGroups)
		);

		let kpiRes;

		let kpiFilters = [...filters];
		let kpiItems = [
			Ldm.OMValue.Avg,
			Ldm.OMUnitOfMeasurement,
			demoLdm,
			...dataConfig.slicesBy,
			Ldm.OMGranularity
		];

		if (Employee) {
			kpiFilters.push(newPositiveAttributeFilter(Ldm.OMMetricId, Employee.kpiIds));
			kpiRes = await getExecuteResult(kpiItems, kpiFilters);

			const employeeData = getKpiData(kpiRes);
			seriesConfig.push(...employeeData);
		}

		// Filter the departments in the demographics selected to the kpi departments
		const demoDepartmentKpis = demographicsCopy.filter((d: string) =>
			departmentKpis?.some(k => k === d)
		);

		if (
			(Department &&
				demoLdm &&
				demoLdm.attribute.localIdentifier ===
					DEMOGRAPHIC_LDM.Department.attribute.localIdentifier &&
				demoDepartmentKpis.length > 0) ||
			(Department && !demoLdm)
		) {
			if (demographics && demoLdm) {
				kpiItems = [
					Ldm.OMValue.Avg,
					Ldm.OMUnitOfMeasurement,
					Ldm.OMDepartment,
					...dataConfig.slicesBy,
					Ldm.OMGranularity
				];

				const omDepartmentGroups = demographics['Department'].demographicSubGroups;

				kpiFilters = [...dataConfig.dateFilter];
				kpiFilters.push(newPositiveAttributeFilter(Ldm.OMDepartment, omDepartmentGroups));
			}

			kpiFilters.push(newPositiveAttributeFilter(Ldm.OMMetricId, Department.kpiIds));
			kpiRes = await getExecuteResult(kpiItems, kpiFilters);

			const departmentData = getKpiData(kpiRes);
			seriesConfig.push(...departmentData);
		}

		return seriesConfig;
	};

	const getDiagnosticMeasure = (diagnosticRes: IDataView | undefined, measures: string[]) => {
		const seriesConfig: ISeriesConfig[] = [];

		const m = diagnosticRes?.data! as DataValue[][];
		const data = m.map(inner => inner.slice());
		const attributeItems = diagnosticRes?.headerItems[1]!;

		const [segment, trend]: string[][] = getHeaderItemNames(attributeItems);

		// If demographic exists, segment is demographic[], else, segment is months[]
		if (segment && trend) {
			const uniqueDemo = Array.from(new Set(segment));

			uniqueDemo.forEach(d => {
				const lastIndex = segment.lastIndexOf(d) + 1;
				const demoMonth = trend.splice(0, lastIndex);

				const series = getMeasures(
					measures,
					data,
					demoMonth,
					REPORT_DIAGNOSTIC_COLORS,
					d,
					lastIndex
				);
				seriesConfig.push(...series);
			});
		} else {
			const series = getMeasures(measures, data, segment, REPORT_DIAGNOSTIC_COLORS);
			seriesConfig.push(...series);
		}

		return seriesConfig;
	};

	const getSensorData = async (filters: (IAbsoluteDateFilter | IPositiveAttributeFilter)[]) => {
		const { diagnostics } = reportData;

		if (!diagnostics || (diagnostics && !diagnostics!['sensors'])) {
			return [];
		}

		const sensorItems: (IMeasure<IMeasureDefinitionType>[] | IAttribute | null)[] = [
			diagnostics!['sensors']!.diagnosticMetrics
		];
		sensorItems.push(...dataConfig.slicesBy);

		const sensorRes = await getExecuteResult(sensorItems, filters);

		const measures = sensorRes?.headerItems[0][0].map((h: any) =>
			h.measureHeaderItem.name.replace(/Sensor.\s/g, '')
		) as string[];

		const sensorData = getDiagnosticMeasure(sensorRes, measures);

		return sensorData;
	};

	const getDiagnosticData = async (
		filters: (IAbsoluteDateFilter | IPositiveAttributeFilter)[],
		demoLdm: IAttribute | null = null
	) => {
		const { diagnostics } = reportData;
		const seriesConfig: ISeriesConfig[] = [];

		// Regular diagnostics (Chats, Emails, etc...) can be filtered by demographic, while sensors cannot,
		// So they need to have separate execute calls
		let diagnosticItems: (IMeasure<IMeasureDefinitionType>[] | IAttribute | null)[] = [];

		Object.keys(diagnostics!).forEach(d => {
			const diagnostic = d.toLowerCase() as ReportGDDiagnosticType;

			if (diagnostic !== 'sensors') {
				diagnosticItems = diagnosticItems.concat(diagnostics![d].diagnosticMetrics);
			}
		});

		if (diagnosticItems.length > 0) {
			diagnosticItems.push(...[demoLdm, ...dataConfig.slicesBy]);

			const diagnosticRes = await getExecuteResult(diagnosticItems, filters);

			const measures = diagnosticRes?.headerItems[0][0].map((h: any) => {
				const diagnosticName = Object.keys(REPORT_DIAGNOSTIC_METRIC_LDM).filter(d => {
					const diagnostic = d as ReportDiagnosticMetricType;
					const identifier =
						REPORT_DIAGNOSTIC_METRIC_LDM[diagnostic].measure.localIdentifier;

					return identifier === h.measureHeaderItem.name;
				});

				return diagnosticName[0];
			}) as string[];

			const diagnosticData = getDiagnosticMeasure(diagnosticRes, measures);

			seriesConfig.push(...diagnosticData);
		}

		return seriesConfig;
	};

	useEffect(() => {
		const { demographics } = reportData;

		if (demographics && Object.keys(demographics).length > 0) {
			const groupLine: { [id: string]: string } = {};

			const subGroupsByDemo = Object.keys(demographics)
				.map(d => demographics[d].demographicSubGroups)
				.flat();

			subGroupsByDemo.forEach((d, i) => {
				groupLine[d] = SPLINE_DASHES[i];
			});

			setGroupLineStyle(groupLine);
		} else {
			setGroupLineStyle(undefined);
		}

		// eslint-disable-next-line
	}, [reportInfo]);

	useCancelablePromise(
		{
			promise: async () => {
				const { demographics, factors, kpi, diagnostics } = reportData;
				const seriesConfig: ISeriesConfig[] = [];

				if (monthAxis.length === 0) {
					return;
				}

				if (demographics && Object.keys(demographics).length > 0) {
					const a = await Promise.all(
						Object.keys(demographics).map(async d => {
							const demoSeries: ISeriesConfig[] = [];
							const demoInfo = demographics[d];

							const factorItems = [
								...factors,
								demoInfo.demographicLdm,
								...dataConfig.slicesBy
							];

							const filters = [
								...dataConfig.dateFilter,
								newPositiveAttributeFilter(
									demoInfo.demographicLdm,
									demoInfo.demographicSubGroups
								)
							];

							//Get kpi data if available
							if (kpi && Object.keys(kpi).length > 0) {
								const kpiConf = await getKpiGranularity(
									filters,
									demoInfo.demographicLdm
								);

								if (kpiConf) {
									demoSeries.push(...kpiConf);
								}
							}

							// Get diagnostics data if available
							if (diagnostics && Object.keys(diagnostics).length > 0) {
								const diagnosticConf = await getDiagnosticData(
									filters,
									demoInfo.demographicLdm
								);

								if (diagnosticConf) {
									demoSeries.push(...diagnosticConf);
								}
							}

							// Get factor data (Factor option cannot be null)
							const factorConf = await getFactorData(factorItems, filters);
							demoSeries.push(...factorConf);

							return demoSeries;
						})
					);

					seriesConfig.push(...a.flat());
				} else {
					const factorItems = [...factors, ...dataConfig.slicesBy];

					const filters = [...dataConfig.dateFilter];

					// Get factor data
					const factorConf = await getFactorData(factorItems, filters);

					seriesConfig.push(...factorConf);

					//Get kpi data if available
					if (kpi && Object.keys(kpi).length > 0) {
						const kpiConf = await getKpiGranularity(filters);

						if (kpiConf) {
							seriesConfig.push(...kpiConf);
						}
					}

					// Get diagnostics data if available
					if (diagnostics && Object.keys(diagnostics).length > 0) {
						const diagnosticConf = await getDiagnosticData(filters);

						if (diagnosticConf) {
							seriesConfig.push(...diagnosticConf);
						}
					}
				}

				const sensorData = await getSensorData([...dataConfig.dateFilter]);
				seriesConfig.push(...sensorData);

				return seriesConfig;
			},
			onSuccess: r => {
				r!.sort((a, b) => a.name.localeCompare(b.name));

				setChartSeries(r!);
				setIsLoading(false);
			},
			onLoading: () => {
				setChartSeries([]);
				setIsLoading(true);
			},
			onError: () => {
				setIsLoading(false);
				setChartSeries(null);
			}
		},
		[reportInfo, dataConfig, monthAxis]
	);

	return { chartSeries, isLoading };
};
