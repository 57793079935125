import * as Util from 'utils';

import {
	IHeatmapData,
	ISeriesConfig,
	IColumnChartSeries,
	IColumnChartOptions,
	IInsightDataPoint
} from 'types';
import { MISC_COLORS } from 'constants/misc';

/**
 * Helper function to generate stacked bar chart config to be used by Highcharts
 * @author Konstantin Krumin
 * @param series - dataset for bar chart
 * @param fixedWidth - optional parameter for setting up fixed width
 * @param borderWidth - optional parameter for setting up border width
 */
export const stackedBarchartConfig = (
	series: any,
	fixedWidth?: number,
	borderWidth: number = 0
) => {
	const options = {
		chart: {
			type: 'bar',
			height: 50,
			width: fixedWidth ? fixedWidth : null
		},
		title: {
			text: null
		},
		xAxis: {
			visible: false,
			height: 25
		},
		yAxis: {
			visible: false
		},
		legend: {
			enabled: false
		},
		plotOptions: {
			series: {
				stacking: 'percent',
				borderWidth: borderWidth,
				pointPadding: 0,
				groupPadding: 0,
				animation: false
			}
		},
		series: series,
		tooltip: {
			formatter: Util.stackedBarchartTooltipFormatter
		},
		credits: {
			enabled: false
		}
	};

	return options;
};

/**
 * Helper function to generate stacked insights chart config to be used by Highcharts
 * @author Konstantin Krumin
 * @param series - dataset for the stacked insights chart
 * @param xAxisLabels - array of strings that store the x-axis labels for the chart
 */
export const insightsStackedAreaChartConfig = (
	series: IInsightDataPoint[],
	xAxisLabels: string[]
) => {
	const options = {
		chart: {
			type: 'area'
		},
		title: {
			text: null
		},
		xAxis: {
			categories: xAxisLabels,
			gridLineColor: '#fff',
			gridLineWidth: 1,
			gridZIndex: 4,
			lineColor: '#fff',
			lineWidth: 0,
			labels: {
				enabled: false
			}
		},
		yAxis: {
			visible: false
		},
		legend: {
			enabled: false
		},
		tooltip: {
			shared: true,
			headerFormat:
				'<span style="font-size:14px;margin-bottom:3px"><b>{point.key}</b></span><br>',
			padding: 16,
			style: {
				fontSize: '16px'
			}
		},
		plotOptions: {
			area: {
				stacking: 'percent',
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0,
				lineColor: '#fff',
				lineWidth: 0,
				marker: {
					lineWidth: 0,
					lineColor: '#fff',
					symbol: 'circle',
					enabled: false,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			}
		},
		series: series,
		credits: {
			enabled: false
		}
	};

	return options;
};

/**
 * Helper function to generate spline chart config to be used by Highcharts
 * @author Michaela Mempin
 * @param data - dataset for the spline chart
 * @param color - determines a color of the spline chart
 * @param height - fixed height of the chart
 */
export const splineChartConfig = (
	data: any,
	color: string,
	height: number = 120,
	yAxisMinBoundary: number = -5,
	yAxisMaxBoundary: number = 105
) => {
	const options = {
		chart: {
			type: 'spline',
			height: height
		},
		title: {
			text: null
		},
		xAxis: {
			visible: false
		},
		yAxis: {
			visible: false,
			endOnTick: false,
			startOnTick: false,
			min: yAxisMinBoundary,
			max: yAxisMaxBoundary
		},
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		tooltip: {
			borderWidth: 0,
			backgroundColor: 'rgba(255,255,255,0)',
			shadow: false,
			useHTML: true,
			formatter: Util.splineChartTooltipFormatter
		},
		series: [
			{
				data,
				lineWidth: 4,
				color: color
			}
		]
	};
	return options;
};

/**
 * Helper function to generate heatmap chart config to be used by Highcharts
 * @author Michaela Mempin
 * @param data - dataset for the heatmap chart
 * @param yAxis - labels to be displayed across y-axis
 * @param xAxis - labels to be displayed across x-axis
 */
export const heatmapChartConfig = (data: IHeatmapData[], yAxis: string[], xAxis: string[]) => {
	const options = {
		chart: {
			type: 'heatmap',
			marginTop: 0,
			paddingBottom: 30
		},
		title: {
			text: null
		},
		xAxis: {
			categories: xAxis,
			reversed: false,
			lineWidth: 0,
			title: {
				enabled: false
			},
			labels: {
				style: {
					color: MISC_COLORS.grey
				}
			}
		},
		yAxis: {
			categories: yAxis,
			gridLineWidth: 0,
			reversed: false,
			lineWidth: 0,
			title: {
				enabled: false
			},
			labels: {
				style: {
					color: MISC_COLORS.grey
				}
			}
		},
		legend: {
			enabled: false
		},
		series: [
			{
				borderWidth: 0,
				data
			}
		],
		tooltip: {
			borderWidth: 0,
			backgroundColor: 'rgba(255,255,255,0)',
			shadow: false,
			useHTML: true,
			formatter: Util.heatmapChartTooltipFormatter
		},
		credits: {
			enabled: false
		}
	};
	return options;
};

/**
 * Helper function to generate line chart config to be used by Highcharts
 * @author Misael Esperanzate & Michaela Mempin
 * @param bandData - dataset for the chart
 * @param yAxisMax - max y-axis scale of the chart
 * @param uniqueSeriesConfig - array of unique objects for the series
 * @param xAxisConfig - array of strings that store the x-axis labels for the chart
 * @param isSimplifiedChart - optional parameter that is used for the simplified KPI / Operation chart
 */
export const lineChartConfig = (
	xAxisConfig: string[],
	uniqueSeriesConfig: ISeriesConfig[],
	bandData: number[],
	yAxisMax: number,
	isSimplifiedChart: boolean = false
) => {
	const options = {
		title: {
			text: null
		},
		xAxis: {
			categories: xAxisConfig,
			lineWidth: 0,
			minorGridLineWidth: 0,
			lineColor: 'transparent'
		},
		yAxis: [
			{
				title: {
					text: isSimplifiedChart ? undefined : 'Factors'
				},
				gridLineWidth: 0,
				max: yAxisMax + 10,
				min: 0,
				startOnTick: false,
				endOnTick: false
			},
			{
				title: {
					text: 'Operation'
				},
				opposite: true,
				gridLineWidth: 0,
				max: yAxisMax + 10,
				min: 0,
				startOnTick: false,
				endOnTick: false,
				visible: false
			}
		],
		tooltip: {
			formatter: Util.lineChartTooltipFormatter
		},
		legend: {
			labelFormatter: function DemographicLegendFormatter(
				this: any,
				legend: Highcharts.Legend
			): string {
				const title = this.name.split(';;');
				const [demographic, metric] = title;

				return `<span style="color:#696969">${demographic} ${
					demographic ? ' - ' : ''
				}</span> <span style="color:#696969">${metric}</span>`;
			},
			enabled: isSimplifiedChart ? false : true,
			symbolHeight: 0.001,
			symbolWidth: 40
		},
		series: [
			{
				type: 'column',
				yAxis: 0,
				data: bandData,
				color: 'transparent',
				borderColor: '#CCCCCC',
				borderWidth: 1,
				dashStyle: 'LongDash',
				showInLegend: false,
				enableMouseTracking: false
			},
			...uniqueSeriesConfig
		],
		plotOptions: {
			series: {
				connectNulls: true,
				states: {
					inactive: {
						opacity: 1
					}
				},
				lineWidth: 2,
				marker: {
					symbol: 'circle'
				}
			}
		},
		credits: {
			enabled: false
		}
	};

	return options;
};

/**
 * Helper function to generate column chart config to be used by Highcharts
 * @author Konstantin Krumin
 * @param chartName - name for the chart, it is being displayed in the tooltip
 * @param series - data arranged in Series format to be rendered by Highcharts
 * @param xAxisLabels - array of strings that store the x-axis labels for the chart
 * @param showLegend - a boolean determining whether a legend needs to be displayed for this chart
 * @param plotStackingType - determines whether stacking should be applied on the chart columns, and if yes, which type
 */
export const columnChartConfig = (
	chartName: string,
	xAxisLabels: string[],
	series: IColumnChartSeries[],
	showLegend: boolean = false,
	plotStackingType: 'percent' | undefined = undefined
) => {
	let options: IColumnChartOptions = {
		chart: {
			type: 'column'
		},
		credits: {
			enabled: false
		},
		title: {
			text: chartName,
			style: {
				display: 'none'
			}
		},
		subtitle: {
			text: undefined
		},
		legend: {
			enabled: showLegend
		},
		xAxis: {
			categories: xAxisLabels,
			crosshair: true,
			labels: {
				style: {
					color: MISC_COLORS.grey
				}
			}
		},
		yAxis: {
			title: {
				text: undefined
			},
			labels: {
				style: {
					color: MISC_COLORS.grey
				}
			}
		},
		tooltip: {
			borderWidth: 0,
			backgroundColor: 'rgba(255,255,255,0)',
			shadow: false,
			useHTML: true,
			formatter: Util.columnChartTooltipFormatter
		},
		plotOptions: {
			column: {
				stacking: plotStackingType,
				pointPadding: 0.2,
				borderWidth: 0
			}
		},
		series: series
	};

	return options;
};

/**
 * InsightView config for heatmap
 * @author Konstantin Krumin
 */
export const gooddataHeatmapConfig = (colors: string[]) => {
	return {
		legend: {
			enabled: true,
			position: 'bottom',
			responsive: true
		},
		colors,
		enableCompactSize: true
	};
};
