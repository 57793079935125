import React, { ReactNode } from 'react';

/**
 * An icon button component
 * @author Konstantin Krumin
 * @param icon - The icon component to display
 * @param onClick - The typical click event on a button
 * @param customStyles - Optional parameter that will overwrite default styles for this button
 */
interface HolIconButtonProps {
	customStyles?: string;
	icon: ReactNode;
	onClick: any;
}

export const HolIconButton: React.FC<HolIconButtonProps> = ({ icon, onClick, customStyles }) => {
	return (
		<button
			className={
				customStyles
					? customStyles
					: 'flex flex-col justify-center items-center hover:opacity-60 print-hide text-3xl text-center text-gray-300'
			}
			onClick={onClick}
		>
			{icon}
		</button>
	);
};
