/**
 * Holds all the different action types of our redux state management
 * @author Matthew Douglas & Misael Esperanzate & Konstantin Krumin
 */

enum ActionType {
	SET_CURRENT_WORKSPACE = 'set_current_workspace',
	SET_USER_AUTHENTICATED = 'set_user_authenticated',
	SET_MANAGEMENT_TOGGLE = 'set_management_toggle',
	SET_SIDEBAR_TOGGLE = 'set_sidebar_toggle',
	SET_DEMOGRAPHIC = 'set_demographic',
	SET_CURRENT_DIAGNOSTICS = 'set_current_diagnostics',
	SET_DIAGNOSTICS_DATE_FILTER = 'set_diagnostics_date_filter',
	SET_SELECTED_FACTOR = 'set_selected_factor',
	SET_SHOW_ERROR = 'set_show_error',
	// Insights Comments Action Types
	SET_INSIGHTS_COMMENTS = 'set_insights_comments',
	UPDATE_INSIGHTS_COMMENT = 'update_insights_comment',
	DELETE_INSIGHTS_COMMENT = 'delete_insights_comment',
	SET_INSIGHTS_COMMENTS_COUNT = 'set_insights_comments_count',
	SET_INSIGHTS_COMMENTS_CURRENT_PAGE = 'set_insights_comments_current_page',
	// Report Action Types
	ADD_REPORT = 'add_report',
	SET_REPORTS = 'set_reports',
	DELETE_REPORT = 'delete_report',
	SET_REPORTS_COUNT = 'set_reports_count',
	SET_REPORT_FULLSCREEN = 'set_report_fullscreen',
	SET_REPORTS_CURRENT_PAGE = 'set_reports_current_page',
	// User Management Action Types
	SET_ROLE_UPDATES = 'set_role_updates',
	REMOVE_ROLE_UPDATE = 'remove_role_update',
	RESET_ROLE_UPDATES = 'reset_role_updates',
	SET_USER_WORKSPACE_ROLE = 'set_user_workspace_role',
	// Action Planning Action Types
	SET_ACTION_PLAN = 'set_action_plan',
	SET_ACTION_PLANS = 'set_action_plans',
	SET_ACTIVE_ACTION_PLANS = 'set_active_action_plans',
	SET_ARCHIVED_ACTION_PLANS = 'set_archived_action_plans',
	SET_ACTION_PLANS_COUNT = 'set_action_plans_count',
	SET_ACTIVE_ACTION_PLANS_COUNT = 'set_active_action_plans_count',
	SET_ARCHIVED_ACTION_PLANS_COUNT = 'set_archived_action_plans_count',
	CREATE_OR_DUPLICATE_OR_EDIT_ACTION_PLAN = 'create_or_duplicate_or_edit_action_plan',
	SET_ACTION_PLAN_CURRENT_PAGE = 'set_action_plan_current_page',
	SET_ACTIVE_ACTION_PLAN_CURRENT_PAGE = 'set_active_action_plan_current_page',
	SET_ARCHIVED_ACTION_PLAN_CURRENT_PAGE = 'set_archived_action_plan_current_page',
	DELETE_ACTION_PLAN = 'delete_action_plan'
}

export default ActionType;
