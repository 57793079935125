import { SVGIconProps } from 'types';

export const Logo: React.FC<SVGIconProps> = ({ className, fill = '#3E6991' }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 112.82 40.25"
		fill={fill}
	>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-1"
					d="M48.72,9.62h0a5.34,5.34,0,0,0-7.44,0,5.34,5.34,0,0,0-7.44,0,4.8,4.8,0,0,0-1.13,3.68v6.26a1.06,1.06,0,0,0,2.11,0v-6A4.44,4.44,0,0,1,35.56,11a2.76,2.76,0,0,1,4,0,3.35,3.35,0,0,1,.63,1.64c0,.31,0,4.28,0,6.89a1.06,1.06,0,0,0,2.12,0c0-2.62,0-6.56,0-6.87A3.38,3.38,0,0,1,43,11a2.76,2.76,0,0,1,4,0,4.23,4.23,0,0,1,.74,2.49v6a1.06,1.06,0,0,0,2.11,0V13.3a4.8,4.8,0,0,0-1.13-3.68"
				/>
				<path
					className="cls-1"
					d="M29,0A1,1,0,0,0,27.9,1.05V19.62a1.05,1.05,0,0,0,2.1,0V1.05A1,1,0,0,0,29,0"
				/>
				<path
					className="cls-1"
					d="M63.7,13.92c0-3.06-1.73-6.15-5.59-6.15s-6.27,2.63-6.27,6.54a6.11,6.11,0,0,0,6.35,6.42,6.9,6.9,0,0,0,4.69-1.59A.88.88,0,0,0,63,18a.83.83,0,0,0-.59-.33.86.86,0,0,0-.66.19,5,5,0,0,1-3.19,1A4.18,4.18,0,0,1,54,14.6h9a.69.69,0,0,0,.69-.68m-9.62-1.1a3.82,3.82,0,0,1,4-3.17,3.42,3.42,0,0,1,3.47,3.17Z"
				/>
				<path
					className="cls-1"
					d="M71,8.37H69.09V5.19a1.06,1.06,0,0,0-2.11,0V8.37H65.73a.95.95,0,0,0,0,1.89H67v9.36a1.06,1.06,0,0,0,2.11,0V10.26H71a.95.95,0,0,0,0-1.89"
				/>
				<path
					className="cls-1"
					d="M97.69,17.92a.84.84,0,0,0-.6-.32.93.93,0,0,0-.66.2,4.67,4.67,0,0,1-3.15,1,4.26,4.26,0,0,1-4.39-4.53A4.27,4.27,0,0,1,93.2,9.68a5,5,0,0,1,2.7.81.93.93,0,0,0,1.25-.19,1,1,0,0,0,.19-.73A.91.91,0,0,0,97,8.94a6.67,6.67,0,0,0-3.72-1.17,6.3,6.3,0,0,0-6.52,6.52,6.27,6.27,0,0,0,6.37,6.44,6.39,6.39,0,0,0,4.56-1.62.89.89,0,0,0,0-1.19"
				/>
				<path
					className="cls-1"
					d="M104,12.87c-1.16-.53-2.16-1-2.16-1.83,0-.69.48-1.49,1.83-1.49a3.61,3.61,0,0,1,2,.7.92.92,0,0,0,1.08-1.5,5.55,5.55,0,0,0-3.13-1c-2.27,0-3.91,1.44-3.91,3.42s1.71,2.71,3.23,3.44c1.33.63,2.48,1.18,2.48,2.27a2,2,0,0,1-2.27,2.05,4,4,0,0,1-2.61-.94.94.94,0,1,0-1.21,1.43,5.9,5.9,0,0,0,3.84,1.35c2.59,0,4.4-1.61,4.4-3.91s-2-3.27-3.55-4"
				/>
				<path
					className="cls-1"
					d="M83.63,8.35A1.06,1.06,0,0,0,82.57,9.4V19.62a1.06,1.06,0,0,0,2.11,0V9.4a1,1,0,0,0-1.05-1.05"
				/>
				<path
					className="cls-1"
					d="M83.58,7.66A1.34,1.34,0,0,0,85,6.31a1.37,1.37,0,0,0-1.44-1.39,1.35,1.35,0,0,0-1.35,1.39,1.33,1.33,0,0,0,1.35,1.35"
				/>
				<path
					className="cls-1"
					d="M19.05,7.77a6.48,6.48,0,1,0,0,13,6.48,6.48,0,1,0,0-13m4.32,6.49A4.24,4.24,0,0,1,19,18.82a4.43,4.43,0,0,1-4.37-4.48A4.34,4.34,0,0,1,19,9.67a4.44,4.44,0,0,1,4.37,4.59"
				/>
				<path
					className="cls-1"
					d="M16.22,7.18a1.35,1.35,0,0,0,1.45-1.34,1.38,1.38,0,0,0-1.45-1.4,1.36,1.36,0,0,0-1.35,1.4,1.34,1.34,0,0,0,1.35,1.34"
				/>
				<path
					className="cls-1"
					d="M21.71,7.18a1.35,1.35,0,0,0,1.45-1.34,1.38,1.38,0,0,0-1.45-1.4,1.36,1.36,0,0,0-1.35,1.4,1.33,1.33,0,0,0,1.35,1.34"
				/>
				<path
					className="cls-1"
					d="M9.2,9.75h0a5.44,5.44,0,0,0-4-1.64A5.62,5.62,0,0,0,2.11,9V1.05A1.06,1.06,0,0,0,0,1.05V19.62a1.06,1.06,0,0,0,2.11,0V13.35a3.1,3.1,0,1,1,6.19,0v6.27a1,1,0,0,0,2.1,0V13.68A5.2,5.2,0,0,0,9.2,9.75"
				/>
				<path
					className="cls-1"
					d="M76.11,9.74V9.38a1.06,1.06,0,0,0-2.11,0V19.6a1.06,1.06,0,0,0,2.11,0V13.17a4.26,4.26,0,0,1,3.53-3,1,1,0,0,0,.81-.94,1,1,0,0,0-1.09-.95,6.47,6.47,0,0,0-3.25,1.5"
				/>
			</g>
		</g>
	</svg>
);
