import { IAttribute } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';

import {
	IFactor,
	IDiagnostic,
	DemographicType,
	IFactorDataInfo,
	ISensorDataInfo,
	IDiagnosticDataInfo,
	HolmetricsFactorType,
	HolmetricsSensorType,
	HolmetricsDiagnosticType,
	IDiagnosticLightDataInfo,
	HolmetricsDiagnosticLightType
} from 'types';
import { SubmetricBarchartColors } from 'enums';

import { RELATIVE_DATE_FILTERS } from '../dates';

export const FACTORS_INFO: { [factor in HolmetricsFactorType]: IFactorDataInfo } = {
	workEngagement: {
		identifier: 'workEngagement',
		title: 'Work Engagement',
		value: Ldm.BinsWorkEngagementAnonymized
	},
	jobSatisfaction: {
		identifier: 'jobSatisfaction',
		title: 'Job Satisfaction',
		value: Ldm.BinsJobSatisfactionAnonymized
	},
	qualityOfLeadership: {
		identifier: 'qualityOfLeadership',
		title: 'Quality of Leadership',
		value: Ldm.BinsQualityOfLeadershipAnonymized
	},
	roleClarity: {
		identifier: 'roleClarity',
		title: 'Role Clarity',
		value: Ldm.BinsRoleClarityAnonymized
	},
	horizontalTrust: {
		identifier: 'horizontalTrust',
		title: 'Horizontal Trust',
		value: Ldm.BinsHorizontalTrustAnonymized
	},
	verticalTrust: {
		identifier: 'verticalTrust',
		title: 'Vertical Trust',
		value: Ldm.BinsVerticalTrustAnonymized
	},
	senseOfCommunity: {
		identifier: 'senseOfCommunity',
		title: 'Sense of Community',
		value: Ldm.BinsSenseOfCommunityAnonymized
	},
	burnout: {
		identifier: 'burnout',
		title: 'Burnout',
		value: Ldm.BinsBurnoutAnonymized
	}
};

export const SENSORS_INFO: {
	[highlight in HolmetricsSensorType]: ISensorDataInfo;
} = {
	avgAmbientDb: {
		identifier: 'avgAmbientDb',
		title: 'Avg Ambient Db',
		value: Ldm.SensorNoiseAVG
	},
	avgLightSpectrum: {
		identifier: 'avgLightSpectrum',
		title: 'Avg Light Spectrum',
		afterText: 'nm',
		value: Ldm.SensorLight
	},
	avgTemp: {
		identifier: 'avgTemp',
		title: 'Avg Temperature',
		afterText: '°',
		value: Ldm.SensorTemperature
	},
	dailyTemperatureVariance: {
		identifier: 'dailyTemperatureVariance',
		title: 'Daily Temp Variance',
		afterText: '°',
		value: Ldm.SensorTemperatureVariance
	},
	peakVOCs: {
		identifier: 'peakVOCs',
		title: 'Peak VOCs',
		value: Ldm.SensorAirVOCPeak
	},
	peakCO2: {
		identifier: 'peakCO2',
		title: 'Peak CO2',
		value: Ldm.SensorAirCO2Peak
	}
};

export const DIAGNOSTICS_INFO: { [diagnostic in HolmetricsDiagnosticType]: IDiagnosticDataInfo } = {
	eNPS: {
		identifier: 'eNPS',
		title: 'eNPS',
		actionPlanLabel: 'eNPS',
		value: Ldm.ScoreENPS,
		dateFilter: RELATIVE_DATE_FILTERS.prevMonth,
		submetrics: {
			dateFilter: RELATIVE_DATE_FILTERS.prevMonth,
			values: [
				{
					title: 'Promoters',
					value: Ldm.ScoreENPSPromotersPercent,
					color: SubmetricBarchartColors.PURPLE
				},
				{
					title: 'Passives',
					value: Ldm.ScoreENPSPassivesPercent,
					color: SubmetricBarchartColors.YELLOW
				},
				{
					title: 'Dectractors',
					value: Ldm.ScoreENPSDetractorsPercent,
					color: SubmetricBarchartColors.ORANGE
				}
			]
		}
	},
	pulseParticipation: {
		identifier: 'pulseParticipation',
		title: 'Pulse',
		subtitle: 'Participation',
		actionPlanLabel: 'Pulse Participation',
		value: Ldm.PulseParticipationLongTerm,
		dateFilter: RELATIVE_DATE_FILTERS.lastWeek,
		submetrics: {
			dateFilter: RELATIVE_DATE_FILTERS.lastWeek,
			values: [
				{
					value: Ldm.PulseParticipationRate.Avg,
					title: 'Pulse Participation. Last 7 Days'
				}
			]
		}
	},
	activityOWH: {
		identifier: 'activityOWH',
		title: 'Activity',
		subtitle: 'Outside of Work Hours',
		rightSideLabel: 'Meetings',
		actionPlanLabel: 'Meetings Percent Sent Outside Of Work Hours',
		value: Ldm.ActivityOWHMeetingsPercent,
		dateFilter: RELATIVE_DATE_FILTERS.lastWeek,
		submetrics: {
			title: 'Communication',
			subtitle: 'Outside of Work Hours',
			dateFilter: null,
			values: [
				{
					title: 'Internal',
					value: Ldm.CommunicationOWHPercentInternal,
					color: SubmetricBarchartColors.PURPLE
				},
				{
					title: 'External',
					value: Ldm.CommunicationOWHPercentExternal,
					color: SubmetricBarchartColors.ORANGE
				}
			]
		}
	},
	communication: {
		identifier: 'communication',
		title: 'Communication',
		rightSideLabel: 'Toxicity',
		actionPlanLabel: 'Number Of Toxic Messages',
		value: Ldm.ToxicityToxicity.Sum,
		dateFilter: RELATIVE_DATE_FILTERS.lastWeek,
		submetrics: {
			title: 'Sentiment',
			dateFilter: null,
			values: [
				{
					title: 'Positive',
					value: Ldm.SemanticPositivePercentLast7D,
					color: SubmetricBarchartColors.PURPLE
				},
				{
					title: 'Neutral',
					value: Ldm.SemanticNeutralPercentLast7D,
					color: SubmetricBarchartColors.YELLOW
				},
				{
					title: 'Negative',
					value: Ldm.SemanticNegativePercentLast7D,
					color: SubmetricBarchartColors.ORANGE
				}
			]
		}
	},
	connection: {
		identifier: 'connection',
		title: 'Connection',
		rightSideLabel: 'Disconnected',
		actionPlanLabel: 'Percent Of Disconnected Employees',
		value: Ldm.ConnectivityPercentDisconnected,
		dateFilter: RELATIVE_DATE_FILTERS.lastWeek,
		submetrics: {
			title: 'Connectivity',
			dateFilter: null,
			values: [
				{
					title: 'High',
					value: Ldm.ConnectivityPercentHighLast7D,
					color: SubmetricBarchartColors.PURPLE
				},
				{
					title: 'Normal',
					value: Ldm.ConnectivityPercentNormalLast7D,
					color: SubmetricBarchartColors.BLUE
				},
				{
					title: 'Low',
					value: Ldm.ConnectivityPercentLowLast7D,
					color: SubmetricBarchartColors.YELLOW
				},
				{
					title: 'Disconnected',
					value: Ldm.ConnectivityPercentDisconnectedLast7D,
					color: SubmetricBarchartColors.ORANGE
				}
			]
		}
	}
};

export const DIAGNOSTICS_LIGHT_INFO: {
	[diagnostic in HolmetricsDiagnosticLightType]: IDiagnosticLightDataInfo;
} = {
	internalCommunicationOWH: {
		identifier: 'internalCommunicationOWH',
		title: 'Internal Communication OWH',
		value: Ldm.CommunicationOWHPercentInternal,
		dateFilter: null
	},
	externalCommunicationOWH: {
		identifier: 'externalCommunicationOWH',
		title: 'External Communication OWH',
		value: Ldm.CommunicationOWHPercentExternal,
		dateFilter: null
	},
	toxicity: {
		identifier: 'toxicity',
		title: 'Toxicity',
		value: Ldm.ToxicityToxicity.Sum,
		dateFilter: RELATIVE_DATE_FILTERS.lastWeek
	},
	connectivityHigh: {
		identifier: 'connectivityHigh',
		title: 'High Connectivity',
		value: Ldm.ConnectivityPercentHighLast7D,
		dateFilter: null
	},
	connectivityNormal: {
		identifier: 'connectivityNormal',
		title: 'Normal Connectivity',
		value: Ldm.ConnectivityPercentNormalLast7D,
		dateFilter: null
	},
	connectivityLow: {
		identifier: 'connectivityLow',
		title: 'Low Connectivity',
		value: Ldm.ConnectivityPercentLowLast7D,
		dateFilter: null
	},
	connectivityDisconnected: {
		identifier: 'connectivityDisconnected',
		title: '% of Disconnected',
		value: Ldm.ConnectivityPercentDisconnectedLast7D,
		dateFilter: null
	}
};

export const FACTOR_TYPES: HolmetricsFactorType[] = Object.keys(
	FACTORS_INFO
) as HolmetricsFactorType[];

export const SENSOR_TYPES: HolmetricsSensorType[] = Object.keys(
	SENSORS_INFO
) as HolmetricsSensorType[];

export const DIAGNOSTIC_TYPES: HolmetricsDiagnosticType[] = Object.keys(
	DIAGNOSTICS_INFO
) as HolmetricsDiagnosticType[];

export const DIAGNOSTIC_LIGHT_TYPES: HolmetricsDiagnosticLightType[] = Object.keys(
	DIAGNOSTICS_LIGHT_INFO
) as HolmetricsDiagnosticLightType[];

export const FACTORS: IFactor[] = Object.values(FACTORS_INFO).map(factor => {
	return {
		identifier: factor.identifier,
		label: factor.title,
		value: factor.value
	} as IFactor;
});

export const DIAGNOSTICS: IDiagnostic[] = Object.values(DIAGNOSTICS_INFO).map(diagnostic => {
	const setTitle = (identifier: string, title: string, rightSideLabel: string | undefined) => {
		if (rightSideLabel && (identifier === 'communication' || identifier === 'connection')) {
			return rightSideLabel;
		}

		return title;
	};

	const title = setTitle(diagnostic.identifier, diagnostic.title, diagnostic.rightSideLabel);

	return {
		identifier: diagnostic.identifier,
		label: diagnostic.actionPlanLabel,
		value: diagnostic.value,
		title: title,
		subtitle: diagnostic.subtitle
	} as IDiagnostic;
});

export const DIAGNOSTICS_LIGHT: IDiagnostic[] = DIAGNOSTICS.filter(diagnostic => {
	return diagnostic.identifier !== 'eNPS' && diagnostic.identifier !== 'pulseParticipation';
});

export const DEMOGRAPHIC_LDM: { [name in DemographicType]: IAttribute } = {
	Department: Ldm.Department,
	Gender: Ldm.Gender,
	Ethnicity: Ldm.Ethnicity,
	Shift: Ldm.Shift,
	'Age Group': Ldm.AgeGroup,
	Tenure: Ldm.Tenure,
	City: Ldm.LocationCity,
	'Location Type': Ldm.LocationType,
	'Employment Type': Ldm.EmploymentType,
	Language: Ldm.Language,
	Cohort: Ldm.Cohort
};
