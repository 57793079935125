import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { IAttribute, IMeasure, IMeasureDefinition } from '@gooddata/sdk-model';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Util from 'utils';

import { useTypedSelector } from 'hooks';
import { IColumnChartOptions, IStackedColumnChartLabel } from 'types';

import { IDataset } from './types';
import { convertDatasetToSeries, fillDataset, matchColorsToSeries } from './helpers';

/**
 * Displays the stacked column chart
 * @author Konstantin Krumin
 * @param chartName - name for the chart, it is being displayed in the tooltip
 * @param seriesBy - an array with parameters for SeriesBy array of the GoodData Execute component
 * @param slicesBy - custom parameter to slice incoming data from GoodData (i.e., by every day / week/ month)
 * @param labels - this parameter contains name and color properties for available options to be displayed in the stacked chart (it must match those options defined on GoodData side)
 */
interface StackedColumnChartProps {
	chartName: string;
	slicesBy: IAttribute[];
	seriesBy: IMeasure<IMeasureDefinition>[];
	labels: IStackedColumnChartLabel[];
}

export const StackedColumnChart: React.FC<StackedColumnChartProps> = ({
	chartName,
	slicesBy,
	seriesBy,
	labels
}) => {
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const dateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);
	const filtersArr = [dateFilter.relativeDateFilter, ...demographicFilter];

	return (
		<div className="flex flex-wrap col-span-full sm:col-span-6 lg2:col-span-4 justify-center">
			<div className="w-full">
				<div className="flex-grow px-5">
					<Execute
						key={currentWorkspace.id}
						seriesBy={seriesBy}
						slicesBy={slicesBy}
						filters={filtersArr}
						onError={errInfo => {
							if (errInfo.message !== 'CANCELLED') {
								Util.logErrorMessage(errInfo);
							}
						}}
					>
						{({ result, isLoading }) => {
							let holStackedColumnChartOptions: IColumnChartOptions = {};

							if (!result) {
								return <LoadingComponent className="my-8" />;
							}

							if (result) {
								const rawData = result
									.rawData()
									.data()
									.flat()
									.map(value => {
										const valueStr = value as string;
										return Util.convertStrPercentageToIntNum(valueStr);
									});

								const headerArrs = result?.dataView?.headerItems?.[0];
								const datesArr: string[] = headerArrs?.[0]?.map(
									(item: any) => item?.attributeHeaderItem?.name
								);
								const typesArr: string[] = headerArrs?.[1]?.map(
									(item: any) => item?.attributeHeaderItem?.name
								);

								// aggregates incoming data in the Dataset format
								const incomingData: IDataset[] = rawData.map((item, idx) => {
									return {
										value: item,
										date: datesArr[idx],
										name: typesArr[idx]
									};
								});

								const baseCategories = labels.map(label => label.name);
								const baseDates = Array.from(new Set(datesArr));

								// creates a dataset with placeholder cells of size dates x categories
								const emptyDataset: IDataset[] = baseDates
									.map(date => {
										return baseCategories.map(name => {
											return {
												value: 0,
												name,
												date
											};
										});
									})
									.flat();

								const filledDataset = fillDataset(emptyDataset, incomingData);

								const series = convertDatasetToSeries(filledDataset);
								const seriesWithColors = matchColorsToSeries(
									series,
									labels
								).reverse();

								holStackedColumnChartOptions = Util.columnChartConfig(
									chartName,
									baseDates,
									seriesWithColors,
									true,
									'percent'
								);
							}

							return isLoading ? (
								<LoadingComponent className="my-8" />
							) : !isLoading && holStackedColumnChartOptions?.series?.length === 0 ? (
								<div className="flex justify-center">
									<div className="text-gray-400 my-8">
										No data for your filter selection
									</div>
								</div>
							) : (
								<HighchartsReact
									highcharts={Highcharts}
									options={holStackedColumnChartOptions}
								/>
							);
						}}
					</Execute>
				</div>
			</div>
		</div>
	);
};
