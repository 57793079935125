import { RoleType } from 'types';

import { Action } from '../actions';
import ActionType from '../action-types';

interface roleUpdateInfo {
	newRole: RoleType;
	email: string;
}

const initialState: { [id in string]: roleUpdateInfo } = {};

const userManagerReducer = (
	state: { [id in string]: roleUpdateInfo } = initialState,
	action: Action
) => {
	switch (action.type) {
		case ActionType.SET_ROLE_UPDATES:
			const newState = { ...state };

			newState[action.payload.profile] = {
				newRole: action.payload.newRole,
				email: action.payload.email
			};

			return newState;

		case ActionType.REMOVE_ROLE_UPDATE:
			delete state[action.payload.profile];
			return state;

		case ActionType.RESET_ROLE_UPDATES:
			return initialState;

		default:
			return state;
	}
};

export default userManagerReducer;
