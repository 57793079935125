import { IFactorCard } from 'types';
import { FACTORS_INFO } from 'constants/dataset';

import { Action } from '../actions';
import ActionType from '../action-types';

/**
 * State of the selected factor card
 * @author Misael Esperanzate
 */

const initialState: IFactorCard = {
	factorState: FACTORS_INFO.workEngagement,
	selectedCard: 'workEngagement'
};

const factorReducer = (state: IFactorCard = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_SELECTED_FACTOR:
			return {
				...state,
				...action.payload.factorCard
			};

		default:
			return state;
	}
};

export default factorReducer;
