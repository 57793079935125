import React, { useState } from 'react';

import * as Component from 'components';

import { useActions } from 'hooks';
import { RoleType, IGeneralOption, IWorkspaceUserInfo } from 'types';

/**
 * A dropdown for selecting user roles
 * @author Michaela Mempin
 * @param user - user profile information
 * @param defaultRole - default selected role
 * @param options - options available for selection
 */
interface UserRoleSelectProps {
	defaultRole: string;
	user?: IWorkspaceUserInfo;
	options: IGeneralOption[];
}

export const UserRoleSelect: React.FC<UserRoleSelectProps> = ({ defaultRole, user, options }) => {
	const { setUserRolesUpdate, removeUserRoleUpdate } = useActions();

	const defaultOption = options.filter(r => r.label === defaultRole)[0];
	const [selectedRole, setSelectedRole] = useState(defaultOption);

	const handleSelectRole = (selectedOption: IGeneralOption | null) => {
		if (selectedOption) {
			setSelectedRole(selectedOption);

			const selectedRole = selectedOption.value as RoleType;

			if (selectedRole === defaultRole) {
				// if the user's current role is selected, do not add user to list of updates or remove user from list
				removeUserRoleUpdate(user!.profileId);
			} else {
				setUserRolesUpdate(user!.profileId, selectedRole, user!.email);
			}
		}
	};

	return (
		<div className="my-auto w-11/12">
			<Component.HolSingleSelectDropdown
				options={options}
				borderRadius="rounded-xl"
				selectedOption={selectedRole}
				handleSelect={handleSelectRole}
			/>
		</div>
	);
};
