import { IReport, UniqueItemInfoType } from 'types';

import {
	AddReportAction,
	SetReportsAction,
	DeleteReportAction,
	SetReportFullscreenAction,
	SetTotalReportsCountAction,
	SetReportsCurrentPageAction
} from '../actions';
import ActionType from '../action-types';

/**
 * Holds all of the reports state action logic
 * @author Matthew Douglas
 */

export const setReports = (reports: IReport[]): SetReportsAction => {
	return {
		type: ActionType.SET_REPORTS,
		payload: {
			reports
		}
	};
};

export const addReport = (report: IReport): AddReportAction => {
	return {
		type: ActionType.ADD_REPORT,
		payload: {
			report
		}
	};
};

export const deleteReport = (reportId: number): DeleteReportAction => {
	return {
		type: ActionType.DELETE_REPORT,
		payload: {
			reportId
		}
	};
};

export const setReportsCurrentPage = (currentPage: number): SetReportsCurrentPageAction => {
	return {
		type: ActionType.SET_REPORTS_CURRENT_PAGE,
		payload: {
			currentPage
		}
	};
};

export const setTotalReportsCount = (totalReportsCount: number): SetTotalReportsCountAction => {
	return {
		type: ActionType.SET_REPORTS_COUNT,
		payload: {
			totalReportsCount
		}
	};
};

export const setReportFullscreen = (
	fullscreenReport: UniqueItemInfoType
): SetReportFullscreenAction => {
	return {
		type: ActionType.SET_REPORT_FULLSCREEN,
		payload: {
			fullscreenReport
		}
	};
};
