import '@gooddata/sdk-ui-charts/styles/css/main.css';

import { useState } from 'react';
import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { IGeneralOption } from 'types';

/**
 * An Execute component to grab the KPIs for the particular organization (used in the action plan overlay)
 * @author Matthew Douglas
 * @param defaultValue - default value of the KPI
 * @param onKPISelect - callback function that changes a passed parameter in the parent component
 */
interface ActionKPISelectProps {
	defaultValue?: IGeneralOption | undefined | null;
	onKPISelect: (result: any) => void;
}

export const ActionKPISelect: React.FC<ActionKPISelectProps> = ({ defaultValue, onKPISelect }) => {
	const [selectedKPI, setSelectedKPI] = useState<IGeneralOption | null>(
		defaultValue ? defaultValue : null
	);

	const handleKPISelect = (e: IGeneralOption | null) => {
		if (!e?.value) {
			onKPISelect(null);
			setSelectedKPI(e);
		} else {
			onKPISelect(e);
			setSelectedKPI(e);
		}
	};

	return (
		<div className="w-full">
			<Execute
				seriesBy={[Ldm.OMValue.Avg]}
				slicesBy={[Ldm.OMUnitOfMeasurement, Ldm.OMMetricId, Ldm.OMGranularity]}
				onError={errInfo => {
					if (errInfo.message !== 'CANCELLED') {
						Util.logErrorMessage(errInfo);
					}
				}}
			>
				{execution => {
					const { result } = execution;

					let loading = true;

					const slices = result?.data().slices().toArray();
					const kpis: { label: string; value: string | null }[] = [];

					if (slices) {
						loading = false;

						slices.forEach(slice => {
							if (slice.sliceTitles().length > 0) {
								const [name, id, granularity] = slice.sliceTitles();
								kpis.push({
									label: `${name} (${granularity})`,
									value: id
								});
							}
						});
					}

					kpis.push({
						label: 'None',
						value: null
					});

					return (
						<>
							{loading ? (
								<LoadingComponent />
							) : (
								<Component.HolSingleSelectDropdown
									options={kpis}
									selectedOption={selectedKPI}
									handleSelect={handleKPISelect}
									textSize="text-md"
								/>
							)}
						</>
					);
				}}
			</Execute>
		</div>
	);
};
