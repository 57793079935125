import { IReport } from 'types';
import { useAxiosPrivate } from 'hooks';

import {
	IGetReportsRes,
	GetReportsType,
	CreateReportType,
	UpdateReportType,
	DeleteReportType
} from './types';

export const ReportApi = () => {
	const { serverAxios } = useAxiosPrivate();

	/**
	 * An API that retrieves all reports created by user within a workspace
	 * @param userId - current user that is logged in
	 * @param workspaceId - current workspace that the user is in.
	 * @param currentPage - page that is being requested for displaying
	 * @param itemsPerPage - how many items should be showed per page
	 */
	const getReports: GetReportsType = async (
		workspaceId,
		userId,
		currentPage = 1,
		itemsPerPage = 4
	) => {
		return await serverAxios
			.get<IGetReportsRes>(
				`${process.env.REACT_APP_HOST}/report/${workspaceId}/${userId}?page=${currentPage}&limit=${itemsPerPage}`
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that updates the specified report
	 * @param workspaceId - current workspace that the user is in.
	 * @param userId - current user that is logged in
	 * @param reportId - the report id that the user wants to update
	 * @param report - the updated report object
	 */
	const updateReport: UpdateReportType = async (workspaceId, userId, reportId, report) => {
		return await serverAxios
			.put<IReport>(
				`${process.env.REACT_APP_HOST}/report/${workspaceId}/${userId}/${reportId}`,
				report
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that deletes the specified report
	 * @param workspaceId - current workspace that the user is in.
	 * @param userId - current user that is logged in
	 * @param reportId - the report id of the report that the user wants to delete
	 */
	const deleteReport: DeleteReportType = async (workspaceId, userId, reportId) => {
		return await serverAxios
			.delete(`${process.env.REACT_APP_HOST}/report/${workspaceId}/${userId}/${reportId}`)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that creates a new report and store in database
	 * @param report - current report that the user just created.
	 */
	const createReport: CreateReportType = async report => {
		return await serverAxios
			.post<IReport>(`${process.env.REACT_APP_HOST}/report/create`, report)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	return {
		getReports,
		createReport,
		updateReport,
		deleteReport
	};
};
