import '@gooddata/sdk-ui-charts/styles/css/main.css';

import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';

import * as Ldm from 'ldm';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { useActions } from 'hooks';
import { HolmetricsFactorType, HolmetricsDiagnosticType, IFactorDataInfo } from 'types';
import { FACTOR_TYPES, DIAGNOSTIC_TYPES, FACTORS_INFO, DIAGNOSTICS_INFO } from 'constants/dataset';

import { setDiagnosticsSelectedOption } from './helpers';

/**
 * The overview page containing overview metrics related to the selected workspace
 */
export const Overview: React.FC = () => {
	const { setSelectedFactor, setCurrentDiagnostics } = useActions();
	const componentRef = useRef(null);

	const handleDiagnosticSelect = (diagnosticType: HolmetricsDiagnosticType) => {
		const selectedOption = setDiagnosticsSelectedOption(diagnosticType);

		setCurrentDiagnostics(selectedOption);
		Analytics.changeDiagnosticsType(selectedOption);
	};

	const handleFactorSelect = (factorType: HolmetricsFactorType, factorInfo: IFactorDataInfo) => {
		setSelectedFactor({
			factorState: factorInfo,
			selectedCard: factorType
		});
	};

	return (
		<div ref={componentRef} className="max-w-screen-2xl mx-auto mb-16">
			<Component.PrintOrientation orientation="portrait" />
			<Component.PageHeader
				pageType="overview"
				showPrintButton
				showDemographicFilter
				parentComponentRef={componentRef}
				additionalCustomComponentLeft={
					<div className="flex items-center space-x-2">
						<Component.HolText textType="heading1">Diagnostics</Component.HolText>
						<span className="mt-1">
							<Component.LastUpdated slicedBy={Ldm.LastScoresUpdate} />
						</span>
					</div>
				}
			/>

			<div className="grid gap-6 print:block">
				{/* diagnostics */}
				<NavLink
					className="grid md2:grid-cols-2 lg2:grid-cols-3 xl2:grid-cols-5 gap-8 mb-4 print:flex print:flex-wrap print:justify-center"
					to="/diagnostics"
				>
					{DIAGNOSTIC_TYPES.map(diagnosticType => {
						const diagnosticInfo = DIAGNOSTICS_INFO[diagnosticType];

						return (
							<Component.HolCard
								key={diagnosticInfo.identifier}
								styles="print:block print:min-w-80"
								columns="col-span-full md2:col-span-1"
								onClick={() => handleDiagnosticSelect(diagnosticInfo.identifier)}
							>
								<Component.DiagnosticScoreDisplay
									diagnosticInfo={diagnosticInfo}
									isPercentageValue={
										diagnosticInfo.identifier === 'eNPS' ||
										diagnosticInfo.identifier === 'communication'
											? false
											: true
									}
								/>
							</Component.HolCard>
						);
					})}
				</NavLink>

				{/* factors */}
				<div className="flex items-center space-x-2">
					<Component.HolText textType="heading1">Factors</Component.HolText>
				</div>

				<NavLink
					className="grid md2:grid-cols-3 xl2:grid-cols-4 gap-8 mb-4 print:flex print:flex-wrap"
					to="/factors"
				>
					{FACTOR_TYPES.map(factorType => {
						const factorInfo = FACTORS_INFO[factorType];

						return (
							<Component.HolCard
								key={factorInfo.identifier}
								styles="print:block"
								columns="col-span-full md2:col-span-1"
								onClick={() =>
									handleFactorSelect(factorInfo.identifier, factorInfo)
								}
							>
								<Component.FactorCardRegular pageType='overview' factorInfo={factorInfo} />
							</Component.HolCard>
						);
					})}
				</NavLink>

				{/* action plans */}
				<Component.ActiveActionPlanCard />
			</div>
		</div>
	);
};
