import React, { useRef, useEffect } from 'react';

import * as Util from 'utils';
import * as Component from 'components';

import { ActionPlanApi } from 'api';
import { useActions, useTypedSelector } from 'hooks';

/**
 * Displays the Action Planning page which allows users to view existing action plans
 * as well as create new action plans
 * @author Konstantin Krumin
 */
export const ActionPlans: React.FC = () => {
	const { currentWorkspace, userId } = useTypedSelector(state => state.user);
	const { actionPlans, itemsPerPage, currentPage, totalActionPlansCount } = useTypedSelector(
		state => state.actionPlanning
	);

	const { setActionPlans, setTotalActionPlansCount, setActionPlanCurrentPage } = useActions();

	const Api = ActionPlanApi();
	const componentRef = useRef(null);

	useEffect(() => {
		Api.getActionPlans(currentWorkspace.id, userId, false, currentPage, itemsPerPage)
			.then(result => {
				setActionPlans(result.results);
				setTotalActionPlansCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));

		// eslint-disable-next-line
	}, [setActionPlans, userId, currentWorkspace.id]);

	const handlePageChange = (updatedPageNum: number) => {
		setActionPlanCurrentPage(updatedPageNum);

		Api.getActionPlans(currentWorkspace.id, userId, false, updatedPageNum, itemsPerPage)
			.then(result => {
				setActionPlans(result.results);
				setTotalActionPlansCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<div ref={componentRef} className="max-w-screen-2xl mx-auto mb-16">
			<Component.PrintOrientation orientation="landscape" />
			<Component.PageHeader
				showPrintButton
				parentComponentRef={componentRef}
				additionalCustomComponentLeft={<Component.ArchivedActionPlansOverlay />}
				pageType="action-plans"
			/>

			<div className="grid grid-cols-12 gap-6">
				{actionPlans?.map(actionPlan => {
					return (
						<Component.ActionPlanOverview
							key={actionPlan.uuid}
							actionPlan={actionPlan}
						/>
					);
				})}

				{/* New action plan overlay */}
				<div className="col-span-full md2:col-span-6 lg2:col-span-4 xl2:col-span-3 print-hide">
					<Component.ActionPlanOverlay type="create" />
				</div>
			</div>

			{actionPlans && (
				<Component.StatelessPagination
					arrayName="plan(s)"
					bgColor="bg-users-title"
					iconColor="text-users-title"
					itemsPerPage={itemsPerPage}
					totalCount={totalActionPlansCount}
					currentListLength={actionPlans.length}
					onChangePage={handlePageChange}
					currentPage={currentPage}
				/>
			)}
		</div>
	);
};
