import { FactorType, ReportDiagnosticMetricType } from 'types';

export const REPORT_KPI_COLORS: { [id: string]: string } = {
	'Billable Hours (Department)': '#F3722C',
	'Billable Hours (Employee)': '#90BE6D'
};

export const REPORT_DIAGNOSTIC_COLORS: { [id in ReportDiagnosticMetricType]: string } = {
	'Chats Received': '#F9C74F',
	'Chats Sent': '#FFB7FF',
	'Emails Received': '#FFA69E',
	'Emails Sent': '#0096C7',
	'Air CO2': '#84DCC6',
	'Air VOC': '#157145',
	Light: '#C05299',
	Noise: '#EF798A',
	Temperature: '#127475'
};

export const REPORT_FACTOR_COLORS: { [id in FactorType | string]: string } = {
	'Work Engagement': '#3E418E',
	Burnout: '#FFA800',
	'Job Satisfaction': '#E34150',
	'Quality of Leadership': '#6CBEE9',
	'Role Clarity': '#53A47A',
	'Horizontal Trust': '#8DC8E8',
	'Vertical Trust': '#BACBE5',
	'Sense of Community': '#EB7B39'
};

export const MISC_COLORS = {
	green: '#59B28E',
	grey: '#BEC6CD'
};

export const INSIGHT_CHART_COLORS = ['#492E86', '#4FB1D8', '#9FD4BF', '#F4BA4E', '#EB7B39'];
