import React from 'react';

import { FaRegWindowClose } from 'react-icons/fa';

import { NotificationType } from 'types';

/**
 * A notification component
 * @author Michaela Mempin
 * @param message - message to be displayed
 * @param actionButton - active button to show
 * @param type - type of the notification in order to assign correct background color
 * @param onCloseHandler - a callback function that gets called on notification close
 */
interface HolNotificationProps {
	message: string;
	actionButton?: any;
	type: NotificationType;
	onCloseHandler: () => void;
}

export const HolNotification: React.FC<HolNotificationProps> = ({
	type,
	message,
	actionButton,
	onCloseHandler
}) => {
	let messageColor = 'bg-hol-light-blue';

	switch (type) {
		case 'success':
			messageColor = 'bg-hol-dark-green';
			break;
		case 'danger':
			messageColor = 'bg-hol-red';
			break;
		case 'warning':
			messageColor = 'bg-hol-yellow';
			break;
	}

	return (
		<div className="flex flex-col mb-7">
			<div
				className={`flex justify-end ${messageColor} bg-opacity-80 rounded-t-md px-4 py-1`}
			>
				<FaRegWindowClose
					className="text-white text-xl cursor-pointer"
					onClick={onCloseHandler}
				/>
			</div>
			<div className={`flex justify-between ${messageColor} bg-opacity-10 px-4 py-3`}>
				{message}
				{actionButton && actionButton}
			</div>
		</div>
	);
};
