import React from 'react';

import { IFilter, IMeasure } from '@gooddata/sdk-model';
import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import * as Util from 'utils';

import { KpiValueType } from 'types';
import { createReadablePercentageValue, getLatestDataPointVal } from 'utils';

/**
 * A component to display a headline value deriving from LDM Catalog
 * @author Konstantin Krumin
 * @param title - Metric title to display
 * @param filters - filters for the metric
 * @param headlineMeasure - LDM Catalogue reference for the value calculation
 * @param showPercentageSign - optional parameter that determines whether the % sign should show next to the value
 * @param showPlusOrMinusSign - optional parameter that determines whether the + or - sign should show in front of the value
 * @param convertDecimalToPercentage - optional parameter that allows to convert decimal values into integer values (0.67 -> 67)
 */
interface MainHeadlineDisplayProps {
	title: string;
	additionalText?: string;
	headlineMeasure: IMeasure;
	showPercentageSign?: boolean;
	showPlusOrMinusSign?: boolean;
	filters: IFilter[] | undefined;
	convertDecimalToPercentage?: boolean;
}

const MainHeadlineDisplay: React.FC<MainHeadlineDisplayProps> = ({
	title,
	filters,
	additionalText,
	headlineMeasure,
	showPercentageSign = false,
	showPlusOrMinusSign = false,
	convertDecimalToPercentage = false
}) => {
	return (
		<Execute
			seriesBy={[headlineMeasure]}
			filters={filters}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				if (!result) {
					return <LoadingComponent className="my-8" />;
				}

				let value: KpiValueType = 0;

				if (typeof result !== 'undefined') {
					value = convertDecimalToPercentage
						? createReadablePercentageValue(result, 0)
						: getLatestDataPointVal(result, 0);
				}

				if (execution.isLoading) {
					return <LoadingComponent className="my-8" />;
				} else {
					return (
						<div className="flex flex-col text-left w-30 pr-5">
							<div className="font-new-spirit font-medium text-2xl md:text-4xl">
								{showPlusOrMinusSign &&
									value !== 0 &&
									value !== '-' &&
									value !== 'No Data' &&
									`${value > 0 ? '+' : '-'}`}
								{value.toLocaleString()}
								{showPercentageSign && ' %'}
								{additionalText}
							</div>
							<div className="font-sofia-regular text-base md:text-xl">{title}</div>
						</div>
					);
				}
			}}
		</Execute>
	);
};

export default MainHeadlineDisplay;
