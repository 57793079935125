import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';

import { IMeasure } from '@gooddata/sdk-model';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import {
	IReport,
	IKPIFilter,
	IChangeChartData,
	IReportData,
	IGeneralOption,
	IDemographicFilter
} from 'types';
import { ReportApi } from 'api';
import { FACTORS } from 'constants/dataset';
import { useTypedSelector, useActions } from 'hooks';

import { ChangeKPISelect } from './components';
import { INITIAL_REPORT_STATE } from './constants';
import { FormInputValueType, ModalType } from './types';

/**
 * Overlay component that allows users to create / edit reports
 * @param modalType - a type of action being applied (create / update)
 * @param openModal - a boolean that determines whether a modal should be opened
 * @param reportData - an optional parameter to pass an existing report data details
 * @param updateHandler - a callback function used to update data in the parent component
 * @param setOpenModal - a callback fucntion to open a modal from outside of the component for editing
 */
interface ReportBuilderOverlayProps {
	openModal?: boolean;
	modalType?: ModalType;
	reportData?: IReportData;
	updateHandler?: (report: IReport) => void;
	setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReportBuilderOverlay: React.FC<ReportBuilderOverlayProps> = ({
	openModal = false,
	modalType = 'create',
	reportData = INITIAL_REPORT_STATE,
	setOpenModal,
	updateHandler
}) => {
	const Api = ReportApi();

	const dataDeepCopy = JSON.parse(JSON.stringify(reportData));

	const userId = useTypedSelector(state => state.user.userId);
	const workspace = useTypedSelector(state => state.user.currentWorkspace);
	const { currentPage, itemsPerPage } = useTypedSelector(state => state.report);

	const { addReport, setReports, setTotalReportsCount } = useActions();

	const [isOpenModal, setIsOpenModal] = useState(openModal);

	const [formInput, setFormInput] = useState<IReportData>(dataDeepCopy);

	const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

	useEffect(() => {
		resetFormInput();
		// eslint-disable-next-line
	}, [workspace.id, userId]);

	const resetFormInput = () => {
		const deepCopy = JSON.parse(JSON.stringify(reportData));

		const newForm = deepCopy;
		newForm.workspaceId = workspace.id;
		newForm.userId = userId;

		setFormInput(newForm);
	};

	const handleInputChange = (value: FormInputValueType, property: keyof IReportData) => {
		const propString = property as string;

		if (formErrors[propString]) {
			const copyErr = { ...formErrors };

			delete copyErr[propString];
			setFormErrors(copyErr);
		}

		setFormInput(formInput => ({
			...formInput,
			[property]: value
		}));
	};

	const handleDataChange = (values: any, property: keyof IChangeChartData) => {
		const temp = { ...formInput };
		const tempData: IChangeChartData = temp.data;

		let newValue = values;

		// value: GeneralOption. Get the measure of the Factor by v.value
		if (property === 'factors') {
			newValue = values.map((v: any) => v.value);
		}

		tempData[property] = newValue;

		setFormInput(temp);
	};

	const confirmHandler = () => {
		let errors: { [key: string]: string } = {};

		if (formInput.name.length === 0) {
			errors['name'] = 'You must have a name for the chart.';
		}

		if (formInput.data.factors.length === 0) {
			errors['factors'] = 'You must at least have one factor selected.';
		}

		setFormErrors(errors);

		const tempInput: any = { ...formInput };
		tempInput.data = JSON.stringify(formInput.data);

		if (Object.keys(errors).length === 0) {
			// post request to store the created report

			if (modalType === 'create') {
				Api.createReport(tempInput as IReport)
					.then(result => {
						addReport(result);
						Analytics.createNewReport();
					})
					.then(() => {
						Api.getReports(workspace.id, userId, currentPage, itemsPerPage)
							.then(result => {
								setReports(result.results);
								setTotalReportsCount(result.length);
							})
							.catch(err => Util.logErrorMessage(err));
					})
					.catch(err => Util.logErrorMessage(err));
			} else {
				if (updateHandler) {
					updateHandler(tempInput as IReport);
				}
			}

			resetFormInput();
			setIsOpenModal(false);

			if (setOpenModal) {
				setOpenModal(false);
			}
		}
	};

	const factorsSelected = FACTORS.filter(i => {
		const factor = formInput.data.factors.map((j: IMeasure) => j.measure?.localIdentifier);
		return factor.indexOf(i.value.measure.localIdentifier) !== -1;
	});

	return (
		<>
			{modalType === 'create' && (
				<div
					className={`border-2 rounded-md border-font-light flex justify-center items-center cursor-pointer`}
					style={{ height: '519px' }}
					onClick={() => setIsOpenModal(true)}
				>
					<div className="flex flex-col justify-center items-center text-regXL font-sofia-regular text-font-light">
						+ Add Report
					</div>
				</div>
			)}

			<Modal
				isOpen={isOpenModal}
				className="bg-white w-11/12 sm:max-w-lg mx-auto my-auto rounded shadow-card py-10 px-7 z-50"
				overlayClassName="fixed flex bg-gray-50 bg-opacity-40 inset-0 h-screen w-screen z-50 overflow-auto"
				ariaHideApp={false}
			>
				{Object.keys(formErrors).length > 0 && (
					<div className="text-sm text-red mb-3">
						<p>There were the following error(s):</p>
						{Object.keys(formErrors).map((err, idx) => (
							<p key={`err_${idx}`}>{formErrors[err]}</p>
						))}
					</div>
				)}

				<span className="text-font-title font-sofia-bold text-2xl">Report Builder</span>
				<div className="text-font-title mt-6 flex flex-col space-y-6">
					<div className="flex flex-col space-y-2">
						<span className="text-font-dark-1 font-sofia-bold text-lg">Name</span>

						<Component.HolInput
							type="text"
							borderColor={
								Object.keys(formErrors).includes('name') ? 'border-red' : undefined
							}
							defaultValue={formInput.name}
							onValueChange={value => {
								handleInputChange(value, 'name');
							}}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<span className="text-font-dark-1 font-sofia-bold text-lg">
							Date of Change
						</span>

						<DatePicker
							selected={new Date(formInput.data.changeDate)}
							onChange={date => handleDataChange(date as Date, 'changeDate')}
							className={`w-5/12 text-center border border-input-border rounded p-1 focus:outline-none focus:ring-2 focus:ring-default focus:border-transparent`}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<span className="text-font-dark-1 font-sofia-bold text-lg">
							Demographics
						</span>

						<Component.DemographicSelect
							setDemographicSelected={(
								d: { [key: string]: IDemographicFilter } | null
							) => handleDataChange(d, 'demographics')}
							selectedDemographics={formInput.data.demographics}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<span className="text-font-dark-1 font-sofia-bold text-lg">
							Diagnostics
						</span>

						<Component.DiagnosticSelect
							setDiagnosticsSelected={(d: { [key: string]: any } | null) =>
								handleDataChange(d, 'diagnostics')
							}
							selectedDiagnostics={formInput.data.diagnostics}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<span className="text-font-dark-1 font-sofia-bold text-lg">Factors</span>

						<Component.HolMultiSelectDropdown
							options={FACTORS}
							selectedValues={factorsSelected}
							handleSelect={(d: IGeneralOption[]) => handleDataChange(d, 'factors')}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<span className="text-font-dark-1 font-sofia-bold text-lg">KPIs</span>

						<ChangeKPISelect
							kpiSelected={formInput.data.kpi}
							setKPISelect={(d: { [key: string]: IKPIFilter }) =>
								handleDataChange(d, 'kpi')
							}
						/>
					</div>
				</div>

				<div className="flex justify-between items-center mt-5 space-y-2 flex-col xs:flex-row">
					<div className="flex items-center space-x-2">
						<Component.HolText textType="heading3">Share With Team</Component.HolText>

						<input
							type="checkbox"
							checked={formInput.isShared}
							className="form-checkbox h-4 w-4 focus:outline-none rounded text-hol-dark-purple"
							onChange={e => handleInputChange(e.target.checked, 'isShared')}
						/>
					</div>
					<div className="flex justify-end space-x-5">
						<Component.HolButton
							label="Cancel"
							type="secondary"
							onClick={() => {
								resetFormInput();
								setIsOpenModal(false);

								if (setOpenModal) {
									setOpenModal(false);
								}
							}}
						/>

						<Component.HolButton
							type="primary"
							label={modalType === 'create' ? 'Create' : 'Update'}
							onClick={confirmHandler}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};
