import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { IFactorDataInfo } from 'types';
import { useTypedSelector } from 'hooks';
import { RELATIVE_DATE_FILTERS } from 'constants/dataset';

import { ParentWrapper } from './components';

/**
 * Component displaying confidence score for factors
 * @author Konstantin Krumin
 * @param factorInfo - information on the factor
 */
interface FactorConfidenceScoreProps {
	factorInfo: IFactorDataInfo;
}

export const FactorConfidenceScore: React.FC<FactorConfidenceScoreProps> = ({ factorInfo }) => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const dateFilter = RELATIVE_DATE_FILTERS.thisMonth;

	return (
		<Execute
			seriesBy={[Ldm.ScoreAccuracyPercent]}
			filters={[
				...demographicFilter,
				dateFilter,
				newPositiveAttributeFilter(Ldm.Factor, [factorInfo.title])
			]}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				if (!result) {
					return (
						<ParentWrapper>
							<LoadingComponent />
						</ParentWrapper>
					);
				}

				const confidenceScore = Util.createReadablePercentageValue(result, '-');

				if (execution.isLoading) {
					return (
						<ParentWrapper>
							<LoadingComponent />
						</ParentWrapper>
					);
				} else {
					return (
						<ParentWrapper>
							<Component.HolText>
								Confidence: {confidenceScore}
								{confidenceScore !== '-' && '%'}
							</Component.HolText>
						</ParentWrapper>
					);
				}
			}}
		</Execute>
	);
};
