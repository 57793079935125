import React, { ReactNode } from 'react';

/**
 * A tooltip component
 * @author Konstantin Krumin
 * @param bgColor - background color for the tooltip
 * @param message - element to be displayed in the tooltip
 * @param styles - any additional custom styles for the tooltip component
 * @param children - accepts an element to which tooltip will be attached
 */
interface HolTooltipProps {
	styles?: string;
	bgColor?: string;
	message: ReactNode;
	children: ReactNode;
}

export const HolTooltip: React.FC<HolTooltipProps> = ({ children, message, bgColor, styles }) => {
	return (
		<div className="group relative flex items-center justify-center h-12 cursor-pointer z-10">
			{children}

			<span
				className={`absolute rounded-md shadow-md text-white text-center text-sm font-bold p-2 mb-20 min-w-max invisible group-hover:visible ${
					bgColor ? bgColor : 'bg-gray-300'
				} ${styles}`}
			>
				{message}
			</span>
		</div>
	);
};
