import React, { useMemo, useState } from 'react';
import { FaCompressArrowsAlt, FaExpand } from 'react-icons/fa';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useActions, useTypedSelector, useChangeData } from 'hooks';
import { IChangeChartData, IReportDataConfig, IReport } from 'types';

import { ChangeChartActions, ReportDateRange } from './components';

/**
 * Displays a report chart component
 * @author Matthew Douglas, Misael Esperanzate, Michaela Mempin
 * @param report - information that chart needs to be constructed
 */
export interface ReportChartProps {
	report: IReport;
}

export const ReportChart: React.FC<ReportChartProps> = ({ report }) => {
	const userId = useTypedSelector(state => state.user.userId);
	const { fullscreenReport } = useTypedSelector(state => state.report);

	const { setReportFullscreen } = useActions();

	const [monthAxis, setMonthAxis] = useState<string[]>([]);
	const [reportInfo, setReportInfo] = useState<IReport>(report);

	const reportData: IChangeChartData = JSON.parse(reportInfo.data);
	const changeDate = new Date(reportData.changeDate);

	const [dataConfig, setDataConfig] = useState<IReportDataConfig>({
		slicesBy: [Ldm.DateDatasets.Date.MonthYear.Short],
		dateFilter: Util.getChangeDateFilter(Ldm.DateDatasets.Date.identifier, changeDate)
	});

	// Format xAxis label
	const monthYear = Util.getMonthYear(changeDate);

	const { chartSeries, isLoading } = useChangeData(reportInfo, dataConfig, monthAxis);

	const handleResize = (id: number | undefined) => {
		if (!fullscreenReport === true && id) {
			setReportFullscreen({ id, isShow: true });
		} else {
			setReportFullscreen(false);
		}
	};

	const handleMonthAxisChange = (ms: string[]) => setMonthAxis(ms);
	const handleDataConfigChange = (dataConfig: IReportDataConfig) => setDataConfig(dataConfig);

	const MemoChangeChart = useMemo(() => {
		return (
			<Component.HolCard
				title={reportInfo.name}
				headerTitleTextStyle="pl-10 text-sm font-dark font-sofia-bold"
				styles="print-page-break"
				columns={`text-font-dark ${
					fullscreenReport ? 'col-span-full' : 'col-span-full lg2:col-span-6'
				} `}
				titleElement={
					<>
						{reportInfo.isShared ? (
							<Component.HolSharedTooltip
								text="Shared Chart"
								sharedBy={reportInfo.userId}
								sharedDate={reportInfo.createdAt}
							/>
						) : (
							<></>
						)}
					</>
				}
				topRightElement={
					<div className="flex justify-between items-center space-x-7">
						<ReportDateRange
							date={changeDate}
							handleMonthAxisChange={handleMonthAxisChange}
							handleDataConfigChange={handleDataConfigChange}
						/>

						{reportInfo.userId === userId && (
							<ChangeChartActions
								reportInfo={reportInfo}
								setReportInfo={setReportInfo}
							/>
						)}

						<Component.HolIconButton
							icon={fullscreenReport ? <FaCompressArrowsAlt /> : <FaExpand />}
							customStyles="text-2xl mx-2 hover:opacity-60"
							onClick={() => handleResize(reportInfo.id)}
						/>
					</div>
				}
			>
				{
					<Execute
						seriesBy={[]}
						slicesBy={[]}
						onError={errInfo => {
							if (errInfo.message !== 'CANCELLED') {
								Util.logErrorMessage(errInfo);
							}
						}}
					>
						{() => {
							if (isLoading || (chartSeries && chartSeries.length === 0)) {
								return (
									<div className="my-auto h-24">
										<LoadingComponent />
									</div>
								);
							} else if (!chartSeries) {
								return (
									<div className="flex text-gray-400 h-24">
										<p className="m-auto">No data for your filter selection</p>
									</div>
								);
							}

							const yAxisMax = Math.max(
								...chartSeries.map(point => {
									const nums = point.data;
									return Math.max(...nums);
								})
							);

							// Data for the bar. Array of values, everything is 0 except for the month of change
							const bandData = [...monthAxis].map((_, i) => {
								if (monthAxis.indexOf(monthYear) === i) {
									// + 5 to pad top of bar plot
									return yAxisMax + 5;
								}
								return 0;
							});

							const reportOptions = Util.lineChartConfig(
								monthAxis,
								[...chartSeries],
								bandData,
								yAxisMax
							);

							return (
								<div className="mt-2 p-5">
									<HighchartsReact
										highcharts={Highcharts}
										options={reportOptions}
									/>
								</div>
							);
						}}
					</Execute>
				}
			</Component.HolCard>
		);
		// eslint-disable-next-line
	}, [reportInfo, chartSeries, isLoading]);

	return MemoChangeChart;
};
