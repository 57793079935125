/* eslint-disable */
/* THIS FILE WAS AUTO-GENERATED USING CATALOG EXPORTER; YOU SHOULD NOT EDIT THIS FILE; GENERATE TIME: 2023-03-23T21:05:31.711Z; */
// @ts-ignore ignore unused imports here if they happen (e.g. when there is no measure in the workspace)
import {
	newAttribute,
	newMeasure,
	IAttribute,
	IMeasure,
	IMeasureDefinition,
	idRef
} from '@gooddata/sdk-model';

/**
 * Attribute Title: Age Group
 * Attribute ID: attr.employee.age_group
 */
export const AgeGroup: IAttribute = newAttribute('label.employee.age_group');
/**
 * Attribute Title: Answer Code
 * Attribute ID: attr.score_factor_month.answercode
 */
export const AnswerCode: IAttribute = newAttribute('label.score_factor_month.answercode');
/**
 * Attribute Title: Answer Text
 * Attribute ID: attr.score_factor_month.answer_text
 */
export const AnswerText: IAttribute = newAttribute('label.score_factor_month.answer_text');
/**
 * Attribute Title: Cohort
 * Attribute ID: attr.employee.cohort
 */
export const Cohort: IAttribute = newAttribute('label.employee.cohort');
/**
 * Attribute Title: Connectivity
 * Attribute ID: attr.ona_connectivity.connectivity
 */
export const Connectivity: IAttribute = newAttribute('label.ona_connectivity.connectivity');
/**
 * Attribute Title: Consultant Firm
 * Attribute ID: attr.organization.consultant_firm
 */
export const ConsultantFirm: IAttribute = newAttribute('label.organization.consultant_firm');
/**
 * Attribute Title: Consultant Id
 * Attribute ID: attr.organization.consultant_id
 */
export const ConsultantId: IAttribute = newAttribute('label.organization.consultant_id');
/**
 * Attribute Title: Department
 * Attribute ID: attr.employee.department
 */
export const Department: IAttribute = newAttribute('label.employee.department');
/**
 * Attribute Title: Diagnostics Status
 * Attribute ID: attr.diagnostics_availability.diagnostics_status
 */
export const DiagnosticsStatus: IAttribute = newAttribute(
	'label.diagnostics_availability.diagnostics_status'
);
/**
 * Attribute Title: Diagnostics Type
 * Attribute ID: attr.diagnostics_availability.diagnostics_type
 */
export const DiagnosticsType: IAttribute = newAttribute(
	'label.diagnostics_availability.diagnostics_type'
);
/**
 * Attribute Title: Employee Id
 * Attribute ID: attr.employee.employee_id
 */
export const EmployeeId: IAttribute = newAttribute('label.employee.employee_id');
/**
 * Attribute Title: Employment Type
 * Attribute ID: attr.employee.employment_type
 */
export const EmploymentType: IAttribute = newAttribute('label.employee.employment_type');
/**
 * Attribute Title: Ethnicity
 * Attribute ID: attr.employee.ethnicity
 */
export const Ethnicity: IAttribute = newAttribute('label.employee.ethnicity');
/**
 * Attribute Title: Factor
 * Attribute ID: attr.factor.factor
 */
export const Factor: IAttribute = newAttribute('label.factor.factor');
/**
 * Attribute Title: Factor Baseline
 * Attribute ID: attr.factor_baseline.factorbaseline
 */
export const FactorBaseline: IAttribute = newAttribute('label.factor_baseline.factorbaseline');
/**
 * Attribute Title: Factor Description
 * Attribute ID: attr.factor.factor_description
 */
export const FactorDescription: IAttribute = newAttribute('label.factor.factor_description');
/**
 * Attribute Title: Gender
 * Attribute ID: attr.employee.gender
 */
export const Gender: IAttribute = newAttribute('label.employee.gender');
/**
 * Attribute Title: Hour
 * Attribute ID: attr.diagnostics_hours.hour
 */
export const Hour: IAttribute = newAttribute('label.diagnostics_hours.hour');
/**
 * Attribute Title: Insight Answer Text
 * Attribute ID: attr.insight_week.insight_answer_text
 */
export const InsightAnswerText: IAttribute = newAttribute('label.insight_week.insight_answer_text');
/**
 * Attribute Title: Insight Id
 * Attribute ID: attr.insight.insight_id
 */
export const InsightId: IAttribute = newAttribute('label.insight.insight_id');
/**
 * Attribute Title: Insight Question Text
 * Attribute ID: attr.insight.insight_question_text
 */
export const InsightQuestionText: IAttribute = newAttribute('label.insight.insight_question_text');
/**
 * Attribute Title: Job Title
 * Attribute ID: attr.employee.job_title
 */
export const JobTitle: IAttribute = newAttribute('label.employee.job_title');
/**
 * Attribute Title: Language
 * Attribute ID: attr.employee.language
 */
export const Language: IAttribute = newAttribute('label.employee.language');
/**
 * Attribute Title: Last Diagnostics Update
 * Attribute ID: attr.organization.last_diagnostics_update
 */
export const LastDiagnosticsUpdate: IAttribute = newAttribute(
	'label.organization.last_diagnostics_update'
);
/**
 * Attribute Title: Last Scores Update
 * Attribute ID: attr.organization.last_scores_update
 */
export const LastScoresUpdate: IAttribute = newAttribute('label.organization.last_scores_update');
/**
 * Attribute Title: Location Id
 * Attribute ID: attr.location.location_id
 */
export const LocationId: IAttribute = newAttribute('label.location.location_id');
/**
 * Attribute Title: Location Type
 * Attribute ID: attr.employee.location_type
 */
export const LocationType: IAttribute = newAttribute('label.employee.location_type');
/**
 * Attribute Title: Location. City
 * Attribute ID: attr.location.locationcity
 */
export const LocationCity: IAttribute = newAttribute('label.location.locationcity');
/**
 * Attribute Title: Location. Country
 * Attribute ID: attr.location.locationcountry
 */
export const LocationCountry: IAttribute = newAttribute('label.location.locationcountry');
/**
 * Attribute Title: Location. Name
 * Attribute ID: attr.location.locationname
 */
export const LocationName: IAttribute = newAttribute('label.location.locationname');
/**
 * Attribute Title: Location. Postal Code
 * Attribute ID: attr.location.locationpostalcode
 */
export const LocationPostalCode: IAttribute = newAttribute('label.location.locationpostalcode');
/**
 * Attribute Title: Location. Province
 * Attribute ID: attr.location.locationprovince
 */
export const LocationProvince: IAttribute = newAttribute('label.location.locationprovince');
/**
 * Attribute Title: Location. Street
 * Attribute ID: attr.location.locationstreet
 */
export const LocationStreet: IAttribute = newAttribute('label.location.locationstreet');
/**
 * Attribute Title: OM Data Source
 * Attribute ID: attr.operational_metrics.omdatasource
 */
export const OMDataSource: IAttribute = newAttribute('label.operational_metrics.omdatasource');
/**
 * Attribute Title: OM Department
 * Attribute ID: attr.operational_metrics_values.om_department
 */
export const OMDepartment: IAttribute = newAttribute(
	'label.operational_metrics_values.om_department'
);
/**
 * Attribute Title: OM Granularity
 * Attribute ID: attr.operational_metrics.omgranularity
 */
export const OMGranularity: IAttribute = newAttribute('label.operational_metrics.omgranularity');
/**
 * Attribute Title: OM Metric Description
 * Attribute ID: attr.operational_metrics.ommetricdescription
 */
export const OMMetricDescription: IAttribute = newAttribute(
	'label.operational_metrics.ommetricdescription'
);
/**
 * Attribute Title: OM Metric Id
 * Attribute ID: attr.operational_metrics.ommetricid
 */
export const OMMetricId: IAttribute = newAttribute('label.operational_metrics.ommetricid');
/**
 * Attribute Title: OM Time Granularity
 * Attribute ID: attr.operational_metrics.omtimegranularity
 */
export const OMTimeGranularity: IAttribute = newAttribute(
	'label.operational_metrics.omtimegranularity'
);
/**
 * Attribute Title: OM Unit Of Measurement
 * Attribute ID: attr.operational_metrics.omunitofmeasurement
 */
export const OMUnitOfMeasurement: IAttribute = newAttribute(
	'label.operational_metrics.omunitofmeasurement'
);
/**
 * Attribute Title: Organization Admin
 * Attribute ID: attr.organization.organization_admin
 */
export const OrganizationAdmin: IAttribute = newAttribute('label.organization.organization_admin');
/**
 * Attribute Title: Organization Id
 * Attribute ID: attr.organization.organization_id
 */
export const OrganizationId: IAttribute = newAttribute('label.organization.organization_id');
/**
 * Attribute Title: Predictive Indicator
 * Attribute ID: attr.factor.predictiveindicator
 */
export const PredictiveIndicator: IAttribute = newAttribute('label.factor.predictiveindicator');
/**
 * Attribute Title: Question Id
 * Attribute ID: attr.factor.question_id
 */
export const QuestionId: IAttribute = newAttribute('label.factor.question_id');
/**
 * Attribute Title: Question Text
 * Attribute ID: attr.factor.question_text
 */
export const QuestionText: IAttribute = newAttribute('label.factor.question_text');
/**
 * Attribute Title: Semantic Source
 * Attribute ID: attr.testsemanticssent.testcommunicationsource
 */
export const SemanticSource: IAttribute = newAttribute(
	'label.testsemanticssent.testcommunicationsource'
);
/**
 * Attribute Title: Semantic Type
 * Attribute ID: attr.testsemanticssent.testcommunicationcategory
 */
export const SemanticType: IAttribute = newAttribute(
	'label.testsemanticssent.testcommunicationcategory'
);
/**
 * Attribute Title: Sensor Data Type
 * Attribute ID: attr.sensor_values.sensor_data_type
 */
export const SensorDataType: IAttribute = newAttribute('label.sensor_values.sensor_data_type');
/**
 * Attribute Title: Sensor Hour
 * Attribute ID: attr.sensor_values.sensorhour
 */
export const SensorHour: IAttribute = newAttribute('label.sensor_values.sensorhour');
/**
 * Attribute Title: Sensor Id
 * Attribute ID: attr.sensor.sensor_id
 */
export const SensorId: IAttribute = newAttribute('label.sensor.sensor_id');
/**
 * Attribute Title: Shift
 * Attribute ID: attr.employee.shift
 */
export const Shift: IAttribute = newAttribute('label.employee.shift');
/**
 * Attribute Title: Tenure
 * Attribute ID: attr.employee.tenure
 */
export const Tenure: IAttribute = newAttribute('label.employee.tenure');
/**
 * Attribute Title: Zone
 * Attribute ID: attr.sensor.zone
 */
export const Zone: IAttribute = newAttribute('label.sensor.zone');
/**
 * Metric Title: Activity. OWH. Meetings %
 * Metric ID: aaokjjvWzfji
 * Metric Type: MAQL Metric
 */
export const ActivityOWHMeetingsPercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaokjjvWzfji', 'measure')
);
/**
 * Metric Title: Bins. Burnout
 * Metric ID: aa0mScHXA5i1
 * Metric Type: MAQL Metric
 */
export const BinsBurnout: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aa0mScHXA5i1', 'measure')
);
/**
 * Metric Title: Bins. Burnout. Anonymized
 * Metric ID: adp9zbtpCUbf
 * Metric Type: MAQL Metric
 */
export const BinsBurnoutAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('adp9zbtpCUbf', 'measure')
);
/**
 * Metric Title: Bins. Horizontal Trust
 * Metric ID: aamnSrQIAkmL
 * Metric Type: MAQL Metric
 */
export const BinsHorizontalTrust: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aamnSrQIAkmL', 'measure')
);
/**
 * Metric Title: Bins. Horizontal Trust. Anonymized
 * Metric ID: ad09yj7S1n1F
 * Metric Type: MAQL Metric
 */
export const BinsHorizontalTrustAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ad09yj7S1n1F', 'measure')
);
/**
 * Metric Title: Bins. Job Satisfaction
 * Metric ID: aaHnD7Z3rmdU
 * Metric Type: MAQL Metric
 */
export const BinsJobSatisfaction: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaHnD7Z3rmdU', 'measure')
);
/**
 * Metric Title: Bins. Job Satisfaction. Anonymized
 * Metric ID: abW9HKYL5lHJ
 * Metric Type: MAQL Metric
 */
export const BinsJobSatisfactionAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abW9HKYL5lHJ', 'measure')
);
/**
 * Metric Title: Bins. Quality of Leadership
 * Metric ID: aabnYhbrCmgo
 * Metric Type: MAQL Metric
 */
export const BinsQualityOfLeadership: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aabnYhbrCmgo', 'measure')
);
/**
 * Metric Title: Bins. Quality of Leadership. Anonymized
 * Metric ID: ab59HVlc1Zvr
 * Metric Type: MAQL Metric
 */
export const BinsQualityOfLeadershipAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ab59HVlc1Zvr', 'measure')
);
/**
 * Metric Title: Bins. Role Clarity
 * Metric ID: aabnZRrxz9ve
 * Metric Type: MAQL Metric
 */
export const BinsRoleClarity: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aabnZRrxz9ve', 'measure')
);
/**
 * Metric Title: Bins. Role Clarity. Anonymized
 * Metric ID: abz9Npbe7uif
 * Metric Type: MAQL Metric
 */
export const BinsRoleClarityAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abz9Npbe7uif', 'measure')
);
/**
 * Metric Title: Bins. Sense of Community
 * Metric ID: aahnWYUnzmFm
 * Metric Type: MAQL Metric
 */
export const BinsSenseOfCommunity: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aahnWYUnzmFm', 'measure')
);
/**
 * Metric Title: Bins. Sense of Community. Anonymized
 * Metric ID: abS9MD8C3sNl
 * Metric Type: MAQL Metric
 */
export const BinsSenseOfCommunityAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abS9MD8C3sNl', 'measure')
);
/**
 * Metric Title: Bins. Vertical Trust
 * Metric ID: aasnIVxZxLLe
 * Metric Type: MAQL Metric
 */
export const BinsVerticalTrust: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aasnIVxZxLLe', 'measure')
);
/**
 * Metric Title: Bins. Vertical Trust. Anonymized
 * Metric ID: ab29IIGL1SAn
 * Metric Type: MAQL Metric
 */
export const BinsVerticalTrustAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ab29IIGL1SAn', 'measure')
);
/**
 * Metric Title: Bins. Work Engagement
 * Metric ID: ac8m6awc2VKP
 * Metric Type: MAQL Metric
 */
export const BinsWorkEngagement: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ac8m6awc2VKP', 'measure')
);
/**
 * Metric Title: Bins. Work Engagement. Anonymized
 * Metric ID: aeH8qBbYhYpj
 * Metric Type: MAQL Metric
 */
export const BinsWorkEngagementAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aeH8qBbYhYpj', 'measure')
);
/**
 * Metric Title: Chats. % Received OWH
 * Metric ID: aaVh3brLggdx
 * Metric Type: MAQL Metric
 */
export const ChatsPercentReceivedOWH: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaVh3brLggdx', 'measure')
);
/**
 * Metric Title: Chats. % Sent OWH
 * Metric ID: aadipwmUuMyC
 * Metric Type: MAQL Metric
 */
export const ChatsPercentSentOWH: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aadipwmUuMyC', 'measure')
);
/**
 * Metric Title: Chats. AVG Received. Weekdays
 * Metric ID: aaM37Rs0oRdB
 * Metric Type: MAQL Metric
 */
export const ChatsAVGReceivedWeekdays: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaM37Rs0oRdB', 'measure')
);
/**
 * Metric Title: Chats. AVG Sent. Weekdays
 * Metric ID: ac13BbC8zf56
 * Metric Type: MAQL Metric
 */
export const ChatsAVGSentWeekdays: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ac13BbC8zf56', 'measure')
);
/**
 * Metric Title: Communication. External. % OWH
 * Metric ID: aaS57XteB3VL
 * Metric Type: MAQL Metric
 */
export const CommunicationExternalPercentOWH: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaS57XteB3VL', 'measure')
);
/**
 * Metric Title: Communication. Internal. % OWH
 * Metric ID: aa959fvQFu5G
 * Metric Type: MAQL Metric
 */
export const CommunicationInternalPercentOWH: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aa959fvQFu5G', 'measure')
);
/**
 * Metric Title: Communication. OWH. % External
 * Metric ID: aaK2LUHPoR51
 * Metric Type: MAQL Metric
 */
export const CommunicationOWHPercentExternal: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaK2LUHPoR51', 'measure')
);
/**
 * Metric Title: Communication. OWH. % Internal
 * Metric ID: aaD2LQm4oweQ
 * Metric Type: MAQL Metric
 */
export const CommunicationOWHPercentInternal: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaD2LQm4oweQ', 'measure')
);
/**
 * Metric Title: Connectivity. % by category
 * Metric ID: aar43n2UdQuv
 * Metric Type: MAQL Metric
 */
export const ConnectivityPercentByCategory: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aar43n2UdQuv', 'measure')
);
/**
 * Metric Title: Connectivity. % Disconnected
 * Metric ID: abzC3INO4vmI
 * Metric Type: MAQL Metric
 */
export const ConnectivityPercentDisconnected: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abzC3INO4vmI', 'measure')
);
/**
 * Metric Title: Connectivity. % Disconnected. Last 7D
 * Metric ID: aaggs3K7Edco
 * Metric Type: MAQL Metric
 */
export const ConnectivityPercentDisconnectedLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaggs3K7Edco', 'measure')
);
/**
 * Metric Title: Connectivity. % High. Last 7D
 * Metric ID: aawgqWpOZo2m
 * Metric Type: MAQL Metric
 */
export const ConnectivityPercentHighLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aawgqWpOZo2m', 'measure')
);
/**
 * Metric Title: Connectivity. % Low. Last 7D
 * Metric ID: abGgoFcXyZeZ
 * Metric Type: MAQL Metric
 */
export const ConnectivityPercentLowLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abGgoFcXyZeZ', 'measure')
);
/**
 * Metric Title: Connectivity. % Normal. Last 7D
 * Metric ID: aaQgoOwzYtkY
 * Metric Type: MAQL Metric
 */
export const ConnectivityPercentNormalLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaQgoOwzYtkY', 'measure')
);
/**
 * Metric Title: Connectivity. % of Poorly Connected
 * Metric ID: ab8Ezko4FyrR
 * Metric Type: MAQL Metric
 */
export const ConnectivityPercentOfPoorlyConnected: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ab8Ezko4FyrR', 'measure')
);
/**
 * Metric Title: Emails. % Received OWH
 * Metric ID: aaGiexcrtH2n
 * Metric Type: MAQL Metric
 */
export const EmailsPercentReceivedOWH: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaGiexcrtH2n', 'measure')
);
/**
 * Metric Title: Emails. % Sent OWH
 * Metric ID: aakiuFYrGQKo
 * Metric Type: MAQL Metric
 */
export const EmailsPercentSentOWH: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aakiuFYrGQKo', 'measure')
);
/**
 * Metric Title: Emails. AVG Received. Weekdays
 * Metric ID: aaJ4eo5CO6xm
 * Metric Type: MAQL Metric
 */
export const EmailsAVGReceivedWeekdays: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaJ4eo5CO6xm', 'measure')
);
/**
 * Metric Title: Emails. AVG Sent. Weekdays
 * Metric ID: abh372FLBUiy
 * Metric Type: MAQL Metric
 */
export const EmailsAVGSentWeekdays: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abh372FLBUiy', 'measure')
);
/**
 * Metric Title: Employees Count
 * Metric ID: abimPFB7pQak
 * Metric Type: MAQL Metric
 */
export const EmployeesCount: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abimPFB7pQak', 'measure')
);
/**
 * Metric Title: Events. % OWH
 * Metric ID: aatirLz8xIqn
 * Metric Type: MAQL Metric
 */
export const EventsPercentOWH: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aatirLz8xIqn', 'measure')
);
/**
 * Metric Title: Inactivity %
 * Metric ID: aaZm7NybXWUm
 * Metric Type: MAQL Metric
 */
export const InactivityPercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaZm7NybXWUm', 'measure')
);
/**
 * Metric Title: Insight. Response Count. Last Week
 * Metric ID: aa9PZffnL9wn
 * Metric Type: MAQL Metric
 */
export const InsightResponseCountLastWeek: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aa9PZffnL9wn', 'measure')
);
/**
 * Metric Title: Meetings. AVG. Weekdays
 * Metric ID: aas4bEyoN5pB
 * Metric Type: MAQL Metric
 */
export const MeetingsAVGWeekdays: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aas4bEyoN5pB', 'measure')
);
/**
 * Metric Title: Pulse participation. Last 7D
 * Metric ID: aaEj5TbKYusT
 * Metric Type: MAQL Metric
 */
export const PulseParticipationLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaEj5TbKYusT', 'measure')
);
/**
 * Metric Title: Pulse participation. Long-term
 * Metric ID: agQy7XzBurgf
 * Metric Type: MAQL Metric
 */
export const PulseParticipationLongTerm: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('agQy7XzBurgf', 'measure')
);
/**
 * Metric Title: Pulse participation. Long-term. Anonymized
 * Metric ID: aaZL21ZixI0C
 * Metric Type: MAQL Metric
 */
export const PulseParticipationLongTermAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaZL21ZixI0C', 'measure')
);
/**
 * Metric Title: Score. Accuracy %
 * Metric ID: aaVokhMfQdNX
 * Metric Type: MAQL Metric
 */
export const ScoreAccuracyPercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaVokhMfQdNX', 'measure')
);
/**
 * Metric Title: Score. Anonymized
 * Metric ID: aadm94iy8myw
 * Metric Type: MAQL Metric
 */
export const ScoreAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aadm94iy8myw', 'measure')
);
/**
 * Metric Title: Score. Burnout
 * Metric ID: aaxmvQlxkb0A
 * Metric Type: MAQL Metric
 */
export const ScoreBurnout: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaxmvQlxkb0A', 'measure')
);
/**
 * Metric Title: Score. Engagement
 * Metric ID: aabmBg4pobZA
 * Metric Type: MAQL Metric
 */
export const ScoreEngagement: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aabmBg4pobZA', 'measure')
);
/**
 * Metric Title: Score. eNPS
 * Metric ID: abTmRO8orCY6
 * Metric Type: MAQL Metric
 */
export const ScoreENPS: IMeasure<IMeasureDefinition> = newMeasure(idRef('abTmRO8orCY6', 'measure'));
/**
 * Metric Title: Score. eNPS. Anonymized
 * Metric ID: aabF4xDMBf2u
 * Metric Type: MAQL Metric
 */
export const ScoreENPSAnonymized: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aabF4xDMBf2u', 'measure')
);
/**
 * Metric Title: Score. eNPS. Detractors %
 * Metric ID: aauoYtWVbTBV
 * Metric Type: MAQL Metric
 */
export const ScoreENPSDetractorsPercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aauoYtWVbTBV', 'measure')
);
/**
 * Metric Title: Score. eNPS. Last Month
 * Metric ID: aa1mXBJWRlfe
 * Metric Type: MAQL Metric
 */
export const ScoreENPSLastMonth: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aa1mXBJWRlfe', 'measure')
);
/**
 * Metric Title: Score. eNPS. Passives %
 * Metric ID: aatoZiZyz6L0
 * Metric Type: MAQL Metric
 */
export const ScoreENPSPassivesPercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aatoZiZyz6L0', 'measure')
);
/**
 * Metric Title: Score. eNPS. Promoters %
 * Metric ID: aago1cZltTzX
 * Metric Type: MAQL Metric
 */
export const ScoreENPSPromotersPercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aago1cZltTzX', 'measure')
);
/**
 * Metric Title: Score. Holmetrics
 * Metric ID: ab4msouA7C9Y
 * Metric Type: MAQL Metric
 */
export const ScoreHolmetrics: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ab4msouA7C9Y', 'measure')
);
/**
 * Metric Title: Score. Horizontal Trust
 * Metric ID: ac6mvzvAsraL
 * Metric Type: MAQL Metric
 */
export const ScoreHorizontalTrust: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ac6mvzvAsraL', 'measure')
);
/**
 * Metric Title: Score. Job Satisfaction
 * Metric ID: aa5mvcP5i4Ko
 * Metric Type: MAQL Metric
 */
export const ScoreJobSatisfaction: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aa5mvcP5i4Ko', 'measure')
);
/**
 * Metric Title: Score. Leadership
 * Metric ID: aacmBy0eeij1
 * Metric Type: MAQL Metric
 */
export const ScoreLeadership: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aacmBy0eeij1', 'measure')
);
/**
 * Metric Title: Score. Quality of Leadership
 * Metric ID: ac5mt7kSr494
 * Metric Type: MAQL Metric
 */
export const ScoreQualityOfLeadership: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ac5mt7kSr494', 'measure')
);
/**
 * Metric Title: Score. Role Clarity
 * Metric ID: acLmuue3r5oI
 * Metric Type: MAQL Metric
 */
export const ScoreRoleClarity: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('acLmuue3r5oI', 'measure')
);
/**
 * Metric Title: Score. Sense of Community
 * Metric ID: abvmwovJs28R
 * Metric Type: MAQL Metric
 */
export const ScoreSenseOfCommunity: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abvmwovJs28R', 'measure')
);
/**
 * Metric Title: Score. Social Capital
 * Metric ID: acrmpLkN5M2m
 * Metric Type: MAQL Metric
 */
export const ScoreSocialCapital: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('acrmpLkN5M2m', 'measure')
);
/**
 * Metric Title: Score. Vertical Trust
 * Metric ID: ac0mukldr4hk
 * Metric Type: MAQL Metric
 */
export const ScoreVerticalTrust: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ac0mukldr4hk', 'measure')
);
/**
 * Metric Title: Score. Work Engagement
 * Metric ID: aaZmxiOHs096
 * Metric Type: MAQL Metric
 */
export const ScoreWorkEngagement: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaZmxiOHs096', 'measure')
);
/**
 * Metric Title: Semantic. % by category
 * Metric ID: abhXrizjZfGu
 * Metric Type: MAQL Metric
 */
export const SemanticPercentByCategory: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abhXrizjZfGu', 'measure')
);
/**
 * Metric Title: Semantic. Negative %
 * Metric ID: aca9peolXWhA
 * Metric Type: MAQL Metric
 */
export const SemanticNegativePercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aca9peolXWhA', 'measure')
);
/**
 * Metric Title: Semantic. Negative %. Last 7D
 * Metric ID: aaokeB34y6T8
 * Metric Type: MAQL Metric
 */
export const SemanticNegativePercentLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaokeB34y6T8', 'measure')
);
/**
 * Metric Title: Semantic. Neutral %
 * Metric ID: ac09n0ezQZzw
 * Metric Type: MAQL Metric
 */
export const SemanticNeutralPercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ac09n0ezQZzw', 'measure')
);
/**
 * Metric Title: Semantic. Neutral %. Last 7D
 * Metric ID: aaHj77ROstW0
 * Metric Type: MAQL Metric
 */
export const SemanticNeutralPercentLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaHj77ROstW0', 'measure')
);
/**
 * Metric Title: Semantic. Positive %
 * Metric ID: ac29n0ezQZzw
 * Metric Type: MAQL Metric
 */
export const SemanticPositivePercent: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ac29n0ezQZzw', 'measure')
);
/**
 * Metric Title: Semantic. Positive %. Last 7D
 * Metric ID: aajkh1UE0wso
 * Metric Type: MAQL Metric
 */
export const SemanticPositivePercentLast7D: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aajkh1UE0wso', 'measure')
);
/**
 * Metric Title: Sensor. Air CO2
 * Metric ID: abUpXTtTpjzU
 * Metric Type: MAQL Metric
 */
export const SensorAirCO2: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abUpXTtTpjzU', 'measure')
);
/**
 * Metric Title: Sensor. Air CO2. Low
 * Metric ID: aaBVHgOq9w8p
 * Metric Type: MAQL Metric
 */
export const SensorAirCO2Low: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaBVHgOq9w8p', 'measure')
);
/**
 * Metric Title: Sensor. Air CO2. Peak
 * Metric ID: aaJVBTgC1MJ5
 * Metric Type: MAQL Metric
 */
export const SensorAirCO2Peak: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaJVBTgC1MJ5', 'measure')
);
/**
 * Metric Title: Sensor. Air VOC
 * Metric ID: ab1pTNoc6SSw
 * Metric Type: MAQL Metric
 */
export const SensorAirVOC: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ab1pTNoc6SSw', 'measure')
);
/**
 * Metric Title: Sensor. Air VOC. Low
 * Metric ID: aabVM0ZZp4ht
 * Metric Type: MAQL Metric
 */
export const SensorAirVOCLow: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aabVM0ZZp4ht', 'measure')
);
/**
 * Metric Title: Sensor. Air VOC. Peak
 * Metric ID: aa5VmJYeTyhW
 * Metric Type: MAQL Metric
 */
export const SensorAirVOCPeak: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aa5VmJYeTyhW', 'measure')
);
/**
 * Metric Title: Sensor. Light
 * Metric ID: abhpXMw8QU2G
 * Metric Type: MAQL Metric
 */
export const SensorLight: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abhpXMw8QU2G', 'measure')
);
/**
 * Metric Title: Sensor. Noise
 * Metric ID: aahp9VijkMn1
 * Metric Type: MAQL Metric
 */
export const SensorNoise: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aahp9VijkMn1', 'measure')
);
/**
 * Metric Title: Sensor. Noise AVG
 * Metric ID: aaQp6hAld6bu
 * Metric Type: MAQL Metric
 */
export const SensorNoiseAVG: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaQp6hAld6bu', 'measure')
);
/**
 * Metric Title: Sensor. Noise Peak
 * Metric ID: aapqasrYkOPb
 * Metric Type: MAQL Metric
 */
export const SensorNoisePeak: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aapqasrYkOPb', 'measure')
);
/**
 * Metric Title: Sensor. Temperature
 * Metric ID: abJpT8eg71VO
 * Metric Type: MAQL Metric
 */
export const SensorTemperature: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abJpT8eg71VO', 'measure')
);
/**
 * Metric Title: Sensor. Temperature. Variance
 * Metric ID: aabUnUB4TBZL
 * Metric Type: MAQL Metric
 */
export const SensorTemperatureVariance: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aabUnUB4TBZL', 'measure')
);
/**
 * Metric Title: Survey. Response Count
 * Metric ID: aaLoUkwiagFH
 * Metric Type: MAQL Metric
 */
export const SurveyResponseCount: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaLoUkwiagFH', 'measure')
);
/**
 * Metric Title: Survey. Response Count. Last Month
 * Metric ID: abYPZCw3GwSm
 * Metric Type: MAQL Metric
 */
export const SurveyResponseCountLastMonth: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('abYPZCw3GwSm', 'measure')
);
/**
 * Metric Title: Survey. Response Count. This Month
 * Metric ID: absRSrDYgdNC
 * Metric Type: MAQL Metric
 */
export const SurveyResponseCountThisMonth: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('absRSrDYgdNC', 'measure')
);
/**
 * Metric Title: Survey. Response Percent. BW
 * Metric ID: aaho1yG4tIJi
 * Metric Type: MAQL Metric
 */
export const SurveyResponsePercentBW: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaho1yG4tIJi', 'measure')
);
/**
 * Metric Title: Survey. Response Percent. Historical
 * Metric ID: ab53RRL8WDDF
 * Metric Type: MAQL Metric
 */
export const SurveyResponsePercentHistorical: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('ab53RRL8WDDF', 'measure')
);
/**
 * Metric Title: Survey. Response Percent. Historical BW
 * Metric ID: aaynSctnbKt6
 * Metric Type: MAQL Metric
 */
export const SurveyResponsePercentHistoricalBW: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaynSctnbKt6', 'measure')
);
/**
 * Metric Title: Survey. Response Percent. Last Month
 * Metric ID: aaf5l6IffGRj
 * Metric Type: MAQL Metric
 */
export const SurveyResponsePercentLastMonth: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aaf5l6IffGRj', 'measure')
);
/**
 * Metric Title: Survey. Response Percent. This Month
 * Metric ID: aayXdF6yEiQB
 * Metric Type: MAQL Metric
 */
export const SurveyResponsePercentThisMonth: IMeasure<IMeasureDefinition> = newMeasure(
	idRef('aayXdF6yEiQB', 'measure')
);
/**
 * Fact Title: Accuracy
 * Fact ID: fact.score_factor_month.accuracy
 */
export const Accuracy = {
	/**
	 * Fact Title: Accuracy
	 * Fact ID: fact.score_factor_month.accuracy
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.score_factor_month.accuracy', 'fact'), m => m.aggregation('sum')),
	/**
	 * Fact Title: Accuracy
	 * Fact ID: fact.score_factor_month.accuracy
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.score_factor_month.accuracy', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Accuracy
	 * Fact ID: fact.score_factor_month.accuracy
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.score_factor_month.accuracy', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Accuracy
	 * Fact ID: fact.score_factor_month.accuracy
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.score_factor_month.accuracy', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Accuracy
	 * Fact ID: fact.score_factor_month.accuracy
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.score_factor_month.accuracy', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Accuracy
	 * Fact ID: fact.score_factor_month.accuracy
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.score_factor_month.accuracy', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Average Employment Length
 * Fact ID: fact.employment_length.average_employment_length
 */
export const AverageEmploymentLength = {
	/**
	 * Fact Title: Average Employment Length
	 * Fact ID: fact.employment_length.average_employment_length
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.employment_length.average_employment_length', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Average Employment Length
	 * Fact ID: fact.employment_length.average_employment_length
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.employment_length.average_employment_length', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Average Employment Length
	 * Fact ID: fact.employment_length.average_employment_length
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.employment_length.average_employment_length', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Average Employment Length
	 * Fact ID: fact.employment_length.average_employment_length
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.employment_length.average_employment_length', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Average Employment Length
	 * Fact ID: fact.employment_length.average_employment_length
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.employment_length.average_employment_length', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Average Employment Length
	 * Fact ID: fact.employment_length.average_employment_length
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.employment_length.average_employment_length', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Chats Day Received
 * Fact ID: fact.diagnostics_days.chats_day_received
 */
export const ChatsDayReceived = {
	/**
	 * Fact Title: Chats Day Received
	 * Fact ID: fact.diagnostics_days.chats_day_received
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.chats_day_received', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Chats Day Received
	 * Fact ID: fact.diagnostics_days.chats_day_received
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.chats_day_received', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Chats Day Received
	 * Fact ID: fact.diagnostics_days.chats_day_received
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.chats_day_received', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Chats Day Received
	 * Fact ID: fact.diagnostics_days.chats_day_received
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.chats_day_received', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Chats Day Received
	 * Fact ID: fact.diagnostics_days.chats_day_received
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.chats_day_received', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Chats Day Received
	 * Fact ID: fact.diagnostics_days.chats_day_received
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.chats_day_received', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Chats Day Received Owh
 * Fact ID: fact.diagnostics_days.chats_day_received_owh
 */
export const ChatsDayReceivedOwh = {
	/**
	 * Fact Title: Chats Day Received Owh
	 * Fact ID: fact.diagnostics_days.chats_day_received_owh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.chats_day_received_owh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Chats Day Received Owh
	 * Fact ID: fact.diagnostics_days.chats_day_received_owh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.chats_day_received_owh', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Chats Day Received Owh
	 * Fact ID: fact.diagnostics_days.chats_day_received_owh
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.chats_day_received_owh', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Chats Day Received Owh
	 * Fact ID: fact.diagnostics_days.chats_day_received_owh
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.chats_day_received_owh', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Chats Day Received Owh
	 * Fact ID: fact.diagnostics_days.chats_day_received_owh
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.chats_day_received_owh', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Chats Day Received Owh
	 * Fact ID: fact.diagnostics_days.chats_day_received_owh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.chats_day_received_owh', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Chats Day Sent
 * Fact ID: fact.diagnostics_days.chats_day_sent
 */
export const ChatsDaySent = {
	/**
	 * Fact Title: Chats Day Sent
	 * Fact ID: fact.diagnostics_days.chats_day_sent
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.chats_day_sent', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Chats Day Sent
	 * Fact ID: fact.diagnostics_days.chats_day_sent
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.chats_day_sent', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Chats Day Sent
	 * Fact ID: fact.diagnostics_days.chats_day_sent
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.chats_day_sent', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Chats Day Sent
	 * Fact ID: fact.diagnostics_days.chats_day_sent
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.chats_day_sent', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Chats Day Sent
	 * Fact ID: fact.diagnostics_days.chats_day_sent
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.chats_day_sent', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Chats Day Sent
	 * Fact ID: fact.diagnostics_days.chats_day_sent
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.chats_day_sent', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Chats Day Sent Owh
 * Fact ID: fact.diagnostics_days.chats_day_sent_owh
 */
export const ChatsDaySentOwh = {
	/**
	 * Fact Title: Chats Day Sent Owh
	 * Fact ID: fact.diagnostics_days.chats_day_sent_owh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.chats_day_sent_owh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Chats Day Sent Owh
	 * Fact ID: fact.diagnostics_days.chats_day_sent_owh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.chats_day_sent_owh', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Chats Day Sent Owh
	 * Fact ID: fact.diagnostics_days.chats_day_sent_owh
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.chats_day_sent_owh', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Chats Day Sent Owh
	 * Fact ID: fact.diagnostics_days.chats_day_sent_owh
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.chats_day_sent_owh', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Chats Day Sent Owh
	 * Fact ID: fact.diagnostics_days.chats_day_sent_owh
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.chats_day_sent_owh', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Chats Day Sent Owh
	 * Fact ID: fact.diagnostics_days.chats_day_sent_owh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.chats_day_sent_owh', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Chats Day Total
 * Fact ID: fact.diagnostics_days.chats_day_total
 */
export const ChatsDayTotal = {
	/**
	 * Fact Title: Chats Day Total
	 * Fact ID: fact.diagnostics_days.chats_day_total
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.chats_day_total', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Chats Day Total
	 * Fact ID: fact.diagnostics_days.chats_day_total
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.chats_day_total', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Chats Day Total
	 * Fact ID: fact.diagnostics_days.chats_day_total
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.chats_day_total', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Chats Day Total
	 * Fact ID: fact.diagnostics_days.chats_day_total
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.chats_day_total', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Chats Day Total
	 * Fact ID: fact.diagnostics_days.chats_day_total
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.chats_day_total', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Chats Day Total
	 * Fact ID: fact.diagnostics_days.chats_day_total
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.chats_day_total', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Chats Hour Sum
 * Fact ID: fact.diagnostics_hours.chats_hour_sum
 */
export const ChatsHourSum = {
	/**
	 * Fact Title: Chats Hour Sum
	 * Fact ID: fact.diagnostics_hours.chats_hour_sum
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_hours.chats_hour_sum', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Chats Hour Sum
	 * Fact ID: fact.diagnostics_hours.chats_hour_sum
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_hours.chats_hour_sum', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Chats Hour Sum
	 * Fact ID: fact.diagnostics_hours.chats_hour_sum
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_hours.chats_hour_sum', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Chats Hour Sum
	 * Fact ID: fact.diagnostics_hours.chats_hour_sum
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_hours.chats_hour_sum', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Chats Hour Sum
	 * Fact ID: fact.diagnostics_hours.chats_hour_sum
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_hours.chats_hour_sum', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Chats Hour Sum
	 * Fact ID: fact.diagnostics_hours.chats_hour_sum
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_hours.chats_hour_sum', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Communication Ext Iwh
 * Fact ID: fact.diagnostics_communication_owh.communicationextiwh
 */
export const CommunicationExtIwh = {
	/**
	 * Fact Title: Communication Ext Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextiwh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_communication_owh.communicationextiwh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Communication Ext Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextiwh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextiwh', 'fact'),
		m => m.aggregation('avg')
	),
	/**
	 * Fact Title: Communication Ext Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextiwh
	 * Fact Aggregation: min
	 */ Min: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextiwh', 'fact'),
		m => m.aggregation('min')
	),
	/**
	 * Fact Title: Communication Ext Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextiwh
	 * Fact Aggregation: max
	 */ Max: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextiwh', 'fact'),
		m => m.aggregation('max')
	),
	/**
	 * Fact Title: Communication Ext Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextiwh
	 * Fact Aggregation: median
	 */ Median: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextiwh', 'fact'),
		m => m.aggregation('median')
	),
	/**
	 * Fact Title: Communication Ext Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextiwh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextiwh', 'fact'),
		m => m.aggregation('runsum')
	)
};
/**
 * Fact Title: Communication Ext Owh
 * Fact ID: fact.diagnostics_communication_owh.communicationextowh
 */
export const CommunicationExtOwh = {
	/**
	 * Fact Title: Communication Ext Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextowh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_communication_owh.communicationextowh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Communication Ext Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextowh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextowh', 'fact'),
		m => m.aggregation('avg')
	),
	/**
	 * Fact Title: Communication Ext Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextowh
	 * Fact Aggregation: min
	 */ Min: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextowh', 'fact'),
		m => m.aggregation('min')
	),
	/**
	 * Fact Title: Communication Ext Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextowh
	 * Fact Aggregation: max
	 */ Max: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextowh', 'fact'),
		m => m.aggregation('max')
	),
	/**
	 * Fact Title: Communication Ext Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextowh
	 * Fact Aggregation: median
	 */ Median: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextowh', 'fact'),
		m => m.aggregation('median')
	),
	/**
	 * Fact Title: Communication Ext Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationextowh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationextowh', 'fact'),
		m => m.aggregation('runsum')
	)
};
/**
 * Fact Title: Communication Int Iwh
 * Fact ID: fact.diagnostics_communication_owh.communicationintiwh
 */
export const CommunicationIntIwh = {
	/**
	 * Fact Title: Communication Int Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintiwh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_communication_owh.communicationintiwh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Communication Int Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintiwh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintiwh', 'fact'),
		m => m.aggregation('avg')
	),
	/**
	 * Fact Title: Communication Int Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintiwh
	 * Fact Aggregation: min
	 */ Min: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintiwh', 'fact'),
		m => m.aggregation('min')
	),
	/**
	 * Fact Title: Communication Int Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintiwh
	 * Fact Aggregation: max
	 */ Max: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintiwh', 'fact'),
		m => m.aggregation('max')
	),
	/**
	 * Fact Title: Communication Int Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintiwh
	 * Fact Aggregation: median
	 */ Median: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintiwh', 'fact'),
		m => m.aggregation('median')
	),
	/**
	 * Fact Title: Communication Int Iwh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintiwh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintiwh', 'fact'),
		m => m.aggregation('runsum')
	)
};
/**
 * Fact Title: Communication Int Owh
 * Fact ID: fact.diagnostics_communication_owh.communicationintowh
 */
export const CommunicationIntOwh = {
	/**
	 * Fact Title: Communication Int Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintowh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_communication_owh.communicationintowh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Communication Int Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintowh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintowh', 'fact'),
		m => m.aggregation('avg')
	),
	/**
	 * Fact Title: Communication Int Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintowh
	 * Fact Aggregation: min
	 */ Min: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintowh', 'fact'),
		m => m.aggregation('min')
	),
	/**
	 * Fact Title: Communication Int Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintowh
	 * Fact Aggregation: max
	 */ Max: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintowh', 'fact'),
		m => m.aggregation('max')
	),
	/**
	 * Fact Title: Communication Int Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintowh
	 * Fact Aggregation: median
	 */ Median: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintowh', 'fact'),
		m => m.aggregation('median')
	),
	/**
	 * Fact Title: Communication Int Owh
	 * Fact ID: fact.diagnostics_communication_owh.communicationintowh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(
		idRef('fact.diagnostics_communication_owh.communicationintowh', 'fact'),
		m => m.aggregation('runsum')
	)
};
/**
 * Fact Title: Connectivity Count
 * Fact ID: fact.ona_connectivity.connectivitycount
 */
export const ConnectivityCount = {
	/**
	 * Fact Title: Connectivity Count
	 * Fact ID: fact.ona_connectivity.connectivitycount
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.ona_connectivity.connectivitycount', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Connectivity Count
	 * Fact ID: fact.ona_connectivity.connectivitycount
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.ona_connectivity.connectivitycount', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Connectivity Count
	 * Fact ID: fact.ona_connectivity.connectivitycount
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.ona_connectivity.connectivitycount', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Connectivity Count
	 * Fact ID: fact.ona_connectivity.connectivitycount
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.ona_connectivity.connectivitycount', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Connectivity Count
	 * Fact ID: fact.ona_connectivity.connectivitycount
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.ona_connectivity.connectivitycount', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Connectivity Count
	 * Fact ID: fact.ona_connectivity.connectivitycount
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.ona_connectivity.connectivitycount', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Degree
 * Fact ID: fact.ona_connectivity.degree
 */
export const Degree = {
	/**
	 * Fact Title: Degree
	 * Fact ID: fact.ona_connectivity.degree
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.ona_connectivity.degree', 'fact'), m => m.aggregation('sum')),
	/**
	 * Fact Title: Degree
	 * Fact ID: fact.ona_connectivity.degree
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.ona_connectivity.degree', 'fact'), m => m.aggregation('avg')),
	/**
	 * Fact Title: Degree
	 * Fact ID: fact.ona_connectivity.degree
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.ona_connectivity.degree', 'fact'), m => m.aggregation('min')),
	/**
	 * Fact Title: Degree
	 * Fact ID: fact.ona_connectivity.degree
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.ona_connectivity.degree', 'fact'), m => m.aggregation('max')),
	/**
	 * Fact Title: Degree
	 * Fact ID: fact.ona_connectivity.degree
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.ona_connectivity.degree', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Degree
	 * Fact ID: fact.ona_connectivity.degree
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.ona_connectivity.degree', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Emails Day Received
 * Fact ID: fact.diagnostics_days.emails_day_received
 */
export const EmailsDayReceived = {
	/**
	 * Fact Title: Emails Day Received
	 * Fact ID: fact.diagnostics_days.emails_day_received
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.emails_day_received', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Emails Day Received
	 * Fact ID: fact.diagnostics_days.emails_day_received
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.emails_day_received', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Emails Day Received
	 * Fact ID: fact.diagnostics_days.emails_day_received
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.emails_day_received', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Emails Day Received
	 * Fact ID: fact.diagnostics_days.emails_day_received
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.emails_day_received', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Emails Day Received
	 * Fact ID: fact.diagnostics_days.emails_day_received
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.emails_day_received', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Emails Day Received
	 * Fact ID: fact.diagnostics_days.emails_day_received
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.emails_day_received', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Emails Day Received Owh
 * Fact ID: fact.diagnostics_days.emails_day_received_owh
 */
export const EmailsDayReceivedOwh = {
	/**
	 * Fact Title: Emails Day Received Owh
	 * Fact ID: fact.diagnostics_days.emails_day_received_owh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.emails_day_received_owh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Emails Day Received Owh
	 * Fact ID: fact.diagnostics_days.emails_day_received_owh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.emails_day_received_owh', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Emails Day Received Owh
	 * Fact ID: fact.diagnostics_days.emails_day_received_owh
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.emails_day_received_owh', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Emails Day Received Owh
	 * Fact ID: fact.diagnostics_days.emails_day_received_owh
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.emails_day_received_owh', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Emails Day Received Owh
	 * Fact ID: fact.diagnostics_days.emails_day_received_owh
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.emails_day_received_owh', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Emails Day Received Owh
	 * Fact ID: fact.diagnostics_days.emails_day_received_owh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.emails_day_received_owh', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Emails Day Sent
 * Fact ID: fact.diagnostics_days.emails_day_sent
 */
export const EmailsDaySent = {
	/**
	 * Fact Title: Emails Day Sent
	 * Fact ID: fact.diagnostics_days.emails_day_sent
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.emails_day_sent', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Emails Day Sent
	 * Fact ID: fact.diagnostics_days.emails_day_sent
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.emails_day_sent', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Emails Day Sent
	 * Fact ID: fact.diagnostics_days.emails_day_sent
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.emails_day_sent', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Emails Day Sent
	 * Fact ID: fact.diagnostics_days.emails_day_sent
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.emails_day_sent', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Emails Day Sent
	 * Fact ID: fact.diagnostics_days.emails_day_sent
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.emails_day_sent', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Emails Day Sent
	 * Fact ID: fact.diagnostics_days.emails_day_sent
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.emails_day_sent', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Emails Day Sent Owh
 * Fact ID: fact.diagnostics_days.emails_day_sent_owh
 */
export const EmailsDaySentOwh = {
	/**
	 * Fact Title: Emails Day Sent Owh
	 * Fact ID: fact.diagnostics_days.emails_day_sent_owh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.emails_day_sent_owh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Emails Day Sent Owh
	 * Fact ID: fact.diagnostics_days.emails_day_sent_owh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.emails_day_sent_owh', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Emails Day Sent Owh
	 * Fact ID: fact.diagnostics_days.emails_day_sent_owh
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.emails_day_sent_owh', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Emails Day Sent Owh
	 * Fact ID: fact.diagnostics_days.emails_day_sent_owh
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.emails_day_sent_owh', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Emails Day Sent Owh
	 * Fact ID: fact.diagnostics_days.emails_day_sent_owh
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.emails_day_sent_owh', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Emails Day Sent Owh
	 * Fact ID: fact.diagnostics_days.emails_day_sent_owh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.emails_day_sent_owh', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Emails Day Total
 * Fact ID: fact.diagnostics_days.emails_day_total
 */
export const EmailsDayTotal = {
	/**
	 * Fact Title: Emails Day Total
	 * Fact ID: fact.diagnostics_days.emails_day_total
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.emails_day_total', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Emails Day Total
	 * Fact ID: fact.diagnostics_days.emails_day_total
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.emails_day_total', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Emails Day Total
	 * Fact ID: fact.diagnostics_days.emails_day_total
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.emails_day_total', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Emails Day Total
	 * Fact ID: fact.diagnostics_days.emails_day_total
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.emails_day_total', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Emails Day Total
	 * Fact ID: fact.diagnostics_days.emails_day_total
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.emails_day_total', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Emails Day Total
	 * Fact ID: fact.diagnostics_days.emails_day_total
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.emails_day_total', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Emails Hour Received Sum
 * Fact ID: fact.diagnostics_hours.emails_hour_received_sum
 */
export const EmailsHourReceivedSum = {
	/**
	 * Fact Title: Emails Hour Received Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_received_sum
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_hours.emails_hour_received_sum', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Emails Hour Received Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_received_sum
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_hours.emails_hour_received_sum', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Emails Hour Received Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_received_sum
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_hours.emails_hour_received_sum', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Emails Hour Received Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_received_sum
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_hours.emails_hour_received_sum', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Emails Hour Received Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_received_sum
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_hours.emails_hour_received_sum', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Emails Hour Received Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_received_sum
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_hours.emails_hour_received_sum', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Emails Hour Sent Sum
 * Fact ID: fact.diagnostics_hours.emails_hour_sent_sum
 */
export const EmailsHourSentSum = {
	/**
	 * Fact Title: Emails Hour Sent Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_sent_sum
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_hours.emails_hour_sent_sum', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Emails Hour Sent Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_sent_sum
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_hours.emails_hour_sent_sum', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Emails Hour Sent Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_sent_sum
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_hours.emails_hour_sent_sum', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Emails Hour Sent Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_sent_sum
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_hours.emails_hour_sent_sum', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Emails Hour Sent Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_sent_sum
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_hours.emails_hour_sent_sum', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Emails Hour Sent Sum
	 * Fact ID: fact.diagnostics_hours.emails_hour_sent_sum
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_hours.emails_hour_sent_sum', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Employed Users Count
 * Fact ID: fact.employees_inactivity.employed_users_count
 */
export const EmployedUsersCount = {
	/**
	 * Fact Title: Employed Users Count
	 * Fact ID: fact.employees_inactivity.employed_users_count
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.employees_inactivity.employed_users_count', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Employed Users Count
	 * Fact ID: fact.employees_inactivity.employed_users_count
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.employees_inactivity.employed_users_count', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Employed Users Count
	 * Fact ID: fact.employees_inactivity.employed_users_count
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.employees_inactivity.employed_users_count', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Employed Users Count
	 * Fact ID: fact.employees_inactivity.employed_users_count
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.employees_inactivity.employed_users_count', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Employed Users Count
	 * Fact ID: fact.employees_inactivity.employed_users_count
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.employees_inactivity.employed_users_count', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Employed Users Count
	 * Fact ID: fact.employees_inactivity.employed_users_count
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.employees_inactivity.employed_users_count', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Event Length Day Avg
 * Fact ID: fact.diagnostics_days.event_length_day_avg
 */
export const EventLengthDayAvg = {
	/**
	 * Fact Title: Event Length Day Avg
	 * Fact ID: fact.diagnostics_days.event_length_day_avg
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.event_length_day_avg', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Event Length Day Avg
	 * Fact ID: fact.diagnostics_days.event_length_day_avg
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.event_length_day_avg', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Event Length Day Avg
	 * Fact ID: fact.diagnostics_days.event_length_day_avg
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.event_length_day_avg', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Event Length Day Avg
	 * Fact ID: fact.diagnostics_days.event_length_day_avg
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.event_length_day_avg', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Event Length Day Avg
	 * Fact ID: fact.diagnostics_days.event_length_day_avg
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.event_length_day_avg', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Event Length Day Avg
	 * Fact ID: fact.diagnostics_days.event_length_day_avg
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.event_length_day_avg', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Event Length Day Sum
 * Fact ID: fact.diagnostics_days.event_length_day_sum
 */
export const EventLengthDaySum = {
	/**
	 * Fact Title: Event Length Day Sum
	 * Fact ID: fact.diagnostics_days.event_length_day_sum
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.event_length_day_sum', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Event Length Day Sum
	 * Fact ID: fact.diagnostics_days.event_length_day_sum
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.event_length_day_sum', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Event Length Day Sum
	 * Fact ID: fact.diagnostics_days.event_length_day_sum
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.event_length_day_sum', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Event Length Day Sum
	 * Fact ID: fact.diagnostics_days.event_length_day_sum
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.event_length_day_sum', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Event Length Day Sum
	 * Fact ID: fact.diagnostics_days.event_length_day_sum
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.event_length_day_sum', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Event Length Day Sum
	 * Fact ID: fact.diagnostics_days.event_length_day_sum
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.event_length_day_sum', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Events Day Count
 * Fact ID: fact.diagnostics_days.events_day_count
 */
export const EventsDayCount = {
	/**
	 * Fact Title: Events Day Count
	 * Fact ID: fact.diagnostics_days.events_day_count
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.events_day_count', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Events Day Count
	 * Fact ID: fact.diagnostics_days.events_day_count
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.events_day_count', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Events Day Count
	 * Fact ID: fact.diagnostics_days.events_day_count
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.events_day_count', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Events Day Count
	 * Fact ID: fact.diagnostics_days.events_day_count
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.events_day_count', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Events Day Count
	 * Fact ID: fact.diagnostics_days.events_day_count
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.events_day_count', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Events Day Count
	 * Fact ID: fact.diagnostics_days.events_day_count
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.events_day_count', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Events Day Count Owh
 * Fact ID: fact.diagnostics_days.events_day_count_owh
 */
export const EventsDayCountOwh = {
	/**
	 * Fact Title: Events Day Count Owh
	 * Fact ID: fact.diagnostics_days.events_day_count_owh
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_days.events_day_count_owh', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Events Day Count Owh
	 * Fact ID: fact.diagnostics_days.events_day_count_owh
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_days.events_day_count_owh', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Events Day Count Owh
	 * Fact ID: fact.diagnostics_days.events_day_count_owh
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_days.events_day_count_owh', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Events Day Count Owh
	 * Fact ID: fact.diagnostics_days.events_day_count_owh
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_days.events_day_count_owh', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Events Day Count Owh
	 * Fact ID: fact.diagnostics_days.events_day_count_owh
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_days.events_day_count_owh', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Events Day Count Owh
	 * Fact ID: fact.diagnostics_days.events_day_count_owh
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_days.events_day_count_owh', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Events Hour Count Sum
 * Fact ID: fact.diagnostics_hours.events_hour_count_sum
 */
export const EventsHourCountSum = {
	/**
	 * Fact Title: Events Hour Count Sum
	 * Fact ID: fact.diagnostics_hours.events_hour_count_sum
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_hours.events_hour_count_sum', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Events Hour Count Sum
	 * Fact ID: fact.diagnostics_hours.events_hour_count_sum
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_hours.events_hour_count_sum', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Events Hour Count Sum
	 * Fact ID: fact.diagnostics_hours.events_hour_count_sum
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_hours.events_hour_count_sum', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Events Hour Count Sum
	 * Fact ID: fact.diagnostics_hours.events_hour_count_sum
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_hours.events_hour_count_sum', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Events Hour Count Sum
	 * Fact ID: fact.diagnostics_hours.events_hour_count_sum
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_hours.events_hour_count_sum', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Events Hour Count Sum
	 * Fact ID: fact.diagnostics_hours.events_hour_count_sum
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_hours.events_hour_count_sum', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Hires Count
 * Fact ID: fact.employees_hires.hires_count
 */
export const HiresCount = {
	/**
	 * Fact Title: Hires Count
	 * Fact ID: fact.employees_hires.hires_count
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.employees_hires.hires_count', 'fact'), m => m.aggregation('sum')),
	/**
	 * Fact Title: Hires Count
	 * Fact ID: fact.employees_hires.hires_count
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.employees_hires.hires_count', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Hires Count
	 * Fact ID: fact.employees_hires.hires_count
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.employees_hires.hires_count', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Hires Count
	 * Fact ID: fact.employees_hires.hires_count
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.employees_hires.hires_count', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Hires Count
	 * Fact ID: fact.employees_hires.hires_count
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.employees_hires.hires_count', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Hires Count
	 * Fact ID: fact.employees_hires.hires_count
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.employees_hires.hires_count', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Inactive Users Count
 * Fact ID: fact.employees_inactivity.inactive_users_count
 */
export const InactiveUsersCount = {
	/**
	 * Fact Title: Inactive Users Count
	 * Fact ID: fact.employees_inactivity.inactive_users_count
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.employees_inactivity.inactive_users_count', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Inactive Users Count
	 * Fact ID: fact.employees_inactivity.inactive_users_count
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.employees_inactivity.inactive_users_count', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Inactive Users Count
	 * Fact ID: fact.employees_inactivity.inactive_users_count
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.employees_inactivity.inactive_users_count', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Inactive Users Count
	 * Fact ID: fact.employees_inactivity.inactive_users_count
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.employees_inactivity.inactive_users_count', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Inactive Users Count
	 * Fact ID: fact.employees_inactivity.inactive_users_count
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.employees_inactivity.inactive_users_count', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Inactive Users Count
	 * Fact ID: fact.employees_inactivity.inactive_users_count
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.employees_inactivity.inactive_users_count', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Insight Response Count
 * Fact ID: fact.insight_week.insight_response_count
 */
export const InsightResponseCount = {
	/**
	 * Fact Title: Insight Response Count
	 * Fact ID: fact.insight_week.insight_response_count
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.insight_week.insight_response_count', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Insight Response Count
	 * Fact ID: fact.insight_week.insight_response_count
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.insight_week.insight_response_count', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Insight Response Count
	 * Fact ID: fact.insight_week.insight_response_count
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.insight_week.insight_response_count', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Insight Response Count
	 * Fact ID: fact.insight_week.insight_response_count
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.insight_week.insight_response_count', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Insight Response Count
	 * Fact ID: fact.insight_week.insight_response_count
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.insight_week.insight_response_count', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Insight Response Count
	 * Fact ID: fact.insight_week.insight_response_count
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.insight_week.insight_response_count', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Number Of Employees
 * Fact ID: fact.employment_length.number_of_employees
 */
export const NumberOfEmployees = {
	/**
	 * Fact Title: Number Of Employees
	 * Fact ID: fact.employment_length.number_of_employees
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.employment_length.number_of_employees', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Number Of Employees
	 * Fact ID: fact.employment_length.number_of_employees
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.employment_length.number_of_employees', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Number Of Employees
	 * Fact ID: fact.employment_length.number_of_employees
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.employment_length.number_of_employees', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Number Of Employees
	 * Fact ID: fact.employment_length.number_of_employees
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.employment_length.number_of_employees', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Number Of Employees
	 * Fact ID: fact.employment_length.number_of_employees
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.employment_length.number_of_employees', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Number Of Employees
	 * Fact ID: fact.employment_length.number_of_employees
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.employment_length.number_of_employees', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: OM Value
 * Fact ID: fact.operational_metrics_values.om_value
 */
export const OMValue = {
	/**
	 * Fact Title: OM Value
	 * Fact ID: fact.operational_metrics_values.om_value
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.operational_metrics_values.om_value', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: OM Value
	 * Fact ID: fact.operational_metrics_values.om_value
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.operational_metrics_values.om_value', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: OM Value
	 * Fact ID: fact.operational_metrics_values.om_value
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.operational_metrics_values.om_value', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: OM Value
	 * Fact ID: fact.operational_metrics_values.om_value
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.operational_metrics_values.om_value', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: OM Value
	 * Fact ID: fact.operational_metrics_values.om_value
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.operational_metrics_values.om_value', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: OM Value
	 * Fact ID: fact.operational_metrics_values.om_value
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.operational_metrics_values.om_value', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Pulse Answered
 * Fact ID: fact.pulse_participation_rate.pulse_answered
 */
export const PulseAnswered = {
	/**
	 * Fact Title: Pulse Answered
	 * Fact ID: fact.pulse_participation_rate.pulse_answered
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.pulse_participation_rate.pulse_answered', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Pulse Answered
	 * Fact ID: fact.pulse_participation_rate.pulse_answered
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.pulse_participation_rate.pulse_answered', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Pulse Answered
	 * Fact ID: fact.pulse_participation_rate.pulse_answered
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.pulse_participation_rate.pulse_answered', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Pulse Answered
	 * Fact ID: fact.pulse_participation_rate.pulse_answered
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.pulse_participation_rate.pulse_answered', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Pulse Answered
	 * Fact ID: fact.pulse_participation_rate.pulse_answered
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.pulse_participation_rate.pulse_answered', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Pulse Answered
	 * Fact ID: fact.pulse_participation_rate.pulse_answered
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.pulse_participation_rate.pulse_answered', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Pulse Participation Rate
 * Fact ID: fact.pulse_participation_rate.pulse_participation_rate
 */
export const PulseParticipationRate = {
	/**
	 * Fact Title: Pulse Participation Rate
	 * Fact ID: fact.pulse_participation_rate.pulse_participation_rate
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.pulse_participation_rate.pulse_participation_rate', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Pulse Participation Rate
	 * Fact ID: fact.pulse_participation_rate.pulse_participation_rate
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(
		idRef('fact.pulse_participation_rate.pulse_participation_rate', 'fact'),
		m => m.aggregation('avg')
	),
	/**
	 * Fact Title: Pulse Participation Rate
	 * Fact ID: fact.pulse_participation_rate.pulse_participation_rate
	 * Fact Aggregation: min
	 */ Min: newMeasure(
		idRef('fact.pulse_participation_rate.pulse_participation_rate', 'fact'),
		m => m.aggregation('min')
	),
	/**
	 * Fact Title: Pulse Participation Rate
	 * Fact ID: fact.pulse_participation_rate.pulse_participation_rate
	 * Fact Aggregation: max
	 */ Max: newMeasure(
		idRef('fact.pulse_participation_rate.pulse_participation_rate', 'fact'),
		m => m.aggregation('max')
	),
	/**
	 * Fact Title: Pulse Participation Rate
	 * Fact ID: fact.pulse_participation_rate.pulse_participation_rate
	 * Fact Aggregation: median
	 */ Median: newMeasure(
		idRef('fact.pulse_participation_rate.pulse_participation_rate', 'fact'),
		m => m.aggregation('median')
	),
	/**
	 * Fact Title: Pulse Participation Rate
	 * Fact ID: fact.pulse_participation_rate.pulse_participation_rate
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(
		idRef('fact.pulse_participation_rate.pulse_participation_rate', 'fact'),
		m => m.aggregation('runsum')
	)
};
/**
 * Fact Title: Pulse Sent
 * Fact ID: fact.pulse_participation_rate.pulse_sent
 */
export const PulseSent = {
	/**
	 * Fact Title: Pulse Sent
	 * Fact ID: fact.pulse_participation_rate.pulse_sent
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.pulse_participation_rate.pulse_sent', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Pulse Sent
	 * Fact ID: fact.pulse_participation_rate.pulse_sent
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.pulse_participation_rate.pulse_sent', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Pulse Sent
	 * Fact ID: fact.pulse_participation_rate.pulse_sent
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.pulse_participation_rate.pulse_sent', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Pulse Sent
	 * Fact ID: fact.pulse_participation_rate.pulse_sent
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.pulse_participation_rate.pulse_sent', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Pulse Sent
	 * Fact ID: fact.pulse_participation_rate.pulse_sent
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.pulse_participation_rate.pulse_sent', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Pulse Sent
	 * Fact ID: fact.pulse_participation_rate.pulse_sent
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.pulse_participation_rate.pulse_sent', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Response Count
 * Fact ID: fact.score_factor_month.response_count
 */
export const ResponseCount = {
	/**
	 * Fact Title: Response Count
	 * Fact ID: fact.score_factor_month.response_count
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.score_factor_month.response_count', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Response Count
	 * Fact ID: fact.score_factor_month.response_count
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.score_factor_month.response_count', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Response Count
	 * Fact ID: fact.score_factor_month.response_count
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.score_factor_month.response_count', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Response Count
	 * Fact ID: fact.score_factor_month.response_count
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.score_factor_month.response_count', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Response Count
	 * Fact ID: fact.score_factor_month.response_count
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.score_factor_month.response_count', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Response Count
	 * Fact ID: fact.score_factor_month.response_count
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.score_factor_month.response_count', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Score
 * Fact ID: fact.score_factor_month.score
 */
export const Score = {
	/**
	 * Fact Title: Score
	 * Fact ID: fact.score_factor_month.score
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.score_factor_month.score', 'fact'), m => m.aggregation('sum')),
	/**
	 * Fact Title: Score
	 * Fact ID: fact.score_factor_month.score
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.score_factor_month.score', 'fact'), m => m.aggregation('avg')),
	/**
	 * Fact Title: Score
	 * Fact ID: fact.score_factor_month.score
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.score_factor_month.score', 'fact'), m => m.aggregation('min')),
	/**
	 * Fact Title: Score
	 * Fact ID: fact.score_factor_month.score
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.score_factor_month.score', 'fact'), m => m.aggregation('max')),
	/**
	 * Fact Title: Score
	 * Fact ID: fact.score_factor_month.score
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.score_factor_month.score', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Score
	 * Fact ID: fact.score_factor_month.score
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.score_factor_month.score', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Score Baseline
 * Fact ID: fact.factor_baseline.score_baseline
 */
export const ScoreBaseline = {
	/**
	 * Fact Title: Score Baseline
	 * Fact ID: fact.factor_baseline.score_baseline
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.factor_baseline.score_baseline', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Score Baseline
	 * Fact ID: fact.factor_baseline.score_baseline
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.factor_baseline.score_baseline', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Score Baseline
	 * Fact ID: fact.factor_baseline.score_baseline
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.factor_baseline.score_baseline', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Score Baseline
	 * Fact ID: fact.factor_baseline.score_baseline
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.factor_baseline.score_baseline', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Score Baseline
	 * Fact ID: fact.factor_baseline.score_baseline
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.factor_baseline.score_baseline', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Score Baseline
	 * Fact ID: fact.factor_baseline.score_baseline
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.factor_baseline.score_baseline', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Semantic Sum
 * Fact ID: fact.testsemanticssent.testsemanticsum
 */
export const SemanticSum = {
	/**
	 * Fact Title: Semantic Sum
	 * Fact ID: fact.testsemanticssent.testsemanticsum
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.testsemanticssent.testsemanticsum', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Semantic Sum
	 * Fact ID: fact.testsemanticssent.testsemanticsum
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.testsemanticssent.testsemanticsum', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Semantic Sum
	 * Fact ID: fact.testsemanticssent.testsemanticsum
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.testsemanticssent.testsemanticsum', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Semantic Sum
	 * Fact ID: fact.testsemanticssent.testsemanticsum
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.testsemanticssent.testsemanticsum', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Semantic Sum
	 * Fact ID: fact.testsemanticssent.testsemanticsum
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.testsemanticssent.testsemanticsum', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Semantic Sum
	 * Fact ID: fact.testsemanticssent.testsemanticsum
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.testsemanticssent.testsemanticsum', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Sensor Value
 * Fact ID: fact.sensor_values.sensor_value
 */
export const SensorValue = {
	/**
	 * Fact Title: Sensor Value
	 * Fact ID: fact.sensor_values.sensor_value
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.sensor_values.sensor_value', 'fact'), m => m.aggregation('sum')),
	/**
	 * Fact Title: Sensor Value
	 * Fact ID: fact.sensor_values.sensor_value
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.sensor_values.sensor_value', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Sensor Value
	 * Fact ID: fact.sensor_values.sensor_value
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.sensor_values.sensor_value', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Sensor Value
	 * Fact ID: fact.sensor_values.sensor_value
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.sensor_values.sensor_value', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Sensor Value
	 * Fact ID: fact.sensor_values.sensor_value
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.sensor_values.sensor_value', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Sensor Value
	 * Fact ID: fact.sensor_values.sensor_value
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.sensor_values.sensor_value', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Tenure Sum
 * Fact ID: fact.employment_length.tenure_sum
 */
export const TenureSum = {
	/**
	 * Fact Title: Tenure Sum
	 * Fact ID: fact.employment_length.tenure_sum
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.employment_length.tenure_sum', 'fact'), m => m.aggregation('sum')),
	/**
	 * Fact Title: Tenure Sum
	 * Fact ID: fact.employment_length.tenure_sum
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.employment_length.tenure_sum', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Tenure Sum
	 * Fact ID: fact.employment_length.tenure_sum
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.employment_length.tenure_sum', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Tenure Sum
	 * Fact ID: fact.employment_length.tenure_sum
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.employment_length.tenure_sum', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Tenure Sum
	 * Fact ID: fact.employment_length.tenure_sum
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.employment_length.tenure_sum', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Tenure Sum
	 * Fact ID: fact.employment_length.tenure_sum
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.employment_length.tenure_sum', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Terminations Count
 * Fact ID: fact.employees_terminations.terminations_count
 */
export const TerminationsCount = {
	/**
	 * Fact Title: Terminations Count
	 * Fact ID: fact.employees_terminations.terminations_count
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.employees_terminations.terminations_count', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Terminations Count
	 * Fact ID: fact.employees_terminations.terminations_count
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.employees_terminations.terminations_count', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Terminations Count
	 * Fact ID: fact.employees_terminations.terminations_count
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.employees_terminations.terminations_count', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Terminations Count
	 * Fact ID: fact.employees_terminations.terminations_count
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.employees_terminations.terminations_count', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Terminations Count
	 * Fact ID: fact.employees_terminations.terminations_count
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.employees_terminations.terminations_count', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Terminations Count
	 * Fact ID: fact.employees_terminations.terminations_count
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.employees_terminations.terminations_count', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Toxicity Identity Attack
 * Fact ID: fact.diagnostics_toxicity_days.toxicity_identityattack
 */
export const ToxicityIdentityAttack = {
	/**
	 * Fact Title: Toxicity Identity Attack
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_identityattack
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_identityattack', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Toxicity Identity Attack
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_identityattack
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_identityattack', 'fact'),
		m => m.aggregation('avg')
	),
	/**
	 * Fact Title: Toxicity Identity Attack
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_identityattack
	 * Fact Aggregation: min
	 */ Min: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_identityattack', 'fact'),
		m => m.aggregation('min')
	),
	/**
	 * Fact Title: Toxicity Identity Attack
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_identityattack
	 * Fact Aggregation: max
	 */ Max: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_identityattack', 'fact'),
		m => m.aggregation('max')
	),
	/**
	 * Fact Title: Toxicity Identity Attack
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_identityattack
	 * Fact Aggregation: median
	 */ Median: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_identityattack', 'fact'),
		m => m.aggregation('median')
	),
	/**
	 * Fact Title: Toxicity Identity Attack
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_identityattack
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_identityattack', 'fact'),
		m => m.aggregation('runsum')
	)
};
/**
 * Fact Title: Toxicity Insult
 * Fact ID: fact.diagnostics_toxicity_days.toxicity_insult
 */
export const ToxicityInsult = {
	/**
	 * Fact Title: Toxicity Insult
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_insult
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_insult', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Toxicity Insult
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_insult
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_insult', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Toxicity Insult
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_insult
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_insult', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Toxicity Insult
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_insult
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_insult', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Toxicity Insult
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_insult
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_insult', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Toxicity Insult
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_insult
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_insult', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Toxicity Obscene
 * Fact ID: fact.diagnostics_toxicity_days.toxicity_obscene
 */
export const ToxicityObscene = {
	/**
	 * Fact Title: Toxicity Obscene
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_obscene
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_obscene', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Toxicity Obscene
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_obscene
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_obscene', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Toxicity Obscene
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_obscene
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_obscene', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Toxicity Obscene
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_obscene
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_obscene', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Toxicity Obscene
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_obscene
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_obscene', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Toxicity Obscene
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_obscene
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_obscene', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Toxicity Severe Toxicity
 * Fact ID: fact.diagnostics_toxicity_days.toxicity_severetoxicity
 */
export const ToxicitySevereToxicity = {
	/**
	 * Fact Title: Toxicity Severe Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_severetoxicity
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_severetoxicity', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Toxicity Severe Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_severetoxicity
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_severetoxicity', 'fact'),
		m => m.aggregation('avg')
	),
	/**
	 * Fact Title: Toxicity Severe Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_severetoxicity
	 * Fact Aggregation: min
	 */ Min: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_severetoxicity', 'fact'),
		m => m.aggregation('min')
	),
	/**
	 * Fact Title: Toxicity Severe Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_severetoxicity
	 * Fact Aggregation: max
	 */ Max: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_severetoxicity', 'fact'),
		m => m.aggregation('max')
	),
	/**
	 * Fact Title: Toxicity Severe Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_severetoxicity
	 * Fact Aggregation: median
	 */ Median: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_severetoxicity', 'fact'),
		m => m.aggregation('median')
	),
	/**
	 * Fact Title: Toxicity Severe Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_severetoxicity
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(
		idRef('fact.diagnostics_toxicity_days.toxicity_severetoxicity', 'fact'),
		m => m.aggregation('runsum')
	)
};
/**
 * Fact Title: Toxicity Threat
 * Fact ID: fact.diagnostics_toxicity_days.toxicity_threat
 */
export const ToxicityThreat = {
	/**
	 * Fact Title: Toxicity Threat
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_threat
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_threat', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Toxicity Threat
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_threat
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_threat', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Toxicity Threat
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_threat
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_threat', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Toxicity Threat
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_threat
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_threat', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Toxicity Threat
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_threat
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_threat', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Toxicity Threat
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_threat
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_threat', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Fact Title: Toxicity Toxicity
 * Fact ID: fact.diagnostics_toxicity_days.toxicity_toxicity
 */
export const ToxicityToxicity = {
	/**
	 * Fact Title: Toxicity Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_toxicity
	 * Fact Aggregation: sum
	 */
	Sum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_toxicity', 'fact'), m =>
		m.aggregation('sum')
	),
	/**
	 * Fact Title: Toxicity Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_toxicity
	 * Fact Aggregation: avg
	 */ Avg: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_toxicity', 'fact'), m =>
		m.aggregation('avg')
	),
	/**
	 * Fact Title: Toxicity Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_toxicity
	 * Fact Aggregation: min
	 */ Min: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_toxicity', 'fact'), m =>
		m.aggregation('min')
	),
	/**
	 * Fact Title: Toxicity Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_toxicity
	 * Fact Aggregation: max
	 */ Max: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_toxicity', 'fact'), m =>
		m.aggregation('max')
	),
	/**
	 * Fact Title: Toxicity Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_toxicity
	 * Fact Aggregation: median
	 */ Median: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_toxicity', 'fact'), m =>
		m.aggregation('median')
	),
	/**
	 * Fact Title: Toxicity Toxicity
	 * Fact ID: fact.diagnostics_toxicity_days.toxicity_toxicity
	 * Fact Aggregation: runsum
	 */ Runsum: newMeasure(idRef('fact.diagnostics_toxicity_days.toxicity_toxicity', 'fact'), m =>
		m.aggregation('runsum')
	)
};
/**
 * Attribute Title: Year (Date)
 * Attribute ID: date.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateYear: IAttribute = newAttribute('date.year.default');
/**
 * Attribute Title: Quarter (Date)
 * Attribute ID: date.quarter.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateQuarter: IAttribute = newAttribute('date.quarter.in.year.default');
/**
 * Attribute Title: Week (Sun-Sat)/Year (Date)
 * Attribute ID: date.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateWeekSunSatYear = {
	/**
	 * Display Form Title: Week #/Year (W1/2010) (Date)
	 * Display Form ID: date.week.wk_year
	 */
	WeekNrYear: newAttribute('date.week.wk_year'),
	/**
	 * Display Form Title: Week Starting (Date)
	 * Display Form ID: date.week.starting
	 */ WeekStarting: newAttribute('date.week.starting'),
	/**
	 * Display Form Title: From - To (Date)
	 * Display Form ID: date.week.from_to
	 */ FromTo: newAttribute('date.week.from_to'),
	/**
	 * Display Form Title: Week #/Year (Cont.) (Date)
	 * Display Form ID: date.week.wk_year_cont
	 */ WeekNrYear_1: newAttribute('date.week.wk_year_cont'),
	/**
	 * Display Form Title: Wk/Qtr/Year (Cont.) (Date)
	 * Display Form ID: date.week.wk_qtr_year_cont
	 */ WkQtrYear: newAttribute('date.week.wk_qtr_year_cont'),
	/**
	 * Display Form Title: Wk/Qtr/Year (Date)
	 * Display Form ID: date.week.wk_qtr_year
	 */ WkQtrYear_1: newAttribute('date.week.wk_qtr_year')
};
/**
 * Attribute Title: Week (Sun-Sat) (Date)
 * Attribute ID: date.week.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateWeekSunSat: IAttribute = newAttribute('date.week.in.year.number_us');
/**
 * Attribute Title: Week (Sun-Sat) of Qtr (Date)
 * Attribute ID: date.week.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateWeekSunSatOfQtr: IAttribute = newAttribute('date.week.in.quarter.number_us');
/**
 * Attribute Title: Week (Mon-Sun)/Year (Date)
 * Attribute ID: date.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateWeekMonSunYear = {
	/**
	 * Display Form Title: Week #/Year (W1/2010) (Date)
	 * Display Form ID: date.euweek.wk_year
	 */
	WeekNrYear: newAttribute('date.euweek.wk_year'),
	/**
	 * Display Form Title: Week Starting (Date)
	 * Display Form ID: date.euweek.starting
	 */ WeekStarting: newAttribute('date.euweek.starting'),
	/**
	 * Display Form Title: From - To (Date)
	 * Display Form ID: date.euweek.from_to
	 */ FromTo: newAttribute('date.euweek.from_to')
};
/**
 * Attribute Title: Week (Mon-Sun) (Date)
 * Attribute ID: date.euweek.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateWeekMonSun: IAttribute = newAttribute('date.euweek.in.year.number_eu');
/**
 * Attribute Title: Week (Mon-Sun) of Qtr (Date)
 * Attribute ID: date.euweek.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateWeekMonSunOfQtr: IAttribute = newAttribute('date.euweek.in.quarter.number_eu');
/**
 * Attribute Title: Month (Date)
 * Attribute ID: date.month.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateMonth = {
	/**
	 * Display Form Title: Short (Jan) (Date)
	 * Display Form ID: date.month.in.year.short
	 */
	Short: newAttribute('date.month.in.year.short'),
	/**
	 * Display Form Title: Long (January) (Date)
	 * Display Form ID: date.month.in.year.long
	 */ Long: newAttribute('date.month.in.year.long'),
	/**
	 * Display Form Title: Number (M1) (Date)
	 * Display Form ID: date.month.in.year.number
	 */ Number: newAttribute('date.month.in.year.number'),
	/**
	 * Display Form Title: M/Q (M1/Q1) (Date)
	 * Display Form ID: date.month.in.year.m_q
	 */ MQ: newAttribute('date.month.in.year.m_q')
};
/**
 * Attribute Title: Month of Quarter (Date)
 * Attribute ID: date.month.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateMonthOfQuarter: IAttribute = newAttribute('date.month.in.quarter.number');
/**
 * Attribute Title: Day of Year (Date)
 * Attribute ID: date.day.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateDayOfYear: IAttribute = newAttribute('date.day.in.year.default');
/**
 * Attribute Title: Day of Week (Sun-Sat) (Date)
 * Attribute ID: date.day.in.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateDayOfWeekSunSat = {
	/**
	 * Display Form Title: Short (Sun) (Date)
	 * Display Form ID: date.day.in.week.short
	 */
	Short: newAttribute('date.day.in.week.short'),
	/**
	 * Display Form Title: Long (Sunday) (Date)
	 * Display Form ID: date.day.in.week.long
	 */ Long: newAttribute('date.day.in.week.long'),
	/**
	 * Display Form Title: Number (1=Sunday) (Date)
	 * Display Form ID: date.day.in.week.number
	 */ Number: newAttribute('date.day.in.week.number')
};
/**
 * Attribute Title: Day of Week (Mon-Sun) (Date)
 * Attribute ID: date.day.in.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateDayOfWeekMonSun = {
	/**
	 * Display Form Title: Short (Mon) (Date)
	 * Display Form ID: date.day.in.euweek.short
	 */
	Short: newAttribute('date.day.in.euweek.short'),
	/**
	 * Display Form Title: Long (Monday) (Date)
	 * Display Form ID: date.day.in.euweek.long
	 */ Long: newAttribute('date.day.in.euweek.long'),
	/**
	 * Display Form Title: Number (1=Monday) (Date)
	 * Display Form ID: date.day.in.euweek.number
	 */ Number: newAttribute('date.day.in.euweek.number')
};
/**
 * Attribute Title: Day of Quarter (Date)
 * Attribute ID: date.day.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateDayOfQuarter: IAttribute = newAttribute('date.day.in.quarter.default');
/**
 * Attribute Title: Day of Month (Date)
 * Attribute ID: date.day.in.month
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateDayOfMonth: IAttribute = newAttribute('date.day.in.month.default');
/**
 * Attribute Title: Quarter/Year (Date)
 * Attribute ID: date.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateQuarterYear: IAttribute = newAttribute('date.quarter.short_us');
/**
 * Attribute Title: Month/Year (Date)
 * Attribute ID: date.month
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateMonthYear = {
	/**
	 * Display Form Title: Short (Jan 2010) (Date)
	 * Display Form ID: date.month.short
	 */
	Short: newAttribute('date.month.short'),
	/**
	 * Display Form Title: Long (January 2010) (Date)
	 * Display Form ID: date.month.long
	 */ Long: newAttribute('date.month.long'),
	/**
	 * Display Form Title: Number (1/2010) (Date)
	 * Display Form ID: date.month.number
	 */ Number: newAttribute('date.month.number')
};
/**
 * Attribute Title: Date (Date)
 * Attribute ID: date.date
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateDate = {
	/**
	 * Display Form Title: mm/dd/yyyy (Date)
	 * Display Form ID: date.date.day.us.mm_dd_yyyy
	 */
	MmDdYyyy: newAttribute('date.date.day.us.mm_dd_yyyy'),
	/**
	 * Display Form Title: yyyy-mm-dd (Date)
	 * Display Form ID: date.date.day.yyyy_mm_dd
	 */ YyyyMmDd: newAttribute('date.date.day.yyyy_mm_dd'),
	/**
	 * Display Form Title: m/d/yy (no leading zeroes) (Date)
	 * Display Form ID: date.date.day.us.m_d_yy
	 */ MDYy: newAttribute('date.date.day.us.m_d_yy'),
	/**
	 * Display Form Title: Long (Mon, Jan 1, 2010) (Date)
	 * Display Form ID: date.date.day.us.long
	 */ Long: newAttribute('date.date.day.us.long'),
	/**
	 * Display Form Title: dd/mm/yyyy (Date)
	 * Display Form ID: date.date.day.uk.dd_mm_yyyy
	 */ DdMmYyyy: newAttribute('date.date.day.uk.dd_mm_yyyy'),
	/**
	 * Display Form Title: dd-mm-yyyy (Date)
	 * Display Form ID: date.date.day.eu.dd_mm_yyyy
	 */ DdMmYyyy_1: newAttribute('date.date.day.eu.dd_mm_yyyy')
};
/**
 * Attribute Title: US Week Year (Date)
 * Attribute ID: date.year.for.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateUSWeekYear: IAttribute = newAttribute('date.year.for.week.number');
/**
 * Attribute Title: EU Week Year (Date)
 * Attribute ID: date.year.for.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateEUWeekYear: IAttribute = newAttribute('date.year.for.euweek.number');
/**
 * Attribute Title: US Week Quarter (Date)
 * Attribute ID: date.quarter.for.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateUSWeekQuarter: IAttribute = newAttribute('date.quarter.for.week.number');
/**
 * Attribute Title: EU Week Quarter (Date)
 * Attribute ID: date.quarter.for.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateEUWeekQuarter: IAttribute = newAttribute('date.quarter.for.euweek.number');
/** Available Date Data Sets */
export const DateDatasets = {
	/**
	 * Date Data Set Title: Date (Date)
	 * Date Data Set ID: date.dataset.dt
	 */
	Date: {
		ref: idRef('date.dataset.dt', 'dataSet'),
		identifier: 'date.dataset.dt',
		/**
		 * Date Attribute: Year (Date)
		 * Date Attribute ID: date.year
		 */ Year: {
			ref: idRef('date.year', 'attribute'),
			identifier: 'date.year',
			/**
			 * Display Form Title: Year (Date)
			 * Display Form ID: date.year.default
			 */ Default: newAttribute('date.year.default')
		},
		/**
		 * Date Attribute: Quarter (Date)
		 * Date Attribute ID: date.quarter.in.year
		 */ Quarter: {
			ref: idRef('date.quarter.in.year', 'attribute'),
			identifier: 'date.quarter.in.year',
			/**
			 * Display Form Title: default (Date)
			 * Display Form ID: date.quarter.in.year.default
			 */ Default: newAttribute('date.quarter.in.year.default')
		},
		/**
		 * Date Attribute: Week (Sun-Sat)/Year (Date)
		 * Date Attribute ID: date.week
		 */ WeekSunSatYear: {
			ref: idRef('date.week', 'attribute'),
			identifier: 'date.week',
			/**
			 * Display Form Title: Week #/Year (W1/2010) (Date)
			 * Display Form ID: date.week.wk_year
			 */ WeekNrYear: newAttribute('date.week.wk_year'),
			/**
			 * Display Form Title: Week Starting (Date)
			 * Display Form ID: date.week.starting
			 */ WeekStarting: newAttribute('date.week.starting'),
			/**
			 * Display Form Title: From - To (Date)
			 * Display Form ID: date.week.from_to
			 */ FromTo: newAttribute('date.week.from_to'),
			/**
			 * Display Form Title: Week #/Year (Cont.) (Date)
			 * Display Form ID: date.week.wk_year_cont
			 */ WeekNrYear_1: newAttribute('date.week.wk_year_cont'),
			/**
			 * Display Form Title: Wk/Qtr/Year (Cont.) (Date)
			 * Display Form ID: date.week.wk_qtr_year_cont
			 */ WkQtrYear: newAttribute('date.week.wk_qtr_year_cont'),
			/**
			 * Display Form Title: Wk/Qtr/Year (Date)
			 * Display Form ID: date.week.wk_qtr_year
			 */ WkQtrYear_1: newAttribute('date.week.wk_qtr_year')
		},
		/**
		 * Date Attribute: Week (Sun-Sat) (Date)
		 * Date Attribute ID: date.week.in.year
		 */ WeekSunSat: {
			ref: idRef('date.week.in.year', 'attribute'),
			identifier: 'date.week.in.year',
			/**
			 * Display Form Title: Number US (Date)
			 * Display Form ID: date.week.in.year.number_us
			 */ NumberUS: newAttribute('date.week.in.year.number_us')
		},
		/**
		 * Date Attribute: Week (Sun-Sat) of Qtr (Date)
		 * Date Attribute ID: date.week.in.quarter
		 */ WeekSunSatOfQtr: {
			ref: idRef('date.week.in.quarter', 'attribute'),
			identifier: 'date.week.in.quarter',
			/**
			 * Display Form Title: Number US (Date)
			 * Display Form ID: date.week.in.quarter.number_us
			 */ NumberUS: newAttribute('date.week.in.quarter.number_us')
		},
		/**
		 * Date Attribute: Week (Mon-Sun)/Year (Date)
		 * Date Attribute ID: date.euweek
		 */ WeekMonSunYear: {
			ref: idRef('date.euweek', 'attribute'),
			identifier: 'date.euweek',
			/**
			 * Display Form Title: Week #/Year (W1/2010) (Date)
			 * Display Form ID: date.euweek.wk_year
			 */ WeekNrYear: newAttribute('date.euweek.wk_year'),
			/**
			 * Display Form Title: Week Starting (Date)
			 * Display Form ID: date.euweek.starting
			 */ WeekStarting: newAttribute('date.euweek.starting'),
			/**
			 * Display Form Title: From - To (Date)
			 * Display Form ID: date.euweek.from_to
			 */ FromTo: newAttribute('date.euweek.from_to')
		},
		/**
		 * Date Attribute: Week (Mon-Sun) (Date)
		 * Date Attribute ID: date.euweek.in.year
		 */ WeekMonSun: {
			ref: idRef('date.euweek.in.year', 'attribute'),
			identifier: 'date.euweek.in.year',
			/**
			 * Display Form Title: Number EU (Date)
			 * Display Form ID: date.euweek.in.year.number_eu
			 */ NumberEU: newAttribute('date.euweek.in.year.number_eu')
		},
		/**
		 * Date Attribute: Week (Mon-Sun) of Qtr (Date)
		 * Date Attribute ID: date.euweek.in.quarter
		 */ WeekMonSunOfQtr: {
			ref: idRef('date.euweek.in.quarter', 'attribute'),
			identifier: 'date.euweek.in.quarter',
			/**
			 * Display Form Title: Number EU (Date)
			 * Display Form ID: date.euweek.in.quarter.number_eu
			 */ NumberEU: newAttribute('date.euweek.in.quarter.number_eu')
		},
		/**
		 * Date Attribute: Month (Date)
		 * Date Attribute ID: date.month.in.year
		 */ Month: {
			ref: idRef('date.month.in.year', 'attribute'),
			identifier: 'date.month.in.year',
			/**
			 * Display Form Title: Short (Jan) (Date)
			 * Display Form ID: date.month.in.year.short
			 */ Short: newAttribute('date.month.in.year.short'),
			/**
			 * Display Form Title: Long (January) (Date)
			 * Display Form ID: date.month.in.year.long
			 */ Long: newAttribute('date.month.in.year.long'),
			/**
			 * Display Form Title: Number (M1) (Date)
			 * Display Form ID: date.month.in.year.number
			 */ Number: newAttribute('date.month.in.year.number'),
			/**
			 * Display Form Title: M/Q (M1/Q1) (Date)
			 * Display Form ID: date.month.in.year.m_q
			 */ MQ: newAttribute('date.month.in.year.m_q')
		},
		/**
		 * Date Attribute: Month of Quarter (Date)
		 * Date Attribute ID: date.month.in.quarter
		 */ MonthOfQuarter: {
			ref: idRef('date.month.in.quarter', 'attribute'),
			identifier: 'date.month.in.quarter',
			/**
			 * Display Form Title: Number (Date)
			 * Display Form ID: date.month.in.quarter.number
			 */ Number: newAttribute('date.month.in.quarter.number')
		},
		/**
		 * Date Attribute: Day of Year (Date)
		 * Date Attribute ID: date.day.in.year
		 */ DayOfYear: {
			ref: idRef('date.day.in.year', 'attribute'),
			identifier: 'date.day.in.year',
			/**
			 * Display Form Title: default (Date)
			 * Display Form ID: date.day.in.year.default
			 */ Default: newAttribute('date.day.in.year.default')
		},
		/**
		 * Date Attribute: Day of Week (Sun-Sat) (Date)
		 * Date Attribute ID: date.day.in.week
		 */ DayOfWeekSunSat: {
			ref: idRef('date.day.in.week', 'attribute'),
			identifier: 'date.day.in.week',
			/**
			 * Display Form Title: Short (Sun) (Date)
			 * Display Form ID: date.day.in.week.short
			 */ Short: newAttribute('date.day.in.week.short'),
			/**
			 * Display Form Title: Long (Sunday) (Date)
			 * Display Form ID: date.day.in.week.long
			 */ Long: newAttribute('date.day.in.week.long'),
			/**
			 * Display Form Title: Number (1=Sunday) (Date)
			 * Display Form ID: date.day.in.week.number
			 */ Number: newAttribute('date.day.in.week.number')
		},
		/**
		 * Date Attribute: Day of Week (Mon-Sun) (Date)
		 * Date Attribute ID: date.day.in.euweek
		 */ DayOfWeekMonSun: {
			ref: idRef('date.day.in.euweek', 'attribute'),
			identifier: 'date.day.in.euweek',
			/**
			 * Display Form Title: Short (Mon) (Date)
			 * Display Form ID: date.day.in.euweek.short
			 */ Short: newAttribute('date.day.in.euweek.short'),
			/**
			 * Display Form Title: Long (Monday) (Date)
			 * Display Form ID: date.day.in.euweek.long
			 */ Long: newAttribute('date.day.in.euweek.long'),
			/**
			 * Display Form Title: Number (1=Monday) (Date)
			 * Display Form ID: date.day.in.euweek.number
			 */ Number: newAttribute('date.day.in.euweek.number')
		},
		/**
		 * Date Attribute: Day of Quarter (Date)
		 * Date Attribute ID: date.day.in.quarter
		 */ DayOfQuarter: {
			ref: idRef('date.day.in.quarter', 'attribute'),
			identifier: 'date.day.in.quarter',
			/**
			 * Display Form Title: default (Date)
			 * Display Form ID: date.day.in.quarter.default
			 */ Default: newAttribute('date.day.in.quarter.default')
		},
		/**
		 * Date Attribute: Day of Month (Date)
		 * Date Attribute ID: date.day.in.month
		 */ DayOfMonth: {
			ref: idRef('date.day.in.month', 'attribute'),
			identifier: 'date.day.in.month',
			/**
			 * Display Form Title: default (Date)
			 * Display Form ID: date.day.in.month.default
			 */ Default: newAttribute('date.day.in.month.default')
		},
		/**
		 * Date Attribute: Quarter/Year (Date)
		 * Date Attribute ID: date.quarter
		 */ QuarterYear: {
			ref: idRef('date.quarter', 'attribute'),
			identifier: 'date.quarter',
			/**
			 * Display Form Title: US Short (Date)
			 * Display Form ID: date.quarter.short_us
			 */ USShort: newAttribute('date.quarter.short_us')
		},
		/**
		 * Date Attribute: Month/Year (Date)
		 * Date Attribute ID: date.month
		 */ MonthYear: {
			ref: idRef('date.month', 'attribute'),
			identifier: 'date.month',
			/**
			 * Display Form Title: Short (Jan 2010) (Date)
			 * Display Form ID: date.month.short
			 */ Short: newAttribute('date.month.short'),
			/**
			 * Display Form Title: Long (January 2010) (Date)
			 * Display Form ID: date.month.long
			 */ Long: newAttribute('date.month.long'),
			/**
			 * Display Form Title: Number (1/2010) (Date)
			 * Display Form ID: date.month.number
			 */ Number: newAttribute('date.month.number')
		},
		/**
		 * Date Attribute: Date (Date)
		 * Date Attribute ID: date.date
		 */ Date: {
			ref: idRef('date.date', 'attribute'),
			identifier: 'date.date',
			/**
			 * Display Form Title: mm/dd/yyyy (Date)
			 * Display Form ID: date.date.day.us.mm_dd_yyyy
			 */ MmDdYyyy: newAttribute('date.date.day.us.mm_dd_yyyy'),
			/**
			 * Display Form Title: yyyy-mm-dd (Date)
			 * Display Form ID: date.date.day.yyyy_mm_dd
			 */ YyyyMmDd: newAttribute('date.date.day.yyyy_mm_dd'),
			/**
			 * Display Form Title: m/d/yy (no leading zeroes) (Date)
			 * Display Form ID: date.date.day.us.m_d_yy
			 */ MDYy: newAttribute('date.date.day.us.m_d_yy'),
			/**
			 * Display Form Title: Long (Mon, Jan 1, 2010) (Date)
			 * Display Form ID: date.date.day.us.long
			 */ Long: newAttribute('date.date.day.us.long'),
			/**
			 * Display Form Title: dd/mm/yyyy (Date)
			 * Display Form ID: date.date.day.uk.dd_mm_yyyy
			 */ DdMmYyyy: newAttribute('date.date.day.uk.dd_mm_yyyy'),
			/**
			 * Display Form Title: dd-mm-yyyy (Date)
			 * Display Form ID: date.date.day.eu.dd_mm_yyyy
			 */ DdMmYyyy_1: newAttribute('date.date.day.eu.dd_mm_yyyy')
		},
		/**
		 * Date Attribute: US Week Year (Date)
		 * Date Attribute ID: date.year.for.week
		 */ USWeekYear: {
			ref: idRef('date.year.for.week', 'attribute'),
			identifier: 'date.year.for.week',
			/**
			 * Display Form Title: Week Year (Date)
			 * Display Form ID: date.year.for.week.number
			 */ WeekYear: newAttribute('date.year.for.week.number')
		},
		/**
		 * Date Attribute: EU Week Year (Date)
		 * Date Attribute ID: date.year.for.euweek
		 */ EUWeekYear: {
			ref: idRef('date.year.for.euweek', 'attribute'),
			identifier: 'date.year.for.euweek',
			/**
			 * Display Form Title: Week Year (Date)
			 * Display Form ID: date.year.for.euweek.number
			 */ WeekYear: newAttribute('date.year.for.euweek.number')
		},
		/**
		 * Date Attribute: US Week Quarter (Date)
		 * Date Attribute ID: date.quarter.for.week
		 */ USWeekQuarter: {
			ref: idRef('date.quarter.for.week', 'attribute'),
			identifier: 'date.quarter.for.week',
			/**
			 * Display Form Title: Week Quarter (Date)
			 * Display Form ID: date.quarter.for.week.number
			 */ WeekQuarter: newAttribute('date.quarter.for.week.number')
		},
		/**
		 * Date Attribute: EU Week Quarter (Date)
		 * Date Attribute ID: date.quarter.for.euweek
		 */ EUWeekQuarter: {
			ref: idRef('date.quarter.for.euweek', 'attribute'),
			identifier: 'date.quarter.for.euweek',
			/**
			 * Display Form Title: Week Quarter (Date)
			 * Display Form ID: date.quarter.for.euweek.number
			 */ WeekQuarter: newAttribute('date.quarter.for.euweek.number')
		}
	}
};
export const Insights = {
	/**
	 * Insight Title: Emails. Sent Heatmap
	 * Insight ID: adpgtAVQ3VzO
	 */
	EmailsSentHeatmap: 'adpgtAVQ3VzO',
	/**
	 * Insight Title: Emails. Received Heatmap
	 * Insight ID: abBgr7j9SO59
	 */ EmailsReceivedHeatmap: 'abBgr7j9SO59',
	/**
	 * Insight Title: Events. Heatmap
	 * Insight ID: abhgvx9iYteS
	 */ EventsHeatmap: 'abhgvx9iYteS',
	/**
	 * Insight Title: Chats. Activity Heatmap
	 * Insight ID: aabibTwyiWC2
	 */ ChatsActivityHeatmap: 'aabibTwyiWC2',
	/**
	 * Insight Title: Chats. Metrics
	 * Insight ID: aaxh38nC903o
	 */ ChatsMetrics: 'aaxh38nC903o',
	/**
	 * Insight Title: Emails. Metrics
	 * Insight ID: aaLiiJADw4R7
	 */ EmailsMetrics: 'aaLiiJADw4R7',
	/**
	 * Insight Title: Events. Metrics
	 * Insight ID: aayieVkKw2fc
	 */ EventsMetrics: 'aayieVkKw2fc',
	/**
	 * Insight Title: Chats. Bar chart
	 * Insight ID: aaDikqTdu47c
	 */ ChatsBarChart: 'aaDikqTdu47c',
	/**
	 * Insight Title: Emails. Bar chart
	 * Insight ID: aapimrq4r1hz
	 */ EmailsBarChart: 'aapimrq4r1hz',
	/**
	 * Insight Title: Events. Bar chart
	 * Insight ID: aaNiqyUzzund
	 */ EventsBarChart: 'aaNiqyUzzund',
	/**
	 * Insight Title: Overview. Diagnostics. Metrics
	 * Insight ID: abdmZiqMvOuT
	 */ OverviewDiagnosticsMetrics: 'abdmZiqMvOuT',
	/**
	 * Insight Title: Overview. Factors
	 * Insight ID: abNmROzDNxy6
	 */ OverviewFactors: 'abNmROzDNxy6',
	/**
	 * Insight Title: Factor's Heatmap
	 * Insight ID: aa9mVO0rQclv
	 */ FactorSHeatmap: 'aa9mVO0rQclv',
	/**
	 * Insight Title: Risk. Turnover. Bar chart
	 * Insight ID: aamnevC7PmC0
	 */ RiskTurnoverBarChart: 'aamnevC7PmC0',
	/**
	 * Insight Title: Risk. Turnover. Heatmap
	 * Insight ID: aaym9IvTMh1l
	 */ RiskTurnoverHeatmap: 'aaym9IvTMh1l',
	/**
	 * Insight Title: Risk. Absenteeism. Bar chart
	 * Insight ID: aabnnmYIgIjK
	 */ RiskAbsenteeismBarChart: 'aabnnmYIgIjK',
	/**
	 * Insight Title: Risk. Absenteeism. Heatmap
	 * Insight ID: abym1gNQxP7N
	 */ RiskAbsenteeismHeatmap: 'abym1gNQxP7N',
	/**
	 * Insight Title: Risk. Absenteeism. Rate
	 * Insight ID: aarnm0M1UM86
	 */ RiskAbsenteeismRate: 'aarnm0M1UM86',
	/**
	 * Insight Title: Leading Indicators
	 * Insight ID: aarnJgmeclNG
	 */ LeadingIndicators: 'aarnJgmeclNG',
	/**
	 * Insight Title: Factors. Engagement. Line chart
	 * Insight ID: aaXrpGX9pYxE
	 */ FactorsEngagementLineChart: 'aaXrpGX9pYxE',
	/**
	 * Insight Title: Action Planning. Insight
	 * Insight ID: ac1PQ9DMqM0A
	 */ ActionPlanningInsight: 'ac1PQ9DMqM0A',
	/**
	 * Insight Title: Operational Metrics. Line Plot
	 * Insight ID: aaiutPCl1yCX
	 */ OperationalMetricsLinePlot: 'aaiutPCl1yCX',
	/**
	 * Insight Title: Diagnostics. eNPS. Heatmap
	 * Insight ID: aaHSHR2qzpET
	 */ DiagnosticsENPSHeatmap: 'aaHSHR2qzpET',
	/**
	 * Insight Title: Diagnostics. eNPS.
	 * Insight ID: aacS20mjDOFU
	 */ DiagnosticsENPS: 'aacS20mjDOFU',
	/**
	 * Insight Title: Diagnostics. eNPS. Bar Chart
	 * Insight ID: aacS5GaTHop1
	 */ DiagnosticsENPSBarChart: 'aacS5GaTHop1',
	/**
	 * Insight Title: Diagnostics. Pulse. Heatmap
	 * Insight ID: aex8dwmXkqfG
	 */ DiagnosticsPulseHeatmap: 'aex8dwmXkqfG',
	/**
	 * Insight Title: Diagnostics. Pulse. Bar chart
	 * Insight ID: aaC8rWQgl8jI
	 */ DiagnosticsPulseBarChart: 'aaC8rWQgl8jI',
	/**
	 * Insight Title: Diagnostics. Pulse. Metrics
	 * Insight ID: aaM8sdk7lM4P
	 */ DiagnosticsPulseMetrics: 'aaM8sdk7lM4P',
	/**
	 * Insight Title: Diagnostics. Noise. Heatmap
	 * Insight ID: aacT84uHJt8O
	 */ DiagnosticsNoiseHeatmap: 'aacT84uHJt8O',
	/**
	 * Insight Title: Diagnostics. Noise. Bar graph
	 * Insight ID: ab8TWb5YBl2D
	 */ DiagnosticsNoiseBarGraph: 'ab8TWb5YBl2D',
	/**
	 * Insight Title: Diagnostics. Noise. Metrics
	 * Insight ID: aaPT1cxvNl0z
	 */ DiagnosticsNoiseMetrics: 'aaPT1cxvNl0z',
	/**
	 * Insight Title: Diagnostics. Temperature. Heatmap
	 * Insight ID: abtT96jpS7cl
	 */ DiagnosticsTemperatureHeatmap: 'abtT96jpS7cl',
	/**
	 * Insight Title: Diagnostics. Temperature. Bar chart
	 * Insight ID: abGT5OT6HLkG
	 */ DiagnosticsTemperatureBarChart: 'abGT5OT6HLkG',
	/**
	 * Insight Title: Diagnostics. Temperature. Metrics
	 * Insight ID: aaDUl4pwSOUp
	 */ DiagnosticsTemperatureMetrics: 'aaDUl4pwSOUp',
	/**
	 * Insight Title: Diagnostics. Light. Heatmap
	 * Insight ID: acaUtnap6WNV
	 */ DiagnosticsLightHeatmap: 'acaUtnap6WNV',
	/**
	 * Insight Title: Diagnostics. Light. Bar chart
	 * Insight ID: ac6Uu5LUYUD5
	 */ DiagnosticsLightBarChart: 'ac6Uu5LUYUD5',
	/**
	 * Insight Title: Diagnostics. Light. Metrics
	 * Insight ID: aa8UG6A0e2x2
	 */ DiagnosticsLightMetrics: 'aa8UG6A0e2x2',
	/**
	 * Insight Title: Diagnostics. Air. Metrics
	 * Insight ID: abmUIlYpqOO8
	 */ DiagnosticsAirMetrics: 'abmUIlYpqOO8',
	/**
	 * Insight Title: Diagnostics. Air Quality. Bar chart
	 * Insight ID: aafVMtet2bCY
	 */ DiagnosticsAirQualityBarChart: 'aafVMtet2bCY',
	/**
	 * Insight Title: Diagnostics. Air Quality. Heatmap
	 * Insight ID: aaqVFCUO63PX
	 */ DiagnosticsAirQualityHeatmap: 'aaqVFCUO63PX',
	/**
	 * Insight Title: Action Planning. Insight. Weekly
	 * Insight ID: ab9vnUMAh5JI
	 */ ActionPlanningInsightWeekly: 'ab9vnUMAh5JI',
	/**
	 * Insight Title: Events. Bar chart. Length sum
	 * Insight ID: abBpvkwcRQ7w
	 */ EventsBarChartLengthSum: 'abBpvkwcRQ7w',
	/**
	 * Insight Title: Risk. Connectivity. Heatmap
	 * Insight ID: abVfxQiA2pwU
	 */ RiskConnectivityHeatmap: 'abVfxQiA2pwU',
	/**
	 * Insight Title: Diagnostics. Toxicity. Identity Attack. Heatmap
	 * Insight ID: abf24nhuY6tk
	 */ DiagnosticsToxicityIdentityAttackHeatmap: 'abf24nhuY6tk',
	/**
	 * Insight Title: Diagnostics. Toxicity. Insult. Heatmap
	 * Insight ID: aa12Z7en5p1o
	 */ DiagnosticsToxicityInsultHeatmap: 'aa12Z7en5p1o',
	/**
	 * Insight Title: Diagnostics. Toxicity. Obscene. Heatmap
	 * Insight ID: aau28xGY8Pli
	 */ DiagnosticsToxicityObsceneHeatmap: 'aau28xGY8Pli',
	/**
	 * Insight Title: Diagnostics. Toxicity. Severe Toxicity. Heatmap
	 * Insight ID: abi23jcktFV8
	 */ DiagnosticsToxicitySevereToxicityHeatmap: 'abi23jcktFV8',
	/**
	 * Insight Title: Diagnostics. Toxicity. Threat. Heatmap
	 * Insight ID: aaT26Ra60pJU
	 */ DiagnosticsToxicityThreatHeatmap: 'aaT26Ra60pJU',
	/**
	 * Insight Title: Diagnostics. Toxicity. Toxicity. Heatmap
	 * Insight ID: aba2ZYwxrBkQ
	 */ DiagnosticsToxicityToxicityHeatmap: 'aba2ZYwxrBkQ',
	/**
	 * Insight Title: Diagnostics. Sentiment. Negative. Heatmap
	 * Insight ID: aaV29DkB1JxW
	 */ DiagnosticsSentimentNegativeHeatmap: 'aaV29DkB1JxW',
	/**
	 * Insight Title: Diagnostics. Sentiment. Neutral. Heatmap
	 * Insight ID: abJ23MPh6Gqa
	 */ DiagnosticsSentimentNeutralHeatmap: 'abJ23MPh6Gqa',
	/**
	 * Insight Title: Diagnostics. Sentiment. Positive. Heatmap
	 * Insight ID: abG28xOyvJN5
	 */ DiagnosticsSentimentPositiveHeatmap: 'abG28xOyvJN5',
	/**
	 * Insight Title: TEST
	 * Insight ID: abJF0bPzABmn
	 */ TEST: 'abJF0bPzABmn'
};
export const Dashboards = {
	/**
	 * Dashboard Title: Validation
	 * Dashboard ID: abWgN9Zelm2P
	 */
	Validation: 'abWgN9Zelm2P',
	/**
	 * Dashboard Title: Web App Components
	 * Dashboard ID: aactBPnOi079
	 */ WebAppComponents: 'aactBPnOi079'
};
