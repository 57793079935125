import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import * as Component from 'components';

import { useTypedSelector, useActions, useOnClickOutside } from 'hooks';

/**
 * Custom component to render when GoodData receives an error on execution
 * @author Konstantin Krumin
 */
interface DataErrorProps {}

export const DataError: React.FC<DataErrorProps> = () => {
	const navigate = useNavigate();

	const error = useTypedSelector(state => state.error);
	const { setError } = useActions();

	const node = useRef<HTMLInputElement | null>(null);
	useOnClickOutside(node, () => setError(undefined, false));

	const handleRedirectHome = () => {
		setError(undefined, false);
		navigate('/', { replace: true });
	};

	return (
		<Modal
			isOpen={error.showError}
			className="bg-white w-11/12 sm:max-w-3xl mx-auto my-auto rounded shadow-lg p-4 z-50"
			overlayClassName="fixed flex bg-gray-50 bg-opacity-40 inset-0 h-screen w-screen z-50 overflow-auto"
			ariaHideApp={false}
		>
			<div ref={node}>
				<div className="flex justify-end">
					<Component.HolButton
						label="X"
						type="secondary"
						onClick={() => setError(undefined, false)}
					/>
				</div>

				<div className="flex flex-col items-center mt-4">
					<div className="flex flex-col items-center space-y-2">
						<Component.HolText textType="heading1">
							Something went wrong...
						</Component.HolText>
						<Component.HolText>
							Unfortunately, we could not process your request. Please click on reload
							button below.
						</Component.HolText>
						<Component.HolText>
							If this issue keeps reoccurring, please contact our support.
						</Component.HolText>
					</div>

					{process.env.REACT_APP_CURRENT_ENV === 'dev' && error.errorDetails && (
						<div className="bg-gray-200 mt-4 p-3 w-full">
							<Component.HolText>
								MESSAGE: {error.errorDetails?.name ?? 'Not provided'}
							</Component.HolText>
							<Component.HolText>
								TYPE: {error.errorDetails?.message ?? 'Not provided'}
							</Component.HolText>
							{error.errorDetails?.stack && (
								<div className="bg-gray-100 my-2 p-3">
									<Component.HolText>
										{error.errorDetails?.stack}
									</Component.HolText>
								</div>
							)}
						</div>
					)}

					<Component.HolButton
						label="Reload"
						type="holmetrics"
						customEdge="rounded-md py-4 px-3"
						customStyle="mx-auto mt-6 md:w-1/6 p-5 text-2xl bg-hol-light-blue"
						onClick={handleRedirectHome}
					/>
				</div>
			</div>
		</Modal>
	);
};
