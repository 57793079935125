import { useExecution, useDataView } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';

import { useTypedSelector } from 'hooks';
import { useEffect, useState } from 'react';

export const useWorkspaceKpis = () => {
	const [operations, setOperations] = useState<{ [key: string]: { [key: string]: string } }>();
	const [nestedOptions, setNestedOptions] = useState<{ [key: string]: string[] }>({});
	const [departmentKpis, setDepartmentKpis] = useState<string[]>();

	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const execution = useExecution({
		workspace: currentWorkspace.id,
		seriesBy: [Ldm.OMValue.Avg, Ldm.OMDepartment],
		slicesBy: [Ldm.OMUnitOfMeasurement, Ldm.OMGranularity, Ldm.OMMetricId]
	});

	const { result } = useDataView({ execution }, [execution?.fingerprint()]);

	useEffect(() => {
		if (!result) {
			return;
		}

		const departments = result.dataView.headerItems[1][0]
			.map((a: any) => a.attributeHeaderItem.name)
			.filter(name => name !== '');

		setDepartmentKpis(departments);

		// Ex of kpis{}
		// {
		// 		'Billable Hours': {
		// 			Department: '1',
		// 			Employee: '3'
		// 		}
		// }
		const kpiInfo: { [key: string]: { [key: string]: string } } = {};
		const kpiNestedOptions: { [key: string]: string[] } = {};

		const headerItems = result.dataView.headerItems[0];
		const [kpis, granularities, ids] = headerItems.map(item =>
			item.map((a: any) => a.attributeHeaderItem.name as string)
		);

		kpis.forEach((kpi: any, i) => {
			const granularity = granularities[i];
			const id = ids[i];

			if (!kpiInfo[kpi]) {
				kpiInfo[kpi] = {};
			}
			if (!kpiNestedOptions[kpi]) {
				kpiNestedOptions[kpi] = [];
			}

			kpiNestedOptions[kpi].push(granularity);
			kpiInfo[kpi][granularity] = id;
		});

		setOperations(kpiInfo);
		setNestedOptions(kpiNestedOptions);
	}, [result]);

	return { operations, nestedOptions, departmentKpis };
};
