import { IActionPlan } from 'types';
import { useAxiosPrivate } from 'hooks';

import {
	GetActionPlanType,
	GetActionPlansType,
	CreateActionPlanType,
	UpdateActionPlanType,
	DeleteActionPlanType,
	IGetActionPlansRes,
	GetArchivedActionPlansType,
	IGetArchivedActionPlansRes,
	UpdateActionPlanArchivingStatusType
} from './types';

export const ActionPlanApi = () => {
	const { serverAxios } = useAxiosPrivate();

	/**
	 * An API that retrieves all action plans created by user within a workspace
	 * @param workspaceId - current workspace that the user is in.
	 * @param userId - current user that is logged in
	 * @param isActive - query boolean parameter that filters to return only currently active action plans
	 * @param currentPage - page that is being requested for displaying
	 * @param itemsPerPage - how many items should be showed per page
	 */
	const getActionPlans: GetActionPlansType = async (
		workspaceId,
		userId,
		isActive = false,
		currentPage = 1,
		itemsPerPage = 4
	) => {
		return await serverAxios
			.get<IGetActionPlansRes>(
				`${process.env.REACT_APP_HOST}/action-plan/${workspaceId}/${userId}?onlyActive=${isActive}&page=${currentPage}&limit=${itemsPerPage}`
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that retrieves the specified action plan
	 * @param userId - current user that is logged in
	 * @param workspaceId - current workspace that the user is in.
	 * @param actionPlanUuid - UUID of an action plan that needs to be retrieved
	 */
	const getActionPlan: GetActionPlanType = async (workspaceId, userId, actionPlanUuid) => {
		return await serverAxios
			.get<IActionPlan[]>(
				`${process.env.REACT_APP_HOST}/action-plan/${workspaceId}/${userId}/${actionPlanUuid}`
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that creates a new action plan and stores it in the database
	 * @param actionPlanDetails - current action plan that the user just created.
	 */
	const createActionPlan: CreateActionPlanType = async actionPlanDetails => {
		return await serverAxios
			.post<IActionPlan>(
				`${process.env.REACT_APP_HOST}/action-plan/create`,
				actionPlanDetails
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that updates the specified action plan
	 * @param userId - current user that is logged in
	 * @param workspaceId - current workspace that the user is in
	 * @param actionPlanUuid - the action plan's UUID that the user wants to update
	 * @param actionPlanDetails - a set of fields in that action plan that needs to be updated
	 */
	const updateActionPlan: UpdateActionPlanType = async (
		workspaceId,
		userId,
		actionPlanUuid,
		actionPlanDetails
	) => {
		return await serverAxios
			.put<IActionPlan>(
				`${process.env.REACT_APP_HOST}/action-plan/${workspaceId}/${userId}/${actionPlanUuid}`,
				actionPlanDetails
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that deletes the specified action plan
	 * @param userId - current user that is logged in
	 * @param workspaceId - current workspace that the user is in
	 * @param actionPlanUuid - the action plan's UUID that the user wants to delete
	 */
	const deleteActionPlan: DeleteActionPlanType = async (workspaceId, userId, actionPlanUuid) => {
		return await serverAxios
			.delete(
				`${process.env.REACT_APP_HOST}/action-plan/${workspaceId}/${userId}/${actionPlanUuid}`
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that retrieves archived action plans created by user within a workspace
	 * @param workspaceId - current workspace that the user is in.
	 * @param userId - current user that is logged in
	 * @param currentPage - page that is being requested for displaying
	 * @param itemsPerPage - how many items should be showed per page
	 */
	const getArchivedActionPlans: GetArchivedActionPlansType = async (
		workspaceId,
		userId,
		currentPage = 1,
		itemsPerPage = 10
	) => {
		return await serverAxios
			.get<IGetArchivedActionPlansRes>(
				`${process.env.REACT_APP_HOST}/action-plan/${workspaceId}/${userId}/archived?page=${currentPage}&limit=${itemsPerPage}`
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	/**
	 * An API that updates archiving status of an action plan
	 * @param userId - current user that is logged in
	 * @param workspaceId - current workspace that the user is in
	 * @param actionPlanUuid - the action plan's UUID that the user wants to update
	 * @param isArchiving - a boolean that determines whether an action plan needs to be archived or unarchived
	 */
	const updateActionPlanArchivingStatus: UpdateActionPlanArchivingStatusType = async (
		workspaceId,
		userId,
		actionPlanUuid,
		isArchiving
	) => {
		return await serverAxios
			.put<IActionPlan>(
				`${process.env.REACT_APP_HOST}/action-plan/${workspaceId}/${userId}/${actionPlanUuid}/archive`,
				{ archivingStatus: isArchiving }
			)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	return {
		getActionPlan,
		getActionPlans,
		createActionPlan,
		updateActionPlan,
		deleteActionPlan,
		getArchivedActionPlans,
		updateActionPlanArchivingStatus
	};
};
