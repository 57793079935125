import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { IMeasure, IMeasureDefinitionType, newAbsoluteDateFilter } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import {
	KpiValueType,
	HolmetricsFactorType,
	ICircleAdditionalParams,
	HolmetricsDiagnosticType
} from 'types';
import { useTypedSelector } from 'hooks';

import { setActionPlanStartDate } from './helpers';

/**
 * An extended score display component to show difference between most recent and action plan starting data points for both factors and diagnostics data
 * @author Konstantin Krumin
 * @param title - title text for the score text
 * @param subtitle - subtitle text for the score text
 * @param endDate - end date to be used by date filter
 * @param startDate - start date to be used by date filter
 * @param identifier - unique identifier of the selected action plan score
 * @param ldmMeasureRef = LDM catalog reference for extracting needed value
 * @param circleAdditionalParams - custom parameter for the progress bar styling
 * @param displayStyle - determines the display styles (large number / progress bar)
 * @param isPercentageValue - determines whether returned value is in percentage format
 * @param showHistoricSplineChart - determines whether spline chart with historic data needs to be shown
 * @param isTitleOnRightSide - determines whether a title needs to be shown on the right side of the return value instead of showing on top
 */
interface ActionPlanScoreDisplayProps {
	endDate: Date;
	startDate: Date;
	title?: string;
	subtitle?: string;
	isPercentageValue?: boolean;
	isTitleOnRightSide?: boolean;
	showHistoricSplineChart?: boolean;
	displayStyle: 'large-number' | 'progress-bar';
	ldmMeasureRef: IMeasure<IMeasureDefinitionType>;
	circleAdditionalParams?: ICircleAdditionalParams;
	identifier: HolmetricsFactorType | HolmetricsDiagnosticType;
}

export const ActionPlanScoreDisplay: React.FC<ActionPlanScoreDisplayProps> = ({
	endDate,
	startDate,
	title,
	subtitle,
	identifier,
	displayStyle,
	ldmMeasureRef,
	circleAdditionalParams,
	isPercentageValue = false,
	isTitleOnRightSide = false,
	showHistoricSplineChart = false
}) => {
	const dateAsset = useTypedSelector(state => state.dataView.dateAsset);

	const actionPlanData = useTypedSelector(state => state.actionPlanning.selectedActionPlan);
	const selectedDemographics = Util.parseDefaultDemographicValues(actionPlanData?.data);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const dateFilter = newAbsoluteDateFilter(
		Ldm.DateDatasets.Date.identifier,
		setActionPlanStartDate(startDate, identifier).toISOString().substr(0, 10),
		new Date(endDate)?.toISOString().substr(0, 10)
	);

	const filtersArr = [dateFilter, ...demographicFilter];

	return (
		<Execute
			filters={filtersArr}
			slicesBy={[dateAsset]}
			seriesBy={[ldmMeasureRef]}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				if (!result) {
					return <LoadingComponent className="my-8" />;
				}

				let kpiValue: KpiValueType = 0;

				const values = result?.rawData().data();

				const dates = Util.extractDatesArr(result);

				if (typeof result !== 'undefined') {
					const unprocessedStartingScore = values[0];
					const unprocessedRecentScore = values[values.length - 1];

					if (unprocessedStartingScore && unprocessedRecentScore) {
						const startingScore = parseFloat(unprocessedStartingScore.toString());
						const mostRecentScore = parseFloat(unprocessedRecentScore.toString());

						if (isPercentageValue) {
							const tempValue = (mostRecentScore - startingScore).toString();

							tempValue === 'NaN'
								? (kpiValue = 'No Data')
								: (kpiValue = Util.convertStrPercentageToIntNum(tempValue));
						} else {
							kpiValue = mostRecentScore - startingScore;
						}
					}
				}

				const splineData = Util.createSplineData(values, dates);

				if (execution.isLoading) {
					return <LoadingComponent className="my-8" />;
				} else {
					return (
						<div
							className={
								isTitleOnRightSide
									? 'flex flex-row-reverse items-center'
									: 'flex flex-col'
							}
						>
							<div
								className={`flex flex-col ${
									isTitleOnRightSide ? 'text-left w-20 xl2:w-40' : 'text-center'
								}`}
							>
								{title && (
									<Component.HolText textType="heading3">
										{title}
									</Component.HolText>
								)}
								{subtitle && (
									<Component.HolText textType="baseLight1">
										{subtitle}
									</Component.HolText>
								)}
							</div>

							<div className="flex justify-center mt-3">
								{displayStyle === 'progress-bar' && (
									<Component.ProgressBar
										circleAdditionalParams={circleAdditionalParams}
										showPercentSign={isPercentageValue}
										kpiValue={kpiValue}
										allowNegativeValues
										showPlusOrMinusSign
										color={Util.getDifferenceMetricColor(kpiValue, identifier)}
									/>
								)}

								{displayStyle === 'large-number' && (
									<div
										className="text-8xl font-new-spirit font-medium"
										style={{
											color: Util.getDifferenceMetricColor(
												kpiValue,
												identifier
											)
										}}
									>
										{Util.scoreDisplayText(kpiValue, isPercentageValue, true)}
									</div>
								)}
							</div>

							{showHistoricSplineChart && (
								<div className="relative mx-auto w-48">
									<HighchartsReact
										highcharts={Highcharts}
										options={Util.splineChartConfig(
											splineData,
											Util.getDifferenceMetricColor(kpiValue, identifier),
											120,
											-1,
											11
										)}
									/>
								</div>
							)}
						</div>
					);
				}
			}}
		</Execute>
	);
};
