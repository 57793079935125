import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { IPositiveAttributeFilter, newAbsoluteDateFilter } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { IFactor, KpiValueType } from 'types';

/**
 * Component for displaying a difference between most recent and action plan starting data points concisely
 * @author Konstantin Krumin
 * @param factor - selected factor
 * @param actionPlanStartDate - starting date for the action plan
 * @param demographicFilter - additional filtering for selected demographic options
 */
interface ActionPlanOverviewScoreDisplayProps {
	factor: IFactor;
	actionPlanStartDate: Date;
	demographicFilter: IPositiveAttributeFilter[];
}

export const ActionPlanOverviewScoreDisplay: React.FC<ActionPlanOverviewScoreDisplayProps> = ({
	factor,
	actionPlanStartDate,
	demographicFilter = []
}) => {
	const dateAsset = useTypedSelector(state => state.dataView.dateAsset);
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const dateFilter = newAbsoluteDateFilter(
		Ldm.DateDatasets.Date.identifier,
		new Date(actionPlanStartDate).toISOString().substr(0, 10),
		new Date()?.toISOString().substr(0, 10)
	);

	const filtersArr = [dateFilter, ...demographicFilter];

	return (
		<Execute
			filters={filtersArr}
			slicesBy={[dateAsset]}
			seriesBy={[factor.value]}
			workspace={currentWorkspace.id}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				let kpiValue: KpiValueType | undefined = undefined;

				// Get the latest data point from trendline to display
				if (typeof result !== 'undefined') {
					const data = result?.rawData().data();

					const unprocessedStartingScore = data[0];
					const unprocessedRecentScore = data[data.length - 1];

					if (unprocessedStartingScore && unprocessedRecentScore) {
						const startingScore = parseInt(unprocessedStartingScore.toString());
						const mostRecentScore = parseInt(unprocessedRecentScore.toString());

						kpiValue = mostRecentScore - startingScore;
					}
				}

				if (execution.isLoading) {
					return <LoadingComponent className="my-8" />;
				} else {
					return (
						<div className="flex justify-between items-center pb-1 space-x-6">
							<Component.HolText>{factor.label}</Component.HolText>

							<div
								className="w-9 h-9 rounded-full flex justify-center items-center"
								style={{
									backgroundColor: Util.getDifferenceMetricColor(
										kpiValue,
										factor.identifier!
									)
								}}
							>
								<Component.HolText textType="heading3" textColor="text-white">
									{kpiValue}
								</Component.HolText>
							</div>
						</div>
					);
				}
			}}
		</Execute>
	);
};
