import React from 'react';

/**
 * A badge component
 * @author Konstantin Krumin
 * @param text - text to be displayed inside the badge
 * @param textColor - color of the text inside the badge
 * @param backgroundColor - background color of the badge
 * @param styles - additional styling for the badge component such as positioning
 */
interface HolBadgeProps {
	text: string;
	styles?: string;
	textColor?: string;
	backgroundColor?: string;
}

export const HolBadge: React.FC<HolBadgeProps> = ({
	text,
	styles,
	textColor = 'white',
	backgroundColor = 'hol-orange'
}) => {
	return (
		<span
			className={`inline-block px-2 py-1 text-sm font-medium rounded-full bg-${backgroundColor} text-${textColor} ${styles}`}
		>
			{text}
		</span>
	);
};
