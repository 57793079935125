import React, { useState } from 'react';

import { ActionPlanApi } from 'api';
import { ActionType, IActionPlan } from 'types';
import { useTypedSelector, useActions } from 'hooks';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

/**
 * Handles action plan edit / delete functionality
 * @author Konstantin Krumin
 * @param actionPlan - selected action plan details
 */
interface ActionPlanActionsProps {
	actionPlan: IActionPlan;
}

export const ActionPlanActions: React.FC<ActionPlanActionsProps> = ({ actionPlan }) => {
	const [actionType, setActionType] = useState<ActionType>('edit');
	const [openModal, setOpenModal] = useState(false);

	const Api = ActionPlanApi();

	const { userId, currentWorkspace } = useTypedSelector(state => state.user);
	const { currentPage, itemsPerPage, actionPlans } = useTypedSelector(
		state => state.actionPlanning
	);

	const { deleteActionPlan, setActionPlans, setTotalActionPlansCount, setActionPlanCurrentPage } =
		useActions();

	const duplicateHandler = () => {
		setOpenModal(true);
		setActionType('duplicate');
	};

	const editHandler = () => {
		setOpenModal(true);
		setActionType('edit');
	};

	const archiveHandler = () => {
		Api.updateActionPlanArchivingStatus(currentWorkspace.id, userId, actionPlan.uuid!, true)
			.then(() => {
				Analytics.archiveActionPlan();

				const pageNum = Util.setupPageNumToDisplay(actionPlans.length, currentPage);
				setActionPlanCurrentPage(pageNum);

				Api.getActionPlans(currentWorkspace.id, userId, false, pageNum, itemsPerPage)
					.then(result => {
						setActionPlans(result.results);
						setTotalActionPlansCount(result.length);
					})
					.catch(err => Util.logErrorMessage(err));
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const deleteHandler = () => {
		Api.deleteActionPlan(currentWorkspace.id, userId, actionPlan.uuid!)
			.then(() => {
				deleteActionPlan(actionPlan.uuid!);
				Analytics.deleteExistingActionPlan();
			})
			.then(() => {
				const pageNum = Util.setupPageNumToDisplay(actionPlans.length, currentPage);
				setActionPlanCurrentPage(pageNum);

				Api.getActionPlans(currentWorkspace.id, userId, false, pageNum, itemsPerPage)
					.then(result => {
						setActionPlans(result.results);
						setTotalActionPlansCount(result.length);
					})
					.catch(err => Util.logErrorMessage(err));
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<div className="relative flex justify-end">
			<Component.HolThreeDotMenu
				options={[
					{
						label: 'Duplicate',
						onClick: duplicateHandler
					},
					{
						label: 'Archive',
						onClick: archiveHandler
					},
					{
						label: 'Delete',
						onClick: deleteHandler
					},
					{
						label: 'Edit',
						onClick: editHandler
					}
				]}
			/>
			{openModal && (
				<Component.ActionPlanOverlay
					openModal
					type={actionType}
					defaultActionPlan={actionPlan}
					openModalCallback={setOpenModal}
				/>
			)}
		</div>
	);
};
