import { RoleType } from 'types';

import ActionType from '../action-types';
import { SetRoleUpdateAction, RemoveRoleUpdateAction, ResetRoleUpdateAction } from '../actions';

/**
 * setting the actions and payload for data view
 * @param dataView - the specified data view (week, biWeek, ....)
 */

export const setUserRolesUpdate = (
	userProfile: string,
	userRole: RoleType,
	email: string
): SetRoleUpdateAction => {
	return {
		type: ActionType.SET_ROLE_UPDATES,
		payload: {
			profile: userProfile,
			newRole: userRole,
			email: email
		}
	};
};

export const removeUserRoleUpdate = (userProfile: string): RemoveRoleUpdateAction => {
	return {
		type: ActionType.REMOVE_ROLE_UPDATE,
		payload: {
			profile: userProfile
		}
	};
};

export const resetRoleUpdates = (): ResetRoleUpdateAction => {
	return {
		type: ActionType.RESET_ROLE_UPDATES
	};
};
