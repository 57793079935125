import React from 'react';

/**
 * A component that contains card contents for the Diagnostics pages
 * @author Konstantin Krumin
 * @param tableStyling - additional styles for the table
 * @param showTableHead - defines whether the table head should be displayed
 * @param tableTitles - defines the titles to show in the table
 * @param tableValues - defines the values to display in the table (comes as an array of rows that contains data for each cell)
 */
interface HolTableProps {
	tableStyling?: string;
	showTableHead?: boolean;
	tableTitles: string[];
	tableValues: string[][];
}

export const HolTable: React.FC<HolTableProps> = ({
	tableTitles,
	tableValues,
	tableStyling = '',
	showTableHead = true
}) => {
	return (
		<table className={`table-auto ${tableStyling}`}>
			{showTableHead && (
				<thead>
					<tr>
						{tableTitles.map((tableTitle, idx) => (
							<th key={idx} className="font-sofia-bold text-base text-left px-3 py-1">
								{tableTitle}
							</th>
						))}
					</tr>
				</thead>
			)}

			<tbody className="divide-y divide-gray-300">
				{tableValues.map((tableRow, idx) => (
					<tr key={idx}>
						{tableRow.map((tableValue, idx) => (
							<td key={idx} className="px-3 py-1">
								{tableValue}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};
