import 'react-datepicker/dist/react-datepicker.css';

import React, { useState, useRef } from 'react';

import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import { useNavigate, useLocation } from 'react-router-dom';

import { LoadingComponent } from '@gooddata/sdk-ui';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import {
	IFactor,
	ActionType,
	IActionPlan,
	IDiagnostic,
	IGeneralOption,
	IDemographicFilter,
	IDemographicSelected
} from 'types';
import { ActionPlanApi } from 'api';
import { WorkspaceType } from 'enums';
import { useActions, useTypedSelector } from 'hooks';
import { DIAGNOSTICS, DIAGNOSTICS_LIGHT, FACTORS } from 'constants/dataset';

import { ActionKPISelect } from './components';

/**
 * Overlay component that allows users to create / edit action plans
 * @author Konstantin Krumin
 * @param type - a type of action being applied (create / duplicate / edit / delete)
 * @param openModal - a boolean that determines whether a modal should be opened
 * @param defaultActionPlan - an optional parameter to pass an existing action plan details (used only for editing)
 * @param openModalCallback - a callback to open a modal from outside of the component for editing
 */
interface ActionPlanOverlayProps {
	type: ActionType;
	openModal?: boolean;
	defaultActionPlan?: IActionPlan;
	openModalCallback?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ActionPlanOverlay: React.FC<ActionPlanOverlayProps> = ({
	type,
	openModal,
	defaultActionPlan,
	openModalCallback
}) => {
	const defaultKPIs = Util.parseDefaultKPIValues(defaultActionPlan?.data);
	const defaultFactors = Util.parseDefaultFactorValues(defaultActionPlan?.data);
	const defaultDiagnostics = Util.parseDefaultDiagnosticValues(defaultActionPlan?.data);
	const defaultDemographics = Util.parseDefaultDemographicValues(defaultActionPlan?.data);
	const defaultSelectedDemo = Util.parseDefaultSelectedDemographicValues(defaultActionPlan?.data);

	const setHeaderText = (type: ActionType) => {
		switch (type) {
			case 'duplicate':
				return 'Duplicate Action Plan';
			case 'edit':
				return 'Edit Action Plan';
			default:
				return 'New Action Plan';
		}
	};

	const navigate = useNavigate();
	const location = useLocation();

	const [isOpenModal, setOpenModal] = useState(openModal ? openModal : false);
	const [createLoading, setCreateLoading] = useState(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);

	const actionPlanName = useRef<string>(
		defaultActionPlan?.actionPlanName ? defaultActionPlan?.actionPlanName : ''
	);
	const detailedSummary = useRef<string>(
		defaultActionPlan?.detailedSummary ? defaultActionPlan?.detailedSummary : ''
	);
	const [startDate, setStartDate] = useState<Date>(
		defaultActionPlan?.startDate ? new Date(defaultActionPlan?.startDate) : new Date()
	);
	const [endDate, setEndDate] = useState<Date>(
		defaultActionPlan?.endDate ? new Date(defaultActionPlan?.endDate) : new Date()
	);
	const factors = useRef<IFactor[]>(defaultFactors);

	const demographics = useRef<IDemographicSelected[]>(defaultDemographics);
	const [selectedDemographics, setSelectedDemographics] = useState<{
		[key: string]: IDemographicFilter;
	} | null>(defaultSelectedDemo);

	const kpis = useRef<IGeneralOption | undefined>(defaultKPIs);

	const diagnostics = useRef<IDiagnostic[]>(defaultDiagnostics);

	const [checkShared, setCheckShared] = useState<boolean>(defaultActionPlan?.isShared ?? false);

	const { userId, currentWorkspace } = useTypedSelector(state => state.user);
	const { itemsPerPage, currentPage } = useTypedSelector(state => state.actionPlanning);

	const {
		setActionPlan,
		setActionPlans,
		setTotalActionPlansCount,
		setActionPlanCurrentPage,
		createOrDuplicateOrEditActionPlan
	} = useActions();

	const Api = ActionPlanApi();

	// handler for the selected factors
	const factorsHandler = (factorsSelected: IGeneralOption[] | null) => {
		if (factorsSelected) {
			factors.current = [...factorsSelected];
		} else {
			factors.current = [];
		}
	};

	// handler for the selected diagnostics
	const diagnosticsHandler = (diagnosticsSelected: IGeneralOption[] | null) => {
		if (diagnosticsSelected) {
			diagnostics.current = [...diagnosticsSelected];
		} else {
			diagnostics.current = [];
		}
	};

	const handleDemographicSelect = (
		selectedDemo: { [key: string]: IDemographicFilter } | null
	) => {
		let demographicFilter: IDemographicSelected[] = [];

		if (selectedDemo) {
			const demoKeys = Object.keys(selectedDemo);

			demoKeys.forEach(d => {
				const demoInfo = selectedDemo[d];

				demographicFilter.push({
					groupsSelected: demoInfo.demographicSubGroups,
					attribute: demoInfo.demographicLdm.attribute,
					demographicLabel: d
				} as IDemographicSelected);
			});
		}

		demographics.current = demographicFilter;
		setSelectedDemographics(selectedDemo);
	};

	const cleanOverlayData = () => {
		actionPlanName.current = '';
		detailedSummary.current = '';
		demographics.current = [];

		factors.current = [];
		kpis.current = undefined;
		diagnostics.current = [];

		setSelectedDemographics({});
		setStartDate(new Date());
		setEndDate(new Date());
		setCheckShared(false);

		setFormErrors([]);
	};

	const cancelHandler = () => {
		setOpenModal(false);
		openModalCallback && openModalCallback(false);

		cleanOverlayData();
	};

	const confirmHandler = () => {
		let errors = [];

		if (actionPlanName?.current.trim() === '') {
			errors.push('You must have an action plan name.');
		}

		if (detailedSummary.current.trim().length > 120) {
			errors.push('Detailed summary should be no longer than 120 characters.');
		}

		if (factors?.current.length === 0 && diagnostics?.current.length === 0 && !kpis?.current) {
			errors.push('There should be at least 1 Factor, Diagnostic or KPI selected.');
		}

		if (!startDate) {
			errors.push('You must have a start date.');
		}

		if (!endDate) {
			errors.push('You must have an end date.');
		}

		if (endDate < startDate) {
			errors.push('Start date should be earlier than end date.');
		}

		setFormErrors(errors);

		if (errors.length > 0) {
			return;
		}

		setCreateLoading(true);

		const factorsValues = factors.current.map(hol => {
			return hol.value;
		});

		const diagnosticsValues = diagnostics.current.map(diagnostic => {
			return diagnostic.value;
		});

		const dataJson = JSON.stringify({
			factors: factorsValues,
			diagnostics: diagnosticsValues,
			operation: kpis.current,
			selectedDemographics: selectedDemographics,
			demographics: demographics.current
		});

		if (type === 'create' || type === 'duplicate') {
			const actionPlanDetails: IActionPlan = {
				userId: userId,
				workspaceId: currentWorkspace.id,
				actionPlanName: actionPlanName.current,
				detailedSummary: detailedSummary.current,
				isShared: checkShared,
				data: dataJson,
				startDate,
				endDate
			};

			Api.createActionPlan(actionPlanDetails)
				.then(result => {
					createOrDuplicateOrEditActionPlan(result);
					setActionPlanCurrentPage(1);

					type === 'duplicate'
						? Analytics.duplicateActionPlan()
						: Analytics.createNewActionPlan();
				})
				.then(() => {
					Api.getActionPlans(currentWorkspace.id, userId, false, 1, itemsPerPage)
						.then(result => {
							setActionPlans(result.results);
							setTotalActionPlansCount(result.length);
						})
						.catch(err => Util.logErrorMessage(err));
				})
				.then(() => {
					if (type === 'duplicate' && location.pathname.includes('/action-plan/')) {
						navigate('/action-plans');
					}
				})
				.catch(err => Util.logErrorMessage(err));
		} else {
			const actionPlanDetails: IActionPlan = {
				actionPlanName: actionPlanName.current,
				detailedSummary: detailedSummary.current,
				isShared: checkShared,
				data: dataJson,
				startDate,
				endDate
			};

			Api.updateActionPlan(
				currentWorkspace.id,
				userId,
				defaultActionPlan?.uuid!,
				actionPlanDetails
			)
				.then(result => {
					createOrDuplicateOrEditActionPlan(result);
					Analytics.editExistingActionPlan();
				})
				.then(() => {
					Api.getActionPlans(
						currentWorkspace.id,
						userId,
						false,
						currentPage,
						itemsPerPage
					)
						.then(result => setActionPlans(result.results))
						.catch(err => Util.logErrorMessage(err));

					Api.getActionPlan(currentWorkspace.id, userId, defaultActionPlan?.uuid!)
						.then(result => setActionPlan(result[0]))
						.catch(err => Util.logErrorMessage(err));
				})
				.catch(err => Util.logErrorMessage(err));
		}

		setCreateLoading(false);

		setOpenModal(false);
		openModalCallback && openModalCallback(false);

		cleanOverlayData();
	};

	return (
		<>
			{type === 'create' && (
				<div
					className="h-full border-2 rounded-md border-font-light flex justify-center items-center cursor-pointer"
					style={{ minHeight: 450 }}
					onClick={() => setOpenModal(true)}
				>
					<div className="flex flex-col justify-center items-center text-regXL font-sofia-regular text-font-light">
						+ Add Action Plan
					</div>
				</div>
			)}
			<Modal
				isOpen={isOpenModal}
				className="bg-white w-11/12 sm:max-w-lg md2:max-w-2xl mx-auto my-auto rounded shadow-lg p-6 z-50 "
				overlayClassName="fixed flex bg-gray-50 bg-opacity-40 inset-0 h-screen w-screen z-50 overflow-auto"
				ariaHideApp={false}
			>
				<div className="pb-6">
					<Component.HolText textType="heading1">{setHeaderText(type)}</Component.HolText>
				</div>

				{createLoading ? (
					<div className="h-full w-full flex flex-col space-y-2 justify-center items-center my-6">
						<LoadingComponent />
					</div>
				) : (
					<>
						{formErrors.length > 0 && (
							<div className="text-red">
								<p>There were the following error(s):</p>
								{formErrors.map((err, idx) => {
									return <p key={`err_${idx}`}>{err}</p>;
								})}
							</div>
						)}

						<div className="my-3 space-y-2 max-w-lg">
							<Component.HolText textType="heading3">Name</Component.HolText>

							<Component.HolInput
								type="text"
								defaultValue={actionPlanName.current}
								onValueChange={value => (actionPlanName.current = value)}
							/>
						</div>

						<div className="my-3 space-y-2 max-w-lg">
							<div className="flex items-center space-x-2">
								<Component.HolText textType="heading3">
									Detailed Summary
								</Component.HolText>

								<Component.HolText textType="baseLight1">
									(120 Characters Max)
								</Component.HolText>
							</div>

							<Component.HolInput
								type="textarea"
								defaultValue={detailedSummary.current}
								onValueChange={value => (detailedSummary.current = value)}
							/>
						</div>

						{currentWorkspace.type === WorkspaceType.ADVANCED && (
							<div className="my-3 space-y-2 max-w-lg">
								<Component.HolText textType="heading3">
									Demographics
								</Component.HolText>

								<Component.DemographicSelect
									selectedDemographics={selectedDemographics}
									setDemographicSelected={(
										d: { [key: string]: IDemographicFilter } | null
									) => handleDemographicSelect(d)}
								/>
							</div>
						)}

						{currentWorkspace.type === WorkspaceType.ADVANCED && (
							<div className="my-3 space-y-2 max-w-lg">
								<Component.HolText textType="heading3">Factors</Component.HolText>

								<Component.HolMultiSelectDropdown
									options={FACTORS}
									selectedValues={factors.current}
									handleSelect={factorsHandler}
								/>
							</div>
						)}

						<div className="my-3 space-y-2 max-w-lg">
							<Component.HolText textType="heading3">KPIs</Component.HolText>

							<ActionKPISelect
								defaultValue={kpis.current}
								onKPISelect={result => (kpis.current = result)}
							/>
						</div>

						<div className="my-3 space-y-2 max-w-lg">
							<Component.HolText textType="heading3">Diagnostics</Component.HolText>

							<Component.HolMultiSelectDropdown
								options={
									currentWorkspace.type === WorkspaceType.LIGHT
										? DIAGNOSTICS_LIGHT
										: DIAGNOSTICS
								}
								selectedValues={diagnostics.current}
								handleSelect={diagnosticsHandler}
							/>
						</div>

						<div className="my-3 max-w-sm grid grid-cols-12 gap-6">
							<div className="space-y-2 col-span-6">
								<Component.HolText textType="heading3">
									Start Date
								</Component.HolText>

								<div className="flex justify-between">
									<DatePicker
										selected={startDate}
										onChange={date => setStartDate(date as Date)}
										className="w-full text-center border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-default focus:border-transparent"
									/>
								</div>
							</div>

							<div className="space-y-2 col-span-6">
								<Component.HolText textType="heading3">End Date</Component.HolText>

								<div className="flex justify-between">
									<DatePicker
										selected={endDate}
										onChange={date => setEndDate(date as Date)}
										className="w-full text-center border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-default focus:border-transparent"
									/>
								</div>
							</div>
						</div>

						<div className="flex justify-between items-center mt-5 space-y-3 flex-col xs:flex-row">
							<div className="flex items-center space-x-2">
								<Component.HolText textType="heading3">
									Share With Team
								</Component.HolText>

								<input
									type="checkbox"
									checked={checkShared}
									className="form-checkbox h-4 w-4 focus:outline-none rounded text-hol-dark-purple"
									onChange={e => setCheckShared(e.target.checked)}
								/>
							</div>

							<div className="flex justify-end items-center space-x-5 pr-3">
								<Component.HolButton
									label="Cancel"
									type="secondary"
									onClick={cancelHandler}
								/>

								<Component.HolButton
									type="primary"
									label={type === 'edit' ? 'Update' : 'Create'}
									onClick={confirmHandler}
								/>
							</div>
						</div>
					</>
				)}
			</Modal>
		</>
	);
};
