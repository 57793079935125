import { HolmetricsDiagnosticType, HolmetricsFactorType } from 'types';

export const setDateToFirstDayOfMonth = (date: Date) => {
	return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const setActionPlanStartDate = (
	date: Date,
	identifier: HolmetricsFactorType | HolmetricsDiagnosticType
) => {
	const formattedDate = new Date(date);

	return identifier === 'eNPS' ? setDateToFirstDayOfMonth(formattedDate) : formattedDate;
};
