import React, { useState, useEffect } from 'react';

import { FaBan } from 'react-icons/fa';

import * as Util from 'utils';
import * as Component from 'components';

import { useActions } from 'hooks';
import { UserApi, RolesApi } from 'api';
import { useTypedSelector } from 'hooks';
import { IPopupMessage, IWorkspaceUserInfo } from 'types';

import { getRoleOptions, isHigherRole } from './helpers';

/**
 * This is a table displaying a list of users
 * @author Michaela Mempin
 * @param userList - list of users
 * @param handleActionMessage - a callback function that sets action message in the parent component
 * @param handleRefreshUsersList - a callback function that refreshes the user list in the parent component
 * @param handleUserDeactivation - a callback function that handles deactivating a user in the parent component
 */
interface UserTableProps {
	userList: IWorkspaceUserInfo[];
	handleRefreshUsersList: () => Promise<void>;
	handleActionMessage: (message: IPopupMessage) => void;
	handleUserDeactivation: (user: IWorkspaceUserInfo) => void;
}

export const UserTable: React.FC<UserTableProps> = ({
	userList,
	handleActionMessage,
	handleUserDeactivation,
	handleRefreshUsersList
}) => {
	const { resetRoleUpdates } = useActions();

	const [pageUserList, setPageUserList] = useState<IWorkspaceUserInfo[]>(userList);

	useEffect(() => {
		setPageUserList(userList);
	}, [userList]);

	const workspace = useTypedSelector(state => state.user.currentWorkspace);
	const rolesUpdateList = useTypedSelector(state => state.userManager);
	const currentRole = useTypedSelector(state => state.user.userInfo.role);

	const roleOptions = getRoleOptions(currentRole);

	const { role, profileId } = useTypedSelector(state => state.user.userInfo);

	const { updateUserRoles } = RolesApi();
	const { deactivateUser, deleteUserFromDomain } = UserApi();

	const handleDeactiveUser = (user: IWorkspaceUserInfo) => {
		if (user.profileId === profileId) {
			handleActionMessage({
				message: 'Unable to deactive yourself from the workspace.',
				type: 'danger'
			});
		} else if (isHigherRole(role, user.userRole)) {
			deactivateUser(workspace.id, user.profileId)
				.then(() => {
					return deleteUserFromDomain(user.profileId);
				})
				.then(() => {
					handleUserDeactivation(user);
					handleRefreshUsersList();
					return;
				})
				.catch(err => {
					return err;
				});
		} else {
			handleActionMessage({
				message: 'Unable to deactive user of higher role.',
				type: 'danger'
			});
		}
	};

	const handleChangeRoles = () => {
		const userIds = Object.keys(rolesUpdateList);

		Promise.all(
			userIds.map(profileId => {
				const roleInfo = rolesUpdateList[profileId]!;
				return updateUserRoles(roleInfo.newRole, profileId, workspace.id);
			})
		)
			.then(() => {
				handleActionMessage({
					message: 'User roles have been updated.',
					type: 'users'
				});

				// Clear list of users to update roles
				resetRoleUpdates();
				handleRefreshUsersList();
			})
			.catch(err => {
				Util.logErrorMessage(err);
			});
	};

	return (
		<div className="bg-white pb-6 px-10 w-full prototype:w-3/4 colxxl:w-7/12 rounded-md">
			{userList.length > 0 ? (
				<div className="text-left text-font-title rounded-md">
					<table className="table-auto w-full">
						<thead>
							<tr className="h-14">
								<th className="w-11"></th>
								<th className="text-lg font-sofia-bold w-1/3">Full Name</th>
								<th className="text-lg font-sofia-bold w-1/3">Email Address</th>
								<th className="text-lg font-sofia-bold w-1/4">Role</th>
							</tr>
						</thead>
						<tbody className="text-sm font-sofia-reg">
							{pageUserList.map(user => (
								<tr
									key={user.profileId}
									className="h-14 group border-t-1 border-font-light"
								>
									<td className="border-8 border-white cursor-pointer">
										<div className="flex flex-row relative justify-center">
											<div className="absolute transform transition delay-100 scale-0 ease-in-out group-hover:scale-100 duration-300">
												<FaBan
													onClick={() => handleDeactiveUser(user)}
													className="text-gray-400 hover:text-hol-red mt-1 text-regXL duration-150"
												/>
											</div>
											<div className="transform transition ease-in-out group-hover:scale-0 duration-300">
												<Component.HolAvatar
													userName={`${user.firstname} ${user.lastname}`}
													textSize="text-base"
													size={34}
												/>
											</div>
										</div>
									</td>
									<td>
										{user.firstname} {user.lastname}
									</td>
									<td>{user.email}</td>
									<td>
										{currentRole === 'Holmetrics' ||
										isHigherRole(currentRole, user.userRole) ? (
											<Component.UserRoleSelect
												defaultRole={user.userRole}
												user={user}
												options={roleOptions}
											/>
										) : (
											user.userRole
										)}
									</td>
									<td className="border-t-2 border-white"></td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<Component.HolText textType="heading3">No users found</Component.HolText>
			)}
			<div className="flex justify-end my-1">
				<Component.HolButton
					label="Update"
					customEdge="rounded-3xl"
					type="users"
					onClick={handleChangeRoles}
				/>
			</div>

			<Component.StatefulPagination
				bgColor="bg-users-title"
				iconColor="text-users-title"
				itemsPerPage={10}
				itemList={userList}
				arrayName="user(s)"
				setPageList={setPageUserList}
			/>
		</div>
	);
};
