import { useState, useRef } from 'react';
import moment from 'moment';

import * as Component from 'components';

import { ICommentInfo } from 'types';
import { useTypedSelector } from 'hooks';

/**
 * This a component responsible for the UI and actions related to a particular comment
 * @author Konstantin Krumin
 * @param commentInfo - information on the current comment
 * @param onEdit - a callback function that handles editing a comment
 * @param onDelete - a callback function that handles deleting a comment
 */
interface CommentRowProps {
	commentInfo: ICommentInfo;
	onDelete: (commentInfoUuid: string) => void;
	onEdit: (commentInfoUuid: string, editedComment: string) => void;
}

export const CommentRow: React.FC<CommentRowProps> = ({ commentInfo, onDelete, onEdit }) => {
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const comment = useRef<string>(commentInfo.comment);

	const { userId } = useTypedSelector(state => state.user);

	const handleEdit = (commentInfoUuid: string) => {
		onEdit(commentInfoUuid, comment.current);
		setIsEditMode(false);
	};

	const handleDelete = (commentInfoUuid: string) => {
		onDelete(commentInfoUuid);
	};

	return (
		<tr className="align-top">
			<td className="px-6 py-4 whitespace-wrap">{commentInfo.fullName}</td>
			<td className="px-6 py-4 whitespace-wrap">{commentInfo.question}</td>
			<td className="px-6 py-4 whitespace-wrap w-1/3">
				{isEditMode ? (
					<Component.HolInput
						type="textarea"
						textareaMaxLength={1000}
						defaultValue={comment.current}
						onValueChange={value => (comment.current = value)}
					/>
				) : (
					commentInfo.comment
				)}
			</td>
			<td className="px-6 py-4 whitespace-wrap">
				{moment(commentInfo.createdAt).format('MMM DD YYYY hh:mm:ss a')}
			</td>
			<td className="px-6 py-4 whitespace-wrap flex justify-center space-x-3">
				{commentInfo.userId === userId && (
					<>
						{isEditMode ? (
							<>
								<Component.HolButton
									type="primary"
									label="Confirm"
									onClick={() => handleEdit(commentInfo.uuid)}
								/>
								<Component.HolButton
									label="Cancel"
									type="secondary"
									onClick={() => setIsEditMode(false)}
								/>
							</>
						) : (
							<>
								<Component.HolButton
									label="Edit"
									type="primary"
									onClick={() => setIsEditMode(true)}
								/>
								<Component.HolButton
									label="Delete"
									type="secondary"
									onClick={() => handleDelete(commentInfo.uuid)}
								/>
							</>
						)}
					</>
				)}
			</td>
		</tr>
	);
};
