import { IMeasure } from '@gooddata/sdk-model/esm/execution/measure';

import { ActionPlanDaysDirection } from 'enums';
import { DIAGNOSTICS, FACTORS } from 'constants/dataset';
import { IDemographicSelected, IGeneralOption, IDemographicFilter } from 'types';

const determineDaysQuantity = (baseDateInMs: number, todayInMs: number) => {
	return Math.round((baseDateInMs - todayInMs) / (1000 * 3600 * 24));
};

/**
 * Utility function that calculates the difference between current date and start or end date (in days) and determines a direction for textual description
 * @author Konstantin Krumin
 * @param startDate - a start date for the action plan
 * @param endDate - an end date for the action plan
 */
export const calculateDifferenceInDays = (startDate: Date, endDate: Date) => {
	const todayInMs = new Date().getTime();

	const startDateInMs = new Date(startDate).getTime();
	const endDateInMs = new Date(endDate).getTime();

	if (startDateInMs > todayInMs && endDateInMs > todayInMs) {
		return {
			days: determineDaysQuantity(startDateInMs, todayInMs),
			subText: ActionPlanDaysDirection.DAYS_UNTIL_START,
			isActive: false
		};
	} else if (startDateInMs <= todayInMs && endDateInMs >= todayInMs) {
		return {
			days: determineDaysQuantity(endDateInMs, todayInMs),
			subText: ActionPlanDaysDirection.DAYS_REMAINING,
			isActive: true
		};
	} else {
		return {
			days: -1,
			subText: '',
			isActive: false
		};
	}
};

export const parseDefaultDemographicValues = (data: string | undefined) => {
	if (data) {
		const defaultData = JSON.parse(data);

		return defaultData.demographics as IDemographicSelected[];
	}

	return [];
};

export const parseDefaultSelectedDemographicValues = (data: string | undefined) => {
	if (data) {
		const defaultData = JSON.parse(data);

		return defaultData.selectedDemographics as { [key: string]: IDemographicFilter };
	}

	return {};
};

export const parseDefaultFactorValues = (data: string | undefined) => {
	if (data) {
		const defaultData = JSON.parse(data);

		return FACTORS.filter(n => {
			const factor = defaultData.factors.map((n: IMeasure) => n.measure?.localIdentifier);
			return factor.indexOf(n.value.measure.localIdentifier) !== -1;
		});
	}

	return [];
};

export const parseDefaultDiagnosticValues = (data: string | undefined) => {
	if (data) {
		const defaultData = JSON.parse(data);

		return DIAGNOSTICS.filter(n => {
			const diagnostic = defaultData.diagnostics.map(
				(n: IMeasure) => n.measure?.localIdentifier
			);
			return diagnostic.indexOf(n.value.measure.localIdentifier) !== -1;
		});
	}

	return [];
};

export const parseDefaultKPIValues = (data: string | undefined) => {
	if (data) {
		const defaultData = JSON.parse(data);

		if (defaultData.operation) {
			return defaultData.operation as IGeneralOption;
		} else {
			return undefined;
		}
	}

	return undefined;
};
