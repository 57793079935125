import React, { useState, Dispatch, SetStateAction } from 'react';

import { FaSearch, FaTimes, FaPlus } from 'react-icons/fa';

import { InputType } from './types';

/**
 * A default input component to be used throughout the app
 * @author Matthew Douglas & Misael Esperanzate
 * @param type - the input type
 * @param placeholder - the placeholder in the input if any
 * @param focusColor - the focus colour when user is using the input
 * @param onValueChange - when the user enters into input
 */

interface HolInputProps {
	type: string;
	placeholder?: string;
	focusColor?: string;
	defaultValue?: string;
	textareaMaxLength?: number;
	setCharacterCount?: Dispatch<SetStateAction<number>>;
	onValueChange: (value: string) => void;
	onSubmit?: (value: string) => void;
	inputType?: InputType;
	shape?: string;
	borderColor?: string;
}

export const HolInput: React.FC<HolInputProps> = ({
	type,
	focusColor,
	placeholder,
	defaultValue,
	onValueChange,
	onSubmit,
	setCharacterCount,
	inputType,
	textareaMaxLength,
	shape,
	borderColor = 'border-input-border'
}) => {
	const [input, setInput] = useState<string>(defaultValue ? defaultValue : '');
	const submitButton = () => {
		switch (inputType!) {
			case 'search':
				return <FaSearch />;
			case 'delete':
				return <FaTimes />;
			case 'add':
				return <FaPlus />;
			default:
				return 'Submit';
		}
	};
	const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setInput(e.target.value);
		onValueChange(e.target.value);
		setCharacterCount && setCharacterCount(e.target.value.length);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInput(e.target.value);
		onValueChange(e.target.value);
	};

	const handleEnterKeypress = (e: any) => {
		if (e.key === 'Enter') {
			onSubmit!(input);
		}
	};

	return (
		<>
			{type === 'textarea' ? (
				<textarea
					className={`w-full border border-input-border rounded p-1 focus:outline-none focus:ring-2 focus:ring-${
						focusColor ? focusColor : 'default'
					} focus:border-transparent`}
					rows={4}
					cols={4}
					value={input}
					placeholder={placeholder}
					onChange={handleTextareaChange}
					maxLength={textareaMaxLength ? textareaMaxLength : 1000}
				/>
			) : (
				<div className="relative w-full text-gray-600 font-sofia-regular">
					{inputType && (
						<span className="absolute inset-y-0 right-0 flex items-center pr-2">
							<button
								className="p-1 focus:outline-none focus:shadow-outline"
								onClick={() => {
									onSubmit!(input);
								}}
							>
								{submitButton()}
							</button>
						</span>
					)}

					<input
						type={type}
						value={input}
						placeholder={placeholder}
						className={`w-full border ${borderColor} ${
							shape ? shape : 'rounded'
						} text-base p-1.5 px-2  placeholder-gray-300 focus:outline-none focus:ring-1 focus:ring-${
							focusColor ? focusColor : 'default'
						} focus:border-transparent`}
						onChange={handleInputChange}
						onKeyPress={handleEnterKeypress}
					/>
				</div>
			)}
		</>
	);
};
