import { SVGIconProps } from 'types';

export const Support: React.FC<SVGIconProps> = ({ fill = '#666' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill={fill}>
		<g id="Group_1060" data-name="Group 1060" transform="translate(-1490.086 -1490.037)">
			<path
				id="Path_291"
				data-name="Path 291"
				d="M1510.087,1490.037h-11.492a.682.682,0,0,0-.75.744c0,1.32,0,2.641,0,3.961,0,.158-.043.2-.2.2-1.717-.005-3.434,0-5.151,0a3.343,3.343,0,0,0-.457.03,2.32,2.32,0,0,0-1.945,2.16c-.019,1.592-.013,3.184,0,4.777a1.125,1.125,0,0,0,1.445,1.023c.183-.058.184-.058.184.144q0,2.754,0,5.508a1.379,1.379,0,0,0,.892,1.355,1.3,1.3,0,0,0,1.493-.333c.081-.082.123-.075.2.005a1.3,1.3,0,0,0,.9.426,1.4,1.4,0,0,0,1.48-1.43V1497.94c0-.051,0-.1,0-.153a.285.285,0,0,1,.309-.306c.221-.005.442.007.663-.005.146-.008.194.031.191.186-.009.606,0,1.212,0,1.818a.683.683,0,0,0,.753.758h11.492a.681.681,0,0,0,.744-.75v-8.7A.682.682,0,0,0,1510.087,1490.037Zm-13.011,6.839a.923.923,0,0,0-.994,1q0,2.687,0,5.373t0,5.356a.783.783,0,1,1-1.565.034c-.007-.113-.005-.227-.005-.34q0-2.694,0-5.389a.311.311,0,0,0-.275-.348.337.337,0,0,0-.332.322,1.233,1.233,0,0,0,0,.136v1.462q0,1.93,0,3.86a1.4,1.4,0,0,1-.115.7.776.776,0,0,1-.839.379.805.805,0,0,1-.618-.686c-.005-.073,0-.147,0-.221V1498a.977.977,0,0,0-.02-.253.294.294,0,0,0-.579.027.777.777,0,0,0,0,.153c0,1.286-.009,2.573,0,3.859a.525.525,0,0,1-.513.59.542.542,0,0,1-.54-.579c0-.107,0-.215,0-.323q0-2.056,0-4.114a1.779,1.779,0,0,1,.528-1.336,1.6,1.6,0,0,1,1.143-.485h5.729c.675,0,1.349,0,2.023,0a.665.665,0,0,1,.666.563.684.684,0,0,1-.444.74.768.768,0,0,1-.285.032Zm13.175,2.512c0,.244,0,.246-.247.246h-11.34c-.236,0-.236,0-.236-.235,0-.584,0-1.168,0-1.752,0-.135.039-.173.173-.171.487.007.975.006,1.462,0a1.239,1.239,0,0,0,1.192-.726,1.209,1.209,0,0,0-.171-1.365,1.177,1.177,0,0,0-.932-.451c-.516-.009-1.031-.005-1.547,0-.125,0-.178-.021-.177-.165.005-1.3,0-2.606,0-3.91,0-.21.008-.217.223-.217h11.374c.225,0,.226,0,.226.226Z"
			/>
			<path
				id="Path_292"
				data-name="Path 292"
				d="M1494.2,1494.458a2.211,2.211,0,1,0-2.207-2.2A2.218,2.218,0,0,0,1494.2,1494.458Zm-.021-3.827a1.617,1.617,0,1,1,.03,3.233,1.617,1.617,0,0,1-.03-3.233Z"
			/>
			<path
				id="Path_293"
				data-name="Path 293"
				d="M1507.318,1495.31c-.612,0-1.224,0-1.836,0-.124,0-.168-.03-.165-.161.009-.317,0-.634,0-.952s0-.634,0-.952a.313.313,0,0,0-.357-.353,2.354,2.354,0,0,0-.3.026,2.719,2.719,0,0,0,.749,5.387,2.745,2.745,0,0,0,2.3-2.606C1507.709,1495.42,1507.6,1495.31,1507.318,1495.31Zm-.258.8a2.1,2.1,0,1,1-2.552-2.549c.208-.054.209-.053.209.152v.9c0,.312,0,.623,0,.935,0,.258.118.371.378.371.606,0,1.213.007,1.819,0C1507.084,1495.91,1507.094,1495.964,1507.06,1496.106Z"
			/>
			<path
				id="Path_294"
				data-name="Path 294"
				d="M1505.963,1492.009c-.251,0-.365.11-.365.36q0,1.129,0,2.259c0,.265.106.373.367.374h1.121c.385,0,.77,0,1.155,0a.306.306,0,0,0,.347-.358,3.3,3.3,0,0,0-.033-.37A2.719,2.719,0,0,0,1505.963,1492.009Zm1.875,2.39c-.5,0-1-.005-1.494,0-.115,0-.146-.041-.144-.149.006-.249,0-.5,0-.747s0-.487,0-.73c0-.106.012-.149.14-.123a2.133,2.133,0,0,1,1.609,1.616C1507.973,1494.383,1507.94,1494.4,1507.838,1494.4Z"
			/>
		</g>
	</svg>
);
