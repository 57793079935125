import { SVGIconProps } from 'types';

export const ActionPlans: React.FC<SVGIconProps> = ({ fill = '#666' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill={fill}>
		<g id="Group_1058" data-name="Group 1058" transform="translate(-1933.693 -1258.896)">
			<path
				id="Path_280"
				data-name="Path 280"
				d="M1943.055,1278.215h-8.045a.707.707,0,0,1-.273-.03c-.271-.107-.38-.29-.38-.649q0-8.634,0-17.268c0-.04,0-.081,0-.121a.567.567,0,0,1,.616-.58l12.977,0a.585.585,0,0,1,.635.628c-.013,1.177-.005,2.354,0,3.53a1.1,1.1,0,0,0,.01.19.355.355,0,0,0,.336.313.366.366,0,0,0,.336-.356c0-.04,0-.08,0-.121,0-1.182-.011-2.365,0-3.547a1.288,1.288,0,0,0-1.306-1.306c-4.326.014-8.652.007-12.978.007a1.217,1.217,0,0,0-1.29,1.288q0,4.343,0,8.686t0,8.7a1.791,1.791,0,0,0,.019.293,1.244,1.244,0,0,0,1.239,1.011c2.7-.013,5.41-.005,8.115-.006a.91.91,0,0,0,.138,0,.322.322,0,0,0,.251-.454C1943.377,1278.242,1943.227,1278.215,1943.055,1278.215Z"
			/>
			<path
				id="Path_281"
				data-name="Path 281"
				d="M1953.21,1274.976q-2.589-1.5-5.181-2.987a.256.256,0,0,1-.15-.257c.006-1,0-2.007,0-3.011s0-2.007,0-3.011c0-.293-.117-.406-.409-.408a5.809,5.809,0,0,0-.912.068,6.805,6.805,0,0,0-5.778,7.368,6.408,6.408,0,0,0,.608,2.237,6.722,6.722,0,0,0,3.448,3.344,6.783,6.783,0,0,0,8.526-2.731C1953.538,1275.307,1953.5,1275.14,1953.21,1274.976Zm-11.214-.288a6.277,6.277,0,0,1-.556-2.161,6.127,6.127,0,0,1,5-6.459c.192-.035.387-.053.581-.079.17-.022.183.046.182.186-.005.928,0,1.856,0,2.784s0,1.845,0,2.768a.265.265,0,0,1-.159.268q-2.4,1.375-4.788,2.766C1942.123,1274.838,1942.065,1274.842,1942,1274.688Zm10.59.878a6.239,6.239,0,0,1-5.041,2.654,6.142,6.142,0,0,1-5.013-2.609c-.1-.139-.082-.2.066-.281q2.4-1.375,4.787-2.765a.266.266,0,0,1,.311,0q2.418,1.407,4.846,2.8C1952.656,1275.428,1952.653,1275.471,1952.586,1275.566Z"
			/>
			<path
				id="Path_282"
				data-name="Path 282"
				d="M1954.986,1270.135a6.815,6.815,0,0,0-5.864-5.273c-.4-.05-.541.082-.541.484v2.888c0,.981,0,1.961,0,2.941a.485.485,0,0,0,.278.471c1.031.587,2.056,1.183,3.084,1.776.688.4,1.375.8,2.065,1.191a.329.329,0,0,0,.523-.165c.018-.036.037-.072.053-.109A6.623,6.623,0,0,0,1954.986,1270.135Zm-.883,3.609c-.04.11-.082.107-.172.055q-1.922-1.114-3.845-2.224c-.23-.132-.459-.265-.691-.392a.23.23,0,0,1-.133-.233c.005-.871,0-1.741,0-2.612s0-1.731,0-2.6c0-.146.047-.179.18-.154a6.132,6.132,0,0,1,4.661,8.156Z"
			/>
			<path
				id="Path_283"
				data-name="Path 283"
				d="M1946.924,1263.58a.334.334,0,0,0,.027-.629.57.57,0,0,0-.238-.03h-10.462c-.3,0-.469.128-.466.345s.171.339.472.339h10.428A.711.711,0,0,0,1946.924,1263.58Z"
			/>
			<path
				id="Path_284"
				data-name="Path 284"
				d="M1941.08,1266.341a.367.367,0,0,0,.353-.2.3.3,0,0,0-.04-.339.4.4,0,0,0-.353-.142q-2.4,0-4.806,0a.677.677,0,0,0-.237.036.329.329,0,0,0-.041.593.515.515,0,0,0,.267.056c.8,0,1.6,0,2.4,0h2.454Z"
			/>
			<path
				id="Path_285"
				data-name="Path 285"
				d="M1939.407,1268.964a.326.326,0,0,0-.37-.337h-2.868a.34.34,0,0,0-.38.332.344.344,0,0,0,.385.33q1.434,0,2.867,0A.327.327,0,0,0,1939.407,1268.964Z"
			/>
			<path
				id="Path_286"
				data-name="Path 286"
				d="M1936.2,1274.993h2.384c.257,0,.409-.131.407-.345s-.149-.336-.4-.337q-1.2,0-2.4,0c-.245,0-.4.139-.4.337S1935.949,1274.992,1936.2,1274.993Z"
			/>
			<path
				id="Path_287"
				data-name="Path 287"
				d="M1936.084,1272.132a1.091,1.091,0,0,0,.206.018c.645,0,1.29,0,1.935,0a1.1,1.1,0,0,0,.172-.011.348.348,0,0,0,.338-.362.362.362,0,0,0-.392-.309c-.358,0-.715,0-1.072,0-.374,0-.748,0-1.123,0a.335.335,0,0,0-.356.3A.321.321,0,0,0,1936.084,1272.132Z"
			/>
		</g>
	</svg>
);
