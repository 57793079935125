import React from 'react';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { DiagnosticsOptions } from 'enums';

import { SENTIMENT_LABELS, SENTIMENT_HEADLINE_METRICS } from './constants';

/**
 * The Sentiment tab on the Diagnostics page
 * @author Konstantin Krumin
 */
export const Sentiment: React.FC = () => {
	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const diagnosticDateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [diagnosticDateFilter.relativeDateFilter, ...demographicFilter];

	return (
		<div className="grid grid-cols-12 gap-6 print:block">
			<Component.HolCard hideHeader columns="col-span-full">
				<header className="flex flex-col md2:flex-row justify-between col-span-full sm:col-span-6 lg2:col-span-4 p-5 print:flex-row">
					<Component.HolText textType="heading1">
						{DiagnosticsOptions.SENTIMENT}
					</Component.HolText>

					<div className="flex flex-col items-center">
						<div className="flex space-x-3">
							{SENTIMENT_HEADLINE_METRICS.map(headlineMetric => {
								return (
									<Component.DiagnosticHeadlineDisplay
										textType="title"
										valueType="percentage"
										filters={filtersArr}
										textColor={headlineMetric.textColor}
										headlineMeasure={headlineMetric.measure}
									/>
								);
							})}
						</div>

						<Component.HolText textType="heading3">Average Sentiment</Component.HolText>
					</div>
				</header>

				<Component.StackedColumnChart
					labels={SENTIMENT_LABELS}
					chartName={DiagnosticsOptions.SENTIMENT}
					seriesBy={[Ldm.SemanticPercentByCategory]}
					slicesBy={[Ldm.DateDatasets.Date.MonthYear.Long, Ldm.SemanticType]}
				/>
			</Component.HolCard>

			<Component.SentimentHeatmapChart />
		</div>
	);
};
