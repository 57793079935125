import { SetSidebarToggleAction } from '../actions';
import ActionType from '../action-types';

/**
 * Determines whether to show or hide sidebar
 * @author Konstantin Krumin
 * @param toggle - the indicator if sidebar needs to show or hide
 */

export const setSidebarToggle = (toggle: boolean): SetSidebarToggleAction => {
	return {
		type: ActionType.SET_SIDEBAR_TOGGLE,
		payload: {
			toggle: toggle
		}
	};
};
