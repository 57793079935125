import '@gooddata/sdk-ui-charts/styles/css/main.css';

import * as Component from 'components';

import { IKPIFilter } from 'types';
import { useWorkspaceKpis } from 'hooks';

/**
 * An Execute component to grab the KPIs for the particular organization (used in the report)
 * @author Michaela Mempin
 * @param kpiSelected - selected KPIs
 * @param setKPISelect - callback function that changes a passed parameter in the parent component
 */
interface ChangeKPISelectProps {
	kpiSelected: { [key: string]: IKPIFilter } | null; // {'Department': ['Billable Hours'], 'Employee': ['Billable Hours']}
	setKPISelect: (d: { [key: string]: IKPIFilter }) => void;
}

export const ChangeKPISelect: React.FC<ChangeKPISelectProps> = ({ kpiSelected, setKPISelect }) => {
	const { operations, nestedOptions } = useWorkspaceKpis();

	const kpiOptionsSelected = () => {
		let selected: [string, string][] = [];

		if (kpiSelected && operations) {
			const kpis = Object.keys(operations);
			const granularities = Object.keys(kpiSelected);

			const g = granularities.map(g => {
				const kpiIds = kpiSelected[g].kpiIds;

				return kpiIds?.map(s => {
					const kpiName = kpis.filter(key => operations[key][g] === s);
					return [kpiName[0], g] as [string, string];
				});
			});

			selected = g.flat();
		}

		return selected;
	};

	const handleSelect = (group: string, subGroup: string) => {
		const copySelectedValues = { ...kpiSelected };

		if (!copySelectedValues[subGroup]) {
			copySelectedValues[subGroup] = {
				kpiIds: [],
				kpiNames: []
			};
		}

		if (operations) {
			const kpiId = operations[group][subGroup];
			// {'Department':['Billable Hours']}
			copySelectedValues[subGroup].kpiIds.push(kpiId);
			copySelectedValues[subGroup].kpiNames.push(group);

			setKPISelect(copySelectedValues);
		}
	};

	const handleRemove = (group: string, subGroup: string) => {
		const copySelectedValues = { ...kpiSelected };

		const { kpiIds, kpiNames } = copySelectedValues[subGroup];
		const nameI = kpiNames.indexOf(group);
		const idI = kpiIds.indexOf(group);

		kpiNames.splice(nameI, 1);
		kpiIds.splice(idI, 1);

		// After removing the option, the array is empty
		if (kpiNames.length === 0 || kpiIds.length === 0) {
			delete copySelectedValues[subGroup];
		}

		setKPISelect(copySelectedValues);
	};

	return (
		<div className="w-full">
			<Component.HolNestedMultiSelectDropdown
				handleNestedOptionSelect={(group: string, subGroup: string) =>
					handleSelect(group, subGroup)
				}
				handleNestedOptionRemove={(group: string, subGroup: string) =>
					handleRemove(group, subGroup)
				}
				nestedOptions={nestedOptions}
				defaultMultiSelected={kpiOptionsSelected()}
				setSelectedOptions={setKPISelect}
				showGroup
			/>
		</div>
	);
};
