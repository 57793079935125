import * as Util from 'utils';

import { RoleType } from 'types';
import { USER_ROLES } from 'constants/misc';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';

import { IGooddataUserRole, IGooddataWorkspaceRoleInfo, IGooddataWorkspaceRoles } from './types';

export const RolesApi = () => {
	const { gooddataAxios } = useAxiosPrivate();

	const getWorkspaceRoles = async (workspaceId: string | number) => {
		return gooddataAxios
			.get<IGooddataWorkspaceRoles>(
				`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/projects/${workspaceId}/roles`,
				{ withCredentials: true }
			)
			.then(res => {
				const data = res.data.projectRoles.roles;

				const rolePromise = data.map((role: string) => {
					return gooddataAxios.get<IGooddataWorkspaceRoleInfo>(
						`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}${role}`,
						{ withCredentials: true }
					);
				});

				return Promise.all(rolePromise);
			})
			.then(res => {
				let roles: any = {};

				res.forEach(role => {
					const roleId = role.data.projectRole.links.roleUsers;
					roles[roleId] = role.data.projectRole.meta.title;
				});

				return roles;
			})
			.catch(err => {
				Util.logErrorMessage(err);
			});
	};

	// Update user roles: https://help.gooddata.com/doc/enterprise/en/expand-your-gooddata-platform/api-reference#operation/changeUserRole
	const updateUserRoles = async (holRole: RoleType, profileId: string, workspaceId: string) => {
		const roleId = USER_ROLES[holRole].roleId;

		return gooddataAxios // Update roles on gooddata
			.post(
				`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/projects/${workspaceId}/roles/${roleId}/users`,
				{
					associateUser: {
						user: `/gdc/account/profile/${profileId}`
					}
				},
				{ withCredentials: true }
			)
			.then(res => {
				if (res.status === 200) {
					return {
						status: res.status,
						message: 'Updated role'
					};
				}

				return {
					status: res.status,
					message: 'Failed to update user role.'
				};
			})
			.catch(err => {
				return {
					status: err.status,
					message: err
				};
			});
	};

	const getCurrentRole = async (workspace: string, userProfileId: string) => {
		const currentRole = await gooddataAxios.get<IGooddataUserRole>(
			`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/projects/${workspace}/users/${userProfileId}/roles`
		);

		const roleUri = currentRole.data.associatedRoles.roles[0];
		const roleId = roleUri.split('/')[5];
		const roleName = Util.getHolmetricsRoleName(roleId);

		return roleName;
	};

	return {
		getWorkspaceRoles,
		updateUserRoles,
		getCurrentRole
	};
};
