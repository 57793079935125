import { ICommentInfo } from 'types';

import {
	SetInsightsCommentsAction,
	UpdateInsightsCommentAction,
	DeleteInsightsCommentAction,
	SetInsightsCommentsCountAction,
	SetInsightsCommentsCurrentPageAction
} from '../actions';
import ActionType from '../action-types';

/**
 * sets insights comments
 * @author Misael Esperanzate
 * @param comments - the comments of all the charts shared by the user
 */
export const setInsightsComments = (comments: ICommentInfo[]): SetInsightsCommentsAction => {
	return {
		type: ActionType.SET_INSIGHTS_COMMENTS,
		payload: {
			comments
		}
	};
};

export const deleteComment = (commentInfoUuid: string): DeleteInsightsCommentAction => {
	return {
		type: ActionType.DELETE_INSIGHTS_COMMENT,
		payload: {
			commentInfoUuid
		}
	};
};

export const updateComment = (
	commentInfoUuid: string,
	updatedComment: string
): UpdateInsightsCommentAction => {
	return {
		type: ActionType.UPDATE_INSIGHTS_COMMENT,
		payload: {
			commentInfoUuid,
			updatedComment
		}
	};
};

export const setInsightsCommentsCurrentPage = (
	currentPage: number
): SetInsightsCommentsCurrentPageAction => {
	return {
		type: ActionType.SET_INSIGHTS_COMMENTS_CURRENT_PAGE,
		payload: {
			currentPage
		}
	};
};

export const setInsightsCommentsCount = (
	totalInsightsCommentsCount: number
): SetInsightsCommentsCountAction => {
	return {
		type: ActionType.SET_INSIGHTS_COMMENTS_COUNT,
		payload: {
			totalInsightsCommentsCount
		}
	};
};
