import '@gooddata/sdk-ui-charts/styles/css/main.css';

import React from 'react';

import { IAttribute } from '@gooddata/sdk-model';
import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';
import * as Util from 'utils';

/**
 * Extracts time updated from Gooddata and maps through rows to get string
 * @author Misael Esperanzate
 * @param slicedBy - Time updated table for the specific page
 */
interface LastUpdatedProps {
	slicedBy: IAttribute;
}

export const LastUpdated: React.FC<LastUpdatedProps> = ({ slicedBy }) => {
	const generateDate = (date: string) => {
		const updatedDate = new Date(date.replace(/-/g, '/'));

		let resultDate: string;
		if (updatedDate > new Date()) {
			resultDate = new Date().toDateString().split(' ').slice(1).join(' ');
		} else {
			resultDate = updatedDate.toDateString().split(' ').slice(1).join(' ');
		}

		return resultDate;
	};

	return (
		<div className="inline-block">
			<Execute
				seriesBy={[Ldm.Score.Avg]}
				slicesBy={[slicedBy]}
				onError={errInfo => {
					if (errInfo.message !== 'CANCELLED') {
						Util.logErrorMessage(errInfo);
					}
				}}
			>
				{execution => {
					const { result } = execution;
					let loading = true;
					const slices = result?.data().slices().toArray();

					if (slices) {
						loading = false;
					}
					return (
						<div>
							{loading ? (
								<LoadingComponent />
							) : (
								<div>
									{slices?.map((slice, idx) => {
										return (
											<div key={idx}>
												<div>
													<p className="font-sofia-light text-font-date text-base">
														Last Updated:{' '}
														{generateDate(slice.sliceTitles()[0])}
													</p>
												</div>
											</div>
										);
									})}
								</div>
							)}
						</div>
					);
				}}
			</Execute>
		</div>
	);
};
