import { GoodDataSdkError } from '@gooddata/sdk-ui';

import ActionType from '../action-types';
import { SetShowErrorAction } from '../actions';

/**
 * Determines whether to show an error and what info to show
 * @author Konstantin Krumin
 * @param showError - boolean determining whether the error component show be showing
 * @param errorDetails - object with info about the error that occurred
 */

export const setError = (
	errorDetails: GoodDataSdkError | undefined,
	showError: boolean = true
): SetShowErrorAction => {
	return {
		type: ActionType.SET_SHOW_ERROR,
		payload: {
			showError: showError,
			errorDetails: errorDetails
		}
	};
};
