import React from 'react';

/**
 * Sets up style orientation for page on print
 * @author Konstantin Krumin
 * @param orientation - can be either portrait or landscape
 */
interface PrintOrientationProps {
	orientation: 'portrait' | 'landscape';
}

export const PrintOrientation: React.FC<PrintOrientationProps> = ({ orientation }) => {
	return (
		<style type="text/css" media="print">
			{`\
					@page { size: ${orientation}; }\
			`}
		</style>
	);
};
