import React, { useRef, useState } from 'react';

import { BsThreeDotsVertical } from 'react-icons/bs';

import { useOnClickOutside } from 'hooks';

/**
 * A default three dot menu component to be used throughout the app
 * @param color - the specified color when an option is hovered
 * @param options - the options to be displayed when the three dot menu is clicked
 */
interface HolThreeDotMenuProps {
	color?: string;
	options: { label: string; onClick: () => void }[];
}

export const HolThreeDotMenu: React.FC<HolThreeDotMenuProps> = ({ color, options }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const node = useRef<HTMLInputElement | null>(null);

	useOnClickOutside(node, () => setDropdownOpen(false));

	return (
		<div ref={node} className="relative r-0 print-hide">
			<div
				className="text-xl cursor-pointer"
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					setDropdownOpen(!dropdownOpen);
				}}
			>
				<BsThreeDotsVertical />
			</div>
			<div
				className={
					dropdownOpen
						? `cursor-pointer origin-top-right z-10 absolute top-full right-0 bg-white border border-gray-200 rounded shadow-lg overflow-hidden mt-1`
						: 'hidden'
				}
			>
				<ul>
					{options.map(option => {
						return (
							<li
								key={option.label}
								className={`${`px-4 py-1.5 cursor-pointer hover:${
									color ? color : 'bg-hol-dark-purple'
								} hover:text-white`} `}
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();

									option.onClick();
									setDropdownOpen(false);
								}}
							>
								{option.label}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
