import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionCreators } from '../../store';

/**
 * Custom hook to gain access to all actions from action-creator
 */
export const useActions = () => {
	const dispatch = useDispatch();

	return useMemo(() => {
		return bindActionCreators(actionCreators, dispatch);
	}, [dispatch]);
};
