import React from 'react';
import { NavLink } from 'react-router-dom';

import * as Util from 'utils';
import * as Component from 'components';

import { IActionPlan } from 'types';
import { useTypedSelector } from 'hooks';

import { ActionPlanActions, ActionPlanOverviewScoreDisplay } from './components';

/**
 * Action Plan's overview component
 * @author Konstantin Krumin
 * @param actionPlan - selected action plan details
 * @param disableActions - determines whether actions need to be disabled
 */
interface ActionPlanOverviewProps {
	actionPlan: IActionPlan;
	disableActions?: boolean;
}

export const ActionPlanOverview: React.FC<ActionPlanOverviewProps> = ({
	actionPlan,
	disableActions = false
}) => {
	const { userId } = useTypedSelector(state => state.user);

	const factors = Util.parseDefaultFactorValues(actionPlan?.data);
	const selectedDemographics = Util.parseDefaultDemographicValues(actionPlan?.data);

	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const daysInfo = Util.calculateDifferenceInDays(actionPlan?.startDate!, actionPlan?.endDate!);
	const daysColor = daysInfo.isActive ? 'text-hol-bright-blue' : 'text-hol-light-grey';

	return (
		<Component.HolCard
			styles="p-3"
			columns="col-span-full md2:col-span-6 lg2:col-span-4 xl2:col-span-3 print:col-span-6 print:shadow-none"
			titleElement={
				<>
					{actionPlan.isShared ? (
						<Component.HolSharedTooltip
							text="Shared Action Plan"
							sharedBy={actionPlan.userId!}
							sharedDate={actionPlan.createdAt?.toString()}
						/>
					) : (
						<></>
					)}
				</>
			}
			topRightElement={
				actionPlan.userId === userId && !disableActions ? (
					<div className="flex justify-end items-center text-right w-full">
						<ActionPlanActions actionPlan={actionPlan} />
					</div>
				) : (
					<></>
				)
			}
		>
			<NavLink to={`/action-plan/${actionPlan.uuid}`}>
				<div className="h-full">
					<div className="flex flex-col justify-center items-center space-y-2">
						<Component.HolText textType="heading3">
							{actionPlan.actionPlanName}
						</Component.HolText>

						{actionPlan?.createdAt && (
							<Component.HolText textType="baseLight1">
								Created on {Util.formatDate(actionPlan?.createdAt)}
							</Component.HolText>
						)}

						<div className="flex flex-col justify-center items-center">
							<Component.HolText
								styles="text-8xl font-new-spirit font-medium"
								textColor={daysColor}
							>
								{daysInfo.days > 0 ? daysInfo.days : '- -'}
							</Component.HolText>

							<Component.HolText textType="baseBold" textColor={daysColor}>
								{daysInfo.subText}
							</Component.HolText>
						</div>

						{factors.length !== 0 && (
							<div className="py-3 space-y-4">
								<Component.HolText textType="heading3">Results</Component.HolText>

								<div>
									{factors.map((factor, idx) => {
										return (
											<div key={idx} className="col-span-full">
												<ActionPlanOverviewScoreDisplay
													factor={factor}
													demographicFilter={demographicFilter}
													actionPlanStartDate={actionPlan.startDate!}
												/>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				</div>
			</NavLink>
		</Component.HolCard>
	);
};
