import { DataValue } from '@gooddata/sdk-backend-spi';
import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { DiagnosticsSubmetricsType } from 'types';

import { ParentWrapper } from './components';

/**
 * Component that handles displaying insight information for each question
 * @author Konstantin Krumin
 * @param submetrics - collection of meta information for extracting submetric values
 */
interface StackedBarChartProps {
	submetrics: DiagnosticsSubmetricsType;
}

export const StackedBarChart: React.FC<StackedBarChartProps> = ({ submetrics }) => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const submetricsInfoArr = submetrics.values.map(submetric => {
		return {
			title: submetric.title,
			color: submetric.color
		};
	});
	const submetricValuesArr = submetrics.values.map(submetric => submetric.value);

	return (
		<Execute
			seriesBy={[...submetricValuesArr]}
			filters={[...demographicFilter, submetrics.dateFilter]}
		>
			{execution => {
				const { result } = execution;

				const rawValues = result?.rawData().data() as DataValue[];

				const parsedValues =
					rawValues?.map(rawValue => {
						if (rawValue) {
							return Util.convertStrPercentageToIntNum(rawValue.toString());
						}

						return 0;
					}) ?? [];

				const textualData = submetricsInfoArr.map((submetric, idx) => {
					return {
						textLabel: submetric.title ?? '',
						value: parsedValues[idx] ?? 0,
						color: submetric.color ?? '#E4E4E4'
					};
				});

				const barchartData =
					textualData
						.filter(dataItem => dataItem.value > 0)
						.map(dataItem => {
							return {
								name: dataItem?.textLabel,
								data: [dataItem?.value],
								color: dataItem?.color
							};
						})
						.reverse() || [];

				if (execution.isLoading) {
					return (
						<ParentWrapper>
							<LoadingComponent className="my-8" />
						</ParentWrapper>
					);
				} else {
					return (
						<ParentWrapper>
							<div className="flex flex-col justify-center items-center h-12">
								<Component.HolText textType="heading2">
									{submetrics.title}
								</Component.HolText>
								{submetrics.subtitle && (
									<Component.HolText textType="heading3">
										{submetrics.subtitle}
									</Component.HolText>
								)}
							</div>

							<div className="flex space-x-3">
								{textualData.map((dataItem, idx) => {
									return (
										<div
											key={idx}
											className="flex flex-col justify-center items-center"
										>
											<Component.HolText textType="heading3">
												{dataItem?.value}%
											</Component.HolText>

											<Component.HolText>
												{dataItem?.textLabel}
											</Component.HolText>
										</div>
									);
								})}
							</div>

							<div>
								{barchartData.length > 0 && (
									<div className="flex justify-center">
										<HighchartsReact
											highcharts={Highcharts}
											options={Util.stackedBarchartConfig(
												barchartData,
												300,
												1
											)}
										/>
									</div>
								)}
							</div>
						</ParentWrapper>
					);
				}
			}}
		</Execute>
	);
};
