import { useState, useRef } from 'react';

import moment from 'moment';
import Modal from 'react-modal';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { ActionPlanApi } from 'api';
import { useOnClickOutside, useTypedSelector, useActions } from 'hooks';

/**
 * This is the modal that displays archived action plans
 * @author Konstantin Krumin
 */
interface ArchivedActionPlansOverlayProps {}

export const ArchivedActionPlansOverlay: React.FC<ArchivedActionPlansOverlayProps> = () => {
	const [isOpenModal, setOpenModal] = useState(false);

	const { currentWorkspace, userId } = useTypedSelector(state => state.user);
	const {
		currentPage,
		itemsPerPage,
		archivedActionPlans,
		archivedItemsPerPage,
		archivedCurrentPage,
		totalArchivedActionPlansCount
	} = useTypedSelector(state => state.actionPlanning);

	const {
		setActionPlans,
		setTotalActionPlansCount,
		deleteActionPlan,
		setArchivedActionPlans,
		setTotalArchivedActionPlansCount,
		setArchivedActionPlanCurrentPage
	} = useActions();

	const Api = ActionPlanApi();

	const node = useRef<HTMLInputElement | null>(null);

	useOnClickOutside(node, () => setOpenModal(false));

	const handleOpenArchivedActionPlansModal = () => {
		Api.getArchivedActionPlans(
			currentWorkspace.id,
			userId,
			archivedCurrentPage,
			archivedItemsPerPage
		)
			.then(result => {
				setArchivedActionPlans(result.results);
				setTotalArchivedActionPlansCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));

		setOpenModal(true);
	};

	const handleUnarchive = (actionPlanUuid: string) => {
		Api.updateActionPlanArchivingStatus(currentWorkspace.id, userId, actionPlanUuid, false)
			.then(() => {
				Analytics.unarchiveActionPlan();

				const pageNum = Util.setupPageNumToDisplay(
					archivedActionPlans.length,
					archivedCurrentPage
				);
				setArchivedActionPlanCurrentPage(pageNum);

				Api.getArchivedActionPlans(
					currentWorkspace.id,
					userId,
					pageNum,
					archivedItemsPerPage
				)
					.then(result => {
						setArchivedActionPlans(result.results);
						setTotalArchivedActionPlansCount(result.length);
					})
					.catch(err => Util.logErrorMessage(err));

				Api.getActionPlans(currentWorkspace.id, userId, false, currentPage, itemsPerPage)
					.then(result => {
						setActionPlans(result.results);
						setTotalActionPlansCount(result.length);
					})
					.catch(err => Util.logErrorMessage(err));
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const handleDelete = (actionPlanUuid: string) => {
		Api.deleteActionPlan(currentWorkspace.id, userId, actionPlanUuid)
			.then(() => {
				deleteActionPlan(actionPlanUuid);
				Analytics.deleteExistingActionPlan();
			})
			.then(() => {
				const pageNum = Util.setupPageNumToDisplay(
					archivedActionPlans.length,
					archivedCurrentPage
				);
				setArchivedActionPlanCurrentPage(pageNum);

				Api.getArchivedActionPlans(
					currentWorkspace.id,
					userId,
					pageNum,
					archivedItemsPerPage
				)
					.then(result => {
						setArchivedActionPlans(result.results);
						setTotalArchivedActionPlansCount(result.length);
					})
					.catch(err => Util.logErrorMessage(err));
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const handlePageChange = (updatedPageNum: number) => {
		setArchivedActionPlanCurrentPage(updatedPageNum);

		Api.getArchivedActionPlans(
			currentWorkspace.id,
			userId,
			updatedPageNum,
			archivedItemsPerPage
		)
			.then(result => {
				setArchivedActionPlans(result.results);
				setTotalArchivedActionPlansCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<>
			<div className="flex items-center">
				<div className="flex justify-end space-x-5">
					<Component.HolButton
						type="primary"
						label="Archived"
						onClick={handleOpenArchivedActionPlansModal}
					/>
				</div>
			</div>

			<Modal
				isOpen={isOpenModal}
				className="bg-white w-11/12 sm:max-w-3xl mx-auto my-auto rounded shadow-lg p-6 z-50"
				overlayClassName="fixed flex bg-gray-50 bg-opacity-40 inset-0 h-screen w-screen z-50 overflow-auto"
				ariaHideApp={false}
			>
				<div ref={node}>
					<Component.HolText textType="heading1">Archived Action Plans</Component.HolText>

					{archivedActionPlans.length > 0 ? (
						<>
							<div
								className="shadow overflow-auto border-b border-gray-200 my-4"
								style={{ maxHeight: '70vh' }}
							>
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-hol-light-blue sticky top-0">
										<tr>
											<th
												scope="col"
												className="px-6 py-3 text-left text-sm font-medium text-font-reg uppercase tracking-wider"
											>
												Action Plan Name
											</th>
											<th
												scope="col"
												className="px-6 py-3 text-left text-sm font-medium text-font-reg uppercase tracking-wider"
											>
												Created At
											</th>
											<th
												scope="col"
												className="px-6 py-3 text-left text-sm font-medium text-font-reg uppercase tracking-wider"
											></th>
										</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200">
										{archivedActionPlans.map(archivedActionPlan => {
											return (
												<tr key={archivedActionPlan.uuid}>
													<td className="px-6 py-4 whitespace-wrap">
														{archivedActionPlan.actionPlanName}
													</td>
													<td className="px-6 py-4 whitespace-wrap">
														{moment(
															archivedActionPlan.createdAt
														).format('MMMM DD, YYYY')}
													</td>
													<td className="px-6 py-4 whitespace-wrap flex justify-center space-x-3">
														{archivedActionPlan.userId === userId && (
															<>
																<Component.HolButton
																	type="primary"
																	label="Unarchive"
																	onClick={() =>
																		handleUnarchive(
																			archivedActionPlan.uuid
																		)
																	}
																/>
																<Component.HolButton
																	label="Delete"
																	type="secondary"
																	onClick={() =>
																		handleDelete(
																			archivedActionPlan.uuid
																		)
																	}
																/>
															</>
														)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>

							<Component.StatelessPagination
								arrayName="plan(s)"
								bgColor="bg-users-title"
								iconColor="text-users-title"
								itemsPerPage={archivedItemsPerPage}
								totalCount={totalArchivedActionPlansCount}
								currentListLength={archivedActionPlans.length}
								onChangePage={handlePageChange}
								currentPage={archivedCurrentPage}
							/>
						</>
					) : (
						<div className="flex justify-center p-12">
							<Component.HolText textType="heading3">
								There are no archived action plans...
							</Component.HolText>
						</div>
					)}

					<div className="flex justify-end mt-4">
						<Component.HolButton
							label="Close"
							type="secondary"
							onClick={() => {
								setOpenModal(false);
							}}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};
