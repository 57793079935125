import { ICommentInfo } from 'types';

import { Action } from '../actions';
import ActionType from '../action-types';

/**
 * This is the data needed for the insights comments
 * @author Misael Esperanzate
 */

interface InsightsCommentsState {
	comments: ICommentInfo[];
	currentPage: number;
	itemsPerPage: number;
	totalInsightsCommentsCount: number;
}

const initialState: InsightsCommentsState = {
	comments: [],
	currentPage: 1,
	itemsPerPage: 10,
	totalInsightsCommentsCount: 0
};

const insightsCommentsReducer = (state: InsightsCommentsState = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_INSIGHTS_COMMENTS:
			return {
				...state,
				comments: action.payload.comments
			};
		case ActionType.DELETE_INSIGHTS_COMMENT:
			const filteredComments = state.comments.filter(
				comment => comment.uuid !== action.payload.commentInfoUuid
			);

			return {
				...state,
				comments: filteredComments
			};
		case ActionType.SET_INSIGHTS_COMMENTS_COUNT:
			return {
				...state,
				totalInsightsCommentsCount: action.payload.totalInsightsCommentsCount
			};
		case ActionType.SET_INSIGHTS_COMMENTS_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload.currentPage
			};
		default:
			return state;
	}
};

export default insightsCommentsReducer;
