import React, { useState } from 'react';

import { IFilter, IMeasure } from '@gooddata/sdk-model';
import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import * as Util from 'utils';
import * as Component from 'components';

import { createReadablePercentageValue } from 'utils';
import { KpiValueType, TextType, ValueFormatType } from 'types';

/**
 * Custom headlines for diagnostics
 * @author Misael Esperanzate && Konstantin Krumin
 * @param textType - text type to be used
 * @param textColor - text color to be used
 * @param filters - The date chosen from drop down selection
 * @param headlineMeasure - The specified measure from Gooddata
 * @param additionalText - optional parameter for any text to show to the right of the value
 * @param valueType - determines the type of incoming value and decides on how to properly handle it
 */
interface DiagnosticHeadlineDisplayProps {
	textColor?: string;
	textType?: TextType;
	additionalText?: string;
	headlineMeasure: IMeasure;
	valueType?: ValueFormatType;
	filters: IFilter[] | undefined;
}

const DiagnosticHeadlineDisplay: React.FC<DiagnosticHeadlineDisplayProps> = ({
	filters,
	additionalText,
	headlineMeasure,
	valueType = 'float',
	textType = 'heading1',
	textColor = 'text-font-reg'
}) => {
	const [loading, setLoading] = useState(true);

	return (
		<div>
			<Execute
				filters={filters}
				seriesBy={[headlineMeasure]}
				onLoadingFinish={() => setLoading(false)}
				onError={errInfo => {
					if (errInfo.message !== 'CANCELLED') {
						Util.logErrorMessage(errInfo);
					}
				}}
			>
				{execution => {
					const { result } = execution;
					let headlineValue: KpiValueType = 0;

					// Get raw values then convert to float
					if (typeof result !== 'undefined') {
						if (valueType === 'percentage') {
							headlineValue = createReadablePercentageValue(result, 0);
						} else {
							const values = result?.rawData().data();
							const lastValue = values[values.length - 1];
							if (lastValue) {
								if (valueType === 'float') {
									// convert string to float with two decimal places
									headlineValue = parseFloat(
										parseFloat(lastValue!.toString()).toFixed(2)
									);
								} else {
									headlineValue = parseInt(lastValue!.toString());
								}
							}
						}
					}

					return (
						<>
							<div className="flex justify-center">
								{loading ? (
									<LoadingComponent />
								) : (
									<Component.HolText textType={textType} textColor={textColor}>
										{headlineValue.toLocaleString()}
										{valueType === 'percentage' && '%'}
										{additionalText}
									</Component.HolText>
								)}
							</div>
						</>
					);
				}}
			</Execute>
		</div>
	);
};

export default DiagnosticHeadlineDisplay;
