import { USER_ROLES } from 'constants/misc';
import { GooddataRolesType, RoleType } from 'types';

export const getHolmetricsRoleName = (roleId?: string, gdRole?: GooddataRolesType) => {
	const roleName = Object.entries(USER_ROLES).filter(([name, roleInfo]) => {
		return (!roleId || roleId === roleInfo.roleId) && (!gdRole || gdRole === roleInfo.gdRole);
	})[0];

	if (!roleName) {
		// If role does not match anywhere, user is set as 'viewer' by default
		return 'Organization Viewer';
	}

	return roleName[0] as RoleType;
};
