import React from 'react';

import * as Component from 'components';

/**
 * A textual component that shows instead of the heatmap when no data is returned
 * @param text
 */
interface NoDataTextProps {
	text?: string;
}

export const NoDataText: React.FC<NoDataTextProps> = ({
	text = "This chart can't be shown due to the low groups size."
}) => {
	return (
		<div className="m-5">
			<Component.HolText textType="heading3">{text}</Component.HolText>
		</div>
	);
};
