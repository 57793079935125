import React, { useMemo, useState } from 'react';

import { BsXCircle, BsQuestionCircle } from 'react-icons/bs';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { IFactorDataInfo } from 'types';
import { useTypedSelector } from 'hooks';

import { Description } from './components';

/**
 * A card containing factor related information
 * @author Matthew Douglas & Misael Esperanzate & Konstantin Krumin
 * @param factorInfo - information on the factor
 * @param isCardSelected - is this card currently selected
 * @param onSelectFactor - when the user selects (clicks) on the factor card
 */
interface FactorCardAdvancedProps {
	isCardSelected: boolean;
	factorInfo: IFactorDataInfo;
	onSelectFactor: () => void;
}

export const FactorCardAdvanced: React.FC<FactorCardAdvancedProps> = ({
	factorInfo,
	isCardSelected,
	onSelectFactor
}) => {
	const [showDescriptionState, setShowDescriptionState] = useState(false);

	const workspace = useTypedSelector(state => state.user.currentWorkspace);

	// if the user clicks on the question mark then display description
	const toggleDescriptionHandler = () => {
		let result = !showDescriptionState;
		setShowDescriptionState(result);

		if (result) {
			Analytics.checkTooltipInfo(factorInfo.identifier);
		}
	};

	// keeps the factor card from reloading whenever there is a change in the page
	const factorScoreCheck = useMemo(() => {
		if (workspace) {
			return (
				<Component.FactorCardRegular pageType="factors" factorInfo={factorInfo} hideTitle />
			);
		}
	}, [workspace, factorInfo]);

	return (
		<div
			className={`${
				isCardSelected && 'shadow-factors'
			} bg-white rounded-md cursor-pointer print:shadow-none`}
			onClick={onSelectFactor}
		>
			<div className="px-5 pt-5">
				<header className="flex justify-betweeen items-start space-x-2">
					<h2 className="text-md font-new-spirit font-medium flex-auto text-center text-font-reg h-10">
						{factorInfo.title}
					</h2>

					<div className="flex text-xl text-font-reg justify-end print-hide">
						{showDescriptionState ? (
							<BsXCircle onClick={toggleDescriptionHandler} />
						) : (
							<BsQuestionCircle onClick={toggleDescriptionHandler} />
						)}
					</div>
				</header>

				{showDescriptionState ? (
					<Description
						filter={[newPositiveAttributeFilter(Ldm.Factor, [factorInfo.title])]}
					/>
				) : (
					<div className="items-center">{factorScoreCheck}</div>
				)}
			</div>
		</div>
	);
};
