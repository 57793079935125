/**
 * A helper function to round to two decimal places.
 * For example, 0.00955788 but we want 0.01 instead.
 * @author Misael Esperanzate
 * @param num - The number you are trying to round. Type number.
 * @param decimals - How many decimal places you are trying to round. Type number.
 */

export const round = (num: number, decimals: number) => {
	const sign = num >= 0 ? 1 : -1;
	return +(
		Math.round(num * Math.pow(10, decimals) + sign * 0.001) / Math.pow(10, decimals)
	).toFixed(decimals);
};
