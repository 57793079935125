import { useState, useRef } from 'react';
import Modal from 'react-modal';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { useTypedSelector } from 'hooks';
import { InsightsCommentsApi } from 'api';
import { MISC_COLORS } from 'constants/misc';
import { IInsightDataPoint } from 'types';

/**
 * This is the modal that handles the commenting for the insights
 * @author Misael Esperanzate
 * @param factorName - name of the factor
 * @param comments - an array containing comments
 * @param totalResponse - total amount of responses
 * @param questionId - the key of the specific question
 * @param questionTitle - title of the current questions
 */
interface CreateCommentOverlayProps {
	questionId: string;
	factorName: string;
	totalResponse: number;
	responses: IInsightDataPoint[];
	questionTitle?: string | undefined;
}

export const CreateCommentOverlay: React.FC<CreateCommentOverlayProps> = ({
	responses,
	questionId,
	factorName,
	questionTitle,
	totalResponse = 0
}) => {
	const [isOpenModal, setOpenModal] = useState(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [characterCount, setCharacterCount] = useState<number>(0);

	const userId = useTypedSelector(state => state.user.userId);
	const name = useTypedSelector(state => state.user.userInfo.name);
	const workspaceId = useTypedSelector(state => state.user.currentWorkspace.id);

	const Api = InsightsCommentsApi();

	const response = useRef('');

	// function that handles the submit button
	const handleComment = () => {
		let errors = [];

		if (response.current.trim() === '') {
			errors.push('Please include a comment');
		}

		setFormErrors(errors);

		if (errors.length === 0) {
			const comment = {
				fullName: name,
				questionId: questionId,
				question: questionTitle,
				comment: response.current,
				workspaceId,
				userId
			};

			Api.createComment(comment)
				.then(() => {
					setOpenModal(false);
					Analytics.createComment(factorName);
				})
				.catch(err => Util.logErrorMessage(err));
		}
	};

	const handleClickOption = () => {
		setOpenModal(true);
		Analytics.openCreateCommentOverlay(factorName);
	};

	return (
		<>
			<Component.HolThreeDotMenu
				color="bg-hol-light-purple"
				options={[
					{
						label: 'Respond',
						onClick: handleClickOption
					}
				]}
			/>

			<Modal
				isOpen={isOpenModal}
				className="bg-white w-11/12 lg2:max-w-lg mx-auto my-auto rounded shadow-lg p-4 z-50 "
				overlayClassName="fixed flex bg-gray-50 bg-opacity-40 inset-0 h-screen w-screen z-50 overflow-auto"
				ariaHideApp={false}
			>
				<Component.HolText textType="heading1">{questionTitle}</Component.HolText>

				<HighchartsReact
					highcharts={Highcharts}
					options={Util.stackedBarchartConfig(responses)}
				/>

				<div>
					<Component.HolText>Total Reponses: {totalResponse}</Component.HolText>
				</div>

				{formErrors.length > 0 && (
					<div className="text-red">
						<p>There were the following error(s):</p>
						{formErrors.map((err, idx) => {
							return <p key={idx}>{err}</p>;
						})}
					</div>
				)}
				<div className="mt-3 space-y-2">
					<Component.HolText textType="heading3">Comment</Component.HolText>
					<Component.HolInput
						type="textarea"
						placeholder="Leave a comment"
						focusColor={MISC_COLORS.green}
						setCharacterCount={setCharacterCount}
						onValueChange={value => {
							response.current = value;
						}}
					/>
				</div>
				<div className="flex justify-end mb-4">
					<Component.HolText>{characterCount + '/1000'}</Component.HolText>
				</div>
				<div className="flex justify-end space-x-5">
					<Component.HolButton
						label="Cancel"
						type="secondary"
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation();
							setOpenModal(false);
						}}
					/>
					<Component.HolButton
						type="primary"
						label="Comment"
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation();
							handleComment();
						}}
					/>
				</div>
			</Modal>
		</>
	);
};
