import React, { useCallback, useState, useEffect } from 'react';

import * as Util from 'utils';
import * as Component from 'components';

import { UserApi } from 'api';
import { useTypedSelector } from 'hooks';

/**
 * A component displaying all existing workspaces data for the current user
 * @author Konstantin Krumin
 */
interface WorkspacesCardProps {}

export const WorkspacesCard: React.FC<WorkspacesCardProps> = () => {
	const workspaces = useTypedSelector(state => state.user.workspaces);
	const { getWorkspaceUsersCount } = UserApi();

	const [workspacesTableData, setWorkspacesTableData] = useState<string[][]>([]);

	const parseWorkspacesData = useCallback(async () => {
		workspaces.forEach(async workspace => {
			const count = await getWorkspaceUsersCount(workspace.id);

			setWorkspacesTableData(prevData => {
				return [
					...prevData,
					[
						workspace.name,
						Util.formatDate(new Date(workspace.createdAt)),
						count ? count.toString() : '-'
					]
				];
			});
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		parseWorkspacesData();

		return () => {
			// Prevent memory leak https://stackoverflow.com/a/65007703
			setWorkspacesTableData([]);
		};
	}, [parseWorkspacesData]);

	return (
		<Component.HolCard title="Workspaces" columns="col-span-full" styles="print-page-break p-3">
			<Component.HolTable
				tableStyling="mx-5"
				tableTitles={['Name', 'Created', '# of Seats']}
				tableValues={workspacesTableData}
			/>
		</Component.HolCard>
	);
};
