import { IActionPlan, IArchivedActionPlan } from 'types';

import ActionType from '../action-types';
import {
	SetActionPlan,
	SetActionPlans,
	SetActiveActionPlans,
	SetArchivedActionPlans,
	DeleteActionPlanAction,
	SetTotalActionPlansCount,
	SetActionPlanCurrentPageAction,
	SetTotalActiveActionPlansCount,
	SetTotalArchivedActionPlansCount,
	SetActiveActionPlanCurrentPageAction,
	SetArchivedActionPlanCurrentPageAction,
	CreateOrDuplicateOrEditActionPlanAction
} from '../actions';

/**
 * Holds all of the action planning state action logic
 * @author Konstantin Krumin
 */

export const setActionPlans = (actionPlans: IActionPlan[]): SetActionPlans => {
	return {
		type: ActionType.SET_ACTION_PLANS,
		payload: {
			actionPlans
		}
	};
};

export const setTotalActionPlansCount = (
	totalActionPlansCount: number
): SetTotalActionPlansCount => {
	return {
		type: ActionType.SET_ACTION_PLANS_COUNT,
		payload: {
			totalActionPlansCount
		}
	};
};

export const setActionPlanCurrentPage = (currentPage: number): SetActionPlanCurrentPageAction => {
	return {
		type: ActionType.SET_ACTION_PLAN_CURRENT_PAGE,
		payload: {
			currentPage
		}
	};
};

export const setActiveActionPlans = (activeActionPlans: IActionPlan[]): SetActiveActionPlans => {
	return {
		type: ActionType.SET_ACTIVE_ACTION_PLANS,
		payload: {
			activeActionPlans
		}
	};
};

export const setTotalActiveActionPlansCount = (
	totalActiveActionPlansCount: number
): SetTotalActiveActionPlansCount => {
	return {
		type: ActionType.SET_ACTIVE_ACTION_PLANS_COUNT,
		payload: {
			totalActiveActionPlansCount
		}
	};
};

export const setActiveActionPlanCurrentPage = (
	activeCurrentPage: number
): SetActiveActionPlanCurrentPageAction => {
	return {
		type: ActionType.SET_ACTIVE_ACTION_PLAN_CURRENT_PAGE,
		payload: {
			activeCurrentPage
		}
	};
};

export const setArchivedActionPlans = (
	archivedActionPlans: IArchivedActionPlan[]
): SetArchivedActionPlans => {
	return {
		type: ActionType.SET_ARCHIVED_ACTION_PLANS,
		payload: {
			archivedActionPlans
		}
	};
};

export const setTotalArchivedActionPlansCount = (
	totalArchivedActionPlansCount: number
): SetTotalArchivedActionPlansCount => {
	return {
		type: ActionType.SET_ARCHIVED_ACTION_PLANS_COUNT,
		payload: {
			totalArchivedActionPlansCount
		}
	};
};

export const setArchivedActionPlanCurrentPage = (
	archivedCurrentPage: number
): SetArchivedActionPlanCurrentPageAction => {
	return {
		type: ActionType.SET_ARCHIVED_ACTION_PLAN_CURRENT_PAGE,
		payload: {
			archivedCurrentPage
		}
	};
};

export const setActionPlan = (actionPlan: IActionPlan): SetActionPlan => {
	return {
		type: ActionType.SET_ACTION_PLAN,
		payload: {
			actionPlan
		}
	};
};

export const createOrDuplicateOrEditActionPlan = (
	actionPlan: IActionPlan
): CreateOrDuplicateOrEditActionPlanAction => {
	return {
		type: ActionType.CREATE_OR_DUPLICATE_OR_EDIT_ACTION_PLAN,
		payload: {
			actionPlan
		}
	};
};

export const deleteActionPlan = (actionPlanUuid: string): DeleteActionPlanAction => {
	return {
		type: ActionType.DELETE_ACTION_PLAN,
		payload: {
			actionPlanUuid
		}
	};
};
