import React, { useEffect, useRef, useState } from 'react';

import { FaChevronDown, FaTimes } from 'react-icons/fa';

import { IGeneralOption } from 'types';
import { useOnClickOutside } from 'hooks';

import { SELECTED_NONE } from './constants';
import { SelectedValues } from './components';

/**
 * A regular dropdown multi-select component
 * @author Michaela Mempin
 * @param borderRadius - border radius
 * @param options - contains available options for selection
 * @param hoverBgColor - background color to be shown on hover
 * @param placeholder - placeholder text to be shown by default
 * @param selectedValues - contains an array of selected values
 * @param handleSelect - a callback function that passes the selected values to the parent component
 */
interface HolMultiSelectDropdownProps {
	placeholder?: string;
	borderRadius?: string;
	hoverBgColor?: string;
	options: IGeneralOption[] | null;
	selectedValues: IGeneralOption[];
	handleSelect: (opt: IGeneralOption[]) => void;
}

export const HolMultiSelectDropdown: React.FC<HolMultiSelectDropdownProps> = ({
	options,
	selectedValues,
	placeholder = 'Select...',
	borderRadius = 'rounded-md',
	hoverBgColor = 'bg-change-title',
	handleSelect
}) => {
	const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
	const [multiSelected, setMultiSelected] = useState<IGeneralOption[]>(selectedValues);

	const [displayOptions, setDisplayOptions] = useState<IGeneralOption[] | null>(options);

	// Close the dropdown select if user clicks outside of component.
	const node = useRef<HTMLInputElement | null>(null);
	useOnClickOutside(node, () => setIsOpenDropdown(false));

	const handleSelectedOption = (select: IGeneralOption) => {
		if (select.value === null) {
			setMultiSelected([]);
			handleSelect([]);
		} else {
			const valuesCopy = [...multiSelected];
			valuesCopy.push(select);

			setMultiSelected(valuesCopy);
			handleSelect(valuesCopy);
		}
	};

	const handleRemoveSelect = (index: number) => {
		const valuesCopy = [...multiSelected];
		valuesCopy.splice(index, 1);

		setMultiSelected(valuesCopy);
		handleSelect(valuesCopy);
	};

	const handleClearAll = () => {
		setMultiSelected([]);
		handleSelect([]);
	};

	const handleClick = (e: any) => {
		const target = e.target;

		if (target.id === 'multi-select') {
			setIsOpenDropdown(!isOpenDropdown);
		}
	};

	useEffect(() => {
		if (options) {
			// If the sub group is already selected, do not add it as option.
			const unselectedOptions = options.filter(opt => {
				const findOpt = multiSelected.find(a => a.label === opt.label);
				return !findOpt;
			});

			if (unselectedOptions) {
				setDisplayOptions(unselectedOptions);
			}
		}
	}, [options, multiSelected]);

	return (
		<div className="select-none flex bg-white flex-col">
			<div
				className="relative duration-150 ease-in-out w-full text-base"
				ref={node}
				onClick={handleClick}
			>
				<div
					id="multi-select"
					className={`flex flex-row items-center cursor-pointer justify-between border border-input-border hover:border-font-light-1 ${borderRadius} px-2.5 transition-all duration-150 ease-in-out`}
					style={{ minHeight: '35px' }}
				>
					{/* Show loading if there are no options yet or Display what is currently selected */}
					<SelectedValues
						options={options}
						multiSelected={multiSelected}
						handleRemove={(index: number) => handleRemoveSelect(index)}
						placeholder={placeholder}
					/>

					<p className="p-3.5"></p>

					<div className="flex flex-row space-x-3 items-center text-sm">
						{/* Clear multi selected values */}
						{multiSelected.length > 0 && (
							<FaTimes
								className="transition-all duration-150 ease-in-out hover:text-font-light-1 text-gray-300 cursor-pointer"
								onClick={() => handleClearAll()}
							/>
						)}
						{/* Chevron to indicate dropdown open/close */}
						<div className="border-l pl-3 pr-1">
							<FaChevronDown
								className={`transition-all duration-150 ease-in-out transform hover:text-font-light-1 text-gray-300 cursor-pointer ${
									isOpenDropdown ? 'rotate-180' : ''
								}`}
								onClick={() => {
									setIsOpenDropdown(!isOpenDropdown);
								}}
							/>
						</div>
					</div>
				</div>

				{/* List of options */}
				<div
					className={`mt-0.5 z-10 text-font-select h-min absolute origin-top overflow-hidden w-full transform transition-all duration-150 ease-in-out ${
						isOpenDropdown ? 'scale-y-100' : 'scale-y-0'
					}`}
				>
					<div className={`bg-white w-full border ${borderRadius} `}>
						{displayOptions &&
							displayOptions.map((opt, idx) => (
								<p
									key={idx}
									className={`bg-opacity-20 p-2 hover:${hoverBgColor} hover:bg-opacity-10 cursor-pointer`}
									onClick={() => handleSelectedOption(opt)}
								>
									{opt.label}
								</p>
							))}

						{/* Option to select nothing */}
						<p
							className={`p-2 hover:${hoverBgColor} hover:bg-opacity-10 cursor-pointer`}
							onClick={() => handleSelectedOption(SELECTED_NONE)}
						>
							{SELECTED_NONE.label}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
