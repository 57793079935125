import React, { ReactElement } from 'react';

import { NavLink } from 'react-router-dom';

import * as Component from 'components';

import { TextType } from 'types';

/**
 * A default card component to be used throughout the app
 * @author Matthew Douglas & Misael Esperanzate & Konstantin Krumin
 * @param styles - extra styling
 * @param title - optional title on the card
 * @param columns - determines grid layout inside the card
 * @param headerTitleTextType - determines header title text type
 * @param hideHeader - determines whether the header should be hidden
 * @param headerTitleTextStyle - additional styling for the header title text
 * @param titleElement - this prop takes any element to be shown in the title
 * @param cardBackgroundColor - a parameter that changes card background color
 * @param headerHorizontalMargin - parameter that changes horizontal margin of the header
 * @param isLink - optional boolean prop that tells whether the component is used as navigational link
 * @param topRightElement - this prop takes any element to be shown on the top right corner of the card
 * @param linkPath - optional string prop that specifies the link path where it should redirect on click
 * @param onClick - a function that gets triggered on card click
 */
interface HolCardProps {
	title?: string;
	columns: string;
	styles?: string;
	isLink?: boolean;
	linkPath?: string;
	hideHeader?: boolean;
	titleElement?: ReactElement;
	cardBackgroundColor?: string;
	headerTitleTextStyle?: string;
	headerTitleTextType?: TextType;
	topRightElement?: ReactElement;
	headerHorizontalMargin?: string;
	onClick?: () => void;
}

export const HolCard: React.FC<HolCardProps> = ({
	title,
	styles,
	columns,
	children,
	titleElement,
	isLink = false,
	linkPath = '/',
	topRightElement,
	hideHeader = false,
	headerTitleTextType,
	headerTitleTextStyle,
	headerHorizontalMargin = 'mx-5',
	cardBackgroundColor = 'bg-white',
	onClick
}) => {
	const innerContent = (
		<>
			{!hideHeader && (
				<header
					className={`w-100 flex flex-col justify-end items-center space-y-3 md2:flex-row md2:space-y-0 my-4 ${headerHorizontalMargin}`}
				>
					{(title || titleElement) && (
						<div className="flex-col flex-grow">
							<div className="flex items-center space-x-2">
								<Component.HolText
									styles={headerTitleTextStyle}
									textType={
										headerTitleTextType ? headerTitleTextType : 'heading1'
									}
								>
									{title}
								</Component.HolText>
								{titleElement}
							</div>
						</div>
					)}
					<div className="justify-end align-middle print-hide">
						<div>{topRightElement}</div>
					</div>
				</header>
			)}

			{children}
		</>
	);

	const styling = `relative rounded-lg ${cardBackgroundColor} ${columns} ${styles ? styles : ''}`;

	if (isLink) {
		return (
			<NavLink className={styling} to={linkPath}>
				{innerContent}
			</NavLink>
		);
	} else {
		return (
			<div className={styling} onClick={onClick}>
				{innerContent}
			</div>
		);
	}
};
