import React from 'react';

import * as Component from 'components';

import { useTypedSelector } from 'hooks';

/**
 * Shows info about filters that were selected
 * @author Konstantin Krumin
 */
interface AppliedFiltersInfoProps {}

const AppliedFiltersInfo: React.FC<AppliedFiltersInfoProps> = () => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const selectedDemographicsLabels = selectedDemographics.map(demographic => {
		return demographic.demographicLabel;
	});

	const selectedGroupsLabels = selectedDemographics
		.map(demographic => demographic.groupsSelected)
		.flat();

	const groupLabel = selectedGroupsLabels.length > 1 ? 'Specified Groups' : 'Specified Group';

	return (
		<div className="flex flex-col items-start space-y-1 p-2 max-w-md text-left">
			{selectedDemographicsLabels.length > 0 && (
				<Component.HolText textColor="text-white" styles="text-sm">
					Filtered by: {selectedDemographicsLabels.join(', ')}
				</Component.HolText>
			)}

			{selectedGroupsLabels.length > 0 && (
				<Component.HolText textColor="text-white" styles="text-sm">
					{groupLabel}: {selectedGroupsLabels.join(', ')}
				</Component.HolText>
			)}
		</div>
	);
};

export default AppliedFiltersInfo;
