import { IWorkspaceTypes } from 'types';
import { useAxiosPrivate } from 'hooks';

import { GetWorkspaceTypesType } from './types';

export const WorkspaceApi = () => {
	const { serverAxios } = useAxiosPrivate();

	/**
	 * An API that retrieves all workspace ids with their types (0 - advanced, 1 - light, 2 - sensors only)
	 */
	const getWorkspaceTypes: GetWorkspaceTypesType = async () => {
		return await serverAxios
			.get<IWorkspaceTypes[]>(`${process.env.REACT_APP_HOST}/workspace/workspace_types`)
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	};

	return {
		getWorkspaceTypes
	};
};
