import { Series } from 'highcharts';

import { DiagnosticsOptions } from 'enums';

import { showPercentSign } from '../../misc';

/**
 * Helper function to create a tooltip title text for column charts
 * @author Konstantin Krumin
 * @param seriesData - input data for the current column
 */
export const createTooltipTitle = (seriesData: Series) => {
	if (
		seriesData.chart.options.title?.text === DiagnosticsOptions.TURNOVER ||
		seriesData.chart.options.title?.text === DiagnosticsOptions.TOXICITY ||
		seriesData.chart.options.title?.text === DiagnosticsOptions.SENTIMENT ||
		seriesData.chart.options.title?.text === DiagnosticsOptions.AIR_QUALITY ||
		seriesData.chart.options.title?.text === DiagnosticsOptions.CONNECTIVITY
	) {
		return seriesData.name;
	} else {
		return seriesData.chart.options.title?.text;
	}
};

/**
 * Tooltip container similar to gooddata
 * @param color - color of the hovered point
 */
const tooltipContainerStyle = (color: any) => {
	return `
		position: absolute;
		z-index: 99;
		background-color: rgba(255,255,255, 0.93);
		padding: 10px;
		border-radius: 3px;
		border-top: solid 4px ${color};
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	`;
};

/**
 * Tooltip formatter to display 'filter by' information for HolChangesChart.
 * @param this - the context of the current point we are hovering over.
 */

export function lineChartTooltipFormatter(this: Highcharts.TooltipFormatterContextObject): string {
	const title = this.series.getName().split(';;');

	let tooltipHeader;

	if (title.length > 1) {
		const [demographic, metric] = title;

		const display = metric
			? `<span style="color:#696969">Demographic:</span> <span style="font-weight:600">${
					demographic === '' ? 'All' : demographic
			  }</span> <br />`
			: `<br />`;

		tooltipHeader = `<span style="font-weight:bold;font-size:12px">${metric}</span> <br /> ${display}`;
	} else {
		const titleValue = title[0];

		tooltipHeader = `<span style="font-weight:bold;font-size:12px">${titleValue}</span> <br />`;
	}

	return `${tooltipHeader} <br />
	<span style="color:#696969">Month/Year</span> <br /> 
	<span style="font-weight:600">${this.x}</span> <br />
	<br />
	<span style="color:#696969">Avg Value</span> <br />
	<span style="font-weight:600">${this.y}</span>`;
}

/**
 * Tooltip formatter to display heatmap values.
 * @param this - the context of the current point we are hovering over.
 */
export function heatmapChartTooltipFormatter(
	this: Highcharts.TooltipFormatterContextObject
): string {
	const [factorTitle, xAxis, yAxis] = this.point.name.split(';;');
	const [xAxisLabel, xAxisText] = xAxis.split(':');
	const [yAxisLabel, yAxisText] = yAxis.split(':');

	const point = this.point.value?.toString();

	return `
		<div style="${tooltipContainerStyle(this.point.color)}">
			<p style="font-size: 14px; margin-bottom:7px; font-weight: bold;">${factorTitle}</p>

			<p style="color:#696969">${xAxisLabel} (x-axis)</p>
			<p style="font-size: 14px; margin-bottom:7px">${xAxisText}</p>

			<p style="color:#696969">${yAxisLabel} (y-axis)</p>
			<p style="font-size: 14px; margin-bottom:7px">${yAxisText}</p>

			
			${
				point && point !== '0'
					? `<p style="color:#696969">Value</p><p style="font-size: 14px; font-weight: bold;">${this.point.value}</p>`
					: `<p style="font-size: 14px; font-style: italic;">No Data Available</p>`
			}
		</div>
	`;
}

/**
 * Tooltip formatter to display heatmap values.
 * @param this - the context of the current point we are hovering over.
 */

export function splineChartTooltipFormatter(
	this: Highcharts.TooltipFormatterContextObject
): string {
	const score = this.point.y;

	const additionalData = this.point.name.split(' : ');

	const date = additionalData[0];
	const binRange = additionalData[1];

	if (binRange) {
		return `
			<div style="${tooltipContainerStyle(this.point.color)}">
				<p style="color:#696969">Month/Year</p>
				<p style="font-size: 14px; margin-bottom:7px">${date}</p>

				<p style="color:#696969">Range</p>
				<p style="font-size: 14px">${binRange}</p>
			</div>
		`;
	} else {
		return `
			<div style="${tooltipContainerStyle(this.point.color)}">
				<p style="color:#696969">Month/Year</p>
				<p style="font-size: 14px; margin-bottom:7px">${date}</p>

				<p style="color:#696969">Value</p>
				<p style="font-size: 14px">${score}</p>
			</div>
		`;
	}
}

/**
 * Tooltip formatter to display column chart values.
 * @param this - the context of the current point we are hovering over.
 */

export function columnChartTooltipFormatter(
	this: Highcharts.TooltipFormatterContextObject
): string {
	const date = this.x;

	//@ts-ignore
	const title = createTooltipTitle(this.point.series);
	const percentSign = showPercentSign(this.point.series) ? '%' : '';
	const amount = this.y && Math.round((this.y + Number.EPSILON) * 100) / 100;

	return `
	<div style="${tooltipContainerStyle(this.point.color)}">
		<p style="font-size: 18px; margin-bottom:5px; color:#202020"><span style="font-weight: bold;">${amount}${percentSign}</span> ${title}</p>
		<p style="font-size: 12px; color:#A5A5A5">${date}</p>
	</div>
	`;
}

/**
 * Tooltip formatter to display stacked barchart values.
 * @param this - the context of the current point we are hovering over.
 */

export function stackedBarchartTooltipFormatter(this: Highcharts.TooltipFormatterContextObject) {
	return this.series.name + ': <b>' + this.y + '% </b>';
}
