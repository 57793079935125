import * as Ldm from 'ldm';

export const TOXICITY_HEADLINE_METRICS = [
	{
		measure: Ldm.ToxicityToxicity.Sum,
		textColor: 'text-diagnostic-orange'
	},
	{
		measure: Ldm.ToxicitySevereToxicity.Sum,
		textColor: 'text-diagnostic-purple'
	},
	{
		measure: Ldm.ToxicityObscene.Sum,
		textColor: 'text-diagnostic-blue'
	},
	{
		measure: Ldm.ToxicityIdentityAttack.Sum,
		textColor: 'text-diagnostic-yellow'
	},
	{
		measure: Ldm.ToxicityThreat.Sum,
		textColor: 'text-diagnostic-green'
	},
	{
		measure: Ldm.ToxicityInsult.Sum,
		textColor: 'text-diagnostic-pink'
	}
];
