import React from 'react';

import { useActions, useTypedSelector } from 'hooks';

/**
 * A component that is to be used for opening sidebar navigation menu on mobile devices
 * @author Konstantin Krumin
 */
interface HolHamburgerButtonProps {}

export const HolHamburgerButton: React.FC<HolHamburgerButtonProps> = () => {
	const sidebarToggle = useTypedSelector(state => state.sidebar.toggle);
	const { setSidebarToggle } = useActions();

	return (
		<button
			className="text-gray-600 hover:text-gray-400 md:hidden print-hide"
			aria-controls="sidebar"
			aria-expanded={sidebarToggle}
			onClick={() => setSidebarToggle(!sidebarToggle)}
		>
			<span className="sr-only">Open sidebar</span>
			<svg
				className="w-6 h-6 fill-current"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="4" y="5" width="16" height="2" />
				<rect x="4" y="11" width="16" height="2" />
				<rect x="4" y="17" width="16" height="2" />
			</svg>
		</button>
	);
};
