import { IAuthInfo } from 'types';

import { Action } from '../actions';
import ActionType from '../action-types';

/**
 * This is the user state of the app that will hold all user information
 * and all the workspaces they are associated with
 * @author Matthew Douglas
 */

const initialState: IAuthInfo = {
	userId: '',
	orgId: '',
	// all info on user
	userInfo: {
		name: '',
		email: '',
		companyName: '',
		isAuth: false,
		role: 'Organization Viewer',
		profileId: ''
	},
	// workspaces they belong to
	workspaces: [],
	// id of workspace the user has selected
	currentWorkspace: {
		id: '',
		name: '',
		createdAt: ''
	}
};

const userReducer = (state: IAuthInfo = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_USER_AUTHENTICATED:
			const authInfo: IAuthInfo = action.payload.authInfo;
			return authInfo;
		case ActionType.SET_CURRENT_WORKSPACE:
			return {
				...state,
				currentWorkspace: action.payload.workspace
			};
		case ActionType.SET_USER_WORKSPACE_ROLE:
			const userInfo: IAuthInfo = { ...state };

			userInfo.userInfo.role = action.payload.role;

			return userInfo;
		default:
			return state;
	}
};

export default userReducer;
