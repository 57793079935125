import * as Util from 'utils';

import { useAxiosPrivate } from 'hooks';

import { GetBrandLogoType } from './types';

export const ProfileApi = () => {
	const { serverAxios } = useAxiosPrivate();

	/**
	 * API that retrieves org id, org name and user id
	 */
	const fetchUserOrgId = async (email: string) => {
		const userIdResponse = await serverAxios.get(`${process.env.REACT_APP_HOST}/org/user_id`, {
			params: {
				email: email
			}
		});

		const orgIdResponse = await serverAxios.get(`${process.env.REACT_APP_HOST}/org/org_id`, {
			params: {
				email: email
			}
		});

		const orgNameResponse = await serverAxios.get(
			`${process.env.REACT_APP_HOST}/org/org_name`,
			{
				params: {
					id: orgIdResponse.data.orgId
				}
			}
		);

		return {
			userId: userIdResponse.data.userId,
			orgId: orgIdResponse.data.orgId,
			orgName: orgNameResponse.data.orgName
		};
	};

	/**
	 * API that fetches username
	 */
	const fetchUserName = async (userId: string) => {
		const userName = await serverAxios.get(`${process.env.REACT_APP_HOST}/org/user_name`, {
			params: {
				userId: userId
			}
		});

		return userName.data.userName;
	};

	/**
	 * API that retrieves the brand logo of the user belongs to
	 * @param workspaceId - workspace id of which the current user logged in belongs to
	 */
	const getBrandLogo: GetBrandLogoType = workspaceId => {
		return serverAxios
			.get(`${process.env.REACT_APP_HOST}/brand/${workspaceId}/logo`)
			.then(response => {
				return response.data;
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return {
		fetchUserOrgId,
		fetchUserName,
		getBrandLogo
	};
};
