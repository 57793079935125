import { useEffect, useState } from 'react';

import { ProfileApi } from 'api';
import { useTypedSelector } from 'hooks';

import CompanyInfo from 'utils/company';

/* 
	Gets the logo for the current workspace
	If the current workspace is not under a consultant firm, Holmetrics logo is displayed
	If the current workspace is under a consultant firm, the consultant logo is displayed
*/

export const useLogo = () => {
	const [isLogoLoading, setIsLogoLoading] = useState<boolean>(true);

	const [logoReplacementText, setLogoReplacementText] = useState<string>();
	const [logoImage, setLogoImage] = useState<string | null>(null);

	const companyWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const { getImgPath, getName } = CompanyInfo(companyWorkspace.id);
	const Api = ProfileApi();

	useEffect(() => {
		setLogoImage(null);
		setLogoReplacementText(undefined);
		setIsLogoLoading(true);
	}, [companyWorkspace]);

	useEffect(() => {
		const imgPath = getImgPath();
		const altText = getName();

		if (imgPath) {
			Api.getBrandLogo(imgPath).then(result => {
				if (result?.imageUrl) {
					setLogoImage(result.imageUrl);
				} else {
					setLogoImage(null);
					setLogoReplacementText(altText);
				}

				setIsLogoLoading(false);
			});
		}

		// eslint-disable-next-line
	}, [getImgPath()]);

	return { logoImage, logoReplacementText, isLogoLoading };
};
