import React from 'react';
import { useNavigate } from 'react-router';

import * as Component from 'components';

export const NotFound: React.FC = () => {
	const navigate = useNavigate();

	const handleRedirectHome = () => {
		navigate('/', { replace: true });
	};

	return (
		<div className="grid grid-rows-3 h-screen">
			<div className="row-span-1"></div>
			<div className="h-1/2 text-center">
				<p className="font-sophia-bold text-font-light-1 text-7xl mb-2">404</p>
				<p className="text-font-date text-xl"> Page not found </p>

				<Component.HolButton
					label="Go Home"
					type="holmetrics"
					customEdge="rounded-md py-4 px-3"
					customStyle="mx-auto mt-14 w-1/6 p-5 text-2xl bg-hol-light-blue"
					onClick={handleRedirectHome}
				/>
			</div>
		</div>
	);
};
