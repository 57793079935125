import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { FactorColors } from 'enums';
import { IGeneralOption } from 'types';
import { DEMOGRAPHIC_LDM } from 'constants/dataset';
import { useTypedSelector, useWorkspaceDemographics } from 'hooks';

import { Legend, NoDataText } from './components';
import { extractAxisText, generateHeatmapLegend } from './helpers';

/**
 * Displays factors heatmap
 */
interface FactorsHeatmapChartProps {}

export const FactorsHeatmapChart: React.FC<FactorsHeatmapChartProps> = () => {
	const {
		factorState: { value, identifier, title }
	} = useTypedSelector(state => state.factor);
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const { demographicGroups } = useWorkspaceDemographics();

	const chartRef = useRef<
		| {
				chart: Highcharts.Chart;
				container: React.RefObject<HTMLDivElement> | any;
		  }
		| any
	>(null);

	const [xAxisSelectedDemographic, setXAxisSelectedDemographic] = useState<IGeneralOption>();
	const [yAxisSelectedDemographic, setYAxisSelectedDemographic] = useState<IGeneralOption>();

	// Returns a list of demographic groups ('Department','Gender'...) if there is a sub group that has > 6 users.
	// Ex: If there are 4 women, 7 men, and 1 prefer not to ans, it would still give an option to choose Gender
	const demographicOptions = useMemo(() => {
		if (demographicGroups) {
			const demographicOptions = demographicGroups.map(d => ({
				label: d,
				value: DEMOGRAPHIC_LDM[d]
			}));

			return demographicOptions;
		}

		return;
	}, [demographicGroups]);

	useEffect(() => {
		if (demographicOptions && demographicOptions.length > 1) {
			setYAxisSelectedDemographic(demographicOptions[0]);
			setXAxisSelectedDemographic(demographicOptions[1]);
		} else {
			setXAxisSelectedDemographic({
				label: '-',
				value: undefined
			});

			setYAxisSelectedDemographic({
				label: '-',
				value: undefined
			});
		}

		// eslint-disable-next-line
	}, [demographicOptions]);

	const onXAxisChange = (value: IGeneralOption | null) => {
		if (value) setXAxisSelectedDemographic(value);

		Analytics.changeDemographicType();
	};

	const onYAxisChange = (value: IGeneralOption | null) => {
		if (value) setYAxisSelectedDemographic(value);

		Analytics.changeDemographicType();
	};

	HighchartsHeatmap(Highcharts);

	return (
		<Component.HolCard
			styles="print-page-break"
			columns="my-4 col-span-full lg2:col-span-12"
			topRightElement={
				<div className="flex space-x-3">
					<Component.HolSingleSelectDropdown
						borderRadius="rounded-xl"
						selectedOption={yAxisSelectedDemographic}
						options={demographicOptions}
						handleSelect={onYAxisChange}
					/>

					<Component.HolSingleSelectDropdown
						borderRadius="rounded-xl"
						selectedOption={xAxisSelectedDemographic}
						options={demographicOptions}
						handleSelect={onXAxisChange}
					/>
				</div>
			}
		>
			<Execute
				key={currentWorkspace.id}
				seriesBy={[value]}
				slicesBy={[xAxisSelectedDemographic?.value, yAxisSelectedDemographic?.value]}
				filters={[Util.generateAbsoluteBiweeklyDateFilter()]}
				onError={errInfo => {
					if (errInfo.message !== 'CANCELLED') {
						Util.logErrorMessage(errInfo);
					}
				}}
			>
				{({ result, isLoading }) => {
					if (isLoading) {
						return <LoadingComponent className="my-8" />;
					}

					if (
						xAxisSelectedDemographic?.label === '-' &&
						yAxisSelectedDemographic?.label === '-'
					) {
						return <NoDataText />;
					}

					if (xAxisSelectedDemographic?.label === yAxisSelectedDemographic?.label) {
						return (
							<NoDataText text="Please choose distinct options for each axis, as the same option has been selected for both." />
						);
					}

					const metadataArrs = result?.dataView?.headerItems?.[0];
					const xAxisValues = extractAxisText(metadataArrs?.[0]);
					const yAxisValues = extractAxisText(metadataArrs?.[1]);

					const series = Util.extractDataSeriesInArr(result);

					const datapoints = series?.map(values =>
						values.rawData().map(value => +(value ? value : 0))
					)[0];

					const xAxisUniqueValues = Array.from(new Set(xAxisValues))?.sort();
					const yAxisUniqueValues = Array.from(new Set(yAxisValues))?.sort();

					const combinedDataset = datapoints?.map((datapoint, idx) => {
						return {
							value: datapoint,
							xAxisVal: xAxisValues[idx],
							yAxisVal: yAxisValues[idx]
						};
					});

					const heatmapReadyDataset = combinedDataset?.map(currDatapoint => {
						const xAxisCoordinate = xAxisUniqueValues?.findIndex(
							val => val === currDatapoint.xAxisVal
						);
						const yAxisCoordinate = yAxisUniqueValues?.findIndex(
							val => val === currDatapoint.yAxisVal
						);

						const color = Util.getMetricColor(
							currDatapoint.value * 10,
							identifier,
							FactorColors.LIGHT_GREY
						);

						return {
							name: `
								${title};;
								${xAxisSelectedDemographic?.label}:${currDatapoint.xAxisVal};;
								${yAxisSelectedDemographic?.label}:${currDatapoint.yAxisVal}
							`,
							borderWidth: 0,
							x: xAxisCoordinate,
							y: yAxisCoordinate,
							value: currDatapoint.value,
							color: color
						};
					});

					const legendSettings = generateHeatmapLegend(identifier);

					if (datapoints && datapoints.length > 0) {
						return (
							<div className="mx-auto md2:px-8">
								<HighchartsReact
									ref={(chart: any) => (chartRef.current = chart)}
									highcharts={Highcharts}
									options={Util.heatmapChartConfig(
										heatmapReadyDataset,
										yAxisUniqueValues,
										xAxisUniqueValues
									)}
								/>
								<Legend
									chartRef={chartRef}
									scale={legendSettings.scales}
									colors={legendSettings.colors}
								/>
							</div>
						);
					} else {
						return <NoDataText />;
					}
				}}
			</Execute>
		</Component.HolCard>
	);
};
