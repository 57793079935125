import React, { useCallback, useEffect, useState, useRef } from 'react';
import { LoadingComponent } from '@gooddata/sdk-ui';

import * as Component from 'components';

import { UserApi } from 'api';
import { useTypedSelector } from 'hooks';
import { IPopupMessage, IWorkspaceUserInfo } from 'types';

export const Management: React.FC = () => {
	const { currentWorkspace, workspaces } = useTypedSelector(state => state.user);
	const rolesUpdateList = useTypedSelector(state => state.userManager);

	const [searchUser, setSearchUser] = useState<string>('');
	const [workspaceUsers, setWorkspaceUsers] = useState<IWorkspaceUserInfo[]>([]);
	const [deactivatedUser, setDeactivatedUser] = useState<IWorkspaceUserInfo | undefined>();

	const [actionMessage, setActionMessage] = useState<IPopupMessage | undefined>();

	const { getWorkspaceUsers, enableUser } = UserApi();

	const componentRef = useRef(null);

	// Current company workspace the user is in.
	const companyName = workspaces
		.filter(ws => {
			return ws.id === currentWorkspace.id;
		})
		.map(ws => {
			return ws.name;
		});

	// Get user list in the current workspace from GoodData
	const getUsers = useCallback(async () => {
		const users = (await getWorkspaceUsers(currentWorkspace.id)) as IWorkspaceUserInfo[];

		if (users) {
			// Filters users by search values
			const filteredSearch = users.filter(({ firstname, lastname }) => {
				// Makes sure the search values are not case sensitive
				const uppercaseSearchInput = searchUser.toUpperCase();
				const fname = firstname
					.toUpperCase()
					.substring(0, uppercaseSearchInput.length) as string;
				const lname = lastname
					.toUpperCase()
					.substring(0, uppercaseSearchInput.length) as string;

				return (
					fname === uppercaseSearchInput ||
					lname === uppercaseSearchInput ||
					searchUser === ''
				);
			});

			setWorkspaceUsers(filteredSearch);
		}

		// eslint-disable-next-line
	}, [currentWorkspace.id, searchUser, deactivatedUser]);

	const undoDeactivateUser = async () => {
		if (deactivatedUser) {
			await enableUser(currentWorkspace.id, deactivatedUser?.profileId);
			setDeactivatedUser(undefined);
		}
	};

	useEffect(() => {
		getUsers();

		return () => {
			// Prevent memory leak https://stackoverflow.com/a/65007703
			setWorkspaceUsers([]);
		};
	}, [getUsers]);

	const handleActionMessage = (message: IPopupMessage) => setActionMessage(message);
	const handleUserDeactivation = (user: IWorkspaceUserInfo) => setDeactivatedUser(user);

	const headerElementLeft = (
		<div className="flex items-center space-x-3">
			{/* Search user by name */}
			<div className="flex w-60">
				<Component.HolInput
					type="text"
					inputType="search"
					shape="rounded-lg"
					placeholder="Search"
					onValueChange={() => {}}
					onSubmit={setSearchUser}
				/>
			</div>

			{/* <Component.UserInviteOverlay userList={workspaceUsers} /> */}
		</div>
	);

	return (
		<div ref={componentRef} className="max-w-screen-2xl mx-auto mb-16">
			<Component.PageHeader
				parentComponentRef={componentRef}
				additionalCustomComponentLeft={headerElementLeft}
				pageType="user-management"
			/>

			{workspaceUsers && workspaceUsers.length > 0 ? (
				<>
					{deactivatedUser && (
						<Component.HolNotification
							type="warning"
							message={`Disabled ${deactivatedUser.firstname} ${deactivatedUser.lastname} from accessing ${companyName}'s workspace.`}
							actionButton={
								<Component.HolButton
									label="Undo"
									type="users"
									customStyle="w-20 bg-users-title"
									onClick={undoDeactivateUser}
								/>
							}
							onCloseHandler={() => {
								setDeactivatedUser(undefined);
							}}
						/>
					)}

					{actionMessage && (
						<Component.HolNotification
							type={actionMessage.type}
							message={actionMessage.message}
							onCloseHandler={() => {
								setActionMessage(undefined);
							}}
						/>
					)}

					{Object.keys(rolesUpdateList).length > 0 && (
						<Component.HolNotification
							type="users"
							message="Ensure that you click on the Update button to implement any changes to user roles that have not been saved yet."
							onCloseHandler={() => {
								setActionMessage(undefined);
							}}
						/>
					)}

					<Component.UserTable
						userList={workspaceUsers}
						handleActionMessage={handleActionMessage}
						handleUserDeactivation={handleUserDeactivation}
						handleRefreshUsersList={() => getUsers()}
					/>
				</>
			) : (
				<LoadingComponent />
			)}
		</div>
	);
};
