/**
 * A line that crosses a progress bar anywhere between 0 to 100
 * @author Konstantin Krumin
 * @param baselineValue - a point where baseline needs to be placed (can be anywhere between 0 to 100)
 * @param color - color of the baseline
 */
interface BaselineProps {
	baselineValue: number;
	color: string;
}

export const Baseline: React.FC<BaselineProps> = ({ baselineValue = 50, color }) => {
	const angle = (baselineValue / 100) * 360;
	const length = 100;
	const lineLength = (length / 2) * Math.sqrt(2);

	const hiddenLineLength = lineLength * 0.69;
	const visibleLineLength = lineLength - hiddenLineLength;

	return (
		<svg
			className="w-full h-full"
			viewBox={`0 0 ${length} ${length}`}
			xmlns="http://www.w3.org/2000/svg"
		>
			<line
				x1={length / 2}
				y1={length / 2}
				x2={length / 2 + lineLength * Math.sin(angle * (Math.PI / 180))}
				y2={length / 2 - lineLength * Math.cos(angle * (Math.PI / 180))}
				strokeDasharray={`${visibleLineLength}, ${hiddenLineLength}`}
				strokeDashoffset={hiddenLineLength}
				strokeWidth="1"
				stroke={color}
				fill="transparent"
			/>
		</svg>
	);
};
