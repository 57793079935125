import { useNavigate, useLocation } from 'react-router-dom';

import { ProfileApi, RolesApi } from 'api';
import { useActions, useAxiosPrivate } from 'hooks';
import { IAuthInfo, IWorkspace, IWorkspaceTypes } from 'types';

import { IGoodDataResourceProjects } from 'App.types';

import { OktaContext } from '..';

export const UserContext = () => {
	const Api = ProfileApi();
	const Auth = OktaContext();

	const { gooddataAxios } = useAxiosPrivate();

	const { setUserAuthenticated } = useActions();

	const { getCurrentRole } = RolesApi();

	const navigate = useNavigate();
	const location = useLocation();

	const getUserWorkspaceInfo = async (authInfo: IAuthInfo) => {
		const redirected = new URLSearchParams(location.search).get('redirected');

		await getCurrentRole(authInfo.currentWorkspace.id, authInfo.userInfo.profileId)
			.then(async userRole => {
				authInfo.userInfo.role = userRole;

				return Api.fetchUserOrgId(authInfo.userInfo.email);
			})
			.then(({ userId, orgId, orgName }) => {
				authInfo.userId = userId;
				authInfo.orgId = orgId;
				authInfo.userInfo.companyName = orgName;

				/** For cases when no userId is returned (happens with Google accounts) GoodData ID is used as a userId instead.
				 * This userId needs to be filled for CRUD operations with web app server-side APIs and interactions with web RDS database  */
				if (!authInfo.userId) {
					authInfo.userId = authInfo.userInfo.profileId;
				}

				setUserAuthenticated(authInfo);

				navigate('/', { replace: true });
			})
			.catch(() => {
				if (!redirected) {
					Auth.handleRedirect();
				}
			});
	};

	const getAccessibleWorkspaces = async (
		authInfo: IAuthInfo,
		goodDataAccessibleProjects: string,
		workspaceTypes: IWorkspaceTypes[]
	) => {
		// Get all workspaces user has access to.
		await gooddataAxios
			.get<IGoodDataResourceProjects>(
				`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}${goodDataAccessibleProjects}`
			)
			.then(workspaces => {
				const projectsObj = workspaces.data.projects;
				let workspacesAccessibleToUser: IWorkspace[] = [];

				projectsObj.forEach(v => {
					const workspaceName = v.project.meta.title;
					const workspaceString = v.project.links.self;
					const workspaceCreatedAtDate = v.project.meta.created;

					const workspaceId = workspaceString.substring(
						workspaceString.lastIndexOf('/') + 1
					);

					const workspaceType =
						workspaceTypes.find(workspace => workspace.workspaceId === workspaceId)
							?.type ?? 0;

					// For demo. Filters out customer workspaces
					const demoWorkspaces = process.env.REACT_APP_DEMO_WORKSPACES?.split(',');

					if (workspaceId === (process.env.REACT_APP_JIT_WORKSPACE as string)) {
						authInfo.currentWorkspace = {
							id: workspaceId,
							name: workspaceName,
							createdAt: workspaceCreatedAtDate,
							type: workspaceType
						};
					}

					if (
						((process.env.REACT_APP_CURRENT_ENV === 'demo' &&
							demoWorkspaces?.includes(workspaceId)) ||
							process.env.REACT_APP_CURRENT_ENV !== 'demo') &&
						workspaceId !== process.env.REACT_APP_JIT_WORKSPACE
					) {
						workspacesAccessibleToUser.push({
							id: workspaceId,
							name: workspaceName,
							createdAt: workspaceCreatedAtDate,
							type: workspaceType
						});
					}
				});

				authInfo.workspaces = workspacesAccessibleToUser;

				if (workspacesAccessibleToUser.length > 0) {
					authInfo.currentWorkspace = workspacesAccessibleToUser[0];
					getUserWorkspaceInfo(authInfo);
				} else {
					setUserAuthenticated(authInfo);
					navigate('/', { replace: true });
				}
			});
	};

	return { getAccessibleWorkspaces };
};
