import * as Util from 'utils';

import { DiagnosticColors, ToxicityType } from 'enums';

/**
 * Defines a color scheme for a selected toxicity heatmap
 * @author Konstantin Krumin
 * @param label - a string parameter that identifies currently selected heatmap
 */
export const setToxicityHeatmapColor = (label: ToxicityType) => {
	let colorScheme = DiagnosticColors.ORANGE;

	switch (label) {
		case ToxicityType.THREAT:
			colorScheme = DiagnosticColors.GREEN;
			break;
		case ToxicityType.INSULT:
			colorScheme = DiagnosticColors.PINK;
			break;
		case ToxicityType.TOXICITY:
			colorScheme = DiagnosticColors.ORANGE;
			break;
		case ToxicityType.PROFANITY:
			colorScheme = DiagnosticColors.BLUE;
			break;
		case ToxicityType.SEVERE_TOXICITY:
			colorScheme = DiagnosticColors.PURPLE;
			break;
		case ToxicityType.IDENTITY_ATTACK:
			colorScheme = DiagnosticColors.YELLOW;
			break;
		default:
			colorScheme = DiagnosticColors.ORANGE;
	}

	return Util.gooddataHeatmapConfig([colorScheme]);
};
