import * as Ldm from 'ldm';

import { IStackedColumnChartLabel } from 'types';
import { DiagnosticColors, SentimentType } from 'enums';

export const SENTIMENT_LABELS: IStackedColumnChartLabel[] = [
	{ name: SentimentType.NEGATIVE, color: DiagnosticColors.ORANGE },
	{ name: SentimentType.NEUTRAL, color: DiagnosticColors.YELLOW },
	{ name: SentimentType.POSITIVE, color: DiagnosticColors.PURPLE }
];

export const SENTIMENT_HEADLINE_METRICS = [
	{
		measure: Ldm.SemanticPositivePercent,
		textColor: 'text-diagnostic-purple'
	},
	{
		measure: Ldm.SemanticNeutralPercent,
		textColor: 'text-diagnostic-yellow'
	},
	{
		measure: Ldm.SemanticNegativePercent,
		textColor: 'text-diagnostic-orange'
	}
];
