import { IReport, UniqueItemInfoType } from 'types';

import { Action } from '../actions';
import ActionType from '../action-types';

/**
 * This is the state where all the reports charts will reside
 * @author Matthew Douglas
 */

interface ReportsState {
	reports: IReport[];
	currentPage: number;
	itemsPerPage: number;
	totalReportsCount: number;
	fullscreenReport: UniqueItemInfoType;
}

const initialState: ReportsState = {
	reports: [],
	currentPage: 1,
	itemsPerPage: 4,
	totalReportsCount: 0,
	fullscreenReport: false
};

const reportReducer = (state: ReportsState = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_REPORTS:
			const reports = action.payload.reports;

			return {
				...state,
				reports: reports
			};
		case ActionType.ADD_REPORT:
			return {
				...state,
				reports: [...state.reports, action.payload.report]
			};
		case ActionType.DELETE_REPORT:
			const filteredReports = state.reports.filter(
				report => report.id !== action.payload.reportId
			);
			return {
				...state,
				reports: filteredReports
			};
		case ActionType.SET_REPORTS_COUNT:
			return {
				...state,
				totalReportsCount: action.payload.totalReportsCount
			};
		case ActionType.SET_REPORTS_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload.currentPage
			};
		case ActionType.SET_REPORT_FULLSCREEN:
			return {
				...state,
				fullscreenReport: action.payload.fullscreenReport
			};
		default:
			return state;
	}
};

export default reportReducer;
