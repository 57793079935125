import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { IReportDataConfig } from 'types';

import { RANGE_OPTIONS } from './constants';

/**
 * A component used for setting and displaying date range on the report charts
 * @author Michaela Mempin
 * @param date - currently selected change date
 * @param handleMonthAxisChange - sets up month axis
 * @param handleDataConfigChange - a function used to change the data config
 */
interface ReportDateRangeProps {
	date: Date;
	handleMonthAxisChange: (ms: string[]) => void;
	handleDataConfigChange: (dataConfig: IReportDataConfig) => void;
}

export const ReportDateRange: React.FC<ReportDateRangeProps> = ({
	date,
	handleMonthAxisChange,
	handleDataConfigChange
}) => {
	// Month range is always set to the actual value + 1 because the KPI value returns an array with null in the first month.
	// So a bandaid solution is to query 1 more month value, and remove the first null value in the array.
	const [monthRange, setMonthRange] = useState<number>(2);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const dataConfig: IReportDataConfig = {
		slicesBy: [Ldm.DateDatasets.Date.MonthYear.Short],
		dateFilter: Util.getChangeDateFilter(Ldm.DateDatasets.Date.identifier, date, monthRange)
	};

	const absoluteDateFilterObj = dataConfig.dateFilter?.[0]?.absoluteDateFilter;
	const fromDate = Util.generateLocaleDate(absoluteDateFilterObj?.from);
	const toDate = Util.generateLocaleDate(absoluteDateFilterObj?.to);

	useEffect(() => {
		handleDataConfigChange(dataConfig);

		const dateCopy = new Date(date);
		const now = new Date();

		dateCopy.toLocaleDateString();

		// Set date to 1 because if selected date is January 31, Febuary will be skipped because it has less days
		dateCopy.setDate(1);
		dateCopy.setMonth(dateCopy.getMonth() - monthRange);

		let ms = [];

		// Loop x months from change date, to current date;
		// Ex:
		//		Change date: July 01, 2022
		//
		for (let d = dateCopy; d <= now; d.setMonth(d.getMonth() + 1)) {
			const monthY = Util.getMonthYear(d);
			ms.push(monthY);
		}

		handleMonthAxisChange(ms);

		// eslint-disable-next-line
	}, [monthRange]);

	const onDropdownClick = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	return (
		<>
			<div className="select-none flex relative flex-col" onClick={onDropdownClick}>
				<Component.HolText styles="flex group cursor-pointer border-b-1 px-2 py-1 space-x-2 text-sm font-dark font-sofia-regular">
					<span>
						{fromDate} to {toDate}
					</span>
					<FaChevronDown className="transform transition ease-in-out duration-150 mt-0.5 text-font-date text-sm group-hover:text-font-reg" />
				</Component.HolText>

				<div
					className={`absolute bg-white origin-top overflow-hidden top-6 border rounded-b-lg w-full transform transition-all duration-150 ease-in-out h-100 ${
						isDropdownOpen ? 'scale-y-100' : 'scale-y-0'
					}`}
				>
					{RANGE_OPTIONS.map(opt => (
						<p
							key={opt.label}
							className={`transition duration-100 ease-in-out transform text-sm font-sofia-bold px-2 py-2.5 text-font-reg cursor-pointer ${
								monthRange === opt.value
									? 'bg-change-title bg-opacity-50'
									: 'hover:bg-change-title hover:bg-opacity-10'
							} ${isDropdownOpen ? 'scale-y-100' : 'scale-y-0'}`}
							onClick={() => setMonthRange(opt.value)}
						>
							{opt.label}
						</p>
					))}
				</div>
			</div>
			<Component.HolText styles="text-sm font-dark font-sofia-bold">
				Date: {Util.getParsedDate(date)}
			</Component.HolText>
		</>
	);
};
