import { useState, useEffect, useRef } from 'react';
import { FaCommentAlt } from 'react-icons/fa';
import Modal from 'react-modal';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { InsightsCommentsApi } from 'api';
import { useTypedSelector, useOnClickOutside, useActions } from 'hooks';

import { CommentRow } from './components';
import { TABLE_HEADERS } from './constants';

/**
 * This is the modal that displays the insights comments
 * @author Misael Esperanzate & Konstantin Krumin
 */
interface InsightsCommentsTableProps {}

export const InsightsCommentsTable: React.FC<InsightsCommentsTableProps> = () => {
	const [isOpenModal, setOpenModal] = useState(false);

	const { currentWorkspace, userId } = useTypedSelector(state => state.user);
	const factorType = useTypedSelector(state => state.factor.factorState.title);
	const { comments, itemsPerPage, currentPage, totalInsightsCommentsCount } = useTypedSelector(
		state => state.insightsComments
	);

	const {
		updateComment,
		deleteComment,
		setInsightsComments,
		setInsightsCommentsCount,
		setInsightsCommentsCurrentPage
	} = useActions();

	const Api = InsightsCommentsApi();

	const node = useRef<HTMLInputElement | null>(null);

	useOnClickOutside(node, () => setOpenModal(false));

	const loadComments = () => {
		const pageNum = Util.setupPageNumToDisplay(comments.length, currentPage);
		setInsightsCommentsCurrentPage(pageNum);

		Api.getComments(currentWorkspace.id, pageNum, itemsPerPage)
			.then(result => {
				setInsightsComments(result.results);
				setInsightsCommentsCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
	};

	useEffect(() => {
		loadComments();
		// eslint-disable-next-line
	}, [setInsightsComments, userId, currentWorkspace.id]);

	const showComments = () => {
		loadComments();

		setOpenModal(true);
		Analytics.seeCommentsOverlay(factorType);
	};

	const handleEdit = (commentInfoUuid: string, editedComment: string) => {
		Api.updateComment(currentWorkspace.id, userId, commentInfoUuid, editedComment)
			.then(() => {
				updateComment(commentInfoUuid, editedComment);
				Analytics.updateComment();
			})
			.then(() => {
				loadComments();
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const handleDelete = (commentInfoUuid: string) => {
		Api.deleteComment(currentWorkspace.id, userId, commentInfoUuid)
			.then(() => {
				deleteComment(commentInfoUuid);
				Analytics.deleteComment();
			})
			.then(() => {
				loadComments();
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const handlePageChange = (updatedPageNum: number) => {
		setInsightsCommentsCurrentPage(updatedPageNum);

		Api.getComments(currentWorkspace.id, updatedPageNum, itemsPerPage)
			.then(result => {
				setInsightsComments(result.results);
				setInsightsCommentsCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<>
			<Component.HolTooltip message="Comments" bgColor="bg-hol-dark-green">
				<Component.HolIconButton icon={<FaCommentAlt />} onClick={showComments} />
			</Component.HolTooltip>

			<Modal
				isOpen={isOpenModal}
				className="bg-white min-w-5xl max-w-7xl mx-auto my-auto rounded shadow-lg p-4 z-50"
				overlayClassName="fixed flex bg-gray-50 bg-opacity-40 inset-0 h-screen w-screen z-50 overflow-auto"
				ariaHideApp={false}
			>
				<div ref={node}>
					<Component.HolText textType="heading1">Comments</Component.HolText>

					{comments.length > 0 ? (
						<>
							<div
								className="shadow overflow-auto border-b border-gray-200 my-4"
								style={{ maxHeight: '70vh' }}
							>
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-hol-light-blue sticky top-0">
										<tr>
											{TABLE_HEADERS.map(colHeader => {
												return (
													<th
														scope="col"
														key={colHeader.type}
														className={`px-6 py-3 text-left text-sm font-medium text-font-reg uppercase tracking-wider ${
															colHeader.type === 'column' && 'w-1/3'
														}`}
													>
														{colHeader.text}
													</th>
												);
											})}
										</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200">
										{comments.map(commentInfo => {
											return (
												<CommentRow
													commentInfo={commentInfo}
													key={commentInfo.uuid}
													onDelete={handleDelete}
													onEdit={handleEdit}
												/>
											);
										})}
									</tbody>
								</table>
							</div>

							<Component.StatelessPagination
								arrayName="comment(s)"
								bgColor="bg-hol-light-blue"
								iconColor="text-hol-light-blue"
								itemsPerPage={itemsPerPage}
								totalCount={totalInsightsCommentsCount}
								currentListLength={comments.length}
								onChangePage={handlePageChange}
								currentPage={currentPage}
							/>
						</>
					) : (
						<div className="flex justify-center p-12">
							<Component.HolText textType="heading3">
								There are no comments...
							</Component.HolText>
						</div>
					)}

					<div className="flex justify-end space-x-5">
						<Component.HolButton
							label="Close"
							type="secondary"
							onClick={() => {
								setOpenModal(false);
							}}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};
