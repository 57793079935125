import React, { useEffect, useRef } from 'react';

import * as Util from 'utils';
import * as Component from 'components';

import { ReportApi } from 'api';
import { useActions, useTypedSelector } from 'hooks';

/**
 * Displays the Change page which lets the user create charts
 * to view the changes of the factors depending on a specified date.
 */
export const Change: React.FC = () => {
	const { currentWorkspace, userId } = useTypedSelector(state => state.user);
	const { reports, currentPage, itemsPerPage, totalReportsCount, fullscreenReport } =
		useTypedSelector(state => state.report);

	const { setReports, setTotalReportsCount, setReportsCurrentPage } = useActions();

	const Api = ReportApi();
	const componentRef = useRef(null);

	// retrieve created/shared charts from database
	useEffect(() => {
		Api.getReports(currentWorkspace.id, userId, 1, itemsPerPage)
			.then(result => {
				setReports(result.results);
				setTotalReportsCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
		// eslint-disable-next-line
	}, [setReports, userId, currentWorkspace.id]);

	const handlePageChange = (updatedPageNum: number) => {
		setReportsCurrentPage(updatedPageNum);

		Api.getReports(currentWorkspace.id, userId, updatedPageNum, itemsPerPage)
			.then(result => {
				setReports(result.results);
				setTotalReportsCount(result.length);
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<div ref={componentRef} className="max-w-screen-2xl mx-auto mb-16">
			<Component.PrintOrientation orientation="landscape" />
			<Component.PageHeader
				showPrintButton
				parentComponentRef={componentRef}
				pageType="change"
			/>

			<div className="grid grid-cols-12 gap-6 print:block">
				{!fullscreenReport ? (
					<>
						{/* Existing reports */}
						{reports.map(report => {
							return <Component.ReportChart key={report.id} report={report} />;
						})}

						{/* Report Builder */}
						<div className="col-span-full lg2:col-span-6 print-hide">
							<Component.ReportBuilderOverlay />
						</div>
					</>
				) : (
					<>
						{reports
							.filter(report => report.id === fullscreenReport.id)
							.map(report => {
								return <Component.ReportChart key={report.id} report={report} />;
							})}
					</>
				)}
			</div>

			{reports && !fullscreenReport && (
				<Component.StatelessPagination
					arrayName="chart(s)"
					bgColor="bg-hol-dark-purple"
					iconColor="text-hol-dark-purple"
					itemsPerPage={itemsPerPage}
					totalCount={totalReportsCount}
					currentListLength={reports.length}
					onChangePage={handlePageChange}
					currentPage={currentPage}
				/>
			)}
		</div>
	);
};
