import { IFactorCard } from 'types';

import ActionType from '../action-types';
import { SetSelectedFactorAction } from '../actions';

/**
 * setting the actions and payload for factor cards
 * @param factorCard - the selected factor card
 */
export const setSelectedFactor = (factorCard: IFactorCard): SetSelectedFactorAction => {
	return {
		type: ActionType.SET_SELECTED_FACTOR,
		payload: {
			factorCard
		}
	};
};
