import { IColumnChartSeries, ValueFormatType } from 'types';

export const formatValue = (value: string, type: ValueFormatType) => {
	switch (type) {
		case 'percentage':
			return Math.round(parseFloat(value) * 100);
		case 'integer':
			return parseInt(value);
		case 'float':
			return parseFloat(value);
	}
};

export const findLargestDatasetLength = (series: IColumnChartSeries[]) => {
	let largestDataset = 0;

	series.forEach(serie => {
		const currDatasetLength = serie.data.length;
		largestDataset = currDatasetLength;
	});

	return largestDataset;
};
