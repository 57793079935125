import { useEffect, useState } from 'react';

import { IResultHeader } from '@gooddata/sdk-backend-spi';
import { useExecution, useDataView, useBackend, useCancelablePromise } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';
import * as Util from 'utils';

import { DemographicType } from 'types';
import { DEMOGRAPHIC_LDM } from 'constants/dataset';
import { useAxiosPrivate, useTypedSelector } from 'hooks';

export const useWorkspaceDemographics = () => {
	const workspace = useTypedSelector(state => state.user.currentWorkspace);

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [demographicGroups, setDemographicGroups] = useState<DemographicType[] | null>(null);
	const [nestedDemographicGroups, setNestedDemographicGroups] = useState<{
		[key: string]: string[];
	} | null>(null);

	const { serverAxios } = useAxiosPrivate();

	const backend = useBackend();

	const execution = useExecution({
		seriesBy: [Ldm.EmployeesCount],
		slicesBy: [Ldm.OrganizationId]
	});

	const { result } = useDataView({ execution }, [execution?.fingerprint()]);

	// Gets the subgroups in a demographic group
	useCancelablePromise(
		{
			promise: async () => {
				const nestedGroups: { [key: string]: string[] } | null = {};

				if (demographicGroups) {
					demographicGroups.map(async d => {
						const result = await backend
							?.workspace(workspace.id)
							?.execution()
							?.forItems([Ldm.EmployeesCount, DEMOGRAPHIC_LDM[d]])
							?.execute();

						if (result) {
							try {
								const res = await result?.readAll();

								if (res) {
									const userCount = res?.data[0] as number[];
									const subGroups = res?.headerItems[1][0] as IResultHeader[];

									// Only return subgroups with > 6 users.
									// The number of users in subGroup[x] is userCount[x]

									if (!subGroups) {
										return;
									}

									const validSubGroups = subGroups
										.filter((_, i) => userCount[i] > 6)
										.filter((d: any) => d.attributeHeaderItem.name !== '')
										.map((d: any) => d.attributeHeaderItem.name);

									nestedGroups[d] = validSubGroups;
								}
							} catch (err: any) {
								Util.logErrorMessage(err);
							}
						}
					});

					setNestedDemographicGroups(nestedGroups);
				}
			}
		},
		[demographicGroups]
	);

	useEffect(() => {
		if (result) {
			const id: any = result.dataView.headerItems[0][0][0];

			const organizationId = id?.attributeHeaderItem.name;

			serverAxios
				.get(`${process.env.REACT_APP_HOST}/workspace/demographic_groups/${organizationId}`)
				.then(response => {
					const demographicGroups = response.data.map((d: any) => d.demographic);

					setDemographicGroups(demographicGroups);
					setIsLoading(false);
				})
				.catch(error => {
					throw error;
				});
		}

		// eslint-disable-next-line
	}, [result]);

	return { demographicGroups, nestedDemographicGroups, isLoading };
};
