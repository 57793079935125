import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import { VscListFilter } from 'react-icons/vsc';

import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { IDemographicFilter, IDemographicSelected } from 'types';
import { useActions, useTypedSelector, useWorkspaceDemographics } from 'hooks';

import { AppliedFiltersInfo } from './components';

/**
 * Overlay component that allows users to apply demographic filters globally within the web app
 */
interface DemographicFilterOverlayProps {}

export const DemographicFilterOverlay: React.FC<DemographicFilterOverlayProps> = () => {
	const { filter: appliedFiltersArr, selected: appliedDemographics } = useTypedSelector(
		state => state?.demographics
	);

	const [selectedDemographics, setSelectedDemographics] = useState<IDemographicSelected[]>([]);
	const [defaultSelected, setDefaultSelected] = useState<{ [key: string]: IDemographicFilter }>(
		{}
	);

	const [isOpenModal, setOpenModal] = useState<boolean>(false);

	const { setDemographic } = useActions();
	const { demographicGroups, isLoading } = useWorkspaceDemographics();

	useEffect(() => {
		setDefaultSelected(appliedDemographics);
	}, [appliedDemographics]);

	const demographicHandler = (
		groupDemographics: { [key: string]: IDemographicFilter } | null
	) => {
		if (!groupDemographics) {
			setSelectedDemographics([]);
			setDefaultSelected({});

			return;
		}

		const demoKeys = Object.keys(groupDemographics);

		const demographicFilters: IDemographicSelected[] = demoKeys.map(d => {
			const demoInfo = groupDemographics[d];

			return {
				attribute: demoInfo.demographicLdm.attribute,
				demographicLabel: d,
				groupsSelected: demoInfo.demographicSubGroups
			} as IDemographicSelected;
		});

		setSelectedDemographics(demographicFilters);
		setDefaultSelected(groupDemographics);
	};

	const applyChanges = () => {
		setDemographic(selectedDemographics, defaultSelected);
		Analytics.applyDemographicFilter();

		setOpenModal(false);
	};

	const cancelChanges = () => {
		setSelectedDemographics(appliedFiltersArr);
		setDefaultSelected(appliedDemographics);

		setOpenModal(false);
	};

	return (
		<>
			<div className="flex items-center relative print-hide">
				<Component.HolButton
					label="Filters"
					icon={<VscListFilter className="text-2xl" />}
					onClick={() => setOpenModal(!isOpenModal)}
				/>

				{Object.keys(appliedDemographics).length > 0 && (
					<Component.HolTooltip
						message={<AppliedFiltersInfo />}
						styles="ml-48 md2:m-6 md2:mr-48"
						bgColor="bg-hol-orange"
					>
						<Component.HolBadge
							text={Object.keys(appliedDemographics).length.toString()}
							styles="absolute top-0 right-0 transform -translate-y-1 translate-x-2"
						/>
					</Component.HolTooltip>
				)}
			</div>

			<Modal
				isOpen={isOpenModal}
				className="bg-white w-11/12 sm:max-w-md  mx-auto my-auto rounded shadow-lg p-4 z-50"
				overlayClassName="fixed flex bg-gray-50 bg-opacity-40 inset-0 h-screen w-screen z-50 overflow-auto"
				ariaHideApp={false}
			>
				<p className="font-sofia-bold text-2xl mb-4">Filter</p>

				<div className="flex space-x-3">
					<div className="mt-2 text-font-light-1 font-sofia-reg text-lg w-1/4">
						Filtered by:
					</div>

					<Component.DemographicSelect
						selectedDemographics={defaultSelected}
						setDemographicSelected={(d: { [key: string]: IDemographicFilter } | null) =>
							demographicHandler(d)
						}
					/>
				</div>

				{!isLoading && demographicGroups !== null && demographicGroups.length === 0 && (
					<div className="mt-2 mr-2">
						<Component.HolText>
							Demographic information is not available for this organization. Make
							sure that employees complete demographic questions in the Pulse app.
						</Component.HolText>
					</div>
				)}

				{!isLoading && selectedDemographics.length > 2 && (
					<div className="mt-3">
						<Component.HolText textColor="text-red">
							* Limit of 2 filters reached. Please choose maximum 2 categories
						</Component.HolText>
					</div>
				)}

				<div className="flex justify-end space-x-5 mt-7">
					<Component.HolButton label="Cancel" type="secondary" onClick={cancelChanges} />

					{!isLoading && demographicGroups !== null && demographicGroups.length > 0 && (
						<Component.HolButton
							type="primary"
							label="Confirm"
							isDisabled={selectedDemographics.length > 2}
							onClick={applyChanges}
						/>
					)}
				</div>
			</Modal>
		</>
	);
};
