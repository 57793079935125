import { SVGIconProps } from 'types';

export const MyLibraries: React.FC<SVGIconProps> = ({ fill = '#666' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill={fill}>
		<path
			id="Path_303"
			data-name="Path 303"
			d="M1370.467,1380.52c-.392,0-.784-.015-1.175,0-.214.011-.25-.065-.247-.258.011-.73,0-1.46.006-2.191a1.171,1.171,0,0,0-1.228-1.232c-3.26.018-6.519.007-9.779.011a.347.347,0,0,1-.325-.166c-.6-.852-1.208-1.7-1.807-2.548a.331.331,0,0,0-.31-.156q-3.7.006-7.409,0a1.114,1.114,0,0,0-1.166,1.163q0,8.843,0,17.685a1.053,1.053,0,0,0,1.15,1.148h15.116q1.5,0,3.007,0a1.039,1.039,0,0,0,.993-.666q1.024-2.811,2.044-5.623,1.047-2.877,2.1-5.752A1.1,1.1,0,0,0,1370.467,1380.52Zm-22.612,9.594v-14.856c0-.4.039-.442.447-.442q3.426,0,6.851,0a.384.384,0,0,1,.361.18c.579.825,1.172,1.64,1.751,2.465a.417.417,0,0,0,.387.2q5.058-.009,10.117,0c.372,0,.452.08.452.456,0,.724-.007,1.447.005,2.171,0,.195-.052.24-.243.24q-7.807-.008-15.613,0a1.065,1.065,0,0,0-1.183.818q-1.226,3.34-2.446,6.683-.406,1.111-.815,2.22c-.01.029-.026.056-.039.083l-.032-.012Zm22.747-8.271q-1.318,3.624-2.641,7.246c-.463,1.27-.928,2.539-1.383,3.812a.343.343,0,0,1-.381.26q-4.51-.008-9.021,0H1348.2a.433.433,0,0,1-.235-.023c-.139-.086-.13-.2-.078-.346.412-1.119.818-2.24,1.225-3.361q1.431-3.932,2.861-7.864a.334.334,0,0,1,.363-.241c.584,0,1.168,0,1.752,0h16.15C1370.631,1381.324,1370.738,1381.469,1370.6,1381.843Z"
			transform="translate(-1347.027 -1373.985)"
		/>
	</svg>
);
