import './styles/print/globalPrintStyling.scss';

import React, { useState, useEffect } from 'react';

import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { Route, Routes, useLocation } from 'react-router-dom';

import { WorkspaceProvider } from '@gooddata/sdk-ui';
import { LoadingComponent } from '@gooddata/sdk-ui/esm/base/react/LoadingComponent';

import { OktaContext, UserContext } from 'utils/auth';

import * as Img from 'img';
import * as Page from 'pages';
import * as Component from 'components';

import { WorkspaceApi } from 'api';
import { useAxiosPrivate, useActions, useTypedSelector } from 'hooks';

import { IGoodDataResourceUser } from 'App.types';
import { IAuthInfo, IWorkspaceTypes } from 'types';

const App: React.FC = () => {
	const [loadError, setLoadError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [chatName, setChatName] = useState('');

	const { setUserAuthenticated } = useActions();

	const authContext = useTypedSelector(state => state.user);
	const { showError } = useTypedSelector(state => state.error);
	const { toggle } = useTypedSelector(state => state.manageConsult);
	const { userInfo, currentWorkspace } = useTypedSelector(state => state.user);

	const Auth = OktaContext();
	const UserAuthHelper = UserContext();

	const { gooddataAxios } = useAxiosPrivate();

	const { getWorkspaceTypes } = WorkspaceApi();

	const location = useLocation();

	useEffect(() => {
		if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
			ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
		}

		if (process.env.REACT_APP_HJID && process.env.REACT_APP_HJSV) {
			hotjar.initialize(
				parseInt(process.env.REACT_APP_HJID),
				parseInt(process.env.REACT_APP_HJSV)
			);
		}
	}, []);

	useEffect(() => {
		ReactGA.pageview(location.search);
	}, [location]);

	// Authenticate user before displaying app
	useEffect(() => {
		async function authUserAndRetrieveAvailableWorkspaces() {
			const redirected = new URLSearchParams(location.search).get('redirected');

			let authInfo: IAuthInfo = authContext;

			/**
			 * Chain of axios requests to fullfill user authentication process.
			 * First request is to get user information from GoodData endpoint.
			 */
			gooddataAxios
				.get<IGoodDataResourceUser>(
					`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/account/profile/current`
				)
				.then(authResp => {
					authInfo.userInfo.email = authResp.data.accountSetting.email;
					authInfo.userInfo.name =
						authResp.data.accountSetting.firstName +
						' ' +
						authResp.data.accountSetting.lastName;

					authInfo.userInfo.profileId = authResp.data.accountSetting.links.self
						.split('/')
						.pop()!;

					setChatName(authInfo.userInfo.name);

					// endpoint that can be used to retrieve all workspace/projects accessible to the user.
					const goodDataAccessibleProjects = authResp.data.accountSetting.links.projects;
					authInfo.userInfo.isAuth = true;

					setUserAuthenticated(authInfo);

					return { authInfo, goodDataAccessibleProjects };
				})
				.then(async ({ authInfo, goodDataAccessibleProjects }) => {
					let workspaceTypes: IWorkspaceTypes[] = await getWorkspaceTypes();

					return { authInfo, goodDataAccessibleProjects, workspaceTypes };
				})
				.then(async ({ authInfo, goodDataAccessibleProjects, workspaceTypes }) => {
					await UserAuthHelper.getAccessibleWorkspaces(
						authInfo,
						goodDataAccessibleProjects,
						workspaceTypes
					);
				})
				.then(() => {
					setIsLoading(false);
				})
				.catch(() => {
					if (redirected) {
						setLoadError(true);
						setIsLoading(false);
					} else {
						Auth.handleRedirect();
					}
				});
		}

		authUserAndRetrieveAvailableWorkspaces();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return (
			<div className="flex flex-1 justify-center items-center h-screen overflow-hidden bg-default-background bg-opacity-25">
				<div className="flex flex-col justify-center items-center space-y-3">
					<Img.Logo className="h-12" />

					<LoadingComponent />
				</div>
			</div>
		);
	}

	if (!isLoading && loadError) {
		return <Page.CookieError />;
	}

	if (!isLoading && !loadError && userInfo.isAuth && !currentWorkspace.id) {
		return (
			<div className="flex flex-1 justify-center items-center h-screen overflow-hidden bg-default-background bg-opacity-25">
				<div className="flex flex-col justify-center items-center space-y-3">
					<Img.Logo className="h-12" />

					<Component.HolText styles="max-w-xl">
						We apologize for the inconvenience, but it appears that you do not currently
						have access to any workspaces. In order to gain access, please contact your
						administrator and request to be added to at least one workspace. Once added,
						you will be able to access it without any issues.
					</Component.HolText>

					<Component.HolButton
						label="Logout"
						type="secondary"
						onClick={() => Auth.oktaLogout()}
					/>
				</div>
			</div>
		);
	}

	return (
		<WorkspaceProvider workspace={currentWorkspace.id}>
			{showError && <Component.DataError />}
			<div className="bg-default-background bg-opacity-25 flex h-screen overflow-hidden">
				<Component.ChatWidget chatName={chatName} />
				<Component.Navigation />
				{/* Content */}
				<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
					{/* Pages */}
					<div className="p-5 pt-70px sm:px-6 md:px-11 w-full max-w-10xl">
						<Routes>
							<Route path="*" element={<Page.NotFound />} />

							{toggle ? (
								<Route path="/" element={<Page.AdminOverview />} />
							) : (
								[
									<Route key="/" path="/" element={<Page.Overview />} />,
									<Route
										key="/factors"
										path="/factors"
										element={<Page.Factors />}
									/>,
									<Route
										key="/change"
										path="/change"
										element={<Page.Change />}
									/>,
									<Route
										key="/action-plans"
										path="/action-plans"
										element={<Page.ActionPlans />}
									/>,
									<Route
										key="/action-plan"
										path="/action-plan/:uuid"
										element={<Page.ActionPlan />}
									/>,
									<Route
										key="/diagnostics"
										path="/diagnostics"
										element={<Page.Diagnostics />}
									/>,
									<Route
										key="/support"
										path="/support"
										element={<Page.Support />}
									/>,
									<Route
										key="/users"
										path="/users"
										element={
											userInfo.role === 'Organization Viewer' ? (
												<Page.NotFound />
											) : (
												<Page.Management />
											)
										}
									/>,
									<Route
										key="/account"
										path="/account"
										element={<Page.Account />}
									/>
								]
							)}
						</Routes>
					</div>
				</div>
			</div>
		</WorkspaceProvider>
	);
};

export default App;
