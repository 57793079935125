import React from 'react';

import * as Img from 'img';
import * as Component from 'components';

export const CookieError: React.FC = () => {
	return (
		<div className="flex flex-1 justify-center items-center h-screen overflow-hidden bg-default-background bg-opacity-25">
			<div className="flex flex-col justify-center items-center space-y-3">
				<Img.Logo className="h-12" />

				<Component.HolText styles="max-w-xl">
					This web application uses cookies to retreive your data from GoodData. Please
					accept third party cookies to proceed.
				</Component.HolText>
			</div>
		</div>
	);
};
