import { DiagnosticsOptions } from 'enums';
import { ICurrentDiagnostic, HolmetricsDiagnosticType } from 'types';

/**
 * Helper function to map selected Diagnostic from the Overview page to corresponding Diagnostic tab on the Diagnostics page
 * @author Konstantin Krumin
 * @param diagnosticType - diagnostic type selected on the overview page
 * @returns selected Diagnostic option suitable for Diagnostics tabs
 */
export const setDiagnosticsSelectedOption = (diagnosticType: HolmetricsDiagnosticType) => {
	let selectedOption: ICurrentDiagnostic = {
		label: 'Chats',
		value: DiagnosticsOptions.CHATS
	};

	switch (diagnosticType) {
		case 'eNPS':
			selectedOption = { label: 'eNPS', value: DiagnosticsOptions.ENPS };
			break;
		case 'pulseParticipation':
			selectedOption = { label: 'Pulse', value: DiagnosticsOptions.PULSE };
			break;
		case 'activityOWH':
			selectedOption = { label: 'Meetings', value: DiagnosticsOptions.MEETINGS };
			break;
	}

	return selectedOption;
};
