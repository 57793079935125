import React, { useRef } from 'react';

import * as Component from 'components';

/**
 * Displays the Account page
 * @author Konstantin Krumin
 */
export const Account: React.FC = () => {
	const componentRef = useRef(null);

	return (
		<div ref={componentRef} className="max-w-screen-2xl mx-auto">
			<Component.PrintOrientation orientation="landscape" />
			<Component.PageHeader
				showPrintButton
				parentComponentRef={componentRef}
				pageType="account"
			/>

			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-full xl:col-span-4">
					<Component.WorkspacesCard />
				</div>
			</div>
		</div>
	);
};
