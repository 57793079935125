import { SetManagementToggleAction } from '../actions';
import ActionType from '../action-types';

/**
 * Holds the toggle selected actions
 * @author Misael Esperanzate
 * @param toggle - the indicator if user wants to be in summary view or not
 */

export const setManagementToggle = (toggle: boolean): SetManagementToggleAction => {
	return {
		type: ActionType.SET_MANAGEMENT_TOGGLE,
		payload: {
			toggle: toggle
		}
	};
};
