import React, { useState } from 'react';

import * as Component from 'components';

import { IWorkspace } from 'types';
import { useTypedSelector } from 'hooks';

/**
 * The management consultant overview page where the user can view factor scores from each company
 * @author Misael Esperanzate
 */
export const AdminOverview: React.FC = () => {
	const { workspaces } = useTypedSelector(state => state.user);
	const [currentPageWorkspace, setCurrentPageWorkspace] = useState<IWorkspace[]>(workspaces);

	return (
		<div className="max-w-screen-2xl mx-auto">
			<Component.PageHeader pageType="admin-overview" />

			<div className="grid grid-cols-8 gap-6 mb-3">
				{currentPageWorkspace.length > 0 &&
					currentPageWorkspace.map(workspace => {
						return (
							<Component.AdminOverviewCard key={workspace.id} workspace={workspace} />
						);
					})}
			</div>

			<Component.StatefulPagination
				bgColor="bg-users-title"
				iconColor="text-users-title"
				itemsPerPage={8}
				hideNumItems={true}
				itemList={workspaces}
				setPageList={setCurrentPageWorkspace}
			/>
		</div>
	);
};
