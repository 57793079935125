import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { ISeriesConfig } from 'types';
import { useTypedSelector } from 'hooks';
import { MISC_COLORS } from 'constants/misc';

/**
 * Displays a KPI / Operation chart
 * @author Konstantin Krumin
 * @param title - title of the chart to display
 * @param startDate - start date for the KPI chart
 */
interface KpiChartProps {
	title: string;
	startDate: Date;
}

export const KpiChart: React.FC<KpiChartProps> = ({ title, startDate }) => {
	const dateAsset = useTypedSelector(state => state.dataView.dateAsset);
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const dateFilter = Util.getDateFilter(Ldm.DateDatasets.Date.identifier, startDate);

	const absoluteDateFilterObj = dateFilter?.[0]?.absoluteDateFilter;
	const fromDate = Util.generateLocaleDate(absoluteDateFilterObj?.from);
	const toDate = Util.generateLocaleDate(absoluteDateFilterObj?.to);

	// Format xAxis label
	const monthYear = Util.getMonthYear(startDate);

	return (
		<Component.HolCard
			title={title}
			headerTitleTextStyle="pl-10 text-sm font-dark font-sofia-bold"
			columns="col-span-full text-font-dark lg2:col-span-6 border-2 rounded-md border-font-light"
			styles="print-page-break"
			topRightElement={
				<div className="flex justify-between items-center space-x-7">
					<Component.HolText styles="text-sm font-dark font-sofia-regular">
						{fromDate} to {toDate}
					</Component.HolText>

					<Component.HolText styles="text-sm font-dark font-sofia-bold">
						Date: {Util.getParsedDate(startDate)}
					</Component.HolText>
				</div>
			}
		>
			<Execute
				filters={dateFilter}
				slicesBy={[dateAsset]}
				seriesBy={[Ldm.OMValue.Avg]}
				workspace={currentWorkspace.id}
				onError={errInfo => {
					if (errInfo.message !== 'CANCELLED') {
						Util.logErrorMessage(errInfo);
					}
				}}
			>
				{({ result, isLoading }) => {
					const series = Util.extractDataSeriesInArr(result);
					const slices = result?.data().slices().toArray();

					const xAxisDates =
						slices?.map(slice => {
							const dateStr = slice.sliceTitles()[0];
							return `${dateStr.substring(0, 3)} ${dateStr.substr(-4)}`;
						}) ?? [];

					let yAxisMax = 0;

					let seriesConfig: Set<ISeriesConfig> = new Set();

					// Creates the series for the operation metric
					if (series) {
						series?.forEach(series => {
							// Get max y value
							yAxisMax = Math.max(...series.rawData().map(Number));

							const seriesObj = {
								name: title,
								type: 'spline',
								data: series
									.rawData()
									.map(Number)
									.map(num => Util.round(num, 2)),
								yAxis: 1,
								color: MISC_COLORS.green,
								marker: {
									radius: 5,
									lineWidth: 2,
									lineColor: null
								}
							};

							seriesConfig.add(seriesObj);
						});
					}

					// Array of values, everything is 0 except for the month of change
					const bandData = [...xAxisDates].map((_, i) => {
						if (xAxisDates.indexOf(monthYear) === i) {
							// + 5 to pad top of bar plot
							return yAxisMax + 5;
						}
						return 0;
					});

					const holChangeOptions = Util.lineChartConfig(
						xAxisDates,
						[...Array.from(seriesConfig)],
						bandData,
						yAxisMax,
						true
					);

					return isLoading ? (
						<div className="my-auto">
							<LoadingComponent />
						</div>
					) : (
						<div className="mt-2 p-5">
							<HighchartsReact highcharts={Highcharts} options={holChangeOptions} />
						</div>
					);
				}}
			</Execute>
		</Component.HolCard>
	);
};
