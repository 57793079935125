import { IGooddataRoleInfo, RoleType } from 'types';

export const SHORT_MONTHS = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];

export const USER_ROLES: { [id in RoleType]: IGooddataRoleInfo } = {
	Holmetrics: {
		gdRole: 'Admin',
		roleId: '2'
	},
	'Consultant Admin': {
		gdRole: 'Editor + User Admin',
		roleId: '11'
	},
	// 'Consultant': {
	// 	gdRole: 'Editor + User Admin',
	// 	roleId: '11'
	// },
	// 'Organization Admin': {
	// 	gdRole: 'Editor + User Admin',
	// 	roleId: '11'
	// },
	'Organization Viewer': {
		gdRole: 'Viewer',
		roleId: '4'
	}
};
