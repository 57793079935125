import * as Component from 'components';

interface NoResponseTextProps {}

export const NoResponseText: React.FC<NoResponseTextProps> = () => {
	return (
		<div className="flex justify-center p-4">
			<Component.HolText>No Responses</Component.HolText>
		</div>
	);
};
