import { Series } from 'highcharts';
import { IAttribute } from '@gooddata/sdk-model/esm/execution/attribute';
import { DataViewFacade } from '@gooddata/sdk-ui/esm/base/results/facade';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model/esm/execution/filter/factory';

import * as Ldm from 'ldm';

import { DiagnosticsOptions } from 'enums';
import { KpiValueType } from 'types';
import { RELATIVE_DATE_FILTERS } from 'constants/dataset';
import { DataViewType, IDemographicSelected } from 'types';

import { round } from '../round';

/**
 * Function that creates an array of new positive attribute filters based on demographic and groups.
 * @param demographics - an array that stores objects with selected demographics and groups
 * @returns IPositiveAttributeFilter[]
 */
export const getDemographicFilter = (demographics: IDemographicSelected[]) => {
	return (
		demographics?.map(demographic => {
			const attribute = demographic as IAttribute;
			const groupsSelected = demographic.groupsSelected;

			return newPositiveAttributeFilter(attribute, groupsSelected);
		}) ?? []
	);
};

/**
 * Helper function that gets the right date for the specified dataView
 */
export const getDateAssetByDataView = (dataView: DataViewType) => {
	switch (dataView) {
		case 'Week':
		case 'BiWeek':
			return Ldm.DateDatasets.Date.Date.DdMmYyyy;
		case 'Month':
			return Ldm.DateDatasets.Date.WeekSunSatYear.FromTo;
		case 'Quarter':
		case 'Year':
			return Ldm.DateDatasets.Date.MonthYear.Long;
	}
};

/**
 * Helper function that returns a relative filter based on chosen dataView
 */
export const getFilterDatebyDataView = (dataView: DataViewType) => {
	switch (dataView) {
		case 'Week':
			return RELATIVE_DATE_FILTERS.lastWeek;
		case 'BiWeek':
			return RELATIVE_DATE_FILTERS.lastTwoWeeks;
		case 'Month':
			return RELATIVE_DATE_FILTERS.lastFourWeeks;
		case 'Quarter':
			return RELATIVE_DATE_FILTERS.lastQuarter;
		case 'Year':
			return RELATIVE_DATE_FILTERS.lastYear;
	}
};

/**
 * Helper function to get a numeric value of the latest data point from the Execute component
 * @author Konstantin Krumin
 * @param executeInputValue - incoming value from the Execute component's result
 */
export const getLatestDataPointVal = (
	executeInputValue: DataViewFacade,
	placeholderToShowIfEmpty: 'No Data' | '-' | 0 = 'No Data'
): KpiValueType => {
	const values = executeInputValue?.rawData().data();
	const lastValue = values[values.length - 1];

	if (lastValue) {
		return Math.round(parseFloat(lastValue.toString()));
	}

	return placeholderToShowIfEmpty;
};

/**
 * Helper function to get a readable percentage value from a string
 * @author Konstantin Krumin
 * @param value - incoming string value to be converted into readable percentage number
 */
export const convertStrPercentageToIntNum = (value: string) => {
	// Call round function to get two decimal places then multiply by 100 to get percent.
	let percentDecimal = round(parseFloat(value), 2) * 100;
	// Get two decimal places then convert back to number.
	// Reason why we get two decimal places again is to cover case like 7.0000001 but we only want 7.
	return +parseFloat(percentDecimal.toString()).toFixed(2);
};

/**
 * Helper function to get a readable percentage value from the Execute component most recent data point
 * @author Konstantin Krumin
 * @param executeInputValue - incoming value from the Execute component's result
 */
export const createReadablePercentageValue = (
	executeInputValue: DataViewFacade,
	placeholderToShowIfEmpty: 'No Data' | '-' | 0 = 'No Data'
) => {
	const values = executeInputValue?.rawData().data();
	const lastValue = values[values.length - 1];

	if (lastValue) {
		return convertStrPercentageToIntNum(lastValue.toString());
	}

	return placeholderToShowIfEmpty;
};

/**
 * Helper function that determines whether a percent sign should be displayed for a tooltip chart
 * @author Konstantin Krumin
 * @param seriesData - input data for the current column
 */

export const showPercentSign = (seriesData: Series) => {
	if (seriesData.chart.options.title?.text === DiagnosticsOptions.CONNECTIVITY) {
		return true;
	}

	return false;
};

/**
 * Helper function to determine page number to display next after an item was removed from current array
 * @author Konstantin Krumin
 * @param arrLength - array length at time of removal
 * @param currPage - current page
 */
export const setupPageNumToDisplay = (arrLength: number, currPage: number) => {
	if (arrLength === 1 && currPage !== 1) return currPage - 1;

	return currPage;
};

/**
 * Helper function to extract data series, returned by GoodData Execute component, as an array
 * @author Konstantin Krumin
 * @param arr - array returned by GoodData
 * @returns data array
 */
export const extractDataSeriesInArr = (arr: DataViewFacade | undefined) => {
	return arr?.data().series().toArray() ?? [];
};

/**
 * Helper function that determines correct score display text format
 * @author Konstantin Krumin
 * @param value - score value
 * @param isPercentageValue - a boolean determining whether it is a percentage value
 * @param isPlusOrMinusToShow - a boolean determining whether +/- sign should be showed
 */
export const scoreDisplayText = (
	value: KpiValueType,
	isPercentageValue: boolean,
	isPlusOrMinusToShow: boolean,
	overwriteText?: string | undefined
) => {
	if (overwriteText) return overwriteText;

	if (value === '-') return '-';
	if (value === 'No Data') return 'No Data';
	if (isPercentageValue) return `${value.toFixed(0)}%`;

	if (isPlusOrMinusToShow) {
		if (value === 0) return value;

		return value > 0 ? '+' + Math.abs(value).toFixed(0) : '-' + Math.abs(value).toFixed(0);
	}

	return value.toFixed(0);
};

export const createSplineData = (values: any[], dates: any[], customYAxisLabels?: string[]) => {
	if (values.length > 1 && dates.length > 1) {
		return values?.map((val, idx) => {
			const yval = parseInt(val[0]);
			const date = dates[idx];
			const isLastData = idx === values.length - 1;

			return {
				y: yval,
				name: customYAxisLabels
					? date.attributeHeaderItem.name + ` : ${customYAxisLabels[idx]}`
					: date.attributeHeaderItem.name,
				marker: {
					lineWidth: 5.5,
					radius: isLastData ? 12 : 0, // If datapoint is the last value, show marker
					states: {
						hover: {
							lineWidth: 3.5,
							radius: 10
						}
					}
				}
			};
		});
	}
};
