import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as Component from 'components';

import { IWorkspace } from 'types';
import { WorkspaceType } from 'enums';
import { useActions, useTypedSelector } from 'hooks';

import {
	SENSOR_TYPES,
	FACTOR_TYPES,
	SENSORS_INFO,
	FACTORS_INFO,
	DIAGNOSTICS_INFO,
	RELATIVE_DATE_FILTERS,
	DIAGNOSTIC_LIGHT_TYPES,
	DIAGNOSTICS_LIGHT_INFO
} from 'constants/dataset';

import { AdminScoreDisplay } from './components';

/**
 * This a workspace overview card component
 * @author Misael Esperanzate & Konstantin Krumin
 * @param workspace - workspace details
 */
interface AdminOverviewCardProps {
	workspace: IWorkspace;
}

export const AdminOverviewCard: React.FC<AdminOverviewCardProps> = ({ workspace }) => {
	const navigate = useNavigate();

	const dateAsset = useTypedSelector(state => state.dataView.dateAsset);

	const { setCurrentWorkspace, setManagementToggle } = useActions();

	const onWorkspaceChangeHandler = (ws: IWorkspace) => {
		setCurrentWorkspace(ws);

		if (ws.type !== WorkspaceType.ADVANCED) {
			navigate('/diagnostics', { replace: true });
		}
	};

	return (
		<Component.HolCard
			columns="col-span-full lg:col-span-3 xl:col-span-2 max-w-lg cursor-pointer"
			onClick={() => {
				onWorkspaceChangeHandler(workspace);
				setManagementToggle(false);
			}}
		>
			<div className="flex flex-row justify-between align-top px-6">
				<Component.HolText textType="heading1">{workspace.name}</Component.HolText>
			</div>

			<div className="grid grid-cols-2 gap-2 p-3">
				{workspace.type === WorkspaceType.ADVANCED && (
					<>
						{FACTOR_TYPES.map((factorType, idx) => {
							const factorInfo = FACTORS_INFO[factorType];

							return (
								<div key={idx} className="col-span-full">
									<AdminScoreDisplay
										slicesBy={[dateAsset]}
										dataInfo={factorInfo}
										workspace={workspace.id}
										dateFilter={RELATIVE_DATE_FILTERS.lastYear}
									/>
								</div>
							);
						})}

						<div className="col-span-full">
							<AdminScoreDisplay
								slicesBy={[dateAsset]}
								workspace={workspace.id}
								dataInfo={DIAGNOSTICS_INFO.eNPS}
								dateFilter={RELATIVE_DATE_FILTERS.prevMonth}
							/>
						</div>
					</>
				)}

				{workspace.type === WorkspaceType.LIGHT && (
					<>
						{DIAGNOSTIC_LIGHT_TYPES.map((diagnosticType, idx) => {
							const diagnosticInfo = DIAGNOSTICS_LIGHT_INFO[diagnosticType];

							return (
								<div key={idx} className="col-span-full">
									<AdminScoreDisplay
										slicesBy={[]}
										workspace={workspace.id}
										dataInfo={diagnosticInfo}
										dateFilter={diagnosticInfo.dateFilter}
										isPercentageValue={diagnosticInfo.identifier !== 'toxicity'}
									/>
								</div>
							);
						})}
					</>
				)}

				{workspace.type === WorkspaceType.SENSORS_ONLY && (
					<>
						{SENSOR_TYPES.map((sensorType, idx) => {
							const sensorInfo = SENSORS_INFO[sensorType];

							return (
								<div key={idx} className="col-span-full">
									<AdminScoreDisplay
										slicesBy={[]}
										dataInfo={sensorInfo}
										workspace={workspace.id}
										dateFilter={RELATIVE_DATE_FILTERS.lastYear}
									/>
								</div>
							);
						})}
					</>
				)}
			</div>
		</Component.HolCard>
	);
};
