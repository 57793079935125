import { newRelativeDateFilter } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';

export const RELATIVE_DATE_FILTERS = {
	prevMonth: newRelativeDateFilter(Ldm.DateDatasets.Date.identifier, 'GDC.time.month', -1, -1),
	thisMonth: newRelativeDateFilter(Ldm.DateDatasets.Date.identifier, 'GDC.time.month', 0, 0),
	prevMonthToTwelveMonths: newRelativeDateFilter(
		Ldm.DateDatasets.Date.identifier,
		'GDC.time.month',
		-12,
		-1
	),
	lastYear: newRelativeDateFilter(Ldm.DateDatasets.Date.identifier, 'GDC.time.month', -11, 0),
	lastQuarter: newRelativeDateFilter(Ldm.DateDatasets.Date.identifier, 'GDC.time.month', -2, 0),
	lastFourWeeks: newRelativeDateFilter(
		Ldm.DateDatasets.Date.identifier,
		'GDC.time.week_us',
		-4,
		0
	),
	lastTwoWeeks: newRelativeDateFilter(Ldm.DateDatasets.Date.identifier, 'GDC.time.date', -14, -1),
	lastWeek: newRelativeDateFilter(Ldm.DateDatasets.Date.identifier, 'GDC.time.date', -7, -1)
};
