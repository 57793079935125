import React, { useEffect, useRef } from 'react';

import * as d3 from 'd3';

import { useResizeObserver } from 'hooks';

/**
 * Custom heatmap legend
 * @param scale - an array of values to show in the legend
 * @param colors - an array of colors to be used in the legend
 * @param chartRef - highcharts chart reference
 */
interface LegendProps {
	scale: string[];
	colors: string[];
	chartRef: React.MutableRefObject<any>;
}

export const Legend: React.FC<LegendProps> = ({ scale, colors, chartRef }) => {
	const svgRef = useRef<SVGSVGElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const dimensions = useResizeObserver(wrapperRef);

	useEffect(() => {
		if (!dimensions) return;

		const legendSize = {
			minWidth: 150,
			maxWidth: 800,
			minHeight: 5,
			maxHeight: 10,
			maxFont: 10
		};

		// a reference to the svg element
		const svgLegend = d3.select(svgRef.current);

		// Size of left chart label
		const containerMargin = chartRef.current?.chart.plotLeft;
		const legendContainerWidth = dimensions.width - containerMargin;

		// Legend width is half of chart width + minWidth so it doesn't get smaller than 100px
		const legendWidth = Math.min(
			legendSize.maxWidth,
			legendContainerWidth / 3 + legendSize.minWidth
		);
		const legendHeight = Math.max(legendSize.minHeight, legendWidth * 0.015);

		const leftMargin = (legendContainerWidth - legendWidth) / 2 + containerMargin;

		// the size of each rectangle in the legend
		const legendElementWidth = legendWidth / scale.length;

		// set the viewbox of the svg to make it responsive
		svgLegend
			.attr('preserveAspectRatio', 'xMinYMin meet')
			.attr('viewBox', `0 0 ${dimensions.width} 60`);

		/**
		 * IMPORTANT:
		 * This removes all elements of legend class. Whenever the screen size changes,
		 * then this removes the old legend and we redraw the legend svg again.
		 */
		svgLegend.selectAll('.legend').remove();

		// sets up the legend with the correctly mapped colors for each quartile.
		const legend = svgLegend
			.selectAll('.legend')
			.data(scale)
			.enter()
			.append('g')
			.attr('transform', `translate(${leftMargin}, 0)`)
			.attr('class', 'legend');

		// creates the color blocks for the legend
		legend
			.append('rect')
			.attr('x', (_, i) => {
				return legendElementWidth * i;
			})
			.attr('y', 0)
			.attr('width', `${legendElementWidth}`)
			.attr('height', `${legendHeight}`)
			.style('fill', (_, i) => {
				return colors[i];
			});

		// creates the text labels for the legend
		legend
			.append('text')
			.attr('class', 'mono')
			.text(d => {
				return d;
			})
			.attr('x', (_, i) => {
				// Offset for legend elements
				return legendElementWidth * (i + legendElementWidth * 0.004) + 12;
			})
			.attr('y', `${legendHeight * 3}`) //Distance between label and color block
			.attr('font-size', `min(1.4vw, ${legendSize.maxFont}px)`)
			.style('fill', '#939997');
	}, [dimensions, scale, colors, chartRef]);

	return (
		<div ref={wrapperRef}>
			<svg ref={svgRef} width="100%" height="100%" />
		</div>
	);
};
