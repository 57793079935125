import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';

/**
 * Intermediate file to create store so we dont need to add into index file
 * Also redux-devtools is connected here for tracking the global state via browser extension
 * @author Matthew Douglas & Konstantin Krumin
 */

const middleware = [thunk];

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));
