import React from 'react';

import { IFilter } from '@gooddata/sdk-model';

import * as Component from 'components';

import { DEFAULT_TABLE_TITLES } from './constants';
import { IMeasuresCollection, ITableTitles } from './types';

/**
 * A component that contains card contents for the Diagnostics pages
 * @author Konstantin Krumin
 * @param tableTitles - defines the titles to show in the table
 * @param filters - sets up filters for the diagnostics headlines
 * @param showTableHead - defines whether the table head should be displayed
 * @param measuresCollection - object that accepts optional objects with LDM values and data type indicators
 * (integer / percentage) to display in corresponding table grid of values, where A1 is top-left corner value
 * and B2 is a bottom-right value.
 */
interface DiagnosticsCardProps {
	showTableHead?: boolean;
	tableTitles: ITableTitles;
	filters: IFilter[] | undefined;
	measuresCollection: IMeasuresCollection;
}

export const DiagnosticsCard: React.FC<DiagnosticsCardProps> = ({
	filters,
	measuresCollection,
	showTableHead = false,
	tableTitles = DEFAULT_TABLE_TITLES
}) => {
	return (
		<table className="table-auto">
			{showTableHead && (
				<thead>
					<tr>
						<th></th>
						<th className="font-sofia-bold text-base text-center">
							{tableTitles.firstCol}
						</th>
						{(measuresCollection.B1 || measuresCollection.B2) && (
							<th className="font-sofia-bold text-base text-center">
								{tableTitles.secondCol}
							</th>
						)}
					</tr>
				</thead>
			)}

			<tbody className="divide-y divide-gray-300">
				{(measuresCollection.A1 || measuresCollection.B1) && (
					<tr>
						<td className="px-3 py-1 text-base">{tableTitles.firstRow}</td>

						{measuresCollection.A1 && (
							<td className="px-3 py-1">
								<Component.DiagnosticHeadlineDisplay
									valueType={measuresCollection.A1.dataType}
									headlineMeasure={measuresCollection.A1.measureIndicator}
									additionalText={measuresCollection.A1.additionalText}
									filters={filters}
								/>
							</td>
						)}

						{measuresCollection.B1 && (
							<td className="px-3 py-1">
								<Component.DiagnosticHeadlineDisplay
									valueType={measuresCollection.B1.dataType}
									headlineMeasure={measuresCollection.B1.measureIndicator}
									additionalText={measuresCollection.B1.additionalText}
									filters={filters}
								/>
							</td>
						)}
					</tr>
				)}

				{(measuresCollection.A2 || measuresCollection.B2) && (
					<tr>
						<td className="px-3 py-1 text-base">{tableTitles.secondRow}</td>

						{measuresCollection.A2 && (
							<td className="px-3 py-1">
								<Component.DiagnosticHeadlineDisplay
									valueType={measuresCollection.A2.dataType}
									headlineMeasure={measuresCollection.A2.measureIndicator}
									additionalText={measuresCollection.A2.additionalText}
									filters={filters}
								/>
							</td>
						)}

						{measuresCollection.B2 && (
							<td className="px-3 py-1">
								<Component.DiagnosticHeadlineDisplay
									valueType={measuresCollection.B2.dataType}
									headlineMeasure={measuresCollection.B2.measureIndicator}
									additionalText={measuresCollection.B2.additionalText}
									filters={filters}
								/>
							</td>
						)}
					</tr>
				)}
			</tbody>
		</table>
	);
};
