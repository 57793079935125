import React from 'react';

import { FaTimes } from 'react-icons/fa';
import { LoadingComponent } from '@gooddata/sdk-ui/esm/base/react/LoadingComponent';

import { IGeneralOption } from 'types';

/**
 * A component containing chips with value for selected options
 * @author Michaela Mempin
 * @param placeholder - default placeholder text
 * @param multiSelected - an array of selected items
 * @param options - an array of available options for selection
 * @param handleRemove - a callback function that handles removal of the item from the list in the parent component
 */
interface SelectedValuesProps {
	placeholder?: string;
	options: IGeneralOption[] | null;
	multiSelected?: IGeneralOption[] | null | undefined;
	handleRemove: (index: number) => void;
}

export const SelectedValues: React.FC<SelectedValuesProps> = ({
	options,
	multiSelected,
	placeholder = 'Select...',
	handleRemove
}) => {
	return (
		<>
			{multiSelected && multiSelected.length > 0 ? (
				<div className="flex flex-wrap items-center">
					{multiSelected.map((m, idx) => (
						<span
							key={`opt_${m}`}
							className="bg-font-light items-center text-sm p-1 rounded-md flex mr-1 my-1 space-x-2"
						>
							<p className="text-change-title">{m.label}</p>
							<FaTimes
								className="text-font-dark text-xs cursor-pointer"
								onClick={() => handleRemove(idx)}
								key={`opt_x_${m}`}
							/>
						</span>
					))}
				</div>
			) : !options ? (
				<LoadingComponent />
			) : (
				<p className="text-lg text-font-light-1">{placeholder}</p>
			)}
		</>
	);
};
