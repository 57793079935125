import { IMeasure } from '@gooddata/sdk-model';

import * as Ldm from 'ldm';

import { ReportDiagnosticMetricType, ReportDiagnosticType } from 'types';

export const REPORT_DIAGNOSTIC_OPTIONS: {
	[key in ReportDiagnosticType]: ReportDiagnosticMetricType[];
} = {
	Chats: ['Chats Received', 'Chats Sent'],
	Emails: ['Emails Received', 'Emails Sent'],
	Sensors: ['Air CO2', 'Air VOC', 'Light', 'Noise', 'Temperature']
};

export const REPORT_DIAGNOSTIC_METRIC_LDM: { [key in ReportDiagnosticMetricType]: IMeasure } = {
	'Chats Received': Ldm.ChatsDayReceived.Avg,
	'Chats Sent': Ldm.ChatsDaySent.Avg,
	'Emails Received': Ldm.EmailsDayReceived.Avg,
	'Emails Sent': Ldm.EmailsDaySent.Avg,
	'Air CO2': Ldm.SensorAirCO2,
	'Air VOC': Ldm.SensorAirVOC,
	Light: Ldm.SensorLight,
	Noise: Ldm.SensorNoise,
	Temperature: Ldm.SensorTemperature
};
