import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { IAttribute } from '@gooddata/sdk-model';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Ldm from 'ldm';
import * as Util from 'utils';

import { useTypedSelector } from 'hooks';
import { ValueFormatType, IColumnChartSeries, IColumnChartOptions } from 'types';

import { IColumnChartDataset } from './types';
import { findLargestDatasetLength, formatValue } from './helpers';

/**
 * Displays the column chart
 * @author Konstantin Krumin
 * @param columns - parameters for each column
 * @param chartName - name for the chart, it is being displayed in the tooltip
 * @param valueType - determines formatting of the values that displayed in the chart
 * @param slicesBy - custom parameter to slice incoming data from GoodData (i.e., by every day / week/ month)
 */
interface ColumnChartProps {
	chartName: string;
	slicesBy?: IAttribute[];
	valueType?: ValueFormatType;
	columns: IColumnChartDataset[];
}

export const ColumnChart: React.FC<ColumnChartProps> = ({
	columns,
	chartName,
	valueType = 'float',
	slicesBy = [Ldm.DateDatasets.Date.Date.DdMmYyyy]
}) => {
	const currentWorkspace = useTypedSelector(state => state.user.currentWorkspace);

	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const dateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);
	const filtersArr = [dateFilter.relativeDateFilter, ...demographicFilter];

	const seriesBy = columns.map(column => column.value);

	return (
		<div className="flex flex-wrap col-span-full sm:col-span-6 lg2:col-span-4 justify-center">
			<div className="w-full">
				<div className="flex-grow px-5">
					<Execute
						key={currentWorkspace.id}
						filters={filtersArr}
						seriesBy={seriesBy}
						slicesBy={slicesBy}
						onError={errInfo => {
							if (errInfo.message !== 'CANCELLED') {
								Util.logErrorMessage(errInfo);
							}
						}}
					>
						{({ result, isLoading }) => {
							if (!result) {
								return <LoadingComponent className="my-8" />;
							}

							let series: IColumnChartSeries[] = columns.map(column => {
								return {
									data: [],
									name: column.name,
									color: column.color
								};
							});

							const data = result?.rawData().data();

							for (let i = 0; i < data.length; i++) {
								const currSubArr = data[i];

								if (currSubArr && Array.isArray(currSubArr)) {
									for (let j = 0; j < currSubArr.length; j++) {
										const value = formatValue(
											currSubArr[j] as string,
											valueType
										);

										series[j].data.push(value);
									}
								}
							}

							const showLegend = series?.[1]?.data?.length > 0;

							const slices = result?.data().slices().toArray();
							const xAxisLabels = slices?.map(slice => slice.sliceTitles()[0]);

							const holColumnChartOptions: IColumnChartOptions =
								Util.columnChartConfig(chartName, xAxisLabels, series, showLegend);

							return isLoading ? (
								<LoadingComponent className="my-8" />
							) : !isLoading && findLargestDatasetLength(series) === 0 ? (
								<div className="flex justify-center">
									<div className="text-gray-400 my-8">
										No data for your filter selection
									</div>
								</div>
							) : (
								<HighchartsReact
									highcharts={Highcharts}
									options={holColumnChartOptions}
								/>
							);
						}}
					</Execute>
				</div>
			</div>
		</div>
	);
};
