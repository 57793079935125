import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Security } from '@okta/okta-react';
import { OAuthResponseType, OktaAuth } from '@okta/okta-auth-js';

import { BackendProvider } from '@gooddata/sdk-ui';
import bearFactory, { ContextDeferredAuthProvider } from '@gooddata/sdk-backend-bear';

import { store } from './store';

import App from './App';
import { OktaContext } from 'utils/auth';

require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` });

// connection with GoodData
const backend = bearFactory()
	.onHostname(`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}`)
	.withAuthentication(new ContextDeferredAuthProvider());

const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
	window.location.replace(`${process.env.REACT_APP_HOST}/auth/saml`);
};

const oktaAuth = new OktaAuth({
	issuer: process.env.REACT_APP_OKTA_ISSUER,
	clientId: process.env.REACT_APP_CLIENT_ID,
	responseType: process.env.REACT_APP_RESPONSE_TYPE as OAuthResponseType,
	scopes: process.env.REACT_APP_SCOPES?.split(',')
});

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<BackendProvider backend={backend}>
					<Security
						oktaAuth={oktaAuth}
						restoreOriginalUri={restoreOriginalUri}
						onAuthRequired={() => OktaContext().validateAccess()}
					>
						<App />
					</Security>
				</BackendProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
