import { RoleType } from 'types';
import { WorkspaceType, NavbarColors } from 'enums';

import { INavbarData } from '../types';

export const NAVBAR_DATA: INavbarData[] = [
	{
		path: '/',
		title: 'Overview',
		iconType: 'overview',
		color: NavbarColors.PURPLE,
		category: 'main-category'
	},
	{
		path: '/factors',
		title: 'Factors',
		iconType: 'factors',
		color: NavbarColors.GREEN,
		category: 'main-category'
	},
	{
		path: '/change',
		title: 'Change',
		iconType: 'change',
		color: NavbarColors.PURPLE,
		category: 'main-category'
	},
	{
		path: '/action-plans',
		title: 'Action Plans',
		iconType: 'action-plans',
		color: NavbarColors.YELLOW,
		category: 'main-category'
	},
	{
		path: '/diagnostics',
		title: 'Diagnostics',
		iconType: 'diagnostics',
		color: NavbarColors.ORANGE,
		category: 'main-category'
	}
];

export const NAVBAR_DATA_LIGHT = NAVBAR_DATA.filter(
	navbarItem => navbarItem.path === '/diagnostics' || navbarItem.path === '/action-plans'
);

export const NAVBAR_DATA_SENSORS_ONLY = NAVBAR_DATA.filter(
	navbarItem => navbarItem.path === '/diagnostics'
);

export const SECONDARY_NAVBAR_DATA = (role: RoleType, workspaceType: WorkspaceType | undefined) => {
	let NavUtils: INavbarData[] = [];

	if (workspaceType === WorkspaceType.ADVANCED) {
		NavUtils.push({
			path: '/support',
			title: 'Support',
			iconType: 'support',
			color: NavbarColors.GRAY,
			category: 'sub-category'
		});
	}

	if (role === 'Holmetrics' || role === 'Consultant Admin') {
		NavUtils.push({
			path: '/users',
			title: 'Users',
			iconType: 'users',
			color: NavbarColors.GRAY,
			category: 'sub-category'
		});
	}

	return NavUtils;
};
