import { useExecution, useDataView } from '@gooddata/sdk-ui';

import * as Ldm from 'ldm';

const CompanyInfo = (companyWorkspace: string) => {
	const execution = useExecution({
		workspace: companyWorkspace,
		seriesBy: [Ldm.EmployedUsersCount.Max],
		slicesBy: [Ldm.ConsultantFirm]
	});

	const { result } = useDataView({ execution }, [execution?.fingerprint()]);

	const getName = () => {
		const data = result?.meta().attributeHeaders()[0][0][0];
		const consultantFirmName = data?.attributeHeaderItem.name;

		return consultantFirmName;
	};

	const getImgPath = () => {
		const consultantFirmName = getName();

		if (!consultantFirmName) {
			return `workspaces/${companyWorkspace}`;
		}

		const pngName = consultantFirmName?.split(' ').join('_');

		if (process.env.REACT_APP_CURRENT_ENV === 'demo') {
			return `holmetrics/Demo`;
		} else if (consultantFirmName === 'Holmetrics') {
			return `holmetrics/Holmetrics`;
		}

		if (consultantFirmName) {
			return `consultants/${pngName}`;
		}
	};

	return {
		getName,
		getImgPath
	};
};

export default CompanyInfo;
