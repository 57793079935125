import { IDemographicFilter, IDemographicSelected } from 'types';

import { Action } from '../actions';
import ActionType from '../action-types';

/**
 * This is the selected demographics reducer to set diagnostics page
 * @author Konstantin Krumin
 */

interface DemographicState {
	filter: IDemographicSelected[];
	selected: { [key: string]: IDemographicFilter };
}

const initialState: DemographicState = {
	filter: [],
	selected: {}
};

const demographicFilterReducer = (state: DemographicState = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_DEMOGRAPHIC:
			const demographics = action.payload;
			return {
				...state,
				filter: demographics.filter,
				selected: demographics.selected
			};
		default:
			return state;
	}
};

export default demographicFilterReducer;
