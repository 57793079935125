import React from 'react';
import { InsightView } from '@gooddata/sdk-ui-ext';

import * as Ldm from 'ldm';
import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { DiagnosticColors, DiagnosticsOptions } from 'enums';

/**
 * The Noise section under Diagnostics that display the noise metrics that comes from sensors
 * @author Konstantin Krumin
 */
export const Noise: React.FC = () => {
	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const dateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [dateFilter.relativeDateFilter, ...demographicFilter];

	return (
		<div className="grid grid-cols-12 gap-6 print:block">
			<Component.HolCard hideHeader columns="col-span-full">
				<div className="flex flex-col md2:flex-row justify-between col-span-full sm:col-span-6 lg2:col-span-4 p-5 print:flex-row">
					<Component.HolText textType="heading1">
						{DiagnosticsOptions.NOISE}
					</Component.HolText>

					<div className="flex flex-col md:flex-row md:items-center print:flex-row print:items-center">
						<Component.MainHeadlineDisplay
							title="Avg Ambient Db"
							filters={filtersArr}
							headlineMeasure={Ldm.SensorNoiseAVG}
						/>
					</div>
				</div>

				<Component.ColumnChart
					chartName="Dbs"
					columns={[
						{
							name: 'Dbs',
							color: DiagnosticColors.GREEN,
							value: Ldm.SensorNoise
						}
					]}
				/>
			</Component.HolCard>

			<Component.HolCard
				title="Noise Heatmap"
				columns="col-span-full"
				styles="print-page-break"
			>
				<div className="mx-auto md2:p-8">
					<div className="pl-2 h-full">
						<InsightView
							filters={filtersArr}
							config={Util.gooddataHeatmapConfig([DiagnosticColors.GREEN])}
							insight={Ldm.Insights.DiagnosticsNoiseHeatmap}
						/>
					</div>
				</div>
			</Component.HolCard>
		</div>
	);
};
