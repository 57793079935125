import { Action } from '../actions';
import ActionType from '../action-types';

interface ToggleState {
	toggle: boolean;
}

const initialState: ToggleState = {
	toggle: true
};

const manageConsultantReducer = (state: ToggleState = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_MANAGEMENT_TOGGLE:
			const toggleInfo = action.payload;
			return {
				...state,
				toggle: toggleInfo.toggle
			};
		default:
			return state;
	}
};

export default manageConsultantReducer;
