import { DiagnosticsOptions } from 'enums';
import { ICurrentDiagnostic } from 'types';

export const DIAGNOSTIC_OPTIONS: ICurrentDiagnostic[] = [
	{ label: 'Chats', value: DiagnosticsOptions.CHATS },
	{ label: 'Emails', value: DiagnosticsOptions.EMAILS },
	{ label: 'Meetings', value: DiagnosticsOptions.MEETINGS },
	{ label: 'eNPS', value: DiagnosticsOptions.ENPS },
	{ label: 'Pulse', value: DiagnosticsOptions.PULSE },
	{ label: 'Noise', value: DiagnosticsOptions.NOISE },
	{ label: 'Light', value: DiagnosticsOptions.LIGHT },
	{ label: 'Temperature', value: DiagnosticsOptions.TEMPERATURE },
	{ label: 'Air Quality', value: DiagnosticsOptions.AIR_QUALITY },
	{ label: 'Connectivity', value: DiagnosticsOptions.CONNECTIVITY },
	{ label: 'Sentiment', value: DiagnosticsOptions.SENTIMENT },
	{ label: 'Toxicity', value: DiagnosticsOptions.TOXICITY }
];

export const DIAGNOSTIC_OPTIONS_SENSORS_ONLY = DIAGNOSTIC_OPTIONS.filter(option => {
	return (
		option.label === 'Noise' ||
		option.label === 'Light' ||
		option.label === 'Temperature' ||
		option.label === 'Air Quality'
	);
});
