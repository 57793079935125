import moment from 'moment';
import { newAbsoluteDateFilter } from '@gooddata/sdk-model';
import { DataViewFacade } from '@gooddata/sdk-ui/esm/base/results/facade';

import * as Ldm from 'ldm';

// Convert date from yyyy-mm-ddT00:00:00.000Z to 'Month dd, yyyy'
export const getParsedDate = (date: Date) => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	const dateCopy = new Date(date);
	const [year, month, day] = dateCopy.toISOString().substr(0, 10).split('-');

	return `${months[parseInt(month) - 1]} ${parseInt(day)} ${year}`;
};

// Short format of date (Jan, Feb, Mar...)
export const getMonthYear = (date: Date) => {
	const dateStr = getParsedDate(date);

	return `${dateStr.substring(0, 3)} ${dateStr.substr(-4)}`;
};

export const getDateFilter = (ldmDate: string, date: Date, monthRange: number = 2) => {
	const changeDate = new Date(date);
	const range = monthRange;
	changeDate.setMonth(changeDate.getMonth() - range);

	const dateFormat = changeDate.toISOString().substr(0, 10);
	const currentDate = new Date().toISOString().substr(0, 10);

	const filter = newAbsoluteDateFilter(ldmDate, dateFormat, currentDate);

	return [filter];
};

/**
 * Helper function creates the date filter
 */
export const getChangeDateFilter = (ldmDate: string, changeDate: Date, monthRange: number = 2) => {
	return getDateFilter(ldmDate, changeDate, monthRange);
};

/**
 * Helper function to format a date to a particular look (MMMM DD, YYYY)
 */
export const formatDate = (date: Date) => {
	return moment(date).format('MMMM DD, YYYY');
};

export const generateLocaleDate = (dateStr: string) => {
	return new Date(dateStr).toLocaleDateString();
};

export const extractDatesArr = (input: DataViewFacade) => {
	if (
		!input ||
		!input?.dataView ||
		!input?.dataView?.headerItems ||
		!input?.dataView?.headerItems[0] ||
		!input?.dataView?.headerItems[0][0]
	) {
		return [];
	}

	return input?.dataView.headerItems[0][0];
};

/**
 * Helper function that generates ISO Date string without offsetting browser's timezone
 */
export const getISODateWithoutTimezoneOffset = (date: Date) => {
	const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds

	return new Date(date.valueOf() - tzoffset).toISOString().slice(0, -1);
};

/**
 * Helper function that generates Absolute date filter for an exact date passed as a parameter
 */
export const createAbsoluteExactDateFilter = (date: Date) => {
	const dateFilterStr = getISODateWithoutTimezoneOffset(date).substr(0, 10);

	return newAbsoluteDateFilter(Ldm.DateDatasets.Date.identifier, dateFilterStr, dateFilterStr);
};

/**
 * Helper function used by biweekly measures where data on GoodData side is updated on particular dates of the month (ex., 1st and 15th)
 * It checks today's date and depending on it returns a required Absolute date filter.
 */
export const generateAbsoluteBiweeklyDateFilter = () => {
	const todayDate = new Date().getDate();

	let filteringDate = new Date();
	todayDate >= 15 ? filteringDate.setDate(15) : filteringDate.setDate(1);

	return createAbsoluteExactDateFilter(filteringDate);
};
