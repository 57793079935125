export const INITIAL_REPORT_STATE = {
	userId: '',
	name: '',
	workspaceId: '',
	isShared: false,
	data: {
		changeDate: new Date(),
		factors: [],
		diagnostics: null,
		demographics: null,
		kpi: null
	}
};
