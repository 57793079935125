import { IResultHeader } from '@gooddata/sdk-backend-spi';

/*
    monthAxis: ['Nov 2021', 'Dec 2021', 'Jan 2022', 'Feb 2022', 'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022', 'Jul 2022']
    seriesMonths: ['Jan 2022', 'Feb 2022', 'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022', 'Jul 2022']
    seriesData: [83, 73, 83, 76, 56, 81, 69]

    result: [null, null, 83, 73, 83, 76, 56, 81, 69]
*/
export const getChartData = (seriesMonths: string[], monthAxis: string[], seriesData: number[]) => {
	const data = monthAxis.map(m => {
		if (seriesMonths.includes(m)) {
			return Number(seriesData.shift()?.toFixed(2));
		}

		return null;
	});

	return data;
};

export const getHeaderItemNames = (attributeItems: IResultHeader[][]) => {
	return attributeItems.map(item => {
		const attributeNames = item.map((a: any) => a.attributeHeaderItem.name);

		return attributeNames as string[];
	});
};
