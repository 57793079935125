import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';

import * as Util from 'utils';
import * as Component from 'components';

import { IFactorDataInfo } from 'types';
import { useTypedSelector } from 'hooks';
import { RELATIVE_DATE_FILTERS } from 'constants/dataset';

import { FactorConfidenceScore, ParentWrapper } from './components';
import { FACTOR_PAGE_CIRCLE_PARAMS, OVERVIEW_PAGE_CIRCLE_PARAMS } from './constants';

/**
 * A score display component to display factor progress bar and spline chart
 * @author Konstantin Krumin
 * @param styles - additional styling for the component
 * @param factorInfo - factor meta information and LDM score reference
 * @param pageType - type of the page where this component is displayed (overview / factors)
 * @param hideTitle - a boolean determining whether a title should be hidden for this component
 */
interface FactorCardRegularProps {
	hideTitle?: boolean;
	factorInfo: IFactorDataInfo;
	pageType: 'overview' | 'factors';
}

export const FactorCardRegular: React.FC<FactorCardRegularProps> = ({
	factorInfo,
	hideTitle = false,
	pageType = 'overview'
}) => {
	const title = factorInfo.title;

	const dateAsset = Util.getDateAssetByDataView('Year');

	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	return (
		<Execute
			seriesBy={[factorInfo.value]}
			slicesBy={[dateAsset]}
			filters={[...demographicFilter, RELATIVE_DATE_FILTERS.lastYear]}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				if (!result) {
					return (
						<ParentWrapper>
							<LoadingComponent className="my-8" />
						</ParentWrapper>
					);
				}

				const tempVal = Util.getLatestDataPointVal(result);

				const binText = tempVal.toString();
				const value = tempVal === 'No Data' || tempVal === '-' ? 0 : tempVal * 10;

				if (execution.isLoading) {
					return (
						<ParentWrapper>
							<LoadingComponent className="my-8" />
						</ParentWrapper>
					);
				} else {
					return (
						<ParentWrapper>
							{!hideTitle && (
								<div className="flex flex-col text-center">
									<Component.HolText textType="heading3">
										{title}
									</Component.HolText>
								</div>
							)}

							<div className="flex flex-col justify-between">
								<div className="flex justify-center">
									<Component.ProgressBar
										kpiValue={value}
										baselineValue={52}
										overwriteText={binText}
										circleAdditionalParams={
											pageType === 'overview'
												? OVERVIEW_PAGE_CIRCLE_PARAMS
												: FACTOR_PAGE_CIRCLE_PARAMS
										}
										color={Util.getMetricColor(value, factorInfo.identifier)}
									/>
								</div>

								<Component.SplineChart
									valueType="bin"
									pageType={pageType}
									type={factorInfo.identifier}
									seriesBy={[factorInfo.value]}
									slicesBy={[Util.getDateAssetByDataView('Year')]}
									dateFilter={RELATIVE_DATE_FILTERS.lastYear}
								/>

								<FactorConfidenceScore factorInfo={factorInfo} />
							</div>
						</ParentWrapper>
					);
				}
			}}
		</Execute>
	);
};
