import React from 'react';

import { ICircleAdditionalParams, KpiValueType } from 'types';

import { DEFAULT_CIRCLE_ADDITIONAL_PARAMS } from './constants';
import { Baseline, Circle, ProgressBarText } from './components';
import { calculateAllValues, calculateOnlyPositiveValues } from './helpers';

/**
 * Reusable component to create a custom circular progress bar.
 * @author Misael Esperanzate & Konstantin Krumin
 * @param kpiValue - passed KPI value
 * @param color - The color of the progress bar
 * @param overwriteText - an optional text to overwrite the default value
 * @param baselineValue - a value from o to 100 used for showing baseline line
 * @param circleAdditionalParams - customizable set up for the progress bar circle
 * @param showPercentSign - optional parameter that determines whether percent sign should show
 * @param allowNegativeValues - optional parameter that determines whether negative values can be shown
 * @param showPlusOrMinusSign - optional parameter that enforces to show + / - sign in front of the value (used by action plans)
 */
interface ProgressBarProps {
	color: string;
	kpiValue: KpiValueType;
	overwriteText?: string;
	baselineValue?: number;
	showPercentSign?: boolean;
	allowNegativeValues?: boolean;
	showPlusOrMinusSign?: boolean;
	circleAdditionalParams?: ICircleAdditionalParams;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
	color,
	kpiValue,
	overwriteText,
	baselineValue,
	showPercentSign = false,
	showPlusOrMinusSign = false,
	allowNegativeValues = false,
	circleAdditionalParams = DEFAULT_CIRCLE_ADDITIONAL_PARAMS
}) => {
	const size = circleAdditionalParams.size;
	const rotate = circleAdditionalParams.rotate;
	const values = allowNegativeValues
		? calculateAllValues(kpiValue as number)
		: calculateOnlyPositiveValues(kpiValue);

	return (
		<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
			<g transform={`rotate(-90 ${rotate} ${rotate})`}>
				<Circle
					color="lightgrey"
					percentage={100}
					size={size}
					circleAdditionalParams={circleAdditionalParams}
				/>
				<Circle
					size={size}
					color={color}
					percentage={values.circlePercentage}
					circleAdditionalParams={circleAdditionalParams}
				/>
			</g>

			<ProgressBarText
				value={values.text}
				overwriteText={overwriteText}
				showPercentSign={showPercentSign}
				showPlusOrMinusSign={showPlusOrMinusSign}
				textSize={circleAdditionalParams.textSize}
			/>

			{baselineValue && <Baseline baselineValue={baselineValue} color={color} />}
		</svg>
	);
};
