import { HolmetricsDiagnosticType, HolmetricsFactorType, KpiValueType } from 'types';
import { FactorColors } from 'enums';

/**
 * Helper function to get the color of the progress bar depending on the value of the metric
 * @param value - the value for the specified metric
 * @param metric - selected metric for which it's necessary to retrieve the color
 * @returns Hex Color
 */
export const getMetricColor = (
	value: KpiValueType,
	metric: HolmetricsDiagnosticType | HolmetricsFactorType,
	defaultColor: string = FactorColors.GREY
) => {
	let color = '';

	switch (metric) {
		case 'eNPS':
		case 'connection':
		case 'activityOWH':
		case 'communication':
		case 'pulseParticipation':
			color = FactorColors.PURPLE;
			break;

		case 'burnout':
		case 'roleClarity':
		case 'verticalTrust':
		case 'workEngagement':
		case 'jobSatisfaction':
		case 'horizontalTrust':
		case 'senseOfCommunity':
		case 'qualityOfLeadership':
			if (value === 10) {
				color = FactorColors.ORANGE;
			} else if (value === 20) {
				color = FactorColors.LIGHT_ORANGE;
			} else if (value === 30) {
				color = FactorColors.LIGHT_YELLOW;
			} else if (value === 40) {
				color = FactorColors.YELLOW;
			} else if (value === 50) {
				color = FactorColors.LIGHT_GREEN;
			} else if (value === 60) {
				color = FactorColors.GREEN;
			} else if (value === 70) {
				color = FactorColors.LIGHT_BLUE;
			} else if (value === 80) {
				color = FactorColors.BLUE;
			} else if (value === 90) {
				color = FactorColors.LIGHT_PURPLE;
			} else if (value === 100) {
				color = FactorColors.PURPLE;
			} else {
				color = defaultColor;
			}
			break;

		default:
			color = FactorColors.YELLOW;
	}

	return color;
};

/**
 * Helper function to get the color of the progress bar depending on the value of difference in provided metric values
 * @param value - the difference value for the specified metric
 * @param metric - selected metric for which it's necessary to retrieve the color
 * @returns Hex Color
 */
export const getDifferenceMetricColor = (
	value: KpiValueType | undefined,
	metric: HolmetricsFactorType | HolmetricsDiagnosticType
) => {
	let color = '';

	if (value === undefined || value === 'No Data') {
		return FactorColors.GREY;
	}

	switch (metric) {
		case 'eNPS':
		case 'activityOWH':
		case 'pulseParticipation':
		case 'burnout':
		case 'roleClarity':
		case 'verticalTrust':
		case 'workEngagement':
		case 'jobSatisfaction':
		case 'horizontalTrust':
		case 'senseOfCommunity':
		case 'qualityOfLeadership':
			if (value < 0) {
				color = FactorColors.ORANGE;
			} else if (value >= 0 && value < 1) {
				color = FactorColors.YELLOW;
			} else if (value >= 1 && value < 6) {
				color = FactorColors.GREEN;
			} else {
				color = FactorColors.PURPLE;
			}
			break;

		case 'connection':
		case 'communication':
			if (value <= -6) {
				color = FactorColors.PURPLE;
			} else if (value <= -1 && value > -6) {
				color = FactorColors.GREEN;
			} else if (value <= 0 && value > -1) {
				color = FactorColors.YELLOW;
			} else {
				color = FactorColors.ORANGE;
			}
			break;

		default:
			color = FactorColors.YELLOW;
	}

	return color;
};

/**
 * Adjusts color shade by alpha.
 * @param colorHex - hex code of color
 * @param alpha - alpha value to adjust color by
 * @returns hexShade
 */
export const getColorShade = (colorHex: string, alpha: number) => {
	// Parse hex value excluding # if provided
	const parsedHex = colorHex.match(/[^#].{1}/g);

	const rgb = [
		parseInt(parsedHex![0], 16),
		parseInt(parsedHex![1], 16),
		parseInt(parsedHex![2], 16)
	];

	// Generate shade/tint
	const hexShade = rgb.map(c => {
		const rgbValue = Math.round(c + (255 - c) * alpha);

		// Convert back to hex
		let hexValue = rgbValue.toString(16);

		if (hexValue.length < 2) {
			hexValue = `0${hexValue}`;
		}

		return hexValue;
	});

	return `#${hexShade.join('')}`;
};

/**
 * Generates shades of color array
 * @param baseColor - object that stores the needed values to create an adaptive card
 * @param stops - object that stores the needed values to create an adaptive card
 * @param isDarkToLight - order of color array, default is light to dark
 * @returns [hexColor]
 */
export const getColorScale = (baseColor: string, stops?: number, isDarkToLight?: boolean) => {
	// Number of color shades to generate, default is 10
	const numStops = stops ? stops : 10;

	// Alpha to scale color shades by.
	// Lightest shade has 0.1 alpha as seen from prototype.
	const alpha = 0.9 / (numStops - 1);

	let colorArray = Array.from(Array(numStops).keys()).map(shade => {
		return getColorShade(baseColor, alpha * shade);
	});

	return isDarkToLight ? colorArray : colorArray.reverse();
};
