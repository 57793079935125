import React, { useState } from 'react';

import * as Util from 'utils';
import * as Component from 'components';
import * as Analytics from 'utils/analytics';

import { ReportApi } from 'api';
import { useTypedSelector, useActions } from 'hooks';
import { IChangeChartData, IReportData, IReport } from 'types';

/**
 * Displays a chart with the factors selected and a given time frame to indicate a change
 * @author Matthew Douglas, Misael Esperanzate, Michaela Mempin
 * @param reportInfo - information that chart needs to be constructed
 * @param setReportInfo - set report info in the parent component
 */
interface ChangeChartActionsProps {
	reportInfo: IReport;
	setReportInfo: React.Dispatch<any>;
}

export const ChangeChartActions: React.FC<ChangeChartActionsProps> = ({
	reportInfo,
	setReportInfo
}) => {
	const { name, isShared, id } = reportInfo;
	const reportData: IChangeChartData = JSON.parse(reportInfo.data);

	const data: IReportData = {
		id: id,
		userId: reportInfo.userId,
		name: name,
		workspaceId: reportInfo.workspaceId,
		isShared: isShared,
		data: reportData
	};

	const [openModal, setOpenModal] = useState(false);

	const Api = ReportApi();

	const { userId, currentWorkspace } = useTypedSelector(state => state.user);
	const { currentPage, itemsPerPage } = useTypedSelector(state => state.report);

	const { deleteReport, setReports, setTotalReportsCount } = useActions();

	const deleteHandler = () => {
		// make request to set deleted_at flag to the date and time
		Api.deleteReport(currentWorkspace.id, userId, reportInfo.id!)
			.then(() => {
				deleteReport(reportInfo.id!);
				Analytics.deleteExistingReport();
			})
			.then(() => {
				Api.getReports(currentWorkspace.id, userId, currentPage, itemsPerPage)
					.then(result => {
						setReports(result.results);
						setTotalReportsCount(result.length);
					})
					.catch(err => Util.logErrorMessage(err));
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const updateHandler = (report: IReport) => {
		Api.updateReport(currentWorkspace.id, userId, id!, report)
			.then(() => {
				Api.getReports(currentWorkspace.id, userId, currentPage, itemsPerPage)
					.then(result => {
						setReports(result.results);
						setTotalReportsCount(result.length);
						setReportInfo(report);
					})
					.catch(err => Util.logErrorMessage(err));
			})
			.catch(err => Util.logErrorMessage(err));
	};

	return (
		<div className="relative flex justify-end">
			<Component.HolThreeDotMenu
				options={[
					{
						label: 'Delete',
						onClick: deleteHandler
					},
					{
						label: 'Edit',
						onClick: () => setOpenModal(true)
					}
				]}
			/>
			{openModal && (
				<Component.ReportBuilderOverlay
					modalType="update"
					reportData={data}
					openModal={openModal}
					setOpenModal={setOpenModal}
					updateHandler={updateHandler}
				/>
			)}
		</div>
	);
};
