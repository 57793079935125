import { SVGIconProps } from 'types';

export const Communication: React.FC<SVGIconProps> = ({ fill = '#666' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill={fill}>
		<path
			id="Path_304"
			data-name="Path 304"
			d="M1373.6,1361.1a2.3,2.3,0,0,0-1.736-1.346c-.16-.034-.2-.094-.2-.256.007-1.68,0-3.36,0-5.04,0-.337-.156-.447-.466-.334l-4.972,1.8c-1.593.577-3.189,1.147-4.778,1.735a3.517,3.517,0,0,1-1.378.247c-.665-.025-1.332-.007-2-.006a3.478,3.478,0,0,0-.393.01,4.119,4.119,0,0,0-2.831,1.508,3.965,3.965,0,0,0-.866,3.5,4.063,4.063,0,0,0,3.159,3.227c.122.032.171.075.17.213-.007,1.014,0,2.027,0,3.041q0,1.452,0,2.9a2.222,2.222,0,0,0,.033.39,1.7,1.7,0,0,0,3.377-.343c0-.752,0-1.5,0-2.255,0-.136.039-.181.174-.172.17.01.342.006.512,0,.277-.007.381-.113.381-.385q0-.726,0-1.452a.3.3,0,0,0-.334-.338c-.188,0-.376-.007-.564,0-.121.005-.172-.023-.169-.158.009-.4.008-.8,0-1.2,0-.132.036-.158.155-.113.282.106.567.205.85.308l9.461,3.425c.34.123.483.02.483-.342q0-2.435,0-4.869c0-.356,0-.356.345-.445A2.349,2.349,0,0,0,1373.6,1361.1Zm-12.773,7.226c.382-.029.381-.031.381.35v.358c0,.318-.094.387-.415.316-.071-.016-.074-.058-.074-.111,0-.267,0-.534,0-.8C1360.718,1368.356,1360.747,1368.329,1360.827,1368.323Zm-.688,4.068a1.126,1.126,0,0,1-2.251-.013q0-1.478,0-2.956c0-1,0-1.993,0-2.99,0-.151.039-.192.19-.19.638.009,1.276.006,1.914,0,.115,0,.158.026.158.151C1360.141,1368.394,1360.148,1370.393,1360.139,1372.392Zm.2-6.7c-.734-.01-1.469,0-2.2,0a3.612,3.612,0,0,1-3.633-3.123,3.6,3.6,0,0,1,3.615-4.091q1.127.009,2.255,0c.142,0,.183.04.182.182-.005,1.139,0,2.277,0,3.416s0,2.266,0,3.4C1360.553,1365.637,1360.516,1365.7,1360.337,1365.693Zm10.772-6.22v4.372q0,2.663,0,5.325c0,.208,0,.212-.2.142l-7.021-2.543c-.871-.315-1.742-.631-2.615-.939a.214.214,0,0,1-.174-.24q.008-3.519,0-7.037a.206.206,0,0,1,.16-.233q4.4-1.584,8.789-3.176l.867-.312c.184-.066.191-.062.191.132q0,1.811,0,3.621Zm.708,4.349c-.132.031-.154,0-.153-.123,0-.546,0-1.092,0-1.637s0-1.08,0-1.62c0-.128.03-.149.156-.119a1.79,1.79,0,0,1,0,3.5Z"
			transform="translate(-1353.88 -1354.078)"
		/>
	</svg>
);
