import React, { useState, ReactElement } from 'react';

/**
 * Accordion component
 * @author Konstantin Krumin
 * @param titleContent - text to be displayed in the title of the element
 * @param bodyContent - text to be displayed in the inner part of the element
 */
interface HolAccordionProps {
	titleContent?: ReactElement;
	bodyContent?: ReactElement;
}

export const HolAccordion: React.FC<HolAccordionProps> = ({ titleContent, bodyContent }) => {
	const [isActive, setActive] = useState(false);

	const handleAccordionState = (toggleState: boolean) => setActive(toggleState);

	return (
		<div className="flex flex-col flex-grow justify-start">
			<div
				className="flex group justify-between items-center rounded-md cursor-pointer hover:shadow-lg"
				onClick={() => handleAccordionState(!isActive)}
			>
				<div className="flex flex-grow group">{titleContent}</div>
			</div>

			{isActive && <div className="h-auto rounded-md p-4 mb-2">{bodyContent}</div>}
		</div>
	);
};
