import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { IAttribute, IMeasure, IRelativeDateFilter } from '@gooddata/sdk-model';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Util from 'utils';
import * as Component from 'components';

import { useTypedSelector } from 'hooks';
import { HolmetricsFactorType, HolmetricsDiagnosticType } from 'types';

import { parseKPIValue } from './helpers';
import { SplineChartValueType } from './types';

/**
 * A score display component to display spline chart
 * @author Konstantin Krumin
 * @param factorTitle - title of the factor
 * @param dateFilter - required date filter
 * @param type - current factors or diagnostics type used for coloring
 * @param seriesBy - an array of LDM measure values to be used as seriesBy
 * @param slicesBy - an array of LDM attribute values to be used as slicesBy
 * @param valueType - the type of values to be displayed in the spline chart
 * @param pageType - determines whether the chart is displayed on the overview or factors page
 */
interface SplineChartProps {
	seriesBy: IMeasure[];
	slicesBy: IAttribute[];
	valueType: SplineChartValueType;
	dateFilter: IRelativeDateFilter | null;
	pageType: 'action' | 'overview' | 'factors';
	type: HolmetricsFactorType | HolmetricsDiagnosticType;
}

export const SplineChart: React.FC<SplineChartProps> = ({
	type,
	seriesBy,
	slicesBy,
	dateFilter,
	pageType = 'overview',
	valueType = 'percentage'
}) => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	return (
		<Execute
			seriesBy={seriesBy}
			slicesBy={slicesBy}
			filters={[...demographicFilter, dateFilter]}
			onError={errInfo => {
				if (errInfo.message !== 'CANCELLED') {
					Util.logErrorMessage(errInfo);
				}
			}}
		>
			{execution => {
				const { result } = execution;

				if (!result) {
					return <LoadingComponent className="my-8" />;
				}

				const tempValue =
					valueType === 'percentage'
						? Util.createReadablePercentageValue(result)
						: Util.getLatestDataPointVal(result);
				const lastKpiValue = parseKPIValue(tempValue, valueType);

				const values = result?.rawData().data() as any[];

				const parsedValues = values.map(value => {
					if (value) {
						return valueType === 'percentage'
							? [Util.convertStrPercentageToIntNum(value.toString()).toString()]
							: [parseInt(value.toString())];
					}

					return 0;
				});

				const dates = Util.extractDatesArr(result);
				const splineData = Util.createSplineData(parsedValues, dates);

				if (execution.isLoading) {
					return <LoadingComponent className="my-8" />;
				} else {
					return (
						<div className="w-full justify-center">
							{values.length > 1 ? (
								<div className="relative w-11/12 mx-auto">
									<HighchartsReact
										highcharts={Highcharts}
										options={Util.splineChartConfig(
											splineData,
											Util.getMetricColor(lastKpiValue, type),
											pageType === 'factors' ? 60 : 120,
											valueType === 'percentage' ? -5 : -1,
											valueType === 'percentage' ? 105 : 11
										)}
									/>
								</div>
							) : (
								<div className="flex justify-center">
									<Component.HolText>No Data</Component.HolText>
								</div>
							)}
						</div>
					);
				}
			}}
		</Execute>
	);
};
