import React from 'react';

import { Execute, LoadingComponent } from '@gooddata/sdk-ui';
import { newPositiveAttributeFilter } from '@gooddata/sdk-model';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import * as Ldm from 'ldm';
import * as Util from 'utils';

import { useTypedSelector } from 'hooks';
import { IInsightDataPoint } from 'types';
import { RELATIVE_DATE_FILTERS } from 'constants/dataset';

import {
	sortArrByCodeVal,
	assignInsightsCustomColor,
	reverseArrForExceptionInsights
} from '../../helpers';
import { NoResponseText } from '../no-response';

import {
	uniqueStr,
	populateDataset,
	sortShortGDDates,
	generateDatesArr,
	createEmptyDataset,
	removeRedundantZerosFromDataset
} from './helpers';
import { HistoricInsightUnprocessedDatasetType, SentimentCategoriesWithDataType } from './types';

/**
 * Displays the insights stacked area chart
 * @author Konstantin Krumin
 * @param factorName - name for the currently selected factor
 * @param questionId - question's id to which this chart is related
 */
interface StackedAreaChartProps {
	factorName: string;
	questionId: string;
}

export const StackedAreaChart: React.FC<StackedAreaChartProps> = ({ factorName, questionId }) => {
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const TODAY_DATE = new Date();

	return (
		<Execute
			seriesBy={[Ldm.ResponseCount.Avg]}
			slicesBy={[Ldm.AnswerText, Ldm.AnswerCode]}
			filters={[newPositiveAttributeFilter(Ldm.QuestionId, [questionId])]}
		>
			{execution => {
				const { result, isLoading } = execution;

				if (isLoading) {
					return <LoadingComponent className="my-8" />;
				}

				if (result) {
					const answerTexts: string[] = result.dataView.headerItems[0][0].map(
						(item: any) => item.attributeHeaderItem.name
					);

					const answerMapTextCode: { [key: string]: string } = {};
					result.dataView.headerItems[0][1].forEach((item: any, i: number) => {
						answerMapTextCode[answerTexts[i]] = item.attributeHeaderItem.name;
					});

					return (
						<Execute
							seriesBy={[Ldm.SurveyResponsePercentHistoricalBW]}
							slicesBy={[Ldm.AnswerText, Ldm.DateDatasets.Date.MonthYear.Short]}
							filters={[
								...demographicFilter,
								newPositiveAttributeFilter(Ldm.QuestionId, [questionId]),
								newPositiveAttributeFilter(Ldm.AnswerText, answerTexts),
								RELATIVE_DATE_FILTERS.lastYear
							]}
						>
							{execution => {
								const { result, isLoading } = execution;

								const metadataArrs = result?.dataView?.headerItems?.[0];
								const optionsTextArr: string[] = (metadataArrs?.[0] as any[])?.map(
									item => item?.attributeHeaderItem?.name
								);
								const datesArr: string[] = (metadataArrs?.[1] as any[])?.map(
									item => item?.attributeHeaderItem?.name
								);

								const series = Util.extractDataSeriesInArr(result);

								const datapoints = series?.map(values =>
									values.rawData().map(value => Math.round(+(value ? value : 0)))
								)[0];

								if (optionsTextArr && datapoints) {
									const uniqueSentimentsArr = optionsTextArr.filter(uniqueStr);

									const datasetEmpty = createEmptyDataset(
										TODAY_DATE,
										uniqueSentimentsArr
									);

									// generate an array with data in same format as datasetEmpty with filled values
									const datasetFilled: HistoricInsightUnprocessedDatasetType[] =
										optionsTextArr.map((text, idx) => {
											return {
												category: text,
												date: datesArr[idx],
												value: datapoints[idx]
											};
										});

									// populate a dataset for the last 12 months (datasetEmpty) with data from datasetFilled
									const processedDataset = populateDataset(
										datasetEmpty,
										datasetFilled
									);

									// Populate sentiment categories with values
									let sentimentsWithValues: SentimentCategoriesWithDataType = {};

									uniqueSentimentsArr.forEach(uniqueSentiment => {
										sentimentsWithValues[uniqueSentiment] = { data: [] };
									});

									processedDataset.forEach(datapoint => {
										sentimentsWithValues[datapoint.category] = {
											data: [
												...sentimentsWithValues[datapoint.category]?.data,
												datapoint.value
											]
										};
									});

									const cleanedSentimentsWithValues =
										removeRedundantZerosFromDataset(sentimentsWithValues);

									const responses: IInsightDataPoint[] = uniqueSentimentsArr.map(
										sentiment => {
											return {
												name: sentiment,
												code: answerMapTextCode[sentiment],
												data: cleanedSentimentsWithValues[sentiment]
											};
										}
									);

									const dataArraysLength = responses?.[0].data.length;

									// Extract and sort dates in proper order (ex., ['Jan 2022', 'Feb 2022', ...])
									const uniqueDatesArr: string[] = datesArr?.filter(uniqueStr);
									const sortedDatesArr = sortShortGDDates(uniqueDatesArr);

									// Generate final dates array based on last date value provided in the dataset and length of datasets
									const finalDatesArr = generateDatesArr(
										sortedDatesArr[sortedDatesArr.length - 1],
										dataArraysLength
									);

									// Sort insights' values based on code values
									const sortedByCodeValueArr = sortArrByCodeVal(responses);
									const sortedWithExceptionsArr = reverseArrForExceptionInsights(
										sortedByCodeValueArr,
										factorName,
										questionId
									);

									// Add proper coloring to insights data points
									const sortedResponses = sortedWithExceptionsArr?.map(item => {
										return {
											...item,
											color: assignInsightsCustomColor(
												item.code,
												factorName,
												questionId
											)
										};
									});

									if (isLoading) {
										return <LoadingComponent className="my-8" />;
									} else {
										return sortedResponses ? (
											<HighchartsReact
												highcharts={Highcharts}
												options={Util.insightsStackedAreaChartConfig(
													sortedResponses,
													finalDatesArr
												)}
											/>
										) : (
											<NoResponseText />
										);
									}
								}

								if (isLoading) {
									return <LoadingComponent className="my-8" />;
								} else {
									return <NoResponseText />;
								}
							}}
						</Execute>
					);
				} else {
					return <NoResponseText />;
				}
			}}
		</Execute>
	);
};
