import { IAuthInfo, RoleType, IWorkspace } from 'types';

import ActionType from '../action-types';
import { SetUserAuthenticatedAction, SetUserWorkspaceRole, SetWorkspaceAction } from '../actions';

/**
 * Action for setting the current workspace id of the user.
 */
export const setCurrentWorkspace = (workspace: IWorkspace): SetWorkspaceAction => {
	return {
		type: ActionType.SET_CURRENT_WORKSPACE,
		payload: {
			workspace: workspace
		}
	};
};

/**
 * Action for setting the user info of the user.
 */
export const setUserAuthenticated = (authInfo: IAuthInfo): SetUserAuthenticatedAction => {
	return {
		type: ActionType.SET_USER_AUTHENTICATED,
		payload: {
			authInfo: authInfo
		}
	};
};

/**
 * Action for setting the user's role in a workspace.
 */
export const setUserWorkspaceRole = (userRole: RoleType): SetUserWorkspaceRole => {
	return {
		type: ActionType.SET_USER_WORKSPACE_ROLE,
		payload: {
			role: userRole
		}
	};
};
