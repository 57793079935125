import axios from 'axios';

export const serverAxios = axios.create({
	baseURL: process.env.REACT_APP_HOST,
	withCredentials: false
});

export const gooddataAxios = axios.create({
	baseURL: process.env.REACT_APP_GOODDATA_HOST_NAME,
	withCredentials: true
});
