import * as Ldm from 'ldm';

import { ToxicityType } from 'enums';

export const TOXICITY_HEATMAP_OPTIONS = [
	{ label: ToxicityType.TOXICITY, value: Ldm.Insights.DiagnosticsToxicityToxicityHeatmap },
	{
		label: ToxicityType.SEVERE_TOXICITY,
		value: Ldm.Insights.DiagnosticsToxicitySevereToxicityHeatmap
	},
	{ label: ToxicityType.PROFANITY, value: Ldm.Insights.DiagnosticsToxicityObsceneHeatmap },
	{
		label: ToxicityType.IDENTITY_ATTACK,
		value: Ldm.Insights.DiagnosticsToxicityIdentityAttackHeatmap
	},
	{ label: ToxicityType.THREAT, value: Ldm.Insights.DiagnosticsToxicityThreatHeatmap },
	{ label: ToxicityType.INSULT, value: Ldm.Insights.DiagnosticsToxicityInsultHeatmap }
];
