import { useEffect } from 'react';

import { OktaContext } from 'utils/auth';
import { gooddataAxios, serverAxios } from 'api';

/*
 * To prevent 401 errors when calling any API, the following interceptors:
 * 	gooddataResponseIntercept: refreshes the gooddata (GDCAuthTT) token
 *	serverRequestIntercept: adds the access token received from Okta authentication to the request's header as a token bearer
 */

export const useAxiosPrivate = () => {
	const { getAccessToken } = OktaContext();

	useEffect(() => {
		const gooddataResponseIntercept = gooddataAxios.interceptors.response.use(
			response => response,
			async error => {
				const prevRequest = error?.config;

				if (error?.response?.status === 401 && !prevRequest?.sent) {
					prevRequest.sent = true;

					gooddataAxios
						.get(
							`https://${process.env.REACT_APP_GOODDATA_HOST_NAME}/gdc/account/token`
						)
						.then(() => {
							return Promise.resolve(gooddataAxios(prevRequest));
						})
						.catch(err => {
							return Promise.reject(error);
						});
				}

				return Promise.reject(error);
			}
		);

		const serverRequestIntercept = serverAxios.interceptors.request.use(async req => {
			const accessToken = await getAccessToken();

			if (!req.headers['Authorization']) {
				req.headers['Authorization'] = `Bearer ${accessToken.accessToken}`;
			}

			return req;
		});

		return () => {
			gooddataAxios.interceptors.response.eject(gooddataResponseIntercept);
			serverAxios.interceptors.request.eject(serverRequestIntercept);
		};
	}, [getAccessToken]);

	return {
		gooddataAxios,
		serverAxios
	};
};
