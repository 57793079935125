import React, { useState } from 'react';
import { InsightView } from '@gooddata/sdk-ui-ext';

import * as Util from 'utils';
import * as Component from 'components';

import { IGeneralOption } from 'types';
import { useTypedSelector } from 'hooks';

import { setToxicityHeatmapColor } from './helpers';
import { TOXICITY_HEATMAP_OPTIONS } from './constants';

/**
 * Displays toxicity heatmap
 */
interface ToxicityHeatmapChartProps {}

export const ToxicityHeatmapChart: React.FC<ToxicityHeatmapChartProps> = () => {
	const [selectedToxicityHeatmap, setSelectedToxicityHeatmap] = useState<IGeneralOption>(
		TOXICITY_HEATMAP_OPTIONS[0]
	);

	const currentDiagnostics = useTypedSelector(state => state?.diagnostics);
	const selectedDemographics = useTypedSelector(state => state?.demographics.filter);

	const diagnosticDateFilter = currentDiagnostics?.dateFilter;
	const demographicFilter = Util.getDemographicFilter(selectedDemographics);

	const filtersArr = [diagnosticDateFilter.relativeDateFilter, ...demographicFilter];

	const handleToxicityHeatmapChange = (selectedValue: IGeneralOption | null) => {
		if (selectedValue) setSelectedToxicityHeatmap(selectedValue);
	};

	return (
		<Component.HolCard
			title="Toxicity Heatmap"
			styles="print-page-break"
			columns="col-span-full"
			titleElement={
				<Component.HolSingleSelectDropdown
					textSize="text-md"
					borderRadius="rounded-xl"
					options={TOXICITY_HEATMAP_OPTIONS}
					selectedOption={selectedToxicityHeatmap}
					handleSelect={handleToxicityHeatmapChange}
				/>
			}
		>
			<div className="mx-auto md2:p-8">
				<div className="px-2 h-full">
					<InsightView
						filters={filtersArr}
						insight={selectedToxicityHeatmap.value as string}
						config={setToxicityHeatmapColor(selectedToxicityHeatmap.label)}
					/>
				</div>
			</div>
		</Component.HolCard>
	);
};
