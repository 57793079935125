import { ICircleAdditionalParams } from 'types';

/**
 * Circle element
 * @param size - size of the cicle
 * @param color - color of the circle
 * @param circleAdditionalParams - additional parameters of the circle shape
 * @param percentage - percentage indicated the area of the circle that needs to be filled
 */
interface CircleProps {
	circleAdditionalParams: ICircleAdditionalParams;
	percentage: number;
	color: string;
	size: number;
}

export const Circle: React.FC<CircleProps> = ({
	size,
	color,
	percentage,
	circleAdditionalParams
}) => {
	const r = circleAdditionalParams.radius;
	const circ = 2 * Math.PI * r;
	const strokePct = ((100 - percentage) * circ) / 100;
	const center = size / 2;
	return (
		<circle
			r={r}
			cx={center}
			cy={center}
			fill="transparent"
			stroke={strokePct !== circ ? color : ''} // remove color as 0% sets full circumference
			strokeWidth={circleAdditionalParams.strokeWidth}
			strokeDasharray={circ}
			strokeDashoffset={percentage ? strokePct : 0}
			strokeLinecap="round"
		></circle>
	);
};
