import { useOktaAuth } from '@okta/okta-react';
import { AccessToken, IDToken } from '@okta/okta-auth-js';

import * as Util from 'utils';

export const OktaContext = () => {
	const { oktaAuth } = useOktaAuth();

	const validateAccess = () => {
		getIdToken()
			.then(token => {
				if (token) {
					return;
				} else {
					oktaLogin();
				}
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const oktaLogin = () => {
		oktaAuth.tokenManager.clear();

		oktaAuth.setOriginalUri(window.location.href);
		oktaAuth.signInWithRedirect();
	};

	const oktaLogout = () => {
		oktaAuth
			.revokeAccessToken()
			.then(() => {
				oktaAuth.tokenManager.clear();
				oktaAuth.signOut();
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const loginCallback = () => {
		oktaAuth.token
			.parseFromUrl()
			.then(res => {
				const { idToken, accessToken } = res.tokens;
				const invite_id = oktaAuth.getOriginalUri()?.split('invite=')[1];

				if (idToken) {
					oktaAuth.tokenManager.add('id_token', idToken);
				}

				if (accessToken) {
					oktaAuth.tokenManager.add('access_token', accessToken);
				}

				window.location.replace(
					`${process.env.REACT_APP_OKTA_SAML_LOGIN}?RelayState=${
						process.env.REACT_APP_OKTA_REDIRECT
					}/?redirected=true${invite_id ? '%26invite=' + invite_id : ''}`
				);
			})
			.catch(err => Util.logErrorMessage(err));
	};

	const handleRedirect = () => {
		if (oktaAuth.isLoginRedirect()) {
			loginCallback();
		} else {
			oktaLogin();
		}
	};

	const getIdToken = () => {
		return oktaAuth.tokenManager.get('id_token') as Promise<IDToken>;
	};

	const getAccessToken = () => {
		return oktaAuth.tokenManager.get('access_token') as Promise<AccessToken>;
	};

	return {
		oktaLogin,
		oktaLogout,
		getIdToken,
		loginCallback,
		handleRedirect,
		getAccessToken,
		validateAccess
	};
};
