import { IRelativeDateFilter } from '@gooddata/sdk-model';

import * as Util from 'utils';

import { DiagnosticsOptions } from 'enums';
import { DataViewType, ICurrentDiagnostic, IDemographicSelected } from 'types';

import { Action } from '../actions';
import ActionType from '../action-types';

/**
 * This is a reducer to set diagnostics page
 * @author Konstantin Krumin
 */

interface DiagnosticState {
	demographics: IDemographicSelected[];
	currentDiagnostics: ICurrentDiagnostic;
	dateFilter: {
		relativeDateFilter: IRelativeDateFilter;
		dataView: DataViewType;
	};
}

const initialState: DiagnosticState = {
	demographics: [],
	currentDiagnostics: { label: 'Chats', value: DiagnosticsOptions.CHATS },
	dateFilter: {
		relativeDateFilter: Util.getFilterDatebyDataView('Year'),
		dataView: 'Year'
	}
};

const diagnosticFilterReducer = (state: DiagnosticState = initialState, action: Action) => {
	switch (action.type) {
		case ActionType.SET_CURRENT_DIAGNOSTICS:
			const currentDiagnostics = action.payload.currentDiagnostics;
			return { ...state, currentDiagnostics };
		case ActionType.SET_DIAGNOSTICS_DATE_FILTER:
			const dataView = action.payload.dataView;
			return {
				...state,
				dateFilter: {
					relativeDateFilter: Util.getFilterDatebyDataView(dataView),
					dataView
				}
			};
		default:
			return state;
	}
};

export default diagnosticFilterReducer;
